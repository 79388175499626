import { useQuery } from 'react-query';
import { getTransactions } from 'helpers/http/common';

/*
 * This hook will load all the payment transactions
 */

type Props = { page: number; limit: number; activeFilter: string };

function useTransactions({ page, limit, activeFilter }: Props) {
  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['get-transactions', page, limit, activeFilter],
    () => getTransactions({ page, limit, activeFilter }),
    { keepPreviousData: true }
  );
  return {
    transactionsData: data?.data?.transactionData
      ? data?.data?.transactionData
      : [],
    availableAmount: data?.data?.availableAmount || 0,
    totalZehmizehCut: data?.data?.zehmizeh_cat || 0,
    totalZehmizehProfit: data?.data?.zehmizeh_profit || 0,
    totalResults: data?.data?.total,
    isLoading,
    isRefetching,
    refetch,
  };
}

export default useTransactions;
