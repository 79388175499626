import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { ReactComponent as GreenTickIcon } from 'assets/icons/tick-green.svg';
import { ReactComponent as FlagIcon } from 'assets/icons/flag.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cancel-red.svg';
import { ReactComponent as ClientsIcon } from 'assets/icons/clients.svg';
import { ReactComponent as UnderReviewIcon } from 'assets/icons/under-review.svg';
import { createPortal } from 'react-dom';

const UserDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background: none;
    border: none;
    color: inherit;
    &::after {
      display: none;
    }
    padding: 4px 2px;
  }
  border-radius: 5px;
  .dropdown-menu {
    margin-top: 10px;
    border: 0;
    box-shadow: 0 0 15px rgb(0 0 0 / 25%);
    padding: 4px 20px 24px;
    border-radius: 0.5rem;
    .dropdown-item {
      background-color: transparent;
      padding: 0px;
      &:hover {
        transform: scale(1.05);
        transition: 0.1s ease-in;
      }
      margin-top: 20px;
    }
  }
`;

type Props = {
  isFlagged: boolean;
  onFlag: () => void;
  onApprove: () => void;
  onDecline: () => void;
  onLoginAsUser: () => void;
  onSetUnderReview: () => void;
  disabled?: boolean;
  isLoginAsUser?: number | boolean;
  showLoginAsUser?: boolean;
  withPortal?: boolean;
};

const MoreButton = ({
  onApprove,
  onDecline,
  onLoginAsUser,
  onSetUnderReview,
  disabled,
  isLoginAsUser,
  showLoginAsUser = true,
  isFlagged,
  onFlag,
  withPortal = false,
}: Props) => {
  const DropdownMenu = (
    <Dropdown.Menu align="end" renderOnMount={false}>
      {isLoginAsUser == null && (
        <>
          <Dropdown.Item
            className="d-flex align-items-center fs-1rem fw-400"
            onClick={onApprove}
          >
            <GreenTickIcon />
            <span className="ms-2">Approve</span>
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex align-items-center fs-1rem fw-400"
            onClick={onDecline}
          >
            <CrossIcon /> <span className="ms-2">Decline</span>
          </Dropdown.Item>
        </>
      )}
      {isLoginAsUser == 0 && (
        <>
          <Dropdown.Item
            className="d-flex align-items-center fs-1rem fw-400"
            onClick={onApprove}
          >
            <GreenTickIcon /> <span className="ms-2">Approve</span>
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex align-items-center fs-1rem fw-400"
            onClick={onSetUnderReview}
          >
            <UnderReviewIcon /> <span className="ms-2">Set Under Review</span>
          </Dropdown.Item>
        </>
      )}
      <Dropdown.Item
        className="d-flex align-items-center fs-1rem fw-400"
        onClick={onFlag}
      >
        <FlagIcon />{' '}
        <span className="ms-2">{isFlagged ? 'Remove flag' : 'Flag'}</span>
      </Dropdown.Item>
      {/* Flag to show "Login as user" option. By default set to true */}
      {showLoginAsUser && (
        <Dropdown.Item
          className="d-flex align-items-center fs-1rem fw-400"
          onClick={onLoginAsUser}
        >
          <ClientsIcon /> <span className="ms-2">Login as User</span>
        </Dropdown.Item>
      )}
    </Dropdown.Menu>
  );

  return (
    <UserDropdown>
      <Dropdown.Toggle disabled={disabled}>
        <div className="pointer">
          <MoreIcon />
        </div>
      </Dropdown.Toggle>
      {/* Added Portal because of zindex issue on table. Other 3 dots row were showing above dropdown menu */}
      {withPortal
        ? createPortal(
            DropdownMenu,
            document.querySelector('#more-button-dropdown-menu') ||
              document.body
          )
        : DropdownMenu}
    </UserDropdown>
  );
};

export default MoreButton;
