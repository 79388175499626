import 'react-phone-number-input/style.css';
import PhoneInput, {
  formatPhoneNumber,
  Country,
} from 'react-phone-number-input';
import { useEffect, useState } from 'react';
import { fetchCountriesCode } from 'helpers/http/common';

interface Props {
  onChange?: (phone: string, formattedValue: string) => void;
  initialValue?: string;
}
const PhoneNumberInput = ({ onChange, initialValue }: Props) => {
  const [phone, setPhone] = useState<string>('');
  const [countries, setCountries] = useState<Country[]>([]);

  const getCountriesHandler = async () => {
    const resp = await fetchCountriesCode();
    if (!Array.isArray(resp)) return;
    setCountries(() => resp.map((res) => res.toLocaleUpperCase()));
  };

  useEffect(() => {
    getCountriesHandler();
  }, []);

  useEffect(() => {
    if (initialValue) setPhone(initialValue);
  }, [initialValue]);

  return (
    <>
      {!!countries.length && (
        <PhoneInput
          placeholder="Enter phone number"
          value={phone}
          initialValueFormat="national"
          onChange={(value) => {
            onChange &&
              onChange(formatPhoneNumber(phone), value?.toString() || '');
            setPhone(value?.toString() || '');
          }}
          countries={countries}
          defaultCountry={countries[0]}
          international
          countryCallingCodeEditable={false}
          className="common-phone-number-input"
          limitMaxLength
        />
      )}
    </>
  );
};

export default PhoneNumberInput;
