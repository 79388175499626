import { useEffect, useState } from 'react';
import { useCategories } from './categoryProvider';
import { StyledTable } from 'components/styled/StyledTable';
import moment from 'moment';
import { CiTrash } from 'react-icons/ci';
import { StyledButton } from 'components/forms/Buttons';
import CategoryModal from './categoryModal';
import { DeleteCategoryPrompt } from './delete_category_prompt';

const CategoryTable = ({ showModal, onCloseHandler, setShowModal }: any) => {
  const { categories, fetchCategories } = useCategories();
  const [category, setCategory] = useState<any>({});
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!show) setCategory({});
  }, [show]);

  return (
    <div>
      <CategoryModal
        show={showModal}
        onClose={() => {
          setCategory({});
          onCloseHandler('category');
        }}
        category={category}
      />

      <DeleteCategoryPrompt show={show} setShow={setShow} category={category} />

      <StyledTable responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Freelancers</th>
            <th>Total Skills</th>
            <th>Projects Posted</th>
            <th>Projects Opened</th>
            <th>Date Created</th>
            <th>Action</th>
          </tr>
        </thead>

        {/*
         * Table body and main listing of freelancers
         */}
        <tbody>
          {!!categories?.length &&
            categories?.map((cat, index) => {
              return (
                <tr key={`cat-${index}`}>
                  <td>{cat.id}</td>
                  <td className="text-capitalize">{cat.name}</td>
                  <td>{cat?.freelancer_count ?? 0}</td>
                  <td>{cat?.skills?.length ?? 0}</td>
                  <td>{cat?.job_count ?? 0}</td>
                  <td>{cat?.open_projects_count ?? 0}</td>
                  <td>
                    {moment(cat?.date_created).format('MMM DD, YYYY') || '-'}
                  </td>
                  <td className="d-flex align-items-center justify-content-center gap-3">
                    <StyledButton
                      className="text-white text-capitalize"
                      style={{
                        padding: '5px 1.2rem',
                        fontSize: '14px',
                        minHeight: 'unset',
                        borderRadius: '8px',
                      }}
                      onClick={() => {
                        setCategory(cat);
                        setShowModal(true);
                      }}
                    >
                      Edit
                    </StyledButton>
                    <CiTrash
                      fill="#de1f1f"
                      size={30}
                      onClick={() => {
                        setCategory(cat);
                        setShow(true);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </StyledTable>
    </div>
  );
};

export default CategoryTable;
