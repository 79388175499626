import { yupResolver } from '@hookform/resolvers/yup';
import { StyledModal } from 'components/styled/StyledModal';
import * as yup from 'yup';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ErrorMessage from 'components/ui/ErrorMessage';
import { StyledButton } from 'components/forms/Buttons';
import { useEffect, useState } from 'react';
import LoadingButtons from 'components/LoadingButtons';
import { addSkillApi } from 'helpers/http/common';
import toast from 'react-hot-toast';
import { useCategories } from './categoryProvider';
import AsyncSelect from 'react-select/async';

const SkillModal = ({ show, onClose, skill }: any) => {
  const { fetchCategories } = useCategories();
  const [loading, setLoading] = useState(false);
  const schema = yup.object({
    skill_name: yup.string().required('Skill name is required.'),
    category_ids: yup
      .array()
      .of(yup.number())
      .min(1, 'Please select atleast one category.')
      .required('Please select atleast one category.'),
  });

  const { register, handleSubmit, formState, reset, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const onSubmit = async (payload) => {
    setLoading(true);
    payload.action = skill.id ? 'edit_skill' : 'add_skill';
    if (skill.id) {
      payload.skill_id = skill.id;
    }
    const promise = addSkillApi(payload);

    toast.promise(promise, {
      loading: 'Loading...',
      success: (res) => {
        setLoading(false);
        onClose('skill');
        return res?.message ?? 'Success';
      },
      error: (err) => {
        setLoading(false);
        onClose('skill');
        return err?.response?.data?.message || 'error';
      },
    });
  };

  const categoryLoadHandler = async (keyword = '') => {
    const categories = await fetchCategories(keyword);
    const options = categories.map((cat) => {
      return {
        label: cat.name,
        value: cat.id,
      };
    });

    return options;
  };

  const onSelectChange = (categories) => {
    const categories_ids = categories.map((dt) => dt.value);
    setValue('category_ids', categories_ids);
  };

  const defaultSelectOptionsHandler = () => {
    let default_categories = [];
    if (skill?.categories && Array.isArray(skill?.categories))
      default_categories = skill?.categories?.map((cat) => {
        return {
          label: cat.name,
          value: cat.id,
        };
      });

    onSelectChange(default_categories);
    return default_categories;
  };

  useEffect(() => {
    if (!show) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (skill.name) setValue('skill_name', skill.name);
    else reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skill]);

  return (
    <StyledModal show={show} size="lg" onHide={onClose} centered maxwidth={590}>
      <Modal.Body>
        <Button variant="transparent" className="close" onClick={onClose}>
          &times;
        </Button>
        <div className="fs-24 fw-700 d-flex align-items-center mb-4">
          {skill?.id ? 'Edit' : 'Add'} Skill
        </div>

        <Form
          className="form d-flex flex-column"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <label className="fs-1rem fw-400 mb-2">Skill Name</label>
            <Form.Control type="text" {...register('skill_name')} />
            <ErrorMessage>{errors.skill_name?.message}</ErrorMessage>
          </div>

          <div className="mt-4">
            <label className="fs-1rem fw-400 mb-2">Select Categories</label>
            <AsyncSelect
              isMulti
              defaultOptions
              loadOptions={categoryLoadHandler}
              isSearchable
              onChange={onSelectChange}
              defaultValue={defaultSelectOptionsHandler}
            />
            <ErrorMessage>{errors.category_ids?.message}</ErrorMessage>
          </div>

          <StyledButton
            className="mt-5"
            style={{
              padding: '1.125rem 5rem',
              maxWidth: '60%',
              margin: 'auto',
            }}
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <LoadingButtons />
            ) : (
              `${skill?.id ? 'Edit' : 'Add'} Skill`
            )}
          </StyledButton>
        </Form>
      </Modal.Body>
    </StyledModal>
  );
};

export default SkillModal;
