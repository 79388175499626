/*
 * This is the end job error modal - this will be opened when any error comes while ending the job
 */
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledModal } from 'components/styled/StyledModal';
import { ReactComponent as ErrorIcon } from 'assets/icons/error-orange-icon.svg';

type Props = {
  show: boolean;
  toggle: () => void;
  error?: string;
};

const Content = styled.div`
  .description {
    opacity: 0.63;
  }
`;

const EndJobModal = ({ show, toggle, error }: Props) => {
  return (
    <StyledModal maxwidth={540} show={show} size="lg" onHide={toggle} centered>
      <Modal.Body>
        <Button variant="transparent" className="close" onClick={toggle}>
          &times;
        </Button>
        <Content className="d-flex flex-column justify-content-center align-items-center">
          <ErrorIcon />
          <div className="description fs-20 fw-400 text-center">
            {error ||
              'Please complete/close all the remaining milestones in order to end the job.'}
          </div>
        </Content>
      </Modal.Body>
    </StyledModal>
  );
};

export default EndJobModal;
