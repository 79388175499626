import styled from 'styled-components';
import { ReactComponent as NoDataFoundIcon } from 'assets/icons/nodatafound.svg';

const NoDataWrapper = styled.div`
  height: -webkit-fill-available;
  .description {
    margin-top: 1.625rem;
  }
`;

const NoDataFound = ({ className }: { className?: string }) => {
  return (
    <NoDataWrapper
      className={`d-flex flex-column justify-content-center align-items-center ${className}`}
    >
      <NoDataFoundIcon />
      <div className="description fs-16 fw-400">No data found</div>
    </NoDataWrapper>
  );
};

export default NoDataFound;
