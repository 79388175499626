import { TUTMFilters } from 'components/ui/columnOptions.types';
import { apiClient } from 'helpers/http';
import React, { ReactNode, useEffect } from 'react';
import { useQuery } from 'react-query';

type TUTMContext = {
  filters: TUTMFilters;
  updateFilters: (newFilter: Partial<TUTMFilters>) => void;
  data?: {
    utm_id: string;
    utm_term: string;
    utm_medium: string;
    utm_source: string;
    utm_content: string;
    utm_campaign: string;
    total_clients: number;
    total_freelancers: number;
  }[];
  isLoading: boolean;
  isRefetching: boolean;
  refetch: () => void;
  totalPages: number;
};

const initialContextValue: TUTMContext = {
  filters: {
    page: 1,
    limit: 10,
    sortBy: '',
    sortOrder: '',
    searchBy: '',
    searchText: '',
  },
  data: undefined,
  isLoading: false,
  isRefetching: false,
  refetch: () => {
    //
  },
  totalPages: 0,
  updateFilters: () => {
    //
  },
};

const UTMContext = React.createContext(initialContextValue);

export const getUtmInfo = (params) => {
  const [, filters] = params.queryKey;
  const query = new URLSearchParams(filters).toString();
  return apiClient
    .get(`/admin/utm/get?${query}`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const UTMProvider = ({ children }: { children: ReactNode }) => {
  const queryParamsFilters = Object.fromEntries(
    new URLSearchParams(location.search)
  );

  const [filters, seTUTMFilters] = React.useState<TUTMContext['filters']>({
    ...initialContextValue.filters,
    ...queryParamsFilters,
  });

  const { data, isLoading, isRefetching, refetch } = useQuery<{
    data: TUTMContext['data'];
    total: number;
  }>(['utm', filters], getUtmInfo, {
    enabled: !!filters,
    keepPreviousData: true,
  });

  const updateFilters = React.useCallback<TUTMContext['updateFilters']>(
    (newFilter) => {
      seTUTMFilters((prev) => ({ ...prev, ...newFilter }));
    },
    []
  );

  const value = React.useMemo<TUTMContext>(() => {
    return {
      filters,
      updateFilters,
      data: data?.data,
      isLoading,
      isRefetching,
      refetch,
      totalPages: data?.total ? Math.ceil(data?.total / 10) : 0,
    };
  }, [
    data?.data,
    data?.total,
    filters,
    isLoading,
    isRefetching,
    refetch,
    updateFilters,
  ]);

  // Set query params to url
  useEffect(() => {
    const queryParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key]) queryParams.set(key, filters[key]);
    });

    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname;

    if (queryParams.toString())
      newurl = newurl.concat('?' + queryParams.toString());

    window.history.replaceState({ push: newurl }, '', newurl);
  }, [filters]);

  return <UTMContext.Provider value={value}>{children}</UTMContext.Provider>;
};

function useUtm() {
  return React.useContext(UTMContext);
}

export { UTMProvider, useUtm };
