interface PaymentStatusEnumTypes {
  [propertyName: string]: {
    color: string | any;
    label: string;
  };
}

export const JOB_STATUS: PaymentStatusEnumTypes = {
  active: {
    color: 'blue',
    label: 'Work In Progress',
  },
  closed: {
    color: 'green',
    label: 'Closed',
  },
};

export type TStripeStatus =
  | 'pending'
  | 'currently_due'
  | 'pending_verification'
  | 'bank_account_pending'
  | 'verified';

export const STRIPE_ACCOUNT_STATUS = {
  BANK_ACCOUNT_PENDING: 'bank_account_pending',
  PENDING_VERIFICATION: 'pending_verification',
  CURRENTLY_DUE: 'currently_due',
  VERIFIED: 'verified',
  PENDING: 'pending',
} as const;

export const STRIPE_ACCOUNT_STATUS_COLORS = {
  pending: {
    color: 'yellow',
    label: 'Pending',
    description: 'Not started.',
  },
  verified: {
    color: 'green',
    label: 'Verified',
    description: 'Verification Successful.',
  },
  currently_due: {
    color: 'darkPink',
    label: 'Currently Due',
    description: 'New Submissions Needed.',
  },
  bank_account_pending: {
    color: 'gray',
    label: 'Bank Account Pending',
    description: 'Pending add bank account details',
  },
  pending_verification: {
    color: 'gray',
    label: 'Pending Verification',
    description: 'The information user submitted is being verified.',
  },
};

export const STRIPE_ACCOUNT_STATUS_USER_FACING = {
  NOT_STARTED: 'Not started',
  NEW_SUBMISSIONS_NEEDED: 'New Submissions Needed',
  VERIFYING_INFO: 'Verifying Info',
  VERIFIED: 'Verified',
  BANK_ACCOUNT_PENDING: 'Bank Account Pending',
};

export const FREELANCER_ACCOUNT_STATUS = {
  UNDER_REVIEW: 'Under Review',
  DELETED: 'Deleted',
  DECLINED: 'Declined',
  APPROVED: 'Approved',
};

export const COMPLETED_PROFILE_PERCENTAGE = {
  '0': '0',
  '25': '25',
  '50': '50',
  '75': '75',
  '100': '100',
};
export const NOTE_MAX_CHARACTERS = 1000;

export const MORE_BUTTON_ID = 'MORE_BUTTON';
