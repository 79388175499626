import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import BackButton from 'components/ui/BackButton';
import { Wrapper } from './job-details.styled';
import useJobDetails from './hooks/useJobDetails';
import DetailsBanner from './partials/DetailsBanner';
import Loader from 'components/Loader';
import Tabs from 'components/ui/Tabs';
import Milestones from './partials/Milestones';
import GeneralDetails from './partials/GeneralDetails';
import { TABS } from './consts';
import JobEndDetailsModal from './JobEndDetailsModal';
import JobEndedSuccessModal from './JobEndedSuccessModal';
import EndJobErrorModal from './EndJobErrorModal';
import EndJobStatusModal from './EndJobStatusModal';
import { StyledButton } from 'components/forms/Buttons';

const JobDetails = () => {
  const { userType, jobId, id } = useParams<{
    userType: string;
    jobId: string;
    id: string;
  }>();

  const [showEndJobErrorModalState, setShowEndJobErrorModalState] = useState<{
    show: boolean;
    error: string;
  }>({
    show: false,
    error: '',
  });
  const [showJobEndDetailsModal, setShowJobEndDetailsModal] =
    useState<boolean>(false);
  const [showJobCloseModal, setShowJobCloseModal] = useState<boolean>(false);
  const [endJobSuccessModal, setEndJobSuccessModal] = useState<boolean>(false);
  const { jobData, isLoading, refetch, isRefetching } = useJobDetails(
    jobId,
    id
  );

  const [activeTab, setActiveTab] = useState<string>('gen_details');

  const onTabChange = (id: string) => {
    /* This function will make the selected tab as active and change the below content */
    setActiveTab(id);
  };

  const toggleJobEndDetailsModal = () => {
    setShowJobEndDetailsModal(!showJobEndDetailsModal);
  };

  const toggleJobEndModal = () => {
    setShowJobCloseModal(!showJobCloseModal);
  };

  const toggleEndJobSuccessModal = () => {
    setShowJobCloseModal(false);
    setEndJobSuccessModal(!endJobSuccessModal);
    refetch();
  };

  const onEndJobError = (msg: string) => {
    setShowJobCloseModal(false);
    setShowEndJobErrorModalState({ show: true, error: msg });
  };

  const closeEndJobErrorModal = () => {
    setShowEndJobErrorModalState({
      show: false,
      error: '',
    });
  };

  return (
    <Wrapper>
      <BackButton
        propsElement={
          isRefetching ? (
            <Spinner size="sm" animation="border" className="ms-2" />
          ) : jobData ? (
            <div className="ms-2 fs-18 fw-400">
              <span className="text-capitalize">| {userType}</span>
              {userType === 'clients' ? (
                <span className="text-capitalize">{` / ${jobData?.clientdata?.first_name} ${jobData?.clientdata?.last_name}`}</span>
              ) : (
                <span className="text-capitalize">{` / ${jobData?.freelancerdata?.first_name} ${jobData?.freelancerdata?.last_name}`}</span>
              )}
              <span>{` / ${jobData?.job_title}`}</span>
            </div>
          ) : null
        }
      />

      {isLoading && <Loader />}

      {jobData && (
        <>
          <DetailsBanner jobDetails={jobData} userType={userType || ''} />
          <div className="d-flex flex-row justify-content-between align-items-center flex-wrap gap-3 my-4">
            <Tabs tabs={TABS} activeTab={activeTab} onTabChange={onTabChange} />
            {jobData?.status === 'closed' && (
              <div
                className="job-end-details pointer"
                onClick={toggleJobEndDetailsModal}
              >
                View job end details
              </div>
            )}
            {jobData?.status === 'active' && (
              <StyledButton variant="outline-dark" onClick={toggleJobEndModal}>
                End Job
              </StyledButton>
            )}
          </div>

          {activeTab == 'gen_details' && <GeneralDetails data={jobData} />}

          {activeTab == 'milestones' && (
            <Milestones milestones={jobData?.milestone} />
          )}
        </>
      )}
      <JobEndDetailsModal
        show={showJobEndDetailsModal}
        onCloseModal={toggleJobEndDetailsModal}
        jobCloseDetails={jobData}
      />

      <EndJobStatusModal
        show={showJobCloseModal}
        toggle={toggleJobEndModal}
        jobId={jobData?.job_post_id}
        onEndJob={toggleEndJobSuccessModal}
        onError={onEndJobError}
      />

      <JobEndedSuccessModal
        show={endJobSuccessModal}
        toggle={toggleEndJobSuccessModal}
      />
      <EndJobErrorModal
        show={showEndJobErrorModalState.show}
        toggle={closeEndJobErrorModal}
        error={showEndJobErrorModalState.error}
      />
    </Wrapper>
  );
};

export default JobDetails;
