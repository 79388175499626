/*
 * This component serves the banner od the profile page on freelancer side
 */
import { ProfileBannerWrapper } from '../client-profile.styled';
import { separateValuesWithComma } from 'helpers/utils/misc';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location-blue.svg';
import { ReactComponent as CallIcon } from 'assets/icons/call.svg';
import { StatusBadge } from 'components/styled/Badges';
import { useState } from 'react';
import { EditBasicDetailsModal } from 'pages/freelancer-profile/partials/EditBasicDetailsModal';

const ProfileBanner = ({
  data,
  refetch,
}: {
  data: any;
  refetch: () => void;
}) => {
  const [isEditBasicDetailsModalOpen, setIsEditBasicDetailsModalOpen] =
    useState(false);
  return (
    <>
      <ProfileBannerWrapper className="d-flex flex-wrap justify-content-between mt-4">
        <div className="profile__img-and-details d-flex flex-wrap align-items-center">
          {/* Profile picture */}

          <div className="profile__img">
            <img className="img" src={data?.user_image} />
          </div>

          <div className="profile__details d-flex flex-column">
            {/* Name and designation */}

            <div className="profile__name-title">
              <div className="profile__username fs-24 fw-400">
                {data.first_name} {data?.last_name}
              </div>
              {data.company_name && (
                <div className="profile__description fs-18 fw-400">
                  {data.company_name}
                </div>
              )}
            </div>

            {/* Contact details */}

            <div className="profile__contact d-flex align-items-center flex-wrap">
              <div className="profile__contact-item d-flex align-items-center gap-2">
                <EmailIcon />
                <div className="profile__contact-item-value fs-18 fw-400">
                  {data?.u_email_id}
                </div>
              </div>
              <div className="divider d-none d-lg-block" />
              <div className="profile__contact-item d-flex align-items-center">
                <CallIcon />
                <div className="profile__contact-item-value fs-18 fw-400">
                  {data?.formatted_phonenumber || data?.phone_number}
                </div>
              </div>
            </div>

            {/* Location*/}
            {(data?.location?.state || data?.location?.country_name) && (
              <div className="budget-and-location d-flex align-items-center flex-wrap">
                <div className="profile__badge d-flex align-items-center">
                  <LocationIcon />
                  <div className="budget-and-location-gray-text fs-1rem fw-400">
                    {separateValuesWithComma([
                      data?.location?.state,
                      data?.location?.country_name,
                    ])}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-center gap-4">
          {!data?.is_deleted && (
            <span
              className="mt-2 pointer text-primary"
              onClick={() => setIsEditBasicDetailsModalOpen(true)}
            >
              Edit
            </span>
          )}
        </div>
        {data?.is_deleted ? (
          <div>
            <StatusBadge color="gray">Deleted</StatusBadge>
          </div>
        ) : null}
        {isEditBasicDetailsModalOpen && (
          <EditBasicDetailsModal
            data={data}
            show={isEditBasicDetailsModalOpen}
            onClose={() => {
              refetch();
              setIsEditBasicDetailsModalOpen(false);
            }}
          />
        )}
      </ProfileBannerWrapper>
    </>
  );
};

export default ProfileBanner;
