import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from 'helpers/services/auth';

function useRedirectToDashboardIfLoggedIn(path?: string) {
  const navigate = useNavigate();
  const token = getToken();
  useEffect(() => {
    if (token) {
      navigate(path || '/dashboard');
    }
  }, [navigate, path, token]);
}

export default useRedirectToDashboardIfLoggedIn;
