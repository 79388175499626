import { Row, Col } from 'react-bootstrap';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Spinner } from 'react-bootstrap';
import Tabs from 'components/ui/Tabs';
import { Wrapper } from './client-profile.styled';
import ProfileBanner from './partials/ProfileBanner';
import Jobs from './partials/Jobs';
import PaymentInformation from './partials/PaymentInformation';
import Loader from 'components/Loader';
import BackButton from 'components/ui/BackButton';
import DeleteAccountPrompt from 'components/profile/DeleteAccountPrompt';
import ClientStats from './partials/ClientStats';
import Reviews from './partials/Reviews';
import useClientProfile from './hooks/useClientProfile';
import { deleteUser } from 'helpers/http/common';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import Messages from './Messages';
import { AdminNotes } from 'components/AdminNotes';
import { UTMInfoUI } from 'components/UTMInfoUI';

const TABS = [
  {
    id: 0,
    label: 'General Details',
    key: 'general-details',
  },
  {
    id: 1,
    label: 'Messages',
    key: 'message-history',
  },
  {
    id: 2,
    key: 'admin-notes',
    label: 'Admin Notes',
  },
];

const ClientProfile = () => {
  useStartPageFromTop();

  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false);
  const [showRatingsModal, setShowRatingsModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('general-details');

  const { id } = useParams<{ id: string }>();

  /* This will fetch client profile */
  const { data, isLoading, isRefetching, refetch } = useClientProfile(id);

  /** @function This function will take the user one step back */
  const goBack = useCallback(() => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  }, [navigate]);

  const deleteAccount = () => {
    /* This function will delete the user account */

    if (id && !isDeleting) {
      setIsDeleting(true);

      /* API call to delete the user account */
      const promise = deleteUser(id);

      toast.promise(promise, {
        loading: 'Loading...',
        success: (res) => {
          /* Once account is deleted, it will take the user one step back */
          toggleDeleteAccountPrompt();
          goBack();
          setIsDeleting(false);
          return res.message;
        },
        error: (err) => {
          setIsDeleting(false);
          toggleDeleteAccountPrompt();
          return err?.response?.data?.message || 'error';
        },
      });
    }
  };

  /* This will open/close the ratings (reviews) modal */
  const toggleRatingsModal = () => {
    setShowRatingsModal(!showRatingsModal);
  };

  /* This will open/close the ratings (reviews) modal */
  const toggleDeleteAccountPrompt = () => {
    setShowDeletePrompt((prev) => !prev);
  };

  const onTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const tabs = useMemo(() => {
    let temp: (typeof TABS)[0][] = [...TABS];

    if (temp.findIndex((x) => x.key === 'utm_info') >= 0) return temp;

    if (Object.keys(data?.utm_info || {}).length > 0) {
      temp = [...temp, { id: 10, key: 'utm-info', label: 'UTM Info' }];
    }
    return temp;
  }, [data?.utm_info]);

  return (
    <Wrapper className="mt-3">
      <div className="d-flex align-items-center justify-content-between pointer flex-wrap gap-2">
        <BackButton
          propsElement={
            <div className="d-flex align-items-center pointer flex-wrap fs-18 fw-400">
              &nbsp; | &nbsp;
              {data && `Clients / ${data?.first_name} ${data?.last_name}`}
              {isRefetching && (
                <Spinner animation="border" size="sm" className="ms-2" />
              )}
            </div>
          }
        />

        {/* Delete account button */}
        {data && !data?.is_deleted ? (
          <div
            className={`d-flex align-items-center ${
              isDeleting ? 'opacity-4' : ''
            }`}
            onClick={toggleDeleteAccountPrompt}
          >
            <TrashIcon className="mx-2" />
            <div className="fs-1rem fw-400">Delete account</div>
          </div>
        ) : null}
      </div>
      {isLoading && <Loader />}
      {/* Profile Details Banner */}
      {!isLoading && data && <ProfileBanner data={data} refetch={refetch} />}
      {/* Tabs */}
      {!isLoading && data && (
        <div className="my-4">
          <Tabs tabs={tabs} activeTab={activeTab} onTabChange={onTabChange} />
        </div>
      )}
      {activeTab === 'general-details' && !isLoading && data && (
        <Row className="mt-4">
          <Col lg={12} className="mb-4">
            {/* Client stat (counts) */}
            <ClientStats
              jobsDone={data?.done_jobs}
              toalEarnings={data?.totalearning}
              ratings={data?.avg_rating}
              reviewsCount={data?.count_rating}
              onRatingsClick={toggleRatingsModal}
            />
          </Col>

          {/* Client Jobs Listings */}
          <Col lg={6} className="mb-3">
            <Jobs
              data={data?.jobData}
              clientId={id}
              clientName={data?.first_name + ' ' + data?.last_name}
            />
          </Col>

          {/* Payment details */}
          <Col lg={6} className="mb-3">
            <PaymentInformation data={data?.carddata} />
          </Col>
        </Row>
      )}
      {activeTab === 'message-history' && <Messages />}
      {activeTab === 'admin-notes' && id && (
        <AdminNotes
          userId={id}
          data={data}
          refetch={refetch}
          isFetching={isRefetching}
        />
      )}
      {activeTab === 'utm-info' && <UTMInfoUI utmInfo={data?.utm_info} />}
      {/* Profile data - Jobs and Payment information */}
      <DeleteAccountPrompt
        show={showDeletePrompt}
        toggle={toggleDeleteAccountPrompt}
        onConfirm={deleteAccount}
        userName={`${data?.first_name} ${data?.last_name}`}
        loading={isDeleting}
      />
      {/* Client's reviews modal */}
      {id && (
        <Reviews
          show={showRatingsModal}
          onCloseModal={toggleRatingsModal}
          userId={id}
          ratings={data?.avg_rating}
          reviewsCount={data?.count_rating}
        />
      )}
    </Wrapper>
  );
};

export default ClientProfile;
