import React, { useEffect, useState } from 'react';
import './style.css';
import check from '../../assets/icons/check-new.png';

// Interfaces
interface Option {
  label: string;
  value: string | number;
}

interface SelectProps {
  onChange: (value: any) => void;
  options: Option[];
  title: string;
  selected?: number;
  bordered?: boolean;
}

function makeid(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const CustomSelect = ({
  onChange = () => null,
  options = [],
  title = '',
  selected = 0,
  bordered,
}: SelectProps) => {
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option>(
    options[selected] ?? {
      label: '',
      value: '',
    }
  );

  const [elemId] = useState(makeid(10));

  const onClickOption = (option) => {
    setSelectedOption(option);
    onChange(option);
    setShow(false);
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (!event.target.closest(`.${elemId}`)) {
        setShow(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${elemId} custom-select-container`}>
      <div
        className="custom-select-title-box form-select"
        onClick={() => setShow(!show)}
        style={{ border: bordered ? '1px solid #f2b420' : '' }}
      >
        <div className="custom-select-title">{title}</div>
        {/* <AiOutlineDown /> */}
      </div>
      {show && (
        <div className="custom-select-options">
          {options?.map((option, index) => (
            <div
              className={`custom-select-option ${
                selectedOption.value !== option.value
                  ? 'not-selected'
                  : 'selected'
              }`}
              key={`${option.label}-${option.value}-${index}`}
              onClick={() => onClickOption(option)}
            >
              {selectedOption.value === option.value && (
                <img src={check} alt="check" height={18} width={17} />
              )}
              <span>{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
