import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import StyledHtmlText from 'components/ui/StyledHtmlText';
import AttachmentPreview from 'components/ui/AttachmentPreview';
import { showFormattedBudget, expectedHoursRemap } from 'helpers/utils/misc';

const DetailStyledItem = styled.div`
  margin: auto;
  margin-top: 2rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);
  border-radius: 0.75rem;
  .job-detail-item-title {
    line-height: 2.1rem;
  }
  .description-text {
    opacity: 0.7;
  }
  .line-height-2rem {
    line-height: 2rem;
  }
  .job-detail-item-value {
    margin-top: 1.375rem;
  }
  .attachment {
    border: 1px solid #dedede;
    padding: 0.75rem;
    border-radius: 0.5rem;
    gap: 10px;
    width: 400px;
    max-width: 100%;
    .content {
      max-width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const GeneralDetails = ({ data }: any) => {
  return (
    <Row>
      <Col lg="12">
        <DetailsItem
          title="Project Description"
          atributeValue={
            <div>
              <div className="description-text line-height-2rem fw-300 fs-18 mt-3">
                <StyledHtmlText
                  id="job-description"
                  htmlString={data.job_description}
                  needToBeShorten={true}
                />
              </div>
              {data.attachments?.length > 0 && (
                <div className="d-flex align-items-center gap-3 flex-wrap mt-3">
                  {data.attachments?.map((item: string) => (
                    <div key={item} className="d-flex">
                      <AttachmentPreview
                        uploadedFile={item}
                        removable={false}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          }
        />
      </Col>

      {/* Freelancer's proposal card */}
      {(data?.proposal?.description || data?.proposal?.attachment_file) && (
        <Col lg="12">
          <DetailsItem
            title="Freelancer's Proposal (Accepted)"
            atributeValue={
              <div>
                {data?.proposal?.description && (
                  <div className="description-text line-height-2rem fw-300 fs-18 mt-3">
                    <StyledHtmlText
                      id="job-proposal-description"
                      htmlString={data.proposal.description}
                      needToBeShorten={true}
                    />
                  </div>
                )}
                {data?.proposal?.attachment_file && (
                  <div className="d-flex align-items-center gap-3 flex-wrap mt-3">
                    <div className="d-flex">
                      <AttachmentPreview
                        uploadedFile={data.proposal.attachment_file}
                        removable={false}
                      />
                    </div>
                  </div>
                )}
              </div>
            }
          />
        </Col>
      )}
      <Col lg="6">
        <DetailsItem
          title="Payment Structure"
          atributeValue={
            <div className="job-detail-item-value fs-18 fw-400">
              {data.budget?.type == 'fixed'
                ? 'Project-Based'
                : data.budget?.type == 'hourly'
                ? 'Hourly'
                : 'Unsure'}
            </div>
          }
        />
      </Col>
      {(data.proposal?.status == 'pending' || data.status == 'prospects') &&
        data.status !== 'active' &&
        data.status !== 'closed' && (
          <Col lg="6">
            <DetailsItem
              title="Budget"
              atributeValue={
                <div className="job-detail-item-value fw-400 fs-18 mt-3">
                  {data.status == 'active' || data.status == 'closed'
                    ? showFormattedBudget(data.proposal?.approved_budget)
                    : data?.budget?.isProposal === true
                    ? 'Open to Proposals'
                    : data?.budget
                    ? showFormattedBudget(data.budget)
                    : '-'}
                </div>
              }
            />
          </Col>
        )}
      <Col lg="6">
        <DetailsItem
          title="Skills Category"
          atributeValue={
            <div className="d-flex align-items-center mt-3 flex-wrap">
              {data.skills?.length
                ? data?.skills
                    ?.filter((sk) => !!sk.category_id)
                    .map(
                      (item: any, index: number, arr) =>
                        (item.category_name || item.category_id) && (
                          <div
                            className="description-text text-capitalize line-height-2rem fw-300 fs-18"
                            key={item.category_id}
                          >
                            {item.category_name}
                            {index < arr?.length - 1 ? ',' : ''}&nbsp;
                          </div>
                        )
                    )
                : '-'}
            </div>
          }
        />
      </Col>
      <Col lg="6">
        <DetailsItem
          title="Skill(s)"
          atributeValue={
            <div className="d-flex align-items-center mt-3 flex-wrap">
              {data.skills?.length
                ? data?.skills
                    ?.filter((sk) => !!sk.skill_id)
                    .map(
                      (item: any, index: number, arr) =>
                        (item.skill_name || item.skill_id) && (
                          <div
                            className="description-text text-capitalize line-height-2rem fw-300 fs-18"
                            key={item.skill_id}
                          >
                            {item.skill_name}
                            {index < arr?.length - 1 ? ',' : ''}&nbsp;
                          </div>
                        )
                    )
                : '-'}
            </div>
          }
        />
      </Col>
      <Col lg="6">
        <DetailsItem
          title="Delivery Time"
          atributeValue={
            <div className="job-detail-item-value">
              <div className="d-flex align-items-center g-1 flex-wrap">
                <div className="description-text fs-20 fw-400">Duration:</div>
                <div className="fs-20 fw-400">
                  {data.expected_delivery_date}
                </div>
              </div>
            </div>
          }
        />
      </Col>
      <Col lg="6">
        <DetailsItem
          title="Expected Hours Required"
          atributeValue={
            <div className="mt-3 fs-18 fw-400">
              {expectedHoursRemap(data.time_scope) || '-'}
            </div>
          }
        />
      </Col>

      <Col lg="6">
        <DetailsItem
          title="Language"
          atributeValue={
            <div className="d-flex align-items-center mt-3 flex-wrap">
              {data.languages?.length
                ? data.languages?.map((item: any, index: number) => (
                    <div
                      className="description-text line-height-2rem fw-300 fs-18"
                      key={item.id}
                    >
                      {item.name}
                      {index < data?.languages?.length - 1 ? ',' : ''}&nbsp;
                    </div>
                  ))
                : '-'}
            </div>
          }
        />
      </Col>
      <Col lg="6">
        <DetailsItem
          title="Preferred Freelancer Location"
          atributeValue={
            <div className="mt-3 fs-18 fw-400">
              {data?.preferred_location ? data?.preferred_location : '-'}
            </div>
          }
        />
      </Col>
    </Row>
  );
};

export default GeneralDetails;

const DetailsItem = ({
  title,
  atributeValue,
}: {
  title: string;
  atributeValue: React.ReactNode;
}) => {
  return (
    <DetailStyledItem>
      <div className="job-detail-item-title fs-24 fw-400">{title}</div>
      {atributeValue}
    </DetailStyledItem>
  );
};
