const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const stripeRequirementsHandler = (requirements = {}) => {
  let finalRequirementArr = [];
  const keyArr = Object.keys(requirements);

  keyArr.forEach((keyElem) => {
    finalRequirementArr.push(requirements[keyElem]);
  });

  finalRequirementArr = finalRequirementArr.flat(Infinity);

  finalRequirementArr = [...new Set(finalRequirementArr)].map((elem) => {
    if (typeof elem !== 'string') elem = '';
    const detail =
      elem.split('.').length === 2 ? elem?.split('.')[1] : elem?.split('.')[0];

    return capitalizeFirstLetter(detail.replaceAll('_', ' '));
  });

  finalRequirementArr = finalRequirementArr?.filter((el) => !!el);

  return finalRequirementArr;
};

export const isUserStripeVerified = (stripe) => {
  let finalRequirementArr = stripeRequirementsHandler(
    stripe?.individual?.requirements
  );

  let stripeErrorCheckArr = stripeRequirementsHandler(stripe?.requirements);
  const errors = stripe?.requirements?.errors;
  if (Array.isArray(errors) && errors?.length <= 0) stripeErrorCheckArr = [];

  /*future requirements start*/
  let futureRequirementsErrors = stripeRequirementsHandler(
    stripe?.future_requirements
  );
  const futureErrors = stripe?.future_requirements?.errors;
  if (Array.isArray(futureErrors) && futureErrors?.length <= 0)
    futureRequirementsErrors = [];
  /*future requirements end*/

  finalRequirementArr = finalRequirementArr.concat(
    stripeErrorCheckArr,
    futureRequirementsErrors
  );
  finalRequirementArr = [...new Set(finalRequirementArr)];

  return finalRequirementArr;
};
