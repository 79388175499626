import React, { useMemo } from 'react';
import { VerificationWrapper } from './verification-section.styled';
import { ReactComponent as StripeIcon } from 'assets/icons/stripe.svg';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { ReactComponent as FlagIcon } from 'assets/icons/flag.svg';

// US States mapping
const STATE_MAPPINGS: { [key: string]: string } = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  DC: 'District of Columbia',
};

// Create reverse mapping (full name to abbreviation)
const REVERSE_STATE_MAPPINGS = Object.entries(STATE_MAPPINGS).reduce(
  (acc, [abbr, full]) => {
    acc[full.toLowerCase()] = abbr;
    return acc;
  },
  {} as { [key: string]: string }
);

const normalizeState = (state: string | undefined): string => {
  if (!state) return '';

  const lowerState = state.toLowerCase();
  // Check if it's a full name that needs to be converted to abbreviation
  if (REVERSE_STATE_MAPPINGS[lowerState]) {
    return REVERSE_STATE_MAPPINGS[lowerState];
  }
  // Check if it's already an abbreviation
  if (STATE_MAPPINGS[state.toUpperCase()]) {
    return state.toUpperCase();
  }
  // Return original if no match found
  return state;
};

interface Location {
  state: string;
  country_name: string;
  country_short_name: string;
}

interface StripeAddress {
  state: string;
  country: string;
}

interface StripeIndividual {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address?: StripeAddress;
}

interface StripeData {
  individual: StripeIndividual;
}

interface BankAccount {
  routing_number: string;
  last_4_digit: string;
  bank_name: string;
  account_holder_type: string;
}

interface StripeExternalAccount {
  routing_number: string;
  last4: string;
  bank_name: string;
  account_holder_type: string | null;
}

interface StripeExternalAccounts {
  data: StripeExternalAccount[];
}

interface ProfileData {
  first_name?: string;
  last_name?: string;
  u_email_id?: string;
  formatted_phonenumber?: string;
  location?: Location;
  account?: BankAccount[];
  stripe?: StripeData & {
    external_accounts?: StripeExternalAccounts;
  };
}

interface VerificationSectionProps {
  profileData: ProfileData;
}

interface VerificationCheck {
  field: string;
  profile: string;
  stripe: string;
  matches: boolean;
}

const VerificationSection: React.FC<VerificationSectionProps> = ({
  profileData,
}) => {
  // Move normalizeState outside of useMemo's dependency by creating a memoized version
  const compareAddress = useMemo(
    () =>
      (profileLocation?: Location, stripeAddress?: StripeAddress): boolean => {
        return Boolean(
          normalizeState(profileLocation?.state) ===
            normalizeState(stripeAddress?.state) &&
            profileLocation?.country_short_name === stripeAddress?.country
        );
      },
    []
  );

  const verificationChecks = useMemo(() => {
    if (!profileData?.stripe?.individual) return [];

    const checks: VerificationCheck[] = [
      {
        field: 'First Name',
        profile: profileData.first_name || '',
        stripe: profileData.stripe.individual.first_name || '',
        matches: Boolean(
          profileData.first_name?.toLowerCase() ===
            profileData.stripe.individual.first_name?.toLowerCase()
        ),
      },
      {
        field: 'Last Name',
        profile: profileData.last_name || '',
        stripe: profileData.stripe.individual.last_name || '',
        matches: Boolean(
          profileData.last_name?.toLowerCase() ===
            profileData.stripe.individual.last_name?.toLowerCase()
        ),
      },
      {
        field: 'Email',
        profile: profileData.u_email_id || '',
        stripe: profileData.stripe.individual.email || '',
        matches: Boolean(
          profileData.u_email_id?.toLowerCase() ===
            profileData.stripe.individual.email?.toLowerCase()
        ),
      },
      {
        field: 'Phone',
        profile: profileData.formatted_phonenumber || '',
        stripe: profileData.stripe.individual.phone || '',
        matches: Boolean(
          profileData.formatted_phonenumber ===
            profileData.stripe.individual.phone
        ),
      },
      {
        field: 'Address',
        profile: profileData.location
          ? `${profileData.location.state}, ${profileData.location.country_name}`
          : '',
        stripe: profileData.stripe.individual.address
          ? `${profileData.stripe.individual.address.state}, ${profileData.stripe.individual.address.country}`
          : '',
        matches: compareAddress(
          profileData.location,
          profileData.stripe.individual.address
        ),
      },
      {
        field: 'Routing Number',
        profile: profileData.account?.[0]?.routing_number || '',
        stripe:
          profileData.stripe.external_accounts?.data?.[0]?.routing_number || '',
        matches: Boolean(
          profileData.account?.[0]?.routing_number ===
            profileData.stripe.external_accounts?.data?.[0]?.routing_number
        ),
      },
      {
        field: 'Bank Account',
        profile: profileData.account?.[0]?.last_4_digit || '',
        stripe: profileData.stripe.external_accounts?.data?.[0]?.last4 || '',
        matches: Boolean(
          profileData.account?.[0]?.last_4_digit ===
            profileData.stripe.external_accounts?.data?.[0]?.last4
        ),
      },
      {
        field: 'Bank Name',
        profile: profileData.account?.[0]?.bank_name || '',
        stripe:
          profileData.stripe.external_accounts?.data?.[0]?.bank_name || '',
        matches: Boolean(
          profileData.account?.[0]?.bank_name ===
            profileData.stripe.external_accounts?.data?.[0]?.bank_name
        ),
      },
      {
        field: 'Account Type',
        profile: profileData.account?.[0]?.account_holder_type || '',
        stripe:
          profileData.stripe.external_accounts?.data?.[0]
            ?.account_holder_type || '',
        matches: Boolean(
          profileData.account?.[0]?.account_holder_type ===
            profileData.stripe.external_accounts?.data?.[0]?.account_holder_type
        ),
      },
    ].filter((check) => check.profile && check.stripe);

    return checks;
  }, [profileData, compareAddress]);

  const { matchingFields, mismatchedFields, matchPercentage } = useMemo(() => {
    const matching = verificationChecks.filter((check) => check.matches);
    const mismatched = verificationChecks.filter((check) => !check.matches);
    const percentage = verificationChecks.length
      ? Math.round((matching.length / verificationChecks.length) * 100)
      : 0;

    return {
      matchingFields: matching,
      mismatchedFields: mismatched,
      matchPercentage: percentage,
    };
  }, [verificationChecks]);

  if (!profileData?.stripe?.individual) {
    return null;
  }

  return (
    <VerificationWrapper>
      <div className="verification-content">
        <div className="verification-header">
          <div className="title-with-logo">
            <StripeIcon />
            <span>
              <span>vs</span>
            </span>
            <LogoIcon />
          </div>
          <div className="header-info">
            {verificationChecks.some(
              (check) =>
                (check.field === 'Email' || check.field === 'Phone') &&
                !check.matches
            ) && (
              <div className="warning-flag">
                <FlagIcon className="flag-icon" />
                <span>Contact info mismatch</span>
              </div>
            )}
            <div className="match-percentage">{matchPercentage}% Match</div>
          </div>
        </div>

        <div className="verification-summary">
          <div className="summary-section">
            <div className="summary-title match">
              <span className="check-icon">✓</span> Matching Fields (
              {matchingFields.length})
            </div>
            <div className="summary-fields">
              {matchingFields.map((field) => field.field).join(', ')}
            </div>
          </div>

          {mismatchedFields.length > 0 && (
            <div className="summary-section">
              <div className="summary-title mismatch">
                <span className="x-icon">✗</span> Mismatched Fields (
                {mismatchedFields.length})
              </div>
              <div className="summary-fields">
                {mismatchedFields.map((field, index) => (
                  <div key={index} className="mismatch-detail">
                    {field.field}:{' '}
                    <span className="detail-value">
                      Profile: {field.profile}
                    </span>{' '}
                    vs
                    <span className="detail-value">Stripe: {field.stripe}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </VerificationWrapper>
  );
};

export default VerificationSection;
