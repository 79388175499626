import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import NoDataFound from 'components/ui/NoDataFound';
import Loader from 'components/Loader';
import PaginationComponent from 'components/ui/Pagination';
import JobItem from './JobItem';
import useFreelancerJobs from '../hooks/useFreelancerJobs';

const RECORDS_PER_PAGE = 10;

const ActiveJobs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [query, setQuery] = useState<string>('');

  const { freelancerId }: { freelancerId: string } = useOutletContext();

  const { data, isLoading, isRefetching, totalResults } = useFreelancerJobs({
    query,
    limit: RECORDS_PER_PAGE,
    freelancerId: freelancerId,
    jobStatus: 'active',
  });

  useEffect(() => {
    if (location.search) {
      const params: any = new URLSearchParams(location.search);
      const pg = params.get('pg') || 1;
      setQuery(`pg=${pg}`);
      setCurrentPage(parseInt(pg));
    }
  }, [location.search]);

  const goToJobDetails = (id: string) => () => {
    /*
     * This will take job id and take the user to job details page
     */
    navigate(`/freelancers/job-details/${id}/${freelancerId}`);
  };

  const onPageChange = (page: { selected: number }) => {
    /* This will set next page as active and load new page data - Pagination is implemented locally  */
    setCurrentPage(page?.selected + 1);
    const searchQuery = `pg=${page?.selected + 1}`;
    changeWindowLocation(searchQuery);
    setQuery(searchQuery);
  };

  const changeWindowLocation = (path: string) => {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${path}`;

    window.history.pushState({ path: newurl }, '', newurl);
  };

  return (
    <div className="jobs-listings">
      {isLoading && <Loader />}

      {!isLoading && !isRefetching && data?.length === 0 && <NoDataFound />}

      {data?.map((item: any) => (
        <JobItem
          key={item?.job_post_id}
          goToJobDetails={goToJobDetails(item?.job_post_id)}
          jobData={item}
        />
      ))}

      {totalResults > 0 && (
        <div className="d-flex justify-content-center mt-3">
          <PaginationComponent
            total={Math.ceil(totalResults / RECORDS_PER_PAGE)}
            onPageChange={onPageChange}
            currentPage={currentPage}
          />
        </div>
      )}
    </div>
  );
};

export default ActiveJobs;
