import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import styled from 'styled-components';
import { ListingWrapper } from '../dashboard.styled';
import { transition } from 'styles/transitions';
import DisputeResolveModal from 'components/support/dispute-resolve-modal/DisputeResolveModal';
import { StatusBadge } from 'components/styled/Badges';
import NoDataFound from 'components/ui/NoDataFound';
import Loader from 'components/Loader';
import SupportDetails from 'components/support/support-details/SupportDetails';
import StyledHtmlText from 'components/ui/StyledHtmlText';
import useSupportRequests from '../hooks/useSupportRequests';
import { SupportItemType } from '../types/support.types';

export const SupportItem = styled.div`
  border: 1px solid ${(props) => props.theme.colors.gray6};
  border-radius: 10px;
  .request-details {
    max-width: 80%;
  }
  .light-text {
    opacity: 0.7;
  }
  .support-request--avatar {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    object-fit: cover;
  }
  ${() => transition()};
`;

const SupportRequests = ({ activeFilter }: { activeFilter: string }) => {
  // This function will fetch the dashboard support requests
  const { data, isLoading, isRefetching, refetch } =
    useSupportRequests(activeFilter);

  const [detailsModalState, setDetailsModalState] = useState<{
    show: boolean;
    supportItem?: SupportItemType;
  }>({
    show: false,
  });

  const [resolveModalState, setReloveModalState] = useState<{
    show: boolean;
    supportItem?: SupportItemType;
  }>({
    show: false,
  });

  const [isAccept, setAccept] = useState(false);

  /* This will close the support details mdoal */
  const onCloseDetailsModal = () => {
    setDetailsModalState({ show: false });
  };

  /* This will open the support details modal */
  const showSupportDetails = (item) => () => {
    setDetailsModalState({ show: true, supportItem: item });
  };

  const openResolveModal = () => {
    setReloveModalState({
      show: true,
      supportItem: detailsModalState?.supportItem,
    });
    onCloseDetailsModal();
  };

  const openResolveAcceptModal = () => {
    setReloveModalState({
      show: true,
      supportItem: detailsModalState?.supportItem,
    });
    setAccept(true);
    onCloseDetailsModal();
  };

  const onCloseResolveModal = () => {
    setReloveModalState({
      show: false,
    });
  };

  const onResolving = () => {
    refetch();
    onCloseResolveModal();
  };

  return (
    <ListingWrapper>
      {/* Header */}

      <div className="d-flex align-items-center heading p-4 fs-18 fw-700">
        Support Requests
        {isRefetching && (
          <Spinner animation="border" size="sm" className="ms-3" />
        )}
      </div>

      {/* Listing */}
      <div className="listings px-4 pb-4">
        {!isLoading && !isRefetching && data?.length == 0 && <NoDataFound />}

        {isLoading && <Loader />}

        {data?.length > 0 &&
          data?.map((item: SupportItemType) => (
            <SupportItem
              key={
                item?.dispute_id
                  ? `dispute_${item?.dispute_id}`
                  : `general_${item?.inquiry_id}`
              }
              className="mt-3 p-3 d-flex justify-content-between pointer flex-wrap gap-2"
              onClick={showSupportDetails(item)}
            >
              {/* Support request details */}
              <div className="request-details">
                <div className="fs-18 fw-400">
                  {item?.subject || item?.jobdata?.job_title}
                </div>

                {item?.dispute_id && (
                  <div className="fs-sm fw-400 mt-2 light-text">
                    {item?.milestone?.title}
                  </div>
                )}

                <div className="fs-sm fw-400 mt-2 light-text">
                  <StyledHtmlText
                    htmlString={item?.description}
                    needToBeShorten={true}
                    fontSize={0.875}
                    id={
                      item?.dispute_id
                        ? `dashboard-dispute_${item?.dispute_id}`
                        : `dashboard-inquiry_${item?.inquiry_id}`
                    }
                  />
                </div>

                <div className="support-request--by mt-2 d-flex align-items-center gap-2">
                  <img
                    className="support-request--avatar"
                    src={item?.userdata?.user_image}
                  />
                  <div className="fs-1rem fw-400 light-text">
                    {item?.userdata?.first_name} {item?.userdata?.last_name}
                  </div>
                </div>
              </div>

              {/* Support request type badge and created date */}
              <div className="d-flex flex-column justify-content-between align-items-md-end gap-2">
                <div className="d-flex flex-row align-items-end gap-2">
                  {(item?.dispute_status == 'closed' ||
                    item?.status == 'closed') && (
                    <StatusBadge color="green" className="mt-2">
                      Closed
                    </StatusBadge>
                  )}
                  <StatusBadge color={item?.inquiry_id ? 'blue' : 'darkPink'}>
                    {item?.inquiry_id ? 'General' : 'Dispute'}
                  </StatusBadge>
                </div>
                <div className="fs-sm fw-400 light-text">
                  {item?.date_created
                    ? moment(item?.date_created).format('MMM DD, YYYY')
                    : '-'}
                </div>
              </div>
            </SupportItem>
          ))}
      </div>

      {/* Support details modal */}
      <SupportDetails
        show={detailsModalState?.show}
        onCloseModal={onCloseDetailsModal}
        onResolve={openResolveModal}
        selectedSupportItem={detailsModalState?.supportItem}
        refetch={refetch}
        openResolveAcceptModal={openResolveAcceptModal}
      />

      {/* Dispute resolve modal */}
      <DisputeResolveModal
        show={resolveModalState?.show}
        onCloseModal={onCloseResolveModal}
        supportItem={resolveModalState?.supportItem}
        onResolving={onResolving}
        isAccept={isAccept}
      />
    </ListingWrapper>
  );
};
export default SupportRequests;
