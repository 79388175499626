export const TABS = [
  // {
  //   id: 0,
  //   label: 'All',
  //   key: 'all',
  // },
  // {
  //   id: 1,
  //   label: 'Posted Jobs',
  //   key: 'prospects',
  // },
  {
    id: 2,
    label: 'Jobs In Progress',
    key: 'active',
  },
  {
    id: 3,
    label: 'Closed',
    key: 'closed',
  },
];

interface jobStatusEnumType {
  [propertyName: string]: {
    color: string | any;
  };
}

export const JOBS_STATUS: jobStatusEnumType = {
  pending: {
    color: 'yellow',
  },
  prospects: {
    color: 'yellow',
  },
  active: {
    color: 'blue',
  },
  closed: {
    color: 'green',
  },
  draft: {
    color: 'gray',
  },
};
