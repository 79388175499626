import { yupResolver } from '@hookform/resolvers/yup';
import { StyledModal } from 'components/styled/StyledModal';
import * as yup from 'yup';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ErrorMessage from 'components/ui/ErrorMessage';
import { StyledButton } from 'components/forms/Buttons';
import { useEffect, useState } from 'react';
import LoadingButtons from 'components/LoadingButtons';
import { addCategoryApi } from 'helpers/http/common';
import toast from 'react-hot-toast';

const CategoryModal = ({ show, onClose, category }: any) => {
  const [loading, setLoading] = useState(false);
  const schema = yup.object({
    category_name: yup.string().required('Category name is required.'),
  });

  const { register, handleSubmit, formState, reset, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (payload) => {
    setLoading(true);
    payload.action = category.id ? 'edit_category' : 'add_category';
    if (category.id) {
      payload.category_id = category.id;
    }
    const promise = addCategoryApi(payload);

    toast.promise(promise, {
      loading: 'Loading...',
      success: (res) => {
        setLoading(false);
        onClose('category');
        return res?.message ?? 'Success';
      },
      error: (err) => {
        setLoading(false);
        onClose('category');
        return err?.response?.data?.message || 'error';
      },
    });
  };

  useEffect(() => {
    if (!show) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (category.name) setValue('category_name', category.name);
    else reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const { errors } = formState;

  return (
    <StyledModal show={show} size="lg" onHide={onClose} centered maxwidth={590}>
      <Modal.Body>
        <Button variant="transparent" className="close" onClick={onClose}>
          &times;
        </Button>
        <div className="fs-24 fw-700 d-flex align-items-center mb-4">
          {category?.id ? 'Edit' : 'Add'} Category
        </div>

        <Form
          className="form d-flex flex-column"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <label className="fs-1rem fw-400 mb-2">Category Name</label>
            <Form.Control type="text" {...register('category_name')} />
            <ErrorMessage>{errors.category_name?.message}</ErrorMessage>
          </div>

          <StyledButton
            className="mt-5"
            style={{
              padding: '1.125rem 5rem',
              maxWidth: '60%',
              margin: 'auto',
            }}
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <LoadingButtons />
            ) : (
              `${category?.id ? 'Edit' : 'Add'} Category`
            )}
          </StyledButton>
        </Form>
      </Modal.Body>
    </StyledModal>
  );
};

export default CategoryModal;
