import { useEffect, useMemo, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Wrapper } from './freelancers.styled';
import Search from 'components/forms/Search';
import NoDataFound from 'components/ui/NoDataFound';
import Tabs from 'components/ui/Tabs';
import Loader from 'components/Loader';
import useDebounce from 'helpers/hooks/useDebounce';
import PaginationComponent from 'components/ui/Pagination';
import { TABS } from './consts';
import { useFreelancers } from './controller/freelancer';
import { useNonInitialEffect } from 'helpers/hooks/useNonInitialEffect';
import { ManageColumns } from 'components/ui/ManageColumns';
import { freelancerTableColumns } from 'pages/freelancers/partials/freelancerTableColumns';
import {
  ColumnOrderState,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { editUser, loginAsAnotherUser } from 'helpers/http/freelancer';
import toast from 'react-hot-toast';
import { updateColumns } from 'helpers/http/common';
import { CustomTable } from 'components/ui/CustomTable';
import { useNavigate } from 'react-router-dom';

// TODO : need loading states refactoring
const Freelancers = () => {
  const {
    filters,
    updateFilters,
    isLoading,
    refetch,
    isRefetching,
    data,
    totalPages,
  } = useFreelancers();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [isLoadingManageColumns, setIsLoadingManageColumns] = useState(false);

  const debouncedSearchQuery = useDebounce(searchTerm, 500);

  // gettings freelancer columns settings from user object and updating state
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user?.freelancer_columns?.column_visibility) {
      setColumnVisibility(user.freelancer_columns.column_visibility);
    }
    if (user?.freelancer_columns?.column_order) {
      setColumnOrder(user.freelancer_columns.column_order);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearchTerm(filters.keyword);
  }, [filters.keyword]);

  useNonInitialEffect(() => {
    updateFilters({
      keyword: debouncedSearchQuery?.trim(),
      page: 1,
      searchBy: '',
      searchText: '',
    });
  }, [debouncedSearchQuery, updateFilters]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(e.target.value);

  const onPageChange = (page: { selected: number }) =>
    updateFilters({ page: page?.selected + 1 });

  const onTabChange = (tab: string) => {
    updateFilters({ status: tab, page: 1 });
  };

  const onApproveOrReject = (approve: 0 | 1 | null, id: string) => () => {
    /*
     * This function will either either approve or reject the freelancer's registration request
        based on approve parame's value
     * If approve = 1 => Approve reg. request
     * If approve = 0 => Reject reg. request
     */

    setSelectedId(id);

    /* Api call for approve or reject registration request */
    const promise = editUser({
      user_id: id,
      is_account_approved: approve,
    });

    toast.promise(promise, {
      loading: 'Loading...',
      success: (res) => {
        refetch();
        setSelectedId('');
        return res.message;
      },
      error: (err) => {
        setSelectedId('');
        return err?.response?.data?.message || 'error';
      },
    });
  };

  const onLoginAsAnotherUser = (user_id: string) => () => {
    const promise = loginAsAnotherUser(user_id);

    toast.promise(promise, {
      loading: 'Loading...',
      success: (res) => {
        if (window.location.host.includes('beta')) {
          window.open(
            `https://beta.zehmizeh.com/login?key=${user_id}&token=${res.data.token}`
          );
        } else {
          window.open(
            `https://www.zehmizeh.com/login?key=${user_id}&token=${res.data.token}`
          );
        }
        return res.message;
      },
      error: (err) => {
        return err?.response?.data?.message || 'error';
      },
    });
  };

  const onFlagChange = (id: string, flag: 0 | 1) => () => {
    setSelectedId(id);

    /* Api call for approve or reject registration request */
    const promise = editUser({
      user_id: id,
      is_flagged: flag,
    });

    toast.promise(promise, {
      loading: 'Loading...',
      success: (res) => {
        refetch();
        setSelectedId('');
        return res.message;
      },
      error: (err) => {
        setSelectedId('');
        return err?.response?.data?.message || 'error';
      },
    });
  };

  const memoizedFreelancerTableColumns = useMemo(
    () =>
      freelancerTableColumns(
        onApproveOrReject,
        onLoginAsAnotherUser,
        onFlagChange,
        selectedId,
        filters,
        updateFilters
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedId, filters]
  );

  const table = useReactTable({
    data: data?.freelancers || [],
    columns: memoizedFreelancerTableColumns,
    state: {
      columnVisibility,
      columnOrder,
    },
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
  });

  const handleColumnsSave = () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const payload = {
      freelancer_columns: {
        column_visibility: table?.options?.state?.columnVisibility || {},
        column_order: table?.options?.state?.columnOrder || [],
      },
    };

    // No need to make api call if user haven't changed settings
    if (
      JSON.stringify(user?.freelancer_columns) ===
      JSON.stringify(payload.freelancer_columns)
    )
      return;

    setIsLoadingManageColumns(true);
    updateColumns(payload)
      .then(() => {
        setIsLoadingManageColumns(false);
        toast.success('Updated column settings');
        // updating it in localstorage because user object updates only on logout and login
        localStorage.setItem('user', JSON.stringify({ ...user, ...payload }));
      })
      .catch((err) => {
        setIsLoadingManageColumns(false);
        console.error('Update columns:', err);
        toast.error('Failed to update column settings');
      });
  };

  const resetFilter = () => {
    updateFilters({ sortBy: '', searchBy: '', sortOrder: '', searchText: '' });
  };

  return (
    <Wrapper>
      <div className="d-flex align-items-center my-3">
        <h1 className="fs-32 fw-700 mb-0">Freelancers</h1>
        {(isRefetching || isLoading) && (
          <Spinner animation="border" className="ms-2" size="sm" />
        )}
      </div>

      {/* Search box and status filters*/}
      <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
        {/* Search box */}
        <Search value={searchTerm} onChange={onSearch} />

        {/* Filters */}
        <div className="d-flex flex-direction-row align-items-center">
          <Tabs
            tabs={TABS}
            activeTab={filters.status || TABS[0].key}
            onTabChange={onTabChange}
            counts={{
              all: data?.all_freelancer || 0,
              approved: data?.approved_account || 0,
              under_review: data?.under_review || 0,
              declined: data?.decline || 0,
              deleted: data?.deleted || 0,
              flagged: data?.flagged || 0,
            }}
          />
          {data?.freelancers && data?.freelancers?.length > 0 && (
            <ManageColumns
              elementId="freelancer-table-manage-columns"
              handleSave={handleColumnsSave}
              className="ms-4"
              table={table}
              isLoading={isLoadingManageColumns}
            />
          )}
        </div>
      </div>

      {/* Initial loader */}
      {isLoading && data?.freelancers?.length == 0 && <Loader />}

      <div className="listing-table p-3 mt-4">
        {/* Listing table */}

        <div className="listings">
          {data?.freelancers && data?.freelancers?.length > 0 && (
            <CustomTable
              table={table}
              handleRowClick={(row) => {
                navigate(`/freelancer-profile/${row.original.user_id}`);
              }}
            />
          )}
          <div id="more-button-dropdown-menu"></div>
          {/* No data found */}
          {!isLoading && !isRefetching && data?.freelancers?.length == 0 && (
            <div className="my-5 py-5">
              <NoDataFound />
              {/* START ----------------------------------------- Showing reset filter button only if any filter is applied */}
              {[
                'sortBy',
                'sortOrder',
                'searchBy',
                'searchText',
                'keyword',
              ].some((key) => filters[key]) && (
                <div className="d-flex justify-content-center mt-2">
                  <Button className="text-center" onClick={resetFilter}>
                    Reset Filters
                  </Button>
                </div>
              )}
              {/* END ------------------------------------------- Showing reset filter button only if any filter is applied */}
            </div>
          )}
        </div>

        {/* Pagination */}
        {data?.freelancers && data?.freelancers?.length > 0 && (
          <div className="d-flex justify-content-center mt-3">
            <PaginationComponent
              total={totalPages}
              onPageChange={onPageChange}
              currentPage={filters.page}
            />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default Freelancers;
