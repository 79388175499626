import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Wrapper } from './mails.styled';
import CustomSelect from 'components/custom-select';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import moment from 'moment';
import { Filters, MessageTypes } from './mails.types';
import { getEmailActivty } from 'helpers/http/common';
import { StyledTable } from 'components/styled/StyledTable';
import { Spinner } from 'react-bootstrap';

interface Filter {
  limitOptions;
}
const SendGridLogs = () => {
  const [emails, setEmails] = useState<MessageTypes[]>([]);

  const [filters, setFilters] = useState<Filters>({
    limitOptions: [
      { value: 10, label: '10' },
      { value: 50, label: '50' },
      { value: 100, label: '100' },
      { value: 500, label: '500' },
      { value: 1000, label: '1000' },
    ],
    date: [moment().subtract(1, 'weeks').toDate(), new Date()],
    limit: 10,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const dateFilterHandler = (dateArr: DateObject[]) => {
    let startDate: any = dateArr[0];
    let endDate: any = dateArr[1];
    if (!startDate || !endDate) return;

    startDate = startDate.toDate();
    endDate = endDate.toDate();

    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);

    if (!momentStartDate.isBefore(momentEndDate)) {
      setFilters({ ...filters, date: [endDate, startDate] });
    } else {
      setFilters({ ...filters, date: [startDate, endDate] });
    }
  };

  const apiHandler = async () => {
    setLoading(true);
    const { limit, date } = filters;
    const query = `last_event_time BETWEEN TIMESTAMP "${date[0].toISOString()}" AND TIMESTAMP "${date[1].toISOString()}"`;
    const email_logs = await getEmailActivty({ query, limit });
    setEmails(email_logs);
    setLoading(false);
  };

  useEffect(() => {
    apiHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <Wrapper>
      <div className="d-flex align-items-center my-3 mb-5">
        <h1 className="fs-32 fw-700 mb-0">Mails</h1>
      </div>

      {/* Search box and status filters*/}
      <div className="d-flex align-items-center justify-content-start flex-wrap gap-3 w-100 filters">
        <CustomSelect
          bordered
          options={filters.limitOptions}
          onChange={({ value: limit }) => setFilters({ ...filters, limit })}
          title="Limit"
        />

        <DatePicker
          onChange={dateFilterHandler}
          value={filters.date}
          range
          dateSeparator=" - "
          placeholder="Select Date"
          className="yellow-border"
          style={{ border: '1px solid #f2b420' }}
        />
      </div>

      {/* Listing table */}
      <div className="listing-table p-3">
        <div className="listings">
          <StyledTable responsive>
            {/* Table heading */}
            <thead>
              <tr>
                <th>Message ID</th>
                <th>Status</th>
                <th>Subject</th>
                <th>To Email</th>
                <th>Event Time</th>
              </tr>
            </thead>

            {/* Table body and main listing of freelancers */}
            <tbody className="scroll">
              {loading && (
                <tr>
                  <td colSpan={5}>
                    <div className="text-center py-5">
                      <Spinner animation="border" />
                    </div>
                  </td>
                </tr>
              )}
              {!loading &&
                emails &&
                emails?.map((item: MessageTypes) => {
                  return (
                    <tr key={item?.msg_id} className="align-middle">
                      <td>
                        <div className="d-flex align-items-center text-uppercase">
                          <div className="fs-1rem fw-400">
                            {item.msg_id.slice(0, 5)}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className=" fs-1rem fw-400 text-uppercase">
                            {item.status}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div
                            className="fs-1rem fw-400"
                            style={{
                              width: '100%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {item.subject}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className=" fs-1rem fw-400">{item.to_email}</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className=" fs-1rem fw-400">
                            {moment(item.last_event_time).format(
                              'MMM DD, YYYY'
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </StyledTable>
        </div>
      </div>
    </Wrapper>
  );
};

export default SendGridLogs;
