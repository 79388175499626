import { StripeContainer } from './stripeStyled';
import './style.css';
import Tooltip from 'components/ui/Tooltip';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { isUserStripeVerified } from 'helpers/utils/helper';

interface Prop {
  stripe: any;
  stripeStatus?: any;
  totalEarnings: any;
}

const CONSTANTS = {
  'Ssn last 4': 'Last 4 Digits of your SSN',
  Phone: 'Phone Number',
};

const StripeDetails = (props: Prop) => {
  const { stripe = null, stripeStatus = null }: any = props ?? {};
  const [messageFromStripe, setMessageFromStripe] = useState<string>('');

  const content = useMemo(() => {
    const data = {
      label: 'Not starteds',
      description: 'Not started.',
      tooltip: 'Not started.',
    };

    if (stripeStatus) {
      switch (stripeStatus) {
        case 'pending':
          if (!stripe?.id) {
            data.label = 'Not started';
            data.description = `Not started`;
          } else {
            data.description = `New Submissions Needed`;
            data.label = 'New Submissions Needed';
          }
          break;
        case 'currently_due':
          data.label = 'New Submissions Needed';
          break;
        case 'pending_verification':
          data.label = 'Verifying Info';
          data.description =
            'The information user submitted is being verified.';
          data.tooltip = 'The information you submitted is being verified.';
          break;
        case 'bank_account_pending':
          data.label = 'Bank Account Pending';
          data.description = 'Pending stripe and add bank account details';
          data.tooltip = 'Pending to stripe and add bank account details';
          break;
        case 'verified':
          data.label = 'Verified';
          data.description = `Stripe details verified successfully.`;
          data.tooltip = 'Stripe details verified successfully.';
          break;
        default:
          data.label = (stripeStatus as string).replace(/_/g, ' ');
          data.description = `Pending details`;
          data.tooltip = 'Pending details.';
      }
      if (messageFromStripe) {
        data.label = 'New Submissions Needed';
      }
    }
    return {
      pending: data,
      inprogress: data,
      verified: data,
    };
  }, [messageFromStripe, stripe?.id, stripeStatus]);

  const [stripeDetails, setStripeDetails] = useState<any>({
    status: 'pending',
    message: content['pending'].description,
  });

  const { status, message } = stripeDetails;
  const { individual } = stripe ?? { individual: {} };
  const buttonStyle = {
    fontSize: '15px',
    fontWeight: 500,
    minHeight: 'unset',
    padding: '0.4rem 1rem',
    borderRadius: '6px',
  };

  const missingDetails = (finalRequirementArr: string[]) => {
    // Date of birth
    const dob_arr = ['Month', 'Day', 'Year'];

    for (let i = 0; i < dob_arr.length; i++) {
      if (finalRequirementArr.includes(dob_arr[i])) {
        finalRequirementArr = finalRequirementArr.filter(
          (rq) => !dob_arr.includes(rq)
        );
        finalRequirementArr.push('Birthdate');
        break;
      }
    }

    // address
    const add_arr = ['Postal code', 'City', 'Line1', 'State'];

    for (let i = 0; i < add_arr.length; i++) {
      if (finalRequirementArr.includes(add_arr[i])) {
        finalRequirementArr = finalRequirementArr.filter(
          (rq) => !add_arr.includes(rq)
        );
        finalRequirementArr.push('Address');
        break;
      }
    }

    // ID Number
    const id_num = 'Id number';
    if (finalRequirementArr.includes(id_num)) {
      finalRequirementArr = finalRequirementArr.filter((el) => el !== id_num);
      finalRequirementArr.push('ID Number');
    }

    // URL
    const url = 'Url';
    if (finalRequirementArr.includes(url)) {
      finalRequirementArr = finalRequirementArr.filter((el) => el !== url);
      finalRequirementArr.push('URL');
    }

    // ordering the array
    const valid_order = [
      'URL',
      'ID Number',
      'First name',
      'Last name',
      'Birthdate',
      'Address',
      'Phone',
      'Ssn last 4',
    ];

    const final_arr: any = [];
    valid_order.map((or_el: string) => {
      if (finalRequirementArr.includes(or_el)) final_arr.push(or_el);
    });

    return final_arr.map((el, index) => (
      <li key={`required-stp-${index}`} className="mt-1">
        {CONSTANTS[el] ?? el}
      </li>
    ));
  };

  const stripeInprogressHandler = () => {
    const finalRequirementArr = isUserStripeVerified(stripe);

    let finalMessage: ReactElement | string;

    if (finalRequirementArr.length) {
      // This means only document is pending to submit
      // So changing text to inform user that only document is pending and how to add it
      if (
        finalRequirementArr.length === 1 &&
        finalRequirementArr[0] === 'Document'
      ) {
        finalMessage = (
          <div className="text-center fs-18">
            <b>
              To finish verification - please add a Personal Identity Document.
            </b>
            <p className="mb-0">
              To add: click ‘Go to Stripe’ below, then click ‘Edit’ by your
              personal details.
            </p>
          </div>
        );
      } else {
        finalMessage = (
          <div>
            <p className="mb-0">
              To continue your registration with Stripe, click "Go to Stripe"
              and add this info:
            </p>
            <ul className="ps-3 mt-3">{missingDetails(finalRequirementArr)}</ul>
          </div>
        );
      }
    } else {
      finalMessage = content['inprogress'].description;
    }
    return setStripeDetails({
      ...stripeDetails,
      status: 'inprogress',
      message: finalMessage,
    });
  };

  const stripeDetailsHandler = () => {
    if (!stripe?.id)
      return setStripeDetails((prev) => ({
        ...prev,
        status: 'pending',
        message: content['pending'].description,
      }));

    if (!['verified'].includes(stripeStatus)) return stripeInprogressHandler();
    else
      return setStripeDetails({
        ...stripeDetails,
        status: 'verified',
        message: content['verified'].description,
      });
  };

  const messageFromStripeHandler = () => {
    if (stripe == null) return;
    let errors = stripe?.requirements?.errors ?? [];

    // error for future requirements
    if (!Array.isArray(errors) || errors?.length <= 0) {
      errors = stripe?.future_requirements?.errors ?? [];
    }
    const reason = errors[0]?.reason;
    if (typeof reason !== 'string') return;

    if (reason) stripeInprogressHandler();
    setMessageFromStripe(reason);
  };

  useEffect(() => {
    stripeDetailsHandler();
    messageFromStripeHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe]);

  if (!content[status]) return null;

  return (
    <StripeContainer className={`stripe-${status}`}>
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <h3>Stripe Account Details</h3>
        </div>
        <div className="mt-4">
          <div className="d-flex  gap-3">
            <div className=" stripe-tag">
              <span>{content[status].label}</span>

              {content[status].tooltip && (
                <Tooltip className="stripe-tooltip">
                  {content[status].tooltip}
                </Tooltip>
              )}
            </div>

            {stripe?.id && (
              <div className="stripe-tag">
                <span>{`ACCT: ${stripe?.id.split('_')[1]}`}</span>
              </div>
            )}
          </div>
        </div>
        <div className="mt-4">
          <table className="no-border">
            <tr>
              <td className="pb-3">Full Name</td>
              <td className="ps-5 pb-3">
                {individual?.first_name && individual?.last_name ? (
                  <>
                    {individual?.first_name} {individual?.last_name}
                  </>
                ) : (
                  '-'
                )}
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td className="ps-5">{stripe?.email ?? '-'}</td>
            </tr>
          </table>
        </div>

        <div className="capabilities-container-wrapper mt-4 mb-4">
          <p
            className={`stripe-message my-3 ${
              status === 'pending' ? 'text-center' : ''
            }`}
          >
            {message}
          </p>
          {messageFromStripe && (
            <p className="stripe-message">Stripe: {messageFromStripe}</p>
          )}
        </div>
      </div>
    </StripeContainer>
  );
};

export default StripeDetails;
