import { apiClient } from './index';

/* This api is to get the list of all clients for clients page */
export const getAllClients = (formData: {
  keyword: string;
  page: number;
  limit: number;
}) => {
  return apiClient
    .get(
      `/admin/client/get?page=${formData?.page}&limit=${formData?.limit}&keyword=${formData?.keyword}`
    )
    .then((res) => {
      return res.data;
    });
};

/*
 * This api is to get the freelancer's details
 */
export const getClientDetails = (id?: string) => {
  return apiClient
    .get(`/user/get?is_admin=true&freelancer_id=${id}`)
    .then((res) => {
      return res.data;
    });
};

export const getClientJobs = (formData: {
  clientId?: string;
  status?: string;
  page?: number;
  limit?: number;
}) => {
  const payload = {
    status: formData?.status,
    page: formData?.page,
    limit: formData?.limit,
  };
  return apiClient
    .post(
      `/job/client/get?is_admin=true&user_id=${formData?.clientId}`,
      payload
    )
    .then((res) => {
      return res.data;
    });
};

export const loginAsAnotherUser = (user_id?: string) => {
  return apiClient.post('/admin/login-as-user', { user_id }).then((res) => {
    return res.data;
  });
};
