import styled from 'styled-components';

export const VerificationWrapper = styled.div`
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
  background: ${(props) => props.theme.colors.white};
  border: ${(props) => `1px solid ${props.theme.colors.yellow}`};
  border-radius: 12px;
  padding: 1.5rem;
  margin-top: 1.5rem;

  .verification-content {
    max-height: 300px;
    overflow-y: auto;
  }

  .verification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .title-with-logo {
    display: flex;
    align-items: center;
    gap: 1rem;

    svg {
      width: 50px;
      height: 50px;
      padding: 0.75rem;
      border-radius: 8px;
      background: white;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }
    }

    span {
      font-size: 0.875rem;
      color: #666;
      font-weight: 500;
      position: relative;
      padding: 0.25rem 0.75rem;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 2rem;
        height: 2rem;
        background: rgba(217, 217, 217, 0.2);
        border-radius: 50%;
        z-index: 0;
      }

      span {
        position: relative;
        z-index: 1;
      }
    }
  }

  .header-info {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .warning-flag {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${(props) => props.theme.colors.red};
    background-color: ${(props) => props.theme.colors.red}15;
    padding: 0.5rem 0.75rem;
    border-radius: 6px;
    font-size: 0.875rem;
    font-weight: 500;

    .flag-icon {
      color: ${(props) => props.theme.colors.red};
    }

    span {
      white-space: nowrap;
    }
  }

  .match-percentage {
    font-size: 1.25rem;
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
    background: ${(props) => props.theme.colors.primary}10;
    padding: 0.5rem 1rem;
    border-radius: 20px;
  }

  .verification-summary {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .summary-section {
    background: rgba(217, 217, 217, 0.1);
    padding: 0.75rem;
    border-radius: 0.5rem;
    width: 50%;
  }

  .summary-title {
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &.match {
      color: ${(props) => props.theme.colors.green};
    }

    &.mismatch {
      color: ${(props) => props.theme.colors.red};
    }
  }

  .summary-fields {
    color: #666;
    font-size: 0.9rem;
  }

  .mismatch-detail {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;

    .detail-value {
      color: #999;
      margin: 0 0.25rem;
    }
  }

  .check-icon,
  .x-icon {
    font-weight: bold;
  }
`;
