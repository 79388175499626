import moment from 'moment';
import {
  MilestonesWrapper,
  MileStoneListItem,
} from './hours-management.styled';
import { StatusBadge } from 'components/styled/Badges';
import NoDataFound from 'components/ui/NoDataFound';
import StyledHtmlText from 'components/ui/StyledHtmlText';
import AttachmentPreview from 'components/ui/AttachmentPreview';
import {
  changeStatusDisplayFormat,
  convertToTitleCase,
  numberWithCommas,
  paymentProcessingStatusHandler,
} from 'helpers/utils/misc';

const PAYMENT_STATUS = {
  released: {
    color: 'gray',
  },
  paid: {
    color: 'green',
  },
  under_dispute: {
    color: 'darkPink',
  },
  declined: {
    color: 'darkPink',
  },
  decline: {
    color: 'darkPink',
  },
  cancelled: {
    color: 'darkPink',
  },
  payment_processing: {
    color: 'yellow',
  },
  decline_dispute: {
    color: 'darkPink',
  },
};

const HoursManagement = ({ milestone }: { milestone: any }) => {
  return (
    <MilestonesWrapper>
      {milestone?.length == 0 && <NoDataFound className="py-5" />}

      {milestone?.length > 0 &&
        milestone?.map((item: any, index: number) => (
          <MileStoneListItem key={item?.hourly_id}>
            <div className="d-flex flex-md-row flex-column justify-content-between gap-md-3 gap-4">
              <div>
                <div className="fs-20 fw-400 capital-first-ltr">
                  {item.is_final_milestone
                    ? 'Final Submission'
                    : 'Submission ' + ++index}
                  : {convertToTitleCase(item.title)}
                </div>
                <div className="fs-32 fw-400 line-height-100-perc mt-3">
                  {numberWithCommas(item.total_amount, 'USD')}
                </div>
              </div>
              {item.hourly_status !== 'pending' ? (
                <div className="d-flex flex-column align-items-md-end">
                  {[
                    'paid',
                    'under_dispute',
                    'declined',
                    'decline',
                    'released',
                    'payment_processing',
                    'cancelled',
                    'decline_dispute',
                  ].includes(item.hourly_status) && (
                    <>
                      <div>
                        <StatusBadge
                          color={PAYMENT_STATUS[item.hourly_status]?.color}
                        >
                          {['decline_dispute'].includes(item.hourly_status) &&
                          item?.dispute_submitted_by === 'CLIENT'
                            ? 'Closed by Client'
                            : ['decline_dispute'].includes(
                                item.hourly_status
                              ) && item?.dispute_submitted_by === 'FREELANCER'
                            ? 'Canceled'
                            : ['decline', 'declined'].includes(
                                item.hourly_status
                              )
                            ? 'Declined'
                            : ['cancelled'].includes(item.hourly_status) &&
                              item?.is_paid === 0
                            ? 'Canceled by Freelancer'
                            : item.hourly_status === 'payment_processing'
                            ? paymentProcessingStatusHandler(
                                item?.payment_method
                              )
                            : changeStatusDisplayFormat(
                                item.hourly_status,
                                '_'
                              )}
                        </StatusBadge>
                      </div>
                    </>
                  )}
                  {!!item?.date_created && (
                    <div className="fs-18 fw-400 mt-3">
                      Submitted on
                      {item?.date_created
                        ? ' ' +
                          moment(item?.date_created).format('MMM DD, YYYY')
                        : ' -'}
                    </div>
                  )}
                  {!!item.cancelled_date && (
                    <div className="fs-18 fw-400">
                      Closed on{' '}
                      {item.cancelled_date
                        ? moment(item.cancelled_date).format('MMM DD, YYYY')
                        : ''}
                    </div>
                  )}
                  {item?.hourly_status == 'paid' && (
                    <div className="fs-18 fw-400">
                      Paid on
                      {item?.paid_date
                        ? ' ' + moment(item?.paid_date).format('MMM DD, YYYY')
                        : ' -'}
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            <div className="d-flex mt-md-3 flex-md-row flex-column justify-content-between align-items-md-end gap-3">
              <div>
                <StyledHtmlText
                  needToBeShorten
                  htmlString={item.description}
                  id={`mstone_${item.hourly_id}`}
                />
                {item?.attachments ? (
                  <div className="mt-3 d-flex">
                    <AttachmentPreview
                      uploadedFile={item?.attachments}
                      removable={false}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </MileStoneListItem>
        ))}
    </MilestonesWrapper>
  );
};

export default HoursManagement;
