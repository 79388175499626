import { breakpoints } from 'helpers/hooks/useResponsive';
import { pxToRem } from 'helpers/utils/misc';
import styled from 'styled-components';

export const MessageContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 0.75rem;
  margin: 1.5rem auto;
  display: flex;
  box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  @media ${breakpoints.mobile} {
    flex-direction: column;
  }
  .m-panel--header {
    height: 70px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray6};
    padding: 0 2rem;
  }
  .title {
    line-height: 70px;
    font-size: 1.5rem;
  }
  .m-userlist--wrapper {
    width: ${pxToRem(285)};
    border-right: 1px solid ${(props) => props.theme.colors.gray6};
    @media ${breakpoints.mobile} {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray6};
      padding-bottom: 1rem;
    }
  }
`;

export const NoMessagesFound = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const MessageJobDropdown = styled.div`
  // position: fixed;
  width: 100%;
  background: white;
  padding-bottom: 1rem;
  // top: 0px;
  padding-top: 2rem;
  z-index: 10;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 1rem;
  padding: 2rem 1rem;

  .job-desc {
    font-size: 15px;
    color: #2c2c2c;
  }
`;

export const MessageBubbleWrapper = styled.div`
  margin: auto;
  /*  width: 70%; */
`;

export const MessageHeadBox = styled.div`
  top: 0;
  padding-top: 1rem;
  position: sticky;
  z-index: 10;
  backdrop-filter: blur(10px);
`;
