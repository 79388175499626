import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { MdFlag } from 'react-icons/md';
import { AddNoteModal } from './AddNoteModal';
import { StyledButton } from 'components/forms/Buttons';
import { TAdminNote } from 'helpers/types/adminNotes.type';
import { MdDelete } from 'react-icons/md';
import { editUser } from 'helpers/http/freelancer';
import toast from 'react-hot-toast';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { TUser } from 'helpers/types/user.type';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &[data-isDisabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }
  .notes {
    *,
    p {
      margin: 0px;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 100%;
    .row .col {
      padding: 0;
    }
    .note {
      display: flex;
      margin: 20px 10px;
      flex-direction: column;
      justify-content: space-between;
      white-space: pre-line;
      padding: 20px 20px 4px 20px;
      border: 1px solid #ccc;
      border-radius: 10px;
      position: relative;
      background-color: white;
      .flag-icon,
      .delete-icon {
        cursor: pointer;
        display: none;
        position: absolute;
        right: 4px;
        top: 4px;
      }
      .delete-icon {
        right: 25px;
      }
      hr {
        margin: 10px 0px 4px 0px;
      }
      .created-date {
        margin: 0px;
        font-size: 14px;
      }
      &:hover {
        border: 1px solid #000;
        cursor: pointer;
        .flag-icon,
        .delete-icon {
          display: block;
        }
      }
    }
  }
`;

export const AdminNoteDiv = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  *,
  p {
    margin: 0px;
  }
`;

type Props = {
  userId: string;
  data: any;
  refetch: () => void;
  isFetching: boolean;
};

export const AdminNotes = ({ userId, data, refetch, isFetching }: Props) => {
  const [notes, setNotes] = useState<TAdminNote[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNote, setSelectedNote] = useState<
    (typeof notes)[0] | undefined
  >(undefined);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    !isFetching && setIsDisabled(false);
  }, [isFetching]);

  useEffect(() => {
    if (data?.admin_notes) {
      setNotes(
        data?.admin_notes?.sort((x, y) => moment(y.date).diff(moment(x.date)))
      );
    }
  }, [data]);

  const updateNotes = (payload: TAdminNote[]) => {
    if (isLoading) return;

    const snapShot = [...notes];
    setNotes(payload.sort((x, y) => moment(y.date).diff(moment(x.date))));

    setIsLoading(true);
    const reqPayload: Partial<TUser> = {
      user_id: userId,
      admin_notes: payload,
    };

    // Adding flag to account so that it moves to flagged accounts table
    // whenever note is marked flagged then account should move flagged accounts table
    if (payload.findIndex((note) => note.isFlagged) >= 0) {
      reqPayload['is_flagged'] = 1;
    }

    toast.promise(editUser(reqPayload), {
      loading: 'Loading...',
      success: () => {
        setIsLoading(false);
        refetch();
        return 'Notes Updated';
      },
      error: (err) => {
        setIsLoading(false);
        setNotes(snapShot);
        console.error(err);
        return (
          err?.response?.data?.message ||
          err?.message ||
          'Something went wrong while updating notes'
        );
      },
    });
  };

  const handleAddNote = (data: string) => {
    let notesCopy = [...notes];
    if (selectedNote) {
      const index = notesCopy.findIndex((note) => note.id === selectedNote.id);
      if (index >= 0)
        notesCopy[index] = {
          ...notesCopy[index],
          date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
          text: data,
        };
    } else {
      notesCopy = [
        {
          id: Date.now().toString(),
          date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
          isFlagged: false,
          text: data,
        },
        ...notesCopy,
      ];
    }
    updateNotes(notesCopy);
  };

  const handleAddFlag = (e: React.MouseEvent, note: TAdminNote) => {
    e.stopPropagation();
    const notesCopy = [...notes].map((x) => ({
      ...x,
      isFlagged: false,
    }));
    if (!note.isFlagged) {
      const index = notesCopy.findIndex((x) => x.id === note.id);
      if (index >= 0) notesCopy[index].isFlagged = true;
      else return;
    }
    updateNotes(notesCopy);
  };

  const handleDeleteNote = (e: React.MouseEvent, note: TAdminNote) => {
    e.stopPropagation();
    const notesCopy = [...notes];
    const index = notesCopy.findIndex((x) => x.id === note.id);
    if (index >= 0) notesCopy.splice(index, 1);
    updateNotes(notesCopy);
  };
  const ColumnBasedData = useMemo(() => {
    const columnCount = 4;
    const data: TAdminNote[][] = new Array(columnCount)
      .fill(undefined)
      .map(() => []);
    let colIndex = 0;
    for (let i = 0; i < notes.length; i++) {
      if (colIndex === columnCount) colIndex = 0;

      if (!Array.isArray(data[colIndex])) data[colIndex] = [];
      data[colIndex].push(notes[i]);
      colIndex++;
    }
    return data;
  }, [notes]);

  return (
    <Container key={userId} data-isDisabled={isDisabled}>
      <StyledButton onClick={() => setIsModalOpen(true)}>Add Note</StyledButton>
      <div className="notes">
        <Row className="w-100">
          {ColumnBasedData.map((item, i) => (
            <Col key={i}>
              {item.map((note) => (
                <div
                  data-id={note.id}
                  className="note"
                  key={note.id}
                  onClick={() => {
                    setSelectedNote(note);
                    setIsModalOpen(true);
                  }}
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        {!note.isFlagged ? 'Flag' : 'Remove Flag'}
                      </Tooltip>
                    }
                  >
                    <div
                      className={`flag-icon ${note.isFlagged ? 'd-block' : ''}`}
                    >
                      <MdFlag
                        size={20}
                        onClick={(e) => handleAddFlag(e, note)}
                      />
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Delete Note</Tooltip>}
                  >
                    <div className="delete-icon">
                      <MdDelete
                        size={20}
                        onClick={(e) => handleDeleteNote(e, note)}
                      />
                    </div>
                  </OverlayTrigger>
                  <AdminNoteDiv
                    dangerouslySetInnerHTML={{ __html: note.text }}
                  ></AdminNoteDiv>
                  <div>
                    <hr />
                    <p className="created-date">
                      {moment
                        .utc(note.date)
                        .local()
                        .format('DD MMM YYYY hh:mm A')}
                    </p>
                  </div>
                </div>
              ))}
            </Col>
          ))}
        </Row>
      </div>
      {isModalOpen && (
        <AddNoteModal
          selectedNote={selectedNote}
          show
          onClose={() => {
            setSelectedNote(undefined);
            setIsModalOpen(false);
          }}
          onSubmit={handleAddNote}
        />
      )}
    </Container>
  );
};
