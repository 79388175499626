import styled from 'styled-components';

export const Wrapper = styled.section``;

export const DraftProspectJobBanner = styled.div`
  padding: 2.25rem;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
  background: ${(props) => props.theme.colors.white};
  margin: 2.25rem 0rem 0rem 0rem;
  border-radius: 12px;
  border: ${(props) => `1px solid ${props.theme.colors.yellow}`};
  .banner-title {
    line-height: 2.1rem;
    word-wrap: break-word;
    max-width: 100%;
  }
  .light-text {
    opacity: 0.5;
  }
  .divider {
    opacity: 0.1;
  }
  .location {
    background: ${(props) => props.theme.colors.body};
    padding: 0.375rem 0.875rem;
    border-radius: 1rem;
  }
`;

export const InProgressClosedJobWrapper = styled.div`
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
  background: ${(props) => props.theme.colors.white};
  margin: 2.25rem 0rem 0rem 0rem;
  border-radius: 12px;
  border: ${(props) => `1px solid ${props.theme.colors.yellow}`};
  .header {
    padding: 2.25rem;
  }
  .banner-title {
    line-height: 2.1rem;
    word-wrap: break-word;
  }
  .job-basic-details {
    gap: 1.25rem;
  }
  .img {
    height: 9.5625rem;
    width: 9.5625rem;
    border-radius: 50%;
    object-fit: cover;
  }
  .posted-by-avatar {
    height: 5.25rem;
    width: 5.25rem;
    border-radius: 50%;
    margin-right: 1rem;
  }
  .budget-and-earnings {
    border-top: ${(props) => `1px solid ${props.theme.colors.yellow}`};
    padding: 2.25rem;
  }
  .divider {
    width: 1px;
    height: 58px;
    background-color: #000;
  }
  .light-text {
    opacity: 0.5;
  }
`;
