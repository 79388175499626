export const TABS = [
  {
    id: 0,
    label: 'General Details',
    key: 'gen_details',
  },
  {
    id: 1,
    label: 'Milestones',
    key: 'milestones',
  },
];

interface PaymentStatusEnumTypes {
  [propertyName: string]: {
    color: string | any;
    label: string;
  };
}

export const PAYMENT_STATUS: PaymentStatusEnumTypes = {
  pending: {
    color: 'yellow',
    label: 'Milestone Proposal Pending',
  },
  released: {
    color: 'gray',
    label: 'Paid',
  },
  paid: {
    color: 'green',
    label: 'Milestone Payment Deposited',
  },
  under_dispute: {
    color: 'darkPink',
    label: 'Under Dispute',
  },
  waiting_for_release: {
    color: 'yellow',
    label: 'Waiting For Release',
  },
  decline: {
    color: 'darkPink',
    label: 'Milestone Terminated',
  },
  declined: {
    color: 'darkPink',
    label: 'Milestone Terminated',
  },
  payment_processing: {
    color: 'yellow',
    label: 'Payment Processing',
  },
  cancelled: {
    color: 'darkPink',
    label: 'Canceled by Freelancer',
  },
  request_revision: {
    color: 'yellow',
    label: 'Revisions Requested',
  },
};
