import { pxToRem } from 'helpers/utils/misc';
import { createGlobalStyle } from 'styled-components';
import { myTheme } from './theme';
export const MyGlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${myTheme.font.primary};
    scroll-behavior: smooth;
    background: #FEFBF4;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  .btn-check:focus+.btn, .btn:focus{
    box-shadow: none;
  }
  a{
    text-decoration: none;
    color: inherit;
  }
  .form-check-input {
    height: 24px;
    width: 24px;
    border-radius: 4px;
  }
  .form-check-input:checked{
    background-color:#0067FF;
  }
  .btn.btn-link{
    text-decoration: none;
    /* todo : refactor color */
    color: #283EFF;
  }
  button.btn:not(.btn-link, .btn-transparent) {
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.2) -2px 2px 6px 0px;
      transform: translateY(-2px);
      /* rotate(2deg); */
    }
  }
  .heading-sm{
    color: #0D0D0D;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5;
  }
  .modal-open  .App{ 
    filter: blur(2.5px);
  }
  .delete-listing-modal{
    .modal-dialog{
      min-width: 500px;
    }
    .modal-body{
      padding: 2rem;
    }
    .promptTitle{
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 1.75rem;
    }
    .promptMsg{
      text-align: left!important;
    }
  }
  .pointer{
    cursor: pointer;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #CACACA;
  }

  .btn.btn-secondary{
    background-color: #E7E7E7;
    color: #000000;
    border: none;
  }
  .stripeEleClass{
    background-color: red;
  }
  /* input */
  .password-input{
    position: relative;
    .input-icon{
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 25px;
      height: 25px;
      stroke: #A1A1A1;
      &.active{
        stroke: ${(props) => props.theme.colors.blue};
      }
    }
  }
  /* TODO: move */
  .budget-tooltip{
    .rc-slider-tooltip-inner{
      box-shadow: none;
      font-family: ${myTheme.font.primary};
      background-color: transparent;
      color: #000;
      font-size: ${pxToRem(14)};
      letter-spacing: .25px;
    }
  }
  .width-fit-content {
    width: fit-content
  }
  .ck-editor__editable:not(.ck-editor__nested-editable) { 
    max-height: 300px;
}
/* overwrites */
.btn-primary {
      color: ${myTheme.button.primary.color};
      background-color: ${myTheme.button.primary.bg};
      border-color: ${myTheme.button.primary.bg};
      &:hover, &:focus, :disabled{
        background-color: ${myTheme.button.primary.bg} !important;
        color: ${myTheme.button.primary.color};
        border-color: ${myTheme.button.primary.bg};
      }
      &:focus {
        box-shadow: none !important;
        border: initial;
      }
  }
  .opacity-4 {
    opacity: 0.4;
  }
  .spinner-border {
    font-size: 0.75rem;
  }
`;
