import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import BackButton from 'components/ui/BackButton';
import Tabs from 'components/ui/Tabs';
import { Wrapper } from './client-jobs.styled';
import { TABS } from './consts';

const ClientJobs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>('active');
  const { clientId, clientName } = useParams<{
    clientId: string;
    clientName: string;
  }>();

  const onTabChange = (value: string) => {
    /* This function will make the selected tab as active and change the below content */
    setActiveTab(value);
    navigate(`/client-jobs/${clientId}/${clientName}/` + value, {
      replace: true,
    });
  };

  useEffect(() => {
    const decodedUrl = decodeURIComponent(location.pathname);
    const subUrl = decodedUrl.split(
      `/client-jobs/${clientId}/${clientName}/`
    )[1];

    if (subUrl) {
      setActiveTab(subUrl);
    }
  }, [clientId, clientName, location.pathname]);

  return (
    <Wrapper>
      <BackButton
        propsElement={
          <div className="d-flex align-items-center">
            <div className="ms-2 fs-18 fw-400 text-capitalize">{`| Clients / ${clientName} / Jobs`}</div>
          </div>
        }
      />
      <h1 className="fs-32 fw-700 text-capitalize mt-4">{clientName}'s Jobs</h1>
      <Tabs
        activeTab={activeTab}
        tabs={TABS}
        onTabChange={onTabChange}
        className="my-4"
      />
      <Outlet context={{ clientId, clientName }} />
    </Wrapper>
  );
};

export default ClientJobs;
