import { Button, Modal, Spinner } from 'react-bootstrap';
import { StyledModal } from 'components/styled/StyledModal';
import { useQuery } from 'react-query';
import { DisputeWrapper } from './support-details.styled';
import Loader from 'components/Loader';
import StyledHtmlText from 'components/ui/StyledHtmlText';
import { StatusBadge } from 'components/styled/Badges';
import { StyledButton } from 'components/forms/Buttons';
import { capitalizeFirstLetter } from 'helpers/utils/misc';
import { getSupportDetails } from 'helpers/http/dashboard';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as CallIcon } from 'assets/icons/call.svg';
import { ReactComponent as BlueDownArrowIcon } from 'assets/icons/arrow-down-blue.svg';
import { ReactComponent as CrownIcon } from 'assets/icons/crown.svg';
import { ReactComponent as GreenTickIcon } from 'assets/icons/check-mark-green-large.svg';
import { deleteUser, resolveInquiry } from 'helpers/http/common';
import toast from 'react-hot-toast';

type Props = {
  show: boolean;
  onCloseModal: () => void;
  selectedSupportItem: any;
  onResolve?: () => void;
  refetch: () => void;
  openResolveAcceptModal?: () => void;
};

const SupportDetails = ({
  show,
  onCloseModal,
  selectedSupportItem,
  onResolve,
  refetch,
  openResolveAcceptModal,
}: Props) => {
  /* This will fetch the inquiry or dispute details */
  // const id = selectedSupportItem?.dispute_id
  //   ? selectedSupportItem?.dispute_id
  //   : selectedSupportItem?.inquiry_id
  //   ? selectedSupportItem?.inquiry_id
  //   : selectedSupportItem?.user_id;
  const id = selectedSupportItem?.dispute_id
    ? selectedSupportItem?.dispute_id
    : selectedSupportItem?.inquiry_id
    ? selectedSupportItem?.inquiry_id
    : selectedSupportItem?.user_id;
  const type = selectedSupportItem?.dispute_id
    ? 'Dispute'
    : selectedSupportItem?.inquiry_id
    ? 'General'
    : 'Closures';

  const { data, isLoading, isRefetching } = useQuery(
    ['get-support-details', id, type],
    getSupportDetails,
    { enabled: !!id }
  );
  const openFile = (file: string) => () => {
    /* This will open the attachment in a new tab */
    window.open(file, 'blank');
  };

  const otherUserData =
    data?.userdata?.user_type == 'freelancer'
      ? data?.clientdata
      : data?.freelancerdata;

  return (
    <StyledModal
      show={show}
      size="lg"
      onHide={onCloseModal}
      centered
      maxwidth={604}
    >
      <Modal.Body>
        <Button variant="transparent" className="close" onClick={onCloseModal}>
          &times;
        </Button>

        {(isLoading || isRefetching) && <Loader />}

        {!isLoading && !isRefetching && data && (
          <DisputeWrapper className="d-flex flex-column">
            {/* Title | Subtitle and type of support request */}
            <div className="support-header d-flex gap-3 justify-content-between align-items-center">
              <div className="heading-title">
                <div className="fs-24 fw-700 d-flex align-items-center">
                  {data?.subject || data?.jobdata?.job_title}
                  &nbsp;
                  {isRefetching && (
                    <div>
                      <Spinner size="sm" animation="grow" />
                    </div>
                  )}
                </div>
                {data?.dispute_id && (
                  <div className="fs-18 fw-400 mt-2 light-black">
                    {data?.milestone?.title}
                  </div>
                )}
              </div>
              <div className="d-flex flex-column align-items-end">
                {(data?.dispute_id || data?.inquiry_id) && (
                  <StatusBadge color={data?.inquiry_id ? 'blue' : 'darkPink'}>
                    {data?.inquiry_id ? 'General' : 'Dispute'}
                  </StatusBadge>
                )}
                {data.user_id && (
                  <StatusBadge color={'red'}>{'Closure'}</StatusBadge>
                )}
                {(data?.dispute_status == 'closed' ||
                  data?.status == 'closed') && (
                  <StatusBadge color="green" className="mt-2">
                    Closed
                  </StatusBadge>
                )}
              </div>
            </div>

            {/* Submitted by */}
            <div className="support-request--by p-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="light-black light-text fs-sm fw-400">
                  SUBMITTED BY
                </div>
                {data?.dispute_status == 'closed' &&
                  data?.submitted_by === data?.resolved_for?.toUpperCase() && (
                    <GreenTickIcon />
                  )}
              </div>
              <div className="mt-3 d-flex align-items-center gap-2">
                {(data?.inquiry_id || data?.dispute_id) && (
                  <img
                    className="support-request--avatar"
                    src={
                      data?.submitted_by === 'CLIENT'
                        ? data?.clientdata?.user_image
                        : data?.userdata?.user_image
                    }
                  />
                )}
                {data?.user_id && (
                  <img
                    className="support-request--avatar"
                    src={data?.user_image}
                  />
                )}
                {(data?.inquiry_id || data?.dispute_id) && (
                  <div className="fs-1rem fw-400 light-black text-capitalize">
                    {data?.submitted_by === 'CLIENT'
                      ? `${data?.clientdata?.first_name} ${data?.clientdata?.last_name} (${data?.clientdata?.user_type})`
                      : `${data?.userdata?.first_name} ${data?.userdata?.last_name} (${data?.userdata?.user_type})`}
                  </div>
                )}
                {data?.user_id && (
                  <div className="fs-1rem fw-400 light-black text-capitalize">
                    {data?.first_name} {data?.last_name} ({data?.user_type})
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center gap-3 mt-3 flex-wrap">
                <div className="user-email d-flex align-items-center gap-2">
                  <EmailIcon />
                  {(data?.inquiry_id || data?.dispute_id) && (
                    <div className="fs-18 fw-400">
                      {data?.submitted_by === 'CLIENT'
                        ? data?.clientdata?.u_email_id
                        : data?.userdata?.u_email_id}
                    </div>
                  )}
                  {data?.user_id && (
                    <div className="fs-18 fw-400">{data?.u_email_id}</div>
                  )}
                </div>
                <div className="divider d-none d-lg-block" />
                <div className="user-email d-flex align-items-center gap-2">
                  <CallIcon />
                  {(data?.inquiry_id || data?.dispute_id) && (
                    <div className="fs-18 fw-400">
                      {data?.submitted_by === 'CLIENT'
                        ? data?.clientdata?.formatted_phonenumber ||
                          data?.clientdata?.phone_number
                        : data?.userdata?.formatted_phonenumber ||
                          data?.userdata?.phone_number}
                    </div>
                  )}
                  {data.user_id && (
                    <div className="fs-18 fw-400">
                      {data?.formatted_phonenumber}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Support request description */}
            <div className="support-description fs-sm fw-400 light-black light-text">
              {id && (
                <StyledHtmlText
                  htmlString={data?.description}
                  needToBeShorten={false}
                  id={`support_${id}`}
                />
              )}
            </div>

            {/* If dispute - Project owner */}
            {data?.dispute_id && (
              <div className="project-owner-details p-4">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="light-black light-text fs-sm fw-400">
                    {data?.submitted_by === 'FREELANCER'
                      ? 'PROJECT OWNER'
                      : 'FREELANCER'}
                  </div>
                  {data?.dispute_status == 'closed' &&
                    data?.submitted_by !==
                      data?.resolved_for?.toUpperCase() && <GreenTickIcon />}
                </div>
                <div className="mt-3 d-flex align-items-center gap-2">
                  <img
                    className="support-request--avatar"
                    src={
                      data?.submitted_by === 'FREELANCER'
                        ? data?.clientdata?.user_image
                        : data?.userdata?.user_image
                    }
                  />
                  <div className="fs-1rem fw-400 light-black text-capitalize">
                    {data?.submitted_by === 'FREELANCER'
                      ? `${data?.clientdata?.first_name} ${data?.clientdata?.last_name} (${data?.clientdata?.user_type})`
                      : `${data?.userdata?.first_name} ${data?.userdata?.last_name} (${data?.userdata?.user_type})`}
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3 gap-3 flex-wrap">
                  <div className="user-email d-flex align-items-center gap-2">
                    <EmailIcon />
                    <div className="fs-18 fw-400">
                      {data?.submitted_by === 'FREELANCER'
                        ? data?.clientdata?.u_email_id
                        : data?.userdata?.u_email_id}
                    </div>
                  </div>
                  <div className="divider d-none d-lg-block" />
                  <div className="user-email d-flex align-items-center gap-2">
                    <CallIcon />
                    <div className="fs-18 fw-400">
                      {data?.submitted_by === 'FREELANCER'
                        ? data?.clientdata?.formatted_phonenumber ||
                          data?.clientdata?.phone_number
                        : data?.userdata?.formatted_phonenumber ||
                          data?.userdata?.phone_number}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Resolve comment */}
            {data?.dispute_id &&
              data.action_log &&
              data?.dispute_status === 'closed' && (
                <div>
                  <div className="fs-1rem fw-700">Note:</div>
                  <div className="mt-1 fs-18 fw-400">
                    {data.action_log[0]?.comment}
                  </div>
                </div>
              )}

            {data?.user_id && data?.deletion_requested_note_user && (
              <div>
                <div className="fs-1rem fw-700">User Note:</div>
                <div className="mt-1 fs-18 fw-400">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.deletion_requested_note_user,
                    }}
                  />
                </div>
              </div>
            )}

            {data?.user_id &&
              data.deletion_requested_note_admin &&
              data?.status === 'closed' && (
                <div>
                  <div className="fs-1rem fw-700">Admin Note:</div>
                  <div className="mt-1 fs-18 fw-400">
                    {data.user_type == 'freelancer' &&
                    data.deletion_requested_note_admin === null
                      ? 'Request canceled by user'
                      : data.deletion_requested_note_admin}
                  </div>
                </div>
              )}

            {data?.inquiry_id &&
              data.action_log &&
              data?.status === 'closed' && (
                <div>
                  <div className="fs-1rem fw-700">Note:</div>
                  <div className="mt-1 fs-18 fw-400">
                    {data.action_log[0]?.respond}
                  </div>
                </div>
              )}

            {/* Support request attachment */}
            {data?.attachment_file && (
              <div className="support-attachment d-flex align-items-center gap-3">
                <div className="fs-1rem fw-300">Attachment:</div>
                {data?.attachment_file ? (
                  <div
                    onClick={openFile(data.attachment_file)}
                    className="support-attachment--link d-flex align-items-center pointer"
                  >
                    {data?.attachment_file?.split('/').pop()}
                    <BlueDownArrowIcon />
                  </div>
                ) : (
                  '-'
                )}
              </div>
            )}
            {/*  This button will be displayed only if the type is dispute */}
            {data?.dispute_id && data?.dispute_status !== 'closed' && (
              <div className="d-flex justify-content-center justify-content-md-end">
                <StyledButton onClick={onResolve}>Mark as closed</StyledButton>
              </div>
            )}
            <div className="d-flex justify-content-center justify-content-md-end gap-2">
              {data?.user_id && data?.status !== 'closed' && (
                // <div className="d-flex justify-content-center justify-content-md-end">
                <StyledButton onClick={openResolveAcceptModal}>
                  Accept
                </StyledButton>
                // </div>
              )}

              {data?.user_id && data?.status !== 'closed' && (
                // <div className="d-flex justify-content-center justify-content-md-end">
                <StyledButton onClick={onResolve}>Cancel</StyledButton>
                // </div>
              )}
            </div>

            {data?.inquiry_id && data?.status !== 'closed' && (
              <div className="d-flex justify-content-center justify-content-md-end">
                <StyledButton onClick={onResolve}>
                  Submit the Decision
                </StyledButton>
              </div>
            )}
          </DisputeWrapper>
        )}
      </Modal.Body>
    </StyledModal>
  );
};

export default SupportDetails;
