const ErrorMessage = ({
  message,
  children,
  className,
}: {
  children?: React.ReactNode;
  message?: string;
  className?: string;
}) => (
  <small className={`text-danger d-block mt-1 ${className}`}>
    {children || message}
  </small>
);
export default ErrorMessage;
