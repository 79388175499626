import React from 'react';
import cns from 'classnames';
import { Col, Row, FloatingLabel, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { StyledButton } from 'components/forms/Buttons';
import AuthLayout from 'components/Layout/AuthLayout';
import ErrorMessage from 'components/ui/ErrorMessage';
import LoadingButtons from 'components/LoadingButtons';
import { useAuth } from 'helpers/contexts/auth-context';
import useRedirectToDashboardIfLoggedIn from 'helpers/hooks/useRedirectToDashboardIfLoggedIn';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';

/* Validation schema */
const schema = yup.object({
  email_id: yup
    .string()
    .required('Email is required.')
    .email('Please enter a valid email.'),
  password: yup.string().required('Password is required.'),
});

export default function Login() {
  useRedirectToDashboardIfLoggedIn();

  const [isPasswordPreview, setIsPasswordPreview] = React.useState(false);
  const togglePasswordPreview = () => setIsPasswordPreview(!isPasswordPreview);

  const { signin, isLoading } = useAuth();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any, e: any) => {
    /* This function will call sign in api */
    e.preventDefault();
    signin(data);
  };

  const { errors } = formState;

  return (
    <AuthLayout center small>
      <h1>Log in to admin portal</h1>

      <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12} className="text-start mb-2">
            <FloatingLabel controlId="email" label="Email Address">
              <Form.Control
                type="email"
                placeholder="Email Address"
                {...register('email_id')}
              />
              <ErrorMessage>{errors.email_id?.message}</ErrorMessage>
            </FloatingLabel>
          </Col>
          <Col lg={12} className="text-start mb-2">
            <FloatingLabel
              controlId="password"
              label="Password"
              className="password-input"
            >
              <span className="pointer" onClick={togglePasswordPreview}>
                <Eye
                  className={cns('input-icon', { active: isPasswordPreview })}
                />
              </span>
              <Form.Control
                type={!isPasswordPreview ? 'password' : 'text'}
                placeholder="Password"
                {...register('password')}
              />
            </FloatingLabel>
            <ErrorMessage>{errors.password?.message}</ErrorMessage>
          </Col>
        </Row>

        <StyledButton
          className="mt-5"
          style={{ padding: '1.125rem 5rem' }}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <LoadingButtons /> : 'Log in'}
        </StyledButton>
      </Form>
    </AuthLayout>
  );
}
