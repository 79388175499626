import { AdminNotes } from 'components/AdminNotes';
import { TuseOutletContext } from 'helpers/types/freelancer.type';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

export const FreelancerAdminNotes = () => {
  const { freelancerId, profileData, refetch, isRefetching } =
    useOutletContext<TuseOutletContext>();

  return (
    <AdminNotes
      userId={freelancerId}
      data={profileData}
      refetch={refetch}
      isFetching={isRefetching}
    />
  );
};
