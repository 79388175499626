import { FreelancerProvider } from './controller/freelancer';
import Freelancers from './Freelancers';

// eslint-disable-next-line react/display-name
export default function () {
  return (
    <FreelancerProvider>
      <Freelancers />
    </FreelancerProvider>
  );
}
