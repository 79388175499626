/*
 * This component is a skeleton for the different sections in profile - freelancer side,
  which will have title and add/edit icon at top
 */
import styled from 'styled-components';

const ProfileDetailSectionStyled = styled.div<{ fullWidth: boolean }>`
  background: rgba(255, 255, 255, 0.7);
  height: 100%;
  padding: 2rem;
  border-radius: 0.875rem;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  .button {
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: translateY(-2px);
    }
  }
  @media screen and (max-width: 768px) {
    margin: 0.5rem 0;
    height: auto;
  }
  .about-me {
    line-height: 2.25em;
  }
  .description {
    color: #595959;
    line-height: 180%;
  }
  .skills {
    gap: 10px;
  }
  .education-details {
    gap: 1.375rem;
  }
  .course-name {
    line-height: 1.6rem;
  }
  .education-description {
    opacity: 0.7;
    margin-top: 10px;
    line-height: 1.44rem;
  }
  .education-school-img {
    height: 92px;
    width: 92px;
  }
  .course-certi-details {
    padding: 1.5rem;
    border: 1px solid #dddddd;
    border-radius: 15px;
    word-break: break-word;
  }
  .view-more-btn {
    color: ${(props) => props.theme.colors.lightBlue};
  }
  .add-btn {
    color: ${(props) => props.theme.colors.lightBlue};
  }
  .delete-btn {
    margin-top: 0.75rem;
  }
  .list {
    max-height: 300px;
    overflow-y: auto;
  }
`;

const ProfileDetailSection = ({
  title,
  details,
  fullWidth = false,
}: {
  title: string;
  details?: React.ReactNode;
  fullWidth?: boolean;
}) => {
  return (
    <ProfileDetailSectionStyled
      fullWidth={fullWidth}
      className="d-flex flex-column gap-3"
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="fs-24 fw-400">{title}</div>
      </div>
      {details}
    </ProfileDetailSectionStyled>
  );
};

export default ProfileDetailSection;
