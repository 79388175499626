import { useEffect, useState } from 'react';
import { Wrapper } from './mails.styled';
import CustomSelect from 'components/custom-select';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import moment from 'moment';
import { Filters, LogTypes } from './logs.types';
import { getSystemLogs } from 'helpers/http/common';
import { StyledTable } from 'components/styled/StyledTable';
import { Spinner } from 'react-bootstrap';
import NoDataFound from 'components/ui/NoDataFound';
import PaginationComponent from 'components/ui/Pagination';

const limitOptions = [
  { value: 10, label: '10' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500' },
  { value: 1000, label: '1000' },
];

const SystemLog = () => {
  const [logs, setLogs] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(10);
  const [filters, setFilters] = useState<any>({
    date: [moment().subtract(1, 'weeks').toDate(), new Date()],
    keyword: '',
    limit: 10,
    page: 1,
  });

  const fetchLogs = async () => {
    setLoading(true);

    const { date, limit, page } = filters;
    const payload = {
      start_date: date[0],
      end_date: date[1],
      limit,
      page,
    };
    const { data, count } = await getSystemLogs(payload);

    setLogs(data);
    setPagination(count / filters.limit);
    setLoading(false);
  };

  const onPageChange = ({ selected }) => {
    setFilters({ ...filters, page: selected + 1 });
  };

  const dateFilterHandler = (dateArr: DateObject[]) => {
    let startDate: any = dateArr[0];
    let endDate: any = dateArr[1];
    if (!startDate || !endDate) return;

    startDate = startDate.toDate();
    endDate = endDate.toDate();

    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);

    if (!momentStartDate.isBefore(momentEndDate)) {
      setFilters({ ...filters, date: [endDate, startDate], page: 1 });
    } else {
      setFilters({ ...filters, date: [startDate, endDate], page: 1 });
    }
  };

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  console.log('filters.limit: ', filters.limit);
  return (
    <Wrapper>
      <div className="d-flex align-items-center my-3 mb-5">
        <h1 className="fs-32 fw-700 mb-0">System Logs</h1>
      </div>

      {/* Search box and status filters*/}
      <div className="d-flex align-items-center justify-content-start flex-wrap gap-3 w-100 filters">
        <CustomSelect
          bordered
          selected={0}
          options={limitOptions}
          onChange={({ value: limit }) => {
            setFilters({ ...filters, limit, page: 1 });
          }}
          title="Limit"
        />

        <DatePicker
          onChange={dateFilterHandler}
          value={filters.date}
          range
          dateSeparator=" - "
          placeholder="Select Date"
          className="yellow-border"
          style={{ border: '1px solid #f2b420' }}
        />
      </div>

      {/* Listing table */}
      <div className="listing-table p-3">
        <div className="listings">
          <StyledTable responsive>
            {/* Table heading */}
            <thead>
              <tr>
                <th>First Name</th>
                <th>Message</th>
                <th>Operation</th>
                <th>Email</th>
                <th>User Type</th>
                <th>Created At</th>
              </tr>
            </thead>

            {/* Table body and main listing of freelancers */}
            <tbody className="scroll">
              {loading && (
                <tr>
                  <td colSpan={5}>
                    <div className="text-center py-5">
                      <Spinner animation="border" />
                    </div>
                  </td>
                </tr>
              )}
              {!loading &&
                logs &&
                logs?.length > 0 &&
                logs?.map((item: LogTypes) => {
                  return (
                    <tr key={item?.id} className="align-middle">
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="fs-1rem fw-400">
                            {item?.first_name}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className=" fs-1rem fw-400">{item?.message}</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div
                            className="fs-1rem fw-400"
                            style={{
                              width: '100%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {item?.operation}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className=" fs-1rem fw-400">
                            {item?.u_email_id}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className=" fs-1rem fw-400">
                            {item?.user_type}
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <div className=" fs-1rem fw-400">
                            {moment(item?.created_at).format('MMM DD, YYYY') ||
                              '-'}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              {!loading && logs?.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <div className="my-5 py-5">
                      <NoDataFound />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </StyledTable>

          {/* Pagination */}
          {!loading && (
            <div className="d-flex justify-content-center mt-3">
              <PaginationComponent
                total={pagination}
                onPageChange={(page) => onPageChange(page)}
                currentPage={filters.page}
              />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default SystemLog;
