/*
 * This component serves the banner od the profile page on freelancer side
 */
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ProfileBannerWrapper } from '../freelancer-profile.styled';
import { StatusBadge } from 'components/styled/Badges';
import { numberWithCommas, separateValuesWithComma } from 'helpers/utils/misc';
import { editUser } from 'helpers/http/freelancer';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as DollarCircleIcon } from 'assets/icons/dollar-circle.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location-blue.svg';
import { ReactComponent as GreenTickIcon } from 'assets/icons/tick-green.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cancel-red.svg';
import { ReactComponent as CallIcon } from 'assets/icons/call.svg';
import MoreButton from 'pages/freelancers/partials/MoreButton';
import { EditBasicDetailsModal } from './EditBasicDetailsModal';
import moment from 'moment';

const ProfileBanner = ({
  data,
  refetch,
}: {
  data: any;
  refetch: () => void;
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditBasicDetailsModalOpen, setIsEditBasicDetailsModalOpen] =
    useState(false);

  const onApproveOrReject = (approve: 0 | 1 | null) => () => {
    /*
     * This function will either either approve, reject and set under review the freelancer's registration request based on approve parame's value
     * If approve = 1 => Approve reg. request
     * If approve = 0 => Reject reg. request
     * If approve = null => set it to under review status
     */
    if (!loading) {
      setLoading(true);

      /* Api call for approve or reject registration request */
      const promise = editUser({
        is_account_approved: approve,
        user_id: data?.user_id,
      });

      toast.promise(promise, {
        loading: 'Loading...',
        success: (res) => {
          setLoading(false);

          // if account is approved or set under review then refetching data
          if (approve || approve === null) {
            refetch();
            // if account is declined then navigating back
          } else {
            navigate(-1);
          }
          return res.message;
        },
        error: (err) => {
          setLoading(false);
          return err?.response?.data?.message || 'error';
        },
      });
    }
  };
  if (!data) {
    return null;
  }
  return (
    <>
      <ProfileBannerWrapper className="d-flex flex-wrap justify-content-between mt-4">
        <div className="profile__img-and-details d-flex flex-wrap align-items-center">
          {/* Profile picture */}

          <div className="profile__img">
            <img className="img" src={data?.user_image} />
          </div>

          <div className="profile__details d-flex flex-column">
            {/* Name and designation */}

            <div className="profile__name-title">
              <div className="d-flex align-items-center gap-3 flex-wrap">
                <div className="profile__username fs-24 fw-400">
                  {data?.first_name} {data?.last_name}
                </div>
                {data?.is_agency ? (
                  <StatusBadge color="blue">Agency</StatusBadge>
                ) : null}
              </div>
              {data.job_title && (
                <div className="profile__description fs-18 fw-400">
                  {data.job_title}
                </div>
              )}
            </div>

            {/* Contact details */}

            <div className="profile__contact d-flex align-items-center flex-wrap">
              <div className="profile__contact-item d-flex align-items-center gap-2">
                <EmailIcon />
                <div className="profile__contact-item-value fs-18 fw-400">
                  {data?.u_email_id}
                </div>
              </div>
              <div className="divider d-none d-lg-block" />
              <div className="profile__contact-item d-flex align-items-center">
                <CallIcon />
                <div className="profile__contact-item-value fs-18 fw-400">
                  {data?.formatted_phonenumber || data?.phone_number}
                </div>
              </div>
            </div>

            <div className="profile__contact d-flex align-items-center flex-wrap">
              {data?.date_created && (
                <div className="profile__contact-item d-flex align-items-center gap-2">
                  <b>Applied Date:</b>
                  <div className="profile__contact-item-value fs-18 fw-400">
                    {moment(data.date_created).format('MMM DD, YYYY')}
                  </div>
                </div>
              )}
              {data?.freelancer_approved_date && (
                <>
                  <div className="divider d-none d-lg-block" />
                  <div className="profile__contact-item d-flex align-items-center">
                    <b>Approved Date:</b>
                    <div className="profile__contact-item-value fs-18 fw-400">
                      {moment(data.freelancer_approved_date).format(
                        'MMM DD, YYYY'
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* Hourly rate - location - group */}

            <div className="budget-and-location d-flex align-items-center flex-wrap">
              <div className="profile__badge d-flex align-items-center">
                <DollarCircleIcon />
                <div className="fs-1rem fw-400">
                  {numberWithCommas(data?.hourly_rate, 'USD')}
                  <span className="budget-and-location-gray-text fs-1rem fw-400">
                    /hr
                  </span>
                </div>
              </div>
              {(data?.location?.state || data?.location?.country_name) && (
                <div className="profile__badge d-flex align-items-center">
                  <LocationIcon />
                  <div className="budget-and-location-gray-text fs-1rem fw-400">
                    {separateValuesWithComma([
                      data?.location?.state,
                      data?.location?.country_name,
                    ])}
                  </div>
                </div>
              )}
              {/* {data?.user_group?.group_name && (
                <div className="profile__badge d-flex align-items-center">
                  <GroupIcon />
                  <div className="budget-and-location-gray-text fs-1rem fw-400">
                    {data?.user_group?.group_name}
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-center gap-4">
          {!data?.is_deleted && (
            <span
              className="mt-2 pointer text-primary"
              onClick={() => setIsEditBasicDetailsModalOpen(true)}
            >
              Edit
            </span>
          )}
          {data?.is_account_approved == null && (
            <div className={loading ? 'opacity-4' : ''}>
              <div
                className="accept-decline-btn d-flex align-items-center pointer"
                onClick={onApproveOrReject(1)}
              >
                <GreenTickIcon />
                &nbsp;Accept
              </div>
              <div
                className="accept-decline-btn mt-3 d-flex align-items-center pointer"
                onClick={onApproveOrReject(0)}
              >
                <CrossIcon />
                &nbsp;Decline
              </div>
            </div>
          )}

          {data?.is_account_approved !== null && (
            <div
              className="d-flex flex-direction-column h-25"
              style={{ gap: '10px' }}
            >
              <StatusBadge
                color={
                  data?.is_deleted
                    ? 'gray'
                    : data?.is_account_approved === 0
                    ? 'darkPink'
                    : 'green'
                }
              >
                {data?.is_deleted
                  ? 'Deleted'
                  : data?.is_account_approved === 0
                  ? 'Declined'
                  : 'Approved'}
              </StatusBadge>

              {/* If account is declined then showing two options
              1. Approve
              2. Set under review
            */}
              {data?.is_account_approved === 0 && (
                <div onClick={(e) => e.stopPropagation()}>
                  <MoreButton
                    isFlagged
                    onFlag={() => {
                      //
                    }}
                    onApprove={onApproveOrReject(1)}
                    onDecline={onApproveOrReject(0)}
                    onSetUnderReview={onApproveOrReject(null)}
                    onLoginAsUser={() => {
                      return '';
                    }}
                    disabled={loading}
                    isLoginAsUser={data?.is_account_approved}
                    showLoginAsUser={false}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </ProfileBannerWrapper>
      {isEditBasicDetailsModalOpen && (
        <EditBasicDetailsModal
          data={data}
          show={isEditBasicDetailsModalOpen}
          onClose={() => {
            refetch();
            setIsEditBasicDetailsModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ProfileBanner;
