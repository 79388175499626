/*
 * This is a modal that asks to select status of the job while ending the job
 */
import { useEffect, useState, useCallback } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { StyledButton } from 'components/forms/Buttons';
import { StyledModal } from 'components/styled/StyledModal';
import { ReactComponent as ArrowDown } from 'assets/icons/down-arrow.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/chevronUp.svg';
import { endJob } from 'helpers/http/common';

type Props = {
  show: boolean;
  toggle: () => void;
  jobId: string;
  onEndJob: () => void;
  onError: (msg: string) => void;
};

const Wrapper = styled.div`
  .content {
    gap: 2.75rem;
    margin-top: 2.75rem;
  }
  .status-options {
    gap: 1.25rem;
    margin-top: 1.25rem;
    .option {
      border-radius: 0.875rem;
      border: 1px solid #d9d9d9;
      padding: 1.25rem 0;
    }
    .selected {
      border: ${(props) => `2px solid ${props.theme.colors.lightBlue}`};
    }
  }
  .dropdown-button {
    padding: 1rem 1.25rem;
    border: ${(props) => `1px solid ${props.theme.colors.black}`};
    border-radius: 7px;
    margin-top: 0.75rem;
  }
  .dropdown-options {
    margin-top: 4px;
    border-radius: 8px;
    border: ${(props) => `1px solid ${props.theme.colors.black}`};
    padding-bottom: 0.5rem;
    .option {
      padding: 1rem 1rem 0.5rem 1rem;
      border-radius: 8px;
      &:hover {
        background: ${(props) => props.theme.colors.gray2};
      }
    }
  }
  .form-input {
    padding: 1rem 1.25rem;
    border-radius: 7px;
    border: ${(props) => `1px solid ${props.theme.colors.gray6}`};
  }
`;

const JOB_ENDING_REASONS = ['Not responding', 'Low work quality', 'Other'];

const initialState = {
  selectedStatus: '',
  endingReason: '',
  incompleteJobDescription: '',
  loading: false,
};

const EndJobModal = ({ show, toggle, jobId, onEndJob, onError }: Props) => {
  const [formState, setFormState] = useState(initialState);

  const handleChange = useCallback((field, value) => {
    setFormState((prevFormState: any) => {
      return { ...prevFormState, [field]: value };
    });
  }, []);

  const [showDropdownOptions, setShowDropdownOptions] =
    useState<boolean>(false);

  useEffect(() => {
    if (!show) {
      setFormState(initialState);
    }
  }, [show]);

  const onNext = () => {
    toast.dismiss();
    const { selectedStatus, endingReason, incompleteJobDescription } =
      formState;

    if (!selectedStatus) {
      toast.error('Please select the job status');
      return;
    }
    if (selectedStatus === 'in-complete') {
      if (endingReason === '') {
        toast.error('Please select the reason why are you ending the job');
        return;
      }
      if (incompleteJobDescription === '') {
        toast.error('Please elaborate the reason why are you ending the job');
        return;
      }
    }

    handleChange('loading', true);
    handleEndJob(selectedStatus, endingReason, incompleteJobDescription);
  };

  const handleEndJob = (
    selectedStatus,
    endingReason,
    incompleteJobDescription
  ) => {
    // End job api call
    const body: any = {
      job_id: jobId,
      status: selectedStatus == 'closed' ? 'closed' : 'in-complete',
      rate: 0,
      description: incompleteJobDescription,
    };
    if (selectedStatus == 'in-complete') {
      body.reason = endingReason;
      body.incomplete_description = incompleteJobDescription;
    }

    toast.loading('Please wait...');

    endJob(body)
      .then((res) => {
        if (res.status) {
          toggle();
          onEndJob();
          toast.success(res.message);
        } else {
          onError(res.message);
        }
        toast.dismiss();
      })
      .catch((err) => {
        onError(err?.response?.data?.message);
        toast.dismiss();
      });
  };

  const onSelectReason = (item: any) => () => {
    handleChange('endingReason', item);
    toggleDropdownOptions();
  };

  const toggleDropdownOptions = () => {
    setShowDropdownOptions(!showDropdownOptions);
  };

  const onSelectStatus = (status: string) => () => {
    handleChange('selectedStatus', status);
  };

  return (
    <StyledModal maxwidth={560} show={show} size="lg" onHide={toggle} centered>
      <Modal.Body>
        <Button variant="transparent" className="close" onClick={toggle}>
          &times;
        </Button>
        <Wrapper>
          <div className="fs-32 fw-700">End Job</div>
          <div className="content d-flex flex-column">
            <div>
              <div className="label fs-16 fw-400">Choose status</div>
              <div className="status-options d-flex align-items-center flex-wrap">
                <div
                  className={`option flex-1 text-center pointer ${
                    formState?.selectedStatus == 'closed' ? 'selected' : ''
                  }`}
                  onClick={onSelectStatus('closed')}
                >
                  Completed
                </div>
                <div
                  className={`option flex-1 text-center pointer ${
                    formState?.selectedStatus == 'in-complete' ? 'selected' : ''
                  }`}
                  onClick={onSelectStatus('in-complete')}
                >
                  Incomplete
                </div>
              </div>
            </div>
            {formState?.selectedStatus == 'in-complete' && (
              <div>
                <div className="label fs-16 fw-400">
                  Why are you ending the job
                </div>
                <div className="dropdown">
                  <div
                    className="dropdown-button d-flex justify-content-between align-items-center pointer"
                    onClick={toggleDropdownOptions}
                  >
                    <div className="dropdown-placeholder">
                      {formState?.endingReason
                        ? formState?.endingReason
                        : 'Choose the reason'}
                    </div>
                    {showDropdownOptions ? <ArrowUp /> : <ArrowDown />}
                  </div>
                  {showDropdownOptions && (
                    <div className="dropdown-options">
                      {JOB_ENDING_REASONS.map((item: string) => (
                        <div
                          className="option pointer"
                          key={item}
                          onClick={onSelectReason(item)}
                        >
                          {item}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="label fs-16 fw-400 mt-5">Explaination</div>
                <Form.Control
                  rows={4}
                  as="textarea"
                  maxLength={1000}
                  className="form-input mt-2"
                  placeholder="Please explain further"
                  value={formState?.incompleteJobDescription}
                  onChange={(e) =>
                    handleChange('incompleteJobDescription', e.target.value)
                  }
                />
              </div>
            )}
            <div className="text-end">
              <StyledButton
                padding="0.75rem 2rem"
                variant="primary"
                className="button"
                disabled={formState?.loading}
                onClick={onNext}
              >
                End Job
              </StyledButton>
            </div>
          </div>
        </Wrapper>
      </Modal.Body>
    </StyledModal>
  );
};

export default EndJobModal;
