import { Button, Modal, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledModal } from 'components/styled/StyledModal';
import Loader from 'components/Loader';
import useClientReviews from '../hooks/useClientReviews';
import { ReactComponent as StarIcon } from 'assets/icons/starYellow.svg';
import NoDataFound from 'components/ui/NoDataFound';
import { ReactComponent as StarIconSmall } from 'assets/icons/star-yellow.svg';

const ReviewItem = styled.div`
  border: 1px solid ${(props) => props.theme.colors.gray5};
  border-radius: 1.25rem;
  padding: 2.25rem;
  .description {
    font-style: italic;
    color: ${(props) => props.theme.font.color.heading};
    opacity: 0.8;
  }
  .ratings {
    background-color: ${(props) => props.theme.colors.body};
    border-radius: 2rem;
    padding: 0.875rem 1.25rem;
  }
  .client-img-avatar {
    height: 43px;
    width: 43px;
    border-radius: 50%;
    object-fit: cover;
  }
  .light-text {
    opacity: 0.5;
  }
`;

type Props = {
  show: boolean;
  onCloseModal: () => void;
  userId: string;
  ratings: number;
  reviewsCount: number;
};

const Reviews = ({
  show,
  onCloseModal,
  userId,
  ratings,
  reviewsCount,
}: Props) => {
  /* This will fetch the reviews of the client */
  const { data, isLoading, isRefetching } = useClientReviews(userId);

  return (
    <StyledModal show={show} onHide={onCloseModal} centered maxwidth={970}>
      <Modal.Body>
        <Button variant="transparent" className="close" onClick={onCloseModal}>
          &times;
        </Button>

        {/* Heading */}
        <div className="d-flex align-items-center gap-2 justify-content-between mb-4">
          <div className="d-flex align-items-center gap-2">
            <div className="fs-28 fw-400">Reviews</div>
            {isRefetching && <Spinner animation="grow" size="sm" />}
          </div>
          <div className="d-flex align-items-center g-1 flex-wrap">
            <StarIcon />
            <div className="fs-32 fw-700">{ratings?.toFixed(1)}</div>
            <div className="fs-18 fw400">({reviewsCount} reviews)</div>
          </div>
        </div>

        {isLoading && <Loader />}

        {/* Reviews list */}
        {data?.length > 0 &&
          data?.map((item: any) => (
            <ReviewItem key={item?.feedback_id} className="mb-3">
              <div className="fs-20 fw-700">{item?.job_title}</div>
              <div className="d-flex align-items-center justify-content-between my-4 flex-wrap gap-3">
                <Ratings ratings={ratings} />
                <div className="d-flex align-items-center">
                  <div className="light-text">From: </div>
                  <img
                    src={item?.user_image || '/images/default_avatar.png'}
                    className="client-img-avatar mx-3"
                  />
                  <div>
                    {item?.first_name} {item?.last_name}
                  </div>
                </div>
              </div>
              <div className="fs-18 fw-300 description">
                “{item?.description}”
              </div>
            </ReviewItem>
          ))}

        {!isLoading && !isRefetching && data?.length == 0 && (
          <NoDataFound className="py-5" />
        )}
      </Modal.Body>
    </StyledModal>
  );
};

export default Reviews;

const Ratings = ({ ratings }: { ratings: number }) => {
  return (
    <div className="ratings d-flex align-items-center gap-1">
      {Array(Math.ceil(ratings))
        .fill(null)
        .map((_, i) => (
          <StarIconSmall key={i} />
        ))}
      <div className="mx-1">{ratings}</div>
    </div>
  );
};
