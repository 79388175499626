import { CustomHeader } from 'components/ui/CustomHeader';
import { TUTMFilters } from 'components/ui/columnOptions.types';

export const utmTableColumns = (
  filters: TUTMFilters,
  applyFilter: (data: Partial<TUTMFilters>) => void
) => {
  const headers = [
    { key: 'utm_id', label: 'ID' },
    { key: 'utm_term', label: 'Term' },
    { key: 'utm_medium', label: 'Medium' },
    { key: 'utm_source', label: 'Source' },
    { key: 'utm_content', label: 'Content' },
    { key: 'utm_campaign', label: 'Campaign' },
  ];
  return [
    ...headers.map((header) => {
      return {
        id: header.key,
        header: () => (
          <CustomHeader
            filters={filters}
            applyFilter={applyFilter}
            headerText={header.label}
            id={header.key}
            sortingFeature
            filterType="SEARCH"
            minWidth="50px"
          />
        ),
        cell: ({ row: { original } }) => {
          const value: string = original?.utm_info?.[header.key] || '-';
          return (
            <span className="fs-1rem fw-400">{value.replace('_', ' ')}</span>
          );
        },
      };
    }),
    {
      id: 'total_clients',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Total Clients"
          id="total_clients"
          sortingFeature
          filterType="SEARCH"
          minWidth="200px"
        />
      ),
      cell: ({ row: { original } }) => {
        return (
          <span className="fs-1rem fw-400">
            {original?.total_clients || '-'}
          </span>
        );
      },
    },
    {
      id: 'total_freelancers',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Total Freelancers"
          id="total_freelancers"
          sortingFeature
          filterType="SEARCH"
          minWidth="200px"
        />
      ),
      cell: ({ row: { original } }) => {
        return (
          <span className="fs-1rem fw-400">
            {original?.total_freelancers || '-'}
          </span>
        );
      },
    },
  ];
};
