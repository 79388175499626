export const TABS = [
  {
    id: 0,
    label: 'All requests',
    key: '',
  },
  {
    id: 1,
    label: 'General',
    key: 'general',
  },
  {
    id: 2,
    label: 'Closures',
    key: 'closures',
  },
  {
    id: 3,
    label: 'Disputes',
    key: 'dispute',
  },
  {
    id: 4,
    label: 'Messages',
    key: 'message-history',
  },
];
