import { useState } from 'react';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import { SupportItem } from './support.styled';
import NoDataFound from 'components/ui/NoDataFound';
import { StatusBadge } from 'components/styled/Badges';
import useSupportRequests from './hooks/useSupport';
import SupportDetails from 'components/support/support-details/SupportDetails';
import Tabs from 'components/ui/Tabs';
import StyledHtmlText from 'components/ui/StyledHtmlText';
import PaginationComponent from 'components/ui/Pagination';
import { TABS } from './consts';
import { SupportItemType } from './types/support.types';
import DisputeResolveModal from 'components/support/dispute-resolve-modal/DisputeResolveModal';
import TimeRangeFilters from 'components/ui/TimeRangeFilters';
import Messages from 'pages/messages';

interface filterOptionType {
  [propertyName: string]: string;
}

const FILTER_OPTIONS: filterOptionType = {
  '': 'All',
  active: 'Open',
  closed: 'Closed',
};

const Support = () => {
  const RECORDS_PER_PAGE = 10; // 10 records will be displayed per page

  const [activeTab, setActiveTab] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [activeFilter, setActiveFilter] = useState('active');

  const onChangeFilter = (key: string) => {
    /* This will change the filter and apply filter for the counts */
    setActiveFilter(key);
    setCurrentPage(1);
  };

  const [detailsModalState, setDetailsModalState] = useState<{
    show: boolean;
    supportItem?: SupportItemType;
  }>({
    show: false,
  });

  const [resolveModalState, setReloveModalState] = useState<{
    show: boolean;
    supportItem?: SupportItemType;
  }>({
    show: false,
  });

  const [isAccept, setAccept] = useState(false);

  /* Fetching support requests data */
  const { data, isLoading, isRefetching, totalResults, refetch } =
    useSupportRequests({
      activeTab,
      page: currentPage,
      limit: RECORDS_PER_PAGE,
      statusFilter: activeFilter,
    });

  /* This function will change the tab and thus filter will be applied in api */
  const onTabChange = (tab: string) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  /* This will close the support details mdoal */
  const onCloseDetailsModal = () => {
    setDetailsModalState({ show: false });
  };

  /* This will open the support details modal */
  const showSupportDetails = (item) => () => {
    setDetailsModalState({ show: true, supportItem: item });
  };

  const onPageChange = (page: { selected: number }) => {
    /* This will set next page as active and load new page data */
    setCurrentPage(page?.selected + 1);
  };

  const openResolveModal = () => {
    setReloveModalState({
      show: true,
      supportItem: detailsModalState?.supportItem,
    });
    onCloseDetailsModal();
  };

  const openResolveAcceptModal = () => {
    setReloveModalState({
      show: true,
      supportItem: detailsModalState?.supportItem,
    });
    setAccept(true);
    onCloseDetailsModal();
  };

  const onCloseResolveModal = () => {
    setReloveModalState({
      show: false,
    });
  };

  const onResolving = () => {
    refetch();
    onCloseResolveModal();
  };

  return (
    <div>
      {/* Title */}
      <div className="d-flex align-items-center mt-3">
        <h1 className="fs-32 fw-700 mb-0">Support</h1>
        {(isRefetching || isLoading) && (
          <Spinner animation="border" className="ms-2" size="sm" />
        )}
      </div>

      {/* Filters */}
      <div className="d-flex flex-row justify-content-between align-items-center">
        <Tabs
          tabs={TABS}
          activeTab={activeTab}
          onTabChange={onTabChange}
          className="my-4"
        />

        <div className="d-flex flex-row-reverse">
          <TimeRangeFilters
            options={FILTER_OPTIONS}
            activeFilter={activeFilter}
            onChange={onChangeFilter}
          />
        </div>
      </div>

      {/* No Data found */}
      {!isLoading && !isRefetching && data?.length == 0 && <NoDataFound />}

      {activeTab !== 'message-history' ? (
        <>
          {/* Actual listings */}
          <div className="listings">
            {data?.length > 0 &&
              data?.map((item: SupportItemType) => (
                <SupportItem
                  key={
                    item?.dispute_id
                      ? `dispute_${item?.dispute_id}`
                      : item?.user_id
                      ? `user_${item.user_id}`
                      : `general_${item?.inquiry_id}`
                  }
                  className="mt-3 p-3 d-flex justify-content-between pointer flex-wrap gap-2"
                  onClick={showSupportDetails(item)}
                >
                  {/* Support request details */}
                  <div className="request-details">
                    <div className="support-title d-flex align-items-center flex-wrap">
                      <div className="fs-18 fw-400">
                        {item?.subject || item?.jobdata?.job_title}
                      </div>
                      {item?.dispute_id && (
                        <>
                          <div className="fs-sm fw-400 light-text">|</div>
                          <div className="fs-sm fw-400 light-text">
                            {item?.milestone?.title}
                          </div>
                        </>
                      )}
                    </div>

                    {/* Description */}
                    <div className="fs-sm fw-400 mt-2 light-text">
                      <StyledHtmlText
                        htmlString={item?.description}
                        needToBeShorten={true}
                        fontSize={0.875}
                        id={
                          item?.dispute_id
                            ? `support-list-dispute_${item?.dispute_id}`
                            : `support-list-inquiry_${item?.inquiry_id}`
                        }
                      />
                    </div>

                    <div className="support-request--by mt-2 d-flex align-items-center gap-2">
                      <img
                        className="support-request--avatar"
                        src={item?.userdata?.user_image || item?.user_image}
                      />
                      <div className="fs-1rem fw-400 light-text">
                        {item?.userdata?.first_name || item?.first_name}{' '}
                        {item?.userdata?.last_name || item?.last_name}
                      </div>
                    </div>
                  </div>

                  {/* Support request type badge and created date */}
                  <div className="d-flex flex-column justify-content-between align-items-md-end gap-2">
                    <div className="d-flex flex-row align-items-end gap-2">
                      {(item?.dispute_status == 'closed' ||
                        item?.status == 'closed') && (
                        <StatusBadge color="green" className="mt-2">
                          Closed
                        </StatusBadge>
                      )}
                      {(item?.dispute_id || item?.inquiry_id) && (
                        <StatusBadge
                          color={item?.inquiry_id ? 'blue' : 'darkPink'}
                        >
                          {item?.inquiry_id ? 'General' : 'Dispute'}
                        </StatusBadge>
                      )}
                      {!item?.inquiry_id && !item?.dispute_id && (
                        <StatusBadge color={'red'}>Closure</StatusBadge>
                      )}
                    </div>
                    <div className="fs-sm fw-400 light-text">
                      {item?.date_created
                        ? moment(item?.date_created).format('MMM DD, YYYY')
                        : '-'}
                    </div>
                  </div>
                </SupportItem>
              ))}
          </div>

          {data?.length > 0 && (
            <div className="d-flex justify-content-center mt-3 align-items-center">
              <PaginationComponent
                total={Math.ceil(totalResults / RECORDS_PER_PAGE)}
                onPageChange={onPageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </>
      ) : (
        <Messages />
      )}

      {/* Support details modal */}
      <SupportDetails
        show={detailsModalState?.show}
        onCloseModal={onCloseDetailsModal}
        onResolve={openResolveModal}
        selectedSupportItem={detailsModalState?.supportItem}
        openResolveAcceptModal={openResolveAcceptModal}
        refetch={refetch}
      />

      {/* Dispute resolve modal */}
      <DisputeResolveModal
        show={resolveModalState?.show}
        onCloseModal={onCloseResolveModal}
        supportItem={resolveModalState?.supportItem}
        onResolving={onResolving}
        isAccept={isAccept}
      />
    </div>
  );
};

export default Support;
