import React, { useState } from 'react';
import { Form, Accordion, Modal } from 'react-bootstrap';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-hot-toast';
import { getStripeFees, updateStripeFees } from 'helpers/http/common';
import styled from 'styled-components';
import { StyledButton } from 'components/forms/Buttons';

const StyledCard = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  box-shadow: 0px 4px 1rem rgba(0, 0, 0, 0.04);
  overflow: hidden;
`;

const StyledHeader = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray6};
`;

const StyledBody = styled.div`
  padding: 1.5rem;
`;

const StyledAccordion = styled(Accordion)`
  .accordion-item {
    border: 1px solid ${({ theme }) => theme.colors.gray6};
    border-radius: 8px;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion-button {
    background: transparent;
    padding: 1rem 1.5rem;

    &:not(.collapsed) {
      background: transparent;
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .accordion-body {
    padding: 1.5rem;
    border-top: 1px solid ${({ theme }) => theme.colors.gray6};
  }
`;

interface FeeStructure {
  max_amount: number;
  min_amount: number;
  percentage: number;
  minFixedAmount?: number;
  minFixedCharge?: number;
}

interface StripeFee {
  fee_id: number;
  fee_type: string;
  fee_structure: {
    ACH?: FeeStructure;
    OTHER?: FeeStructure;
    minFixedCharge?: number;
  };
  date_created?: string;
  date_modified?: string;
}

interface StripeFeeCardProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onCloseHandler: (flag: string) => void;
}

const StripeFeeCard: React.FC<StripeFeeCardProps> = ({
  showModal,
  setShowModal,
  onCloseHandler,
}) => {
  const [selectedFee, setSelectedFee] = useState<StripeFee | null>(null);
  const [formData, setFormData] = useState<{
    fee_type: string;
    fee_structure: any;
  }>({
    fee_type: '',
    fee_structure: {},
  });

  const queryClient = useQueryClient();

  const { data: fees = [], isLoading: isLoadingFees } = useQuery(
    ['stripe-fees'],
    () => getStripeFees(),
    {
      select: (response) => (response?.data as StripeFee[]) || [],
    }
  );

  const updateFeeMutation = useMutation(updateStripeFees, {
    onSuccess: () => {
      queryClient.invalidateQueries(['stripe-fees']);
      toast.success('Fee structure updated successfully');
      onCloseHandler('fee');
    },
    onError: (error: any) => {
      toast.error(error.message || 'Failed to update fee structure');
    },
  });

  const handleEdit = (fee: StripeFee, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedFee(fee);
    setFormData({
      fee_type: fee.fee_type,
      fee_structure: JSON.parse(JSON.stringify(fee.fee_structure)),
    });
    setShowModal(true);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const updatedFormData = { ...formData };
    updateFeeMutation.mutate(updatedFormData);
  };

  const renderFeeStructure = (structure: any) => {
    return (
      <div className="fs-14">
        {structure.ACH && (
          <div className="mb-3">
            <h6 className="fs-16 fw-600">ACH Fees</h6>
            <div>Percentage: {structure.ACH.percentage}%</div>
            <div>Min Fixed Amount: ${structure.ACH.minFixedAmount}</div>
            <div>Min Fixed Charge: ${structure.ACH.minFixedCharge}</div>
          </div>
        )}
        {structure.OTHER && (
          <div className="mb-3">
            <h6 className="fs-16 fw-600">Other Payment Methods</h6>
            <div>Percentage: {structure.OTHER.percentage}%</div>
            {structure.OTHER.minFixedAmount && (
              <div>Min Fixed Amount: ${structure.OTHER.minFixedAmount}</div>
            )}
            {structure.OTHER.minFixedCharge && (
              <div>Min Fixed Charge: ${structure.OTHER.minFixedCharge}</div>
            )}
          </div>
        )}
        {structure.minFixedCharge && (
          <div>Overall Min Fixed Charge: ${structure.minFixedCharge}</div>
        )}
      </div>
    );
  };

  const isLoading = isLoadingFees || updateFeeMutation.isLoading;

  return (
    <StyledCard>
      <StyledHeader>
        <h5 className="fs-20 fw-600 mb-0">Stripe Fees Management</h5>
      </StyledHeader>
      <StyledBody>
        {isLoading ? (
          <div className="text-center">Loading...</div>
        ) : (
          <StyledAccordion>
            {fees.map((fee) => (
              <Accordion.Item key={fee.fee_id} eventKey={fee.fee_id.toString()}>
                <Accordion.Header>
                  <div className="d-flex justify-content-between align-items-center w-100 me-3">
                    <span className="fs-16">
                      {fee.fee_type.toUpperCase()} FEES
                    </span>
                    <StyledButton
                      variant="link"
                      className="text-primary"
                      onClick={(e) => handleEdit(fee, e)}
                    >
                      Edit
                    </StyledButton>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {renderFeeStructure(fee.fee_structure)}
                  <div className="text-muted mt-2 fs-12">
                    Created:{' '}
                    {new Date(fee.date_created || '').toLocaleDateString()}
                    {fee.date_modified &&
                      ` • Modified: ${new Date(
                        fee.date_modified
                      ).toLocaleDateString()}`}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </StyledAccordion>
        )}
      </StyledBody>

      <Modal
        show={showModal}
        onHide={() => onCloseHandler('fee')}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-20 fw-600">Edit Fee Structure</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {formData.fee_type.toLowerCase() === 'client' && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label className="fs-14">ACH Percentage (%)</Form.Label>
                  <Form.Control
                    type="number"
                    step="0.1"
                    value={formData.fee_structure.ACH?.percentage || 0}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        fee_structure: {
                          ...formData.fee_structure,
                          ACH: {
                            ...formData.fee_structure.ACH,
                            percentage: parseFloat(e.target.value) || 0,
                          },
                        },
                      })
                    }
                    className="fs-16"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="fs-14">
                    Other Payment Methods Percentage (%)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    step="0.1"
                    value={formData.fee_structure.OTHER?.percentage || 0}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        fee_structure: {
                          ...formData.fee_structure,
                          OTHER: {
                            ...formData.fee_structure.OTHER,
                            percentage: parseFloat(e.target.value) || 0,
                          },
                        },
                      })
                    }
                    className="fs-16"
                  />
                </Form.Group>
              </>
            )}

            {formData.fee_type.toLowerCase() === 'freelancer' && (
              <Form.Group className="mb-3">
                <Form.Label className="fs-14">Percentage (%)</Form.Label>
                <Form.Control
                  type="number"
                  step="0.1"
                  value={formData.fee_structure.OTHER?.percentage || 0}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      fee_structure: {
                        ...formData.fee_structure,
                        OTHER: {
                          ...formData.fee_structure.OTHER,
                          percentage: parseFloat(e.target.value) || 0,
                        },
                      },
                    })
                  }
                  className="fs-16"
                />
              </Form.Group>
            )}

            <div className="d-flex justify-content-end gap-2">
              <StyledButton
                variant="outline"
                onClick={() => onCloseHandler('fee')}
              >
                Cancel
              </StyledButton>
              <StyledButton
                type="submit"
                disabled={isLoading}
                className="text-white"
              >
                {isLoading ? 'Saving...' : 'Save Changes'}
              </StyledButton>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </StyledCard>
  );
};

export default StripeFeeCard;
