import ProfileDetails from './partials/ProfileDetails';
import ActiveJobs from './partials/ActiveJobs';
import ClosedJobs from './partials/ClosedJobs';
import Messages from './partials/Messages';
import { FreelancerAdminNotes } from './partials/FreelancerAdminNotes';
import { UTMInfo } from './partials/UTMInfo';

const FREELANCER_PROFILE_ROUTES = [
  {
    id: 'details',
    label: 'General details',
    component: ProfileDetails,
  },
  {
    id: 'active-jobs',
    label: 'Active Jobs',
    component: ActiveJobs,
  },
  {
    id: 'closed-jobs',
    label: 'Closed Jobs',
    component: ClosedJobs,
  },
  {
    id: 'message-history',
    label: 'Messages',
    component: Messages,
  },
  {
    id: 'admin-notes',
    label: 'Admin Notes',
    component: FreelancerAdminNotes,
  },
  {
    id: 'utm-info',
    label: 'UTM Info',
    component: UTMInfo,
  },
];

export default FREELANCER_PROFILE_ROUTES;
