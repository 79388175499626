import { Row, Col } from 'react-bootstrap';
import { DashboardWrapper } from './dashboard.styled';
import NewRegistrations from './partials/NewRegistrations';
import SupportRequests from './partials/SupportRequests';
import DashboardStats from './partials/DashboardStats';
import TimeRangeFilters from 'components/ui/TimeRangeFilters';
import { useState } from 'react';

interface filterOptionType {
  [propertyName: string]: string;
}

const FILTER_OPTIONS: filterOptionType = {
  alltime: 'All time',
  today: 'Today',
  thisweek: 'This week',
  thismonth: 'This month',
  thisyear: 'This year',
  last3years: 'last 3 years',
};

function Dashboard() {
  const [activeFilter, setActiveFilter] = useState<string>('alltime');

  const onChangeFilter = (key: string) => {
    /* This will change the filter and apply filter for the counts */
    setActiveFilter(key);
  };

  return (
    <DashboardWrapper className="mt-3">
      <div className="d-flex align-items-center justify-content-between">
        {/* Title */}
        <h1 className="fs-32 fw-700">Dashboard</h1>

        {/* Filters for counts */}
        <TimeRangeFilters
          options={FILTER_OPTIONS}
          activeFilter={activeFilter}
          onChange={onChangeFilter}
        />
      </div>

      {/* Stats */}
      <DashboardStats activeFilter={activeFilter} />

      <Row className="mt-4">
        <Col lg={6} className="mb-3">
          {/* New registration requests table */}
          <NewRegistrations activeFilter={activeFilter} />
        </Col>

        <Col lg={6} className="mb-3">
          {/* New registration requests table */}
          <SupportRequests activeFilter={activeFilter} />
        </Col>
      </Row>
    </DashboardWrapper>
  );
}

export default Dashboard;
