import styled from 'styled-components';

export const DisputeWrapper = styled.div`
  .form {
    gap: 2rem;
  }
  .light-black {
    color: ${(props) => props.theme.font.color.heading};
  }
  .light-text {
    opacity: 0.7;
  }
  .selected {
    border: 2px solid ${(props) => props.theme.font.color.heading};
  }
  .form-input {
    padding: 1rem 1.25rem;
    border-radius: 7px;
    border: 1px solid ${(props) => props.theme.colors.gray6};
  }
`;

export const OptionButton = styled.div<{ selected: boolean }>`
  border: ${(props) =>
    props.selected
      ? `2px solid ${props.theme.font.color.heading}`
      : `1px solid ${props.theme.colors.gray6}`};
  border-radius: 11px;
  flex: 1;
  padding: 1.125rem;
`;
