import styled from 'styled-components';
import { TFilters, TUTMFilters } from './columnOptions.types';
import { useEffect, useMemo, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp, IoIosSearch } from 'react-icons/io';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { CiFilter } from 'react-icons/ci';
import { IoClose } from 'react-icons/io5';
import { CiCalendarDate } from 'react-icons/ci';
import { Calendar, DateObject } from 'react-multi-date-picker';
import moment from 'moment';

const HeaderContainer = styled.div<{ $minWidth?: string }>`
  position: relative;
  min-width: ${(props) => props.$minWidth};
  &:hover {
    opacity: 0.5;
    .hover-div {
      display: block;
    }
  }
  .hover-div {
    display: none;
    &[data-isactive='true'] {
      display: block;
    }
  }
  .popover {
    padding: 10px;
  }
`;

type Props = {
  headerText: string;
  filters: TFilters | TUTMFilters;
  applyFilter: (data: Partial<TFilters | TUTMFilters>) => void;
  id: string;
  sortingFeature?: boolean;
  filterType?: 'SEARCH' | 'FILTER' | 'DATE_FILTER';
  options?: string[];
  numberOnly?: boolean;
  minWidth?: string;
};

export const CustomHeader = ({
  headerText,
  applyFilter,
  id,
  sortingFeature,
  filterType,
  options,
  numberOnly,
  filters,
  minWidth,
}: Props) => {
  // Column id
  const searchBy =
    id === filters.searchBy ? Boolean(filters.searchText) : false;
  const sortBy = id === filters.sortBy ? Boolean(filters.sortOrder) : false;

  const [sortOrder, setSortOrder] = useState('');
  const [isShowingFilterPopover, setIsShowingFilterPopover] = useState(false);
  const [searchText, setSearchText] = useState('');

  /* START ----------------------------------------- Set initial values of state and change if filters value is changed */
  useEffect(() => {
    setSortOrder(sortBy ? filters.sortOrder : '');
    setSearchText(searchBy ? filters.searchText : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.sortBy, filters?.searchText]);
  /* END ------------------------------------------- Set initial values of state and change if filters value is changed */

  const handleSort = (id: string, order: string) => {
    applyFilter({
      sortBy: order ? id : '',
      sortOrder: order,
      page: 1,
      keyword: '',
    });
    setSortOrder(order);
  };

  const handleSearch = (id: string, text: string) => {
    applyFilter({
      searchBy: text ? id : '',
      searchText: text,
      page: 1,
      keyword: '',
    });
    setSearchText(text);
    setIsShowingFilterPopover(false);
  };

  const dateFilterHandler = (dateArr: DateObject[]) => {
    const startDate = dateArr[0];
    const endDate = dateArr[1];
    if (!startDate || !endDate) return;

    const momentStartDate = moment(startDate.toDate()).startOf('day');
    const momentEndDate = moment(endDate.toDate()).endOf('day');

    // If to date is before from date then switch the dates else keep it as it is
    if (!momentStartDate.isBefore(momentEndDate)) {
      setSearchText(
        `${momentEndDate.toISOString()} and ${momentStartDate.toISOString()}`
      );
    } else {
      setSearchText(
        `${momentStartDate.toISOString()} and ${momentEndDate.toISOString()}`
      );
    }
  };

  const clearAll = () => {
    applyFilter({
      searchBy: '',
      searchText: '',
      sortBy: '',
      sortOrder: '',
      page: 1,
      keyword: '',
    });
    setSortOrder('');
    setSearchText('');
    setIsShowingFilterPopover(false);
  };

  // take searchText value if it exists or take value from filters object

  const datePickerValue = useMemo(() => {
    if (searchText) {
      const [start, end] = searchText
        .split(' and ')
        .map((date) => new DateObject({ date }));
      return [start, end];
    }
    if (filters?.searchText) {
      const [start, end] = filters.searchText
        .split(' and ')
        .map((date) => new DateObject({ date }));
      return [start, end];
    }
    return undefined;
  }, [filters.searchText, searchText]);

  return (
    <HeaderContainer
      className="d-flex justify-content-between"
      $minWidth={minWidth}
    >
      <span>{headerText}</span>
      <div className="d-flex flex-direction-row">
        {/* START ----------------------------------------- Sorting */}
        {sortingFeature && (
          <div
            className="pointer mx-1"
            onClick={() => {
              handleSort(id, sortOrder === 'ASC' ? 'DESC' : 'ASC');
            }}
          >
            <div className="hover-div">
              {sortOrder === '' && <IoIosArrowUp />}
            </div>
            {sortOrder === 'ASC' ? (
              <IoIosArrowUp />
            ) : sortOrder === 'DESC' ? (
              <IoIosArrowDown />
            ) : (
              <></>
            )}
          </div>
        )}
        {/* END ------------------------------------------- Sorting */}

        {/* START ----------------------------------------- Searching */}
        {filterType === 'SEARCH' && (
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose
            onExited={() => setIsShowingFilterPopover(false)}
            overlay={
              <Popover
                className="d-flex flex-column p-2"
                style={{ minWidth: '200px' }}
              >
                <label className="text-secondary" htmlFor="column-search-input">
                  Search
                </label>
                <input
                  autoComplete="off"
                  id="column-search-input"
                  placeholder="Enter text here..."
                  autoFocus
                  value={searchText}
                  type={numberOnly ? 'number' : 'text'}
                  onChange={(e) => {
                    if (e.target.value.length < 20)
                      setSearchText(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch(id, searchText);
                    }
                  }}
                />
                <hr />
                <div className="d-flex flex-1 justify-content-around align-items-center">
                  <p
                    className="flex-1 btn btn-link text-success pointer m-0 p-0"
                    onClick={() => {
                      handleSearch(id, searchText);
                    }}
                  >
                    Done
                  </p>
                  <p
                    className="flex-1 btn btn-link text-danger pointer m-0 p-0"
                    onClick={() => {
                      handleSearch(id, '');
                    }}
                  >
                    Reset
                  </p>
                </div>
              </Popover>
            }
          >
            <div
              className="hover-div pointer mx-1"
              data-isactive={(searchBy || isShowingFilterPopover).toString()}
              onClick={() => setIsShowingFilterPopover((prev) => !prev)}
            >
              <IoIosSearch />
            </div>
          </OverlayTrigger>
        )}
        {/* END ------------------------------------------- Searching */}

        {/* START ----------------------------------------- Filter */}
        {filterType === 'FILTER' && options && options?.length > 0 && (
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose
            onExited={() => setIsShowingFilterPopover(false)}
            overlay={
              <Popover
                className="d-flex flex-column p-2"
                style={{ minWidth: '200px' }}
              >
                <label className="text-secondary">Filter</label>
                {options.map((item) => {
                  return (
                    <label
                      htmlFor={`filter-feature-${item}`}
                      key={item}
                      className="d-flex flex-row align-items-center"
                    >
                      <input
                        type="radio"
                        name="filter-feature"
                        value={item}
                        id={`filter-feature-${item}`}
                        className="my-1"
                        checked={searchText === item}
                        onChange={() => setSearchText(item)}
                      />{' '}
                      <span className="ms-2">{item}</span>
                    </label>
                  );
                })}
                <hr />
                <div className="d-flex flex-1 justify-content-around align-items-center">
                  <p
                    className="flex-1 btn btn-link text-success pointer m-0 p-0"
                    onClick={() => {
                      handleSearch(id, searchText);
                    }}
                  >
                    Done
                  </p>
                  <p
                    className="flex-1 btn btn-link text-danger pointer m-0 p-0"
                    onClick={() => {
                      handleSearch(id, '');
                    }}
                  >
                    Reset
                  </p>
                </div>
              </Popover>
            }
          >
            <div
              className="hover-div pointer mx-1"
              data-isactive={(searchBy || isShowingFilterPopover).toString()}
              onClick={() => setIsShowingFilterPopover((prev) => !prev)}
            >
              <CiFilter />
            </div>
          </OverlayTrigger>
        )}
        {/* END ------------------------------------------- Filter */}
        {/* START ----------------------------------------- Date Filter */}
        {filterType === 'DATE_FILTER' && (
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose
            onExited={() => setIsShowingFilterPopover(false)}
            overlay={
              <Popover
                className="d-flex flex-column p-2"
                style={{ minWidth: '200px' }}
              >
                <Calendar
                  onChange={dateFilterHandler}
                  value={datePickerValue}
                  range
                  className="shadow-none"
                />
                <hr />
                <div className="d-flex flex-1 justify-content-around align-items-center">
                  <p
                    className="flex-1 btn btn-link text-success pointer m-0 p-0"
                    onClick={() => {
                      handleSearch(id, searchText);
                    }}
                  >
                    Done
                  </p>
                  <p
                    className="flex-1 btn btn-link text-danger pointer m-0 p-0"
                    onClick={() => {
                      handleSearch(id, '');
                    }}
                  >
                    Reset
                  </p>
                </div>
              </Popover>
            }
          >
            <div
              className="hover-div pointer mx-1"
              data-isactive={(searchBy || isShowingFilterPopover).toString()}
              onClick={() => setIsShowingFilterPopover((prev) => !prev)}
            >
              <CiCalendarDate />
            </div>
          </OverlayTrigger>
        )}
        {/* END ------------------------------------------- Date Filter */}
        {/* START ----------------------------------------- Cancel */}
        {(Boolean(searchText) || Boolean(sortOrder)) &&
          !isShowingFilterPopover && (
            <div className="hover-div pointer mx-1" onClick={clearAll}>
              <IoClose />
            </div>
          )}
        {/* END ------------------------------------------- Cancel */}
      </div>
    </HeaderContainer>
  );
};
