import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ fontSize?: number }>`
  font-size: ${(props) =>
    props.fontSize ? `${props.fontSize}rem` : '1.125rem'};
  p {
    margin-bottom: 0px;
    word-break: break-word;
  }
  a {
    color: ${(props) => props.theme.colors.yellow};
  }
  .view-more-btn {
    color: ${(props) => props.theme.colors.lightBlue};
    display: inline;
  }
  .description {
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    font-size: ${(props) =>
      props.fontSize ? `${props.fontSize}rem` : '1.125rem'};
    line-height: ${(props) =>
      props.fontSize ? `${props.fontSize * 1.6}rem` : '1.8rem'};
    max-height: 9rem; /* fallback */
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
`;
type Props = {
  htmlString: string;
  needToBeShorten?: boolean;
  id?: string;
  className?: string;
  fontSize?: number;
};

const StyledHtmlText = ({
  htmlString,
  needToBeShorten,
  id,
  className,
  fontSize,
}: Props) => {
  const [showViewMore, setShowViewMore] = useState<boolean>(false);
  const [viewMore, setViewMore] = useState<boolean>(false);

  function getLinesCount(element) {
    const prevLH = element.style.lineHeight;
    const factor = 1000;
    element.style.lineHeight = factor + 'px';

    const height = element.getBoundingClientRect().height;
    element.style.lineHeight = prevLH;

    return Math.floor(height / factor);
  }

  const formatDescription = useCallback(
    (htmlString: string) => {
      let element;
      if (id) {
        element = document.getElementById(id);
      } else {
        element = document.getElementById('htmlString');
      }
      if (element) {
        element.innerHTML = htmlString;
        const totalLines = getLinesCount(element);
        if (totalLines > 3) {
          if (needToBeShorten) {
            setShowViewMore(true);
            element.classList.toggle('description');
          }
        } else {
          setShowViewMore(false);
        }
      }
    },
    [id, needToBeShorten]
  );

  useEffect(() => {
    if (htmlString) {
      const htmlStringFormatted = htmlString?.replace(/(<p>&nbsp;<\/p>)+$/, '');
      formatDescription(htmlStringFormatted);
    }
  }, [htmlString, id, needToBeShorten, formatDescription]);

  const toggleReadMore = (e) => {
    e.stopPropagation();
    setViewMore(!viewMore);
    let element;
    if (id) {
      element = document.getElementById(id);
    } else {
      element = document.getElementById('htmlString');
    }
    element.classList.toggle('description');
  };

  return (
    <Wrapper className={className} fontSize={fontSize}>
      <span id={id || 'htmlString'}></span>
      {showViewMore == true && (
        <>
          <div className="view-more-btn pointer" onClick={toggleReadMore}>
            {!viewMore ? ' View all' : ' View less'}
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default StyledHtmlText;
