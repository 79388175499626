import styled from 'styled-components';
import { transition } from 'styles/transitions';

export const SupportItem = styled.div`
  border: ${(props) => `1px solid ${props.theme.colors.gray6}`};
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  .listing {
    min-height: 600px;
  }
  .request-details {
    max-width: 80%;
  }
  .support-title {
    gap: 10px;
  }
  .light-text {
    opacity: 0.7;
  }
  .support-request--avatar {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    object-fit: cover;
  }
  ${() => transition()};
`;
