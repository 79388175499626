import { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { MdDragIndicator } from 'react-icons/md';
import { Table } from '@tanstack/react-table';
import { outsideClick } from 'helpers/utils/outsideClick';
import { CiViewColumn } from 'react-icons/ci';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import { useNonInitialEffect } from 'helpers/hooks/useNonInitialEffect';
import { MORE_BUTTON_ID } from 'helpers/consts';

const PopoverContainer = styled.div`
  top: 38px;
  right: 0;
  background: #fff;
  z-index: 9;
  width: max-content;
  border: 1px solid rgb(243, 243, 243);
  * {
    cursor: default !important;
  }
  hr {
    margin: 4px;
  }
  .pointer {
    cursor: pointer !important;
  }
  .btn-link:hover {
    background #efefef;
  }
  .content-container {
    user-select: none;
    padding: 4px 0px;
    [type='checkbox'] {
      height: 16px;
      width: 16px;
    }
    [type='checkbox'],
    label {
      cursor: pointer !important;
    }
    svg,
    svg * {
      cursor: grab !important;
    }
  }
`;

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? 'rgb(243, 243, 243)' : '#fff',
  ...draggableStyle,
});

type Props<T> = {
  elementId: string;
  table: Table<T>;
  handleSave: () => void;
  className?: string;
  isLoading?: boolean;
};

export const ManageColumns = <T,>({
  elementId,
  className = '',
  table,
  handleSave,
  isLoading = false,
}: Props<T>) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const allColumns = table
    .getAllLeafColumns()
    .filter((column) => column.id !== MORE_BUTTON_ID);

  // Hide popover when clicked outside
  useEffect(() => {
    const removeEventListener = outsideClick(
      document.getElementById(elementId),
      () => {
        setIsPopoverOpen(false);
      }
    );
    return () => {
      removeEventListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when popover closes
  useNonInitialEffect(() => {
    if (!isPopoverOpen) {
      handleSave();
    }
  }, [isPopoverOpen]);

  const onDragEnd = (result: DropResult) => {
    // getting all columns ids
    const data = [...allColumns.map((column) => column.id)];

    // if destination object is there then removing item from destination index
    // and adding it to where it was dropped
    if (result.destination) {
      const [removedItem] = data.splice(result.source.index, 1);
      data.splice(result.destination.index, 0, removedItem);
    }

    // will update state which was passed to useReactTable
    table.setColumnOrder(data);
  };

  const toggleManageColumns = () => {
    setIsPopoverOpen((prev) => !prev);
  };

  const handleReset = () => {
    table.setColumnOrder([]);
    table.setColumnVisibility({});
    toggleManageColumns();
  };

  if (!table) return <></>;

  return (
    <div
      id={elementId}
      className={`position-relative ${
        isLoading ? 'opacity-50 pe-none' : ''
      } ${className}`}
    >
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>Manage columns</Tooltip>}
      >
        <div>
          <CiViewColumn
            size={40}
            className="pointer"
            onClick={toggleManageColumns}
          />
        </div>
      </OverlayTrigger>
      <PopoverContainer
        className={`shadow-lg rounded position-absolute p-2 ${
          isPopoverOpen ? 'd-block' : 'd-none'
        }`}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="freelancer-table-manage-columns">
            {(providedParent) => (
              <div
                {...providedParent.droppableProps}
                ref={providedParent.innerRef}
              >
                {allColumns.map((column, i) => {
                  return (
                    <Draggable
                      key={column.id}
                      draggableId={column.id}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            className="content-container"
                          >
                            <div className="d-flex flex-direction-row align-items-center">
                              <MdDragIndicator size={24} color="#666" />
                              <input
                                id={`manage-column-checkbox${column.id}`}
                                type="checkbox"
                                aria-label="toggle columns"
                                checked={column.getIsVisible()}
                                onChange={column.getToggleVisibilityHandler()}
                              />
                              <label
                                htmlFor={`manage-column-checkbox${column.id}`}
                                className="fs-16 ms-1 me-4"
                              >
                                {column.id}
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {providedParent.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <hr />
        <div className="d-flex flex-1 justify-content-around align-items-center">
          <p
            className="flex-1 btn btn-link text-success pointer mb-0"
            onClick={() => {
              setIsPopoverOpen(false);
            }}
          >
            Done
          </p>
          <p
            className="flex-1 btn btn-link text-danger pointer mb-0"
            onClick={handleReset}
          >
            Reset
          </p>
        </div>
      </PopoverContainer>
    </div>
  );
};
