import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ListingWrapper, NewRegistrationItem } from '../dashboard.styled';
import NoDataFound from 'components/ui/NoDataFound';
import { StatusBadge } from 'components/styled/Badges';
import Loader from 'components/Loader';
import { editUser } from 'helpers/http/freelancer';
import useRegistrationRequests from '../hooks/useRegistrationRequests';
import { ReactComponent as GreenTickIcon } from 'assets/icons/tick-green.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cancel-red.svg';

const NewRegistrations = ({ activeFilter }: { activeFilter: string }) => {
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState<string>(''); // This will be used to display loader in item (when deleting)

  // This will fetch the new registration requests
  const { data, isLoading, refetch, isRefetching } =
    useRegistrationRequests(activeFilter);

  const onApproveOrReject = (approve: 0 | 1 | null, id: string) => (e: any) => {
    /*
     * This function will take approve: 0 | 1 and freelancer_id as parameter,
     * and either approve or reject the request based on approve param
     */
    e.stopPropagation();

    if (selectedId == '') {
      // This will stop callig api on multiple clicks, once any one is selected
      setSelectedId(id);

      // API call for approve / reject new registration request
      const promise = editUser({
        is_account_approved: approve,
        user_id: id,
      });

      toast.promise(promise, {
        loading: 'Loading...',
        success: (res) => {
          /*
           * After approve / reject, it will refetch the data
           */
          refetch();
          setSelectedId(''); // Loader will be hidden by this
          return res.message;
        },
        error: (err) => {
          setSelectedId(''); // Loader will be hidden by this
          return err?.response?.data?.message || 'error';
        },
      });
    }
  };

  const goToFreelancerProfile = (id: string) => () => {
    /* This funtion will take freelancer id and take the user to freelancer profile page */
    navigate(`/freelancer-profile/${id}?reg_request=true`);
  };

  return (
    <ListingWrapper>
      {/* Header */}

      <div className="d-flex align-items-center heading p-4 fs-18 fw-700">
        New Registration Requests ({data?.freelancers?.length || 0})
        {isRefetching && (
          <Spinner animation="border" size="sm" className="ms-3" />
        )}
      </div>

      {/* Listing */}
      <div className="listings px-4 pb-4">
        {isLoading && <Loader />}

        {!isLoading && !isRefetching && data?.freelancers?.length == 0 && (
          <NoDataFound />
        )}

        {data?.freelancers?.map((item: any) => (
          <NewRegistrationItem
            key={item?.user_id}
            className="mt-3 p-3 d-flex align-items-center justify-content-between pointer gap-3"
            onClick={goToFreelancerProfile(item?.user_id)}
          >
            <div className="d-flex align-items-center gap-3">
              {/* Profile picture */}
              <img src={item?.user_image} className="avatar" />

              <div>
                {/* Freelancer / agency name and badge */}
                <div className="d-flex align-items-center gap-2 flex-wrap">
                  <div className="fs-18 fw-400">
                    {item?.first_name} {item?.last_name}
                  </div>
                  <StatusBadge color={item?.is_agency ? 'blue' : 'green'}>
                    {item?.is_agency ? 'Agency' : 'Freelancer'}
                  </StatusBadge>
                </div>

                {/* Job title */}
                <div className="mt-2 fs-1rem fw-400 designation">
                  {item?.job_title}
                </div>
              </div>
            </div>

            {/* Approve and reject buttons */}
            <div
              className={`d-flex align-items-center gap-3 ${
                selectedId == item?.user_id ? 'opacity-4' : ''
              }`}
            >
              <div
                onClick={onApproveOrReject(0, item?.user_id)}
                className="approve-reject-btn justify-content-center align-items-center d-flex"
              >
                <CrossIcon />
              </div>
              <div
                onClick={onApproveOrReject(1, item?.user_id)}
                className="approve-reject-btn justify-content-center align-items-center d-flex"
              >
                <GreenTickIcon />
              </div>
            </div>
          </NewRegistrationItem>
        ))}
      </div>
    </ListingWrapper>
  );
};
export default NewRegistrations;
