import { useState } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { getMessages } from 'helpers/http/common';
import { MultiSelectCustomStyle } from 'pages/messages/multiSelectCustomStyle';
import MessageBubble from 'pages/messages/MessageBubble';
import Loader from 'components/Loader';
import {
  MessageBubbleWrapper,
  MessageHeadBox,
  MessageJobDropdown,
  NoMessagesFound,
} from 'pages/messages/messaging.styled';
import { Spinner } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import { getFreelancerJobs } from 'helpers/http/freelancer';
import { TuseOutletContext } from 'helpers/types/freelancer.type';

const singleSelectProps = {
  closeMenuOnSelect: true,
  isMulti: false,
  styles: MultiSelectCustomStyle,
};

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [freelancerID, setFreelancerID] = useState('');
  const [isfetching, setIsFetching] = useState<boolean>(false);

  const { freelancerId } = useOutletContext<TuseOutletContext>();

  const jobLoadOptions = (searchedValue: string) => {
    const jobs: { label: any; value: any }[] = [];

    const fetchActiveJob = () => {
      const payload = {
        userId: freelancerId,
        status: 'active',
        limit: 100,
        page: 1,
        text: searchedValue,
      };
      return getFreelancerJobs(payload);
    };
    const fetchCloseJob = () => {
      const payload = {
        userId: freelancerId,
        status: 'closed',
        limit: 100,
        page: 1,
        text: searchedValue,
      };
      return getFreelancerJobs(payload);
    };
    const promises = [fetchActiveJob(), fetchCloseJob()];
    return Promise.all(promises).then((data) => {
      const activeJobList = data[0].data;
      const closeJobList = data[1].data;
      const concatCloseAndActiveJob = activeJobList.concat(closeJobList);
      concatCloseAndActiveJob.forEach(function (item: any) {
        const obj = {
          label: item.job_title,
          value: item.job_post_id,
        };
        jobs.push(obj);
      });
      return jobs;
    });
  };

  const handleUpdate = (selected) => {
    setIsFetching(true);
    return getMessages(selected.value).then((res) => {
      if (res) {
        setFreelancerID(res.freelancerID);
        setMessages(res.chats);
        setIsFetching(false);
      }
    });
  };

  return (
    <>
      <MessageHeadBox>
        <div className="d-flex align-items-center mb-3">
          <h1 className="fs-32 fw-700 mb-0 mt-0">Messages</h1>
          {isfetching && (
            <Spinner animation="border" className="ms-2" size="sm" />
          )}
        </div>

        <MessageJobDropdown className="d-flex gap-3 align-items-end">
          <div className="w-100">
            <p className="job-desc mb-3">
              Please select the job for which you want to see the conversation
              between client and the freelancer.
            </p>

            <AsyncSelect
              {...singleSelectProps}
              placeholder="Select Job"
              components={{ NoOptionsMessage }}
              loadOptions={jobLoadOptions}
              onChange={handleUpdate}
              defaultOptions={true}
            />
          </div>
        </MessageJobDropdown>
      </MessageHeadBox>

      {isfetching && <Loader />}

      <MessageBubbleWrapper>
        {messages?.map((message: any) => (
          <MessageBubble
            author={
              message._from_user_id == freelancerID ? 'freelancer' : 'client'
            }
            data={message}
            key={'chat_id'}
          />
        ))}
      </MessageBubbleWrapper>

      {messages.length <= 0 && (
        <NoMessagesFound>No Message found</NoMessagesFound>
      )}
    </>
  );
};

export default Messages;

const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <div>
        {props?.selectProps?.inputValue
          ? `No result found for '${props?.selectProps?.inputValue}'`
          : 'Search...'}
      </div>
    </components.NoOptionsMessage>
  );
};
