import { useQuery } from 'react-query';
import { getThisUserReviews } from 'helpers/http/common';

/*
 * This hook will fetch the client reviews
 */
function useClientReviews(userId?: string) {
  const { data, isLoading, isRefetching } = useQuery(
    'get-client-reviews',
    () => getThisUserReviews({ userId }),
    { enabled: !!userId }
  );
  return {
    data: data?.data || null,
    isLoading,
    isRefetching,
  };
}

export default useClientReviews;
