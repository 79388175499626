import React from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { apiClient } from 'helpers/http';
import NotAllowedMobile from 'components/NotAllowedMobile';
import { useMediaQuery } from 'react-responsive';
const BASE_URL = process.env.REACT_APP_BACKEND_API;
const client = axios.create({
  baseURL: BASE_URL,
});

interface AuthContextType {
  user: any;
  signin: (user: string) => void;
  signout: () => void;
  isLoading: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isBoostraping, setIsBootstraping] = React.useState(true);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });
  React.useEffect(() => {
    setIsBootstraping(true);
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    if (user && token) {
      setUser(JSON.parse(user));
      apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      setIsBootstraping(false);
    }
    setTimeout(() => {
      setIsBootstraping(false);
    }, 100);
  }, []);

  const signin = React.useCallback(
    (formdata: any) => {
      setIsLoading(true);
      client
        .post('/admin/login', formdata)
        .then((res) => {
          setIsLoading(false);
          if (res.data.status) {
            setUser(res.data?.data?.user);
            localStorage.setItem('user', JSON.stringify(res.data?.data?.user));
            localStorage.setItem('token', res.data?.data?.token);
            apiClient.defaults.headers.common['Authorization'] =
              'Bearer ' + res.data?.data?.token;
            navigate('/dashboard');
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(
            err.response?.data?.message || 'Something went wrong, try later!'
          );
        });
    },
    [navigate]
  );

  // const signin = (formdata: any) => {
  //   setIsLoading(true);
  //   client
  //     .post('/admin/login', formdata)
  //     .then((res) => {
  //       setIsLoading(false);
  //       if (res.data.status) {
  //         setUser(res.data?.data?.user);
  //         localStorage.setItem('user', JSON.stringify(res.data?.data?.user));
  //         localStorage.setItem('token', res.data?.data?.token);
  //         apiClient.defaults.headers.common['Authorization'] =
  //           'Bearer ' + res.data?.data?.token;
  //         navigate('/dashboard');
  //       } else {
  //         toast.error(res.data.message);
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       toast.error(
  //         err.response?.data?.message || 'Something went wrong, try later!'
  //       );
  //     });
  // };

  const signout = React.useCallback(() => {
    setUser(null);
    navigate('/login');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }, [navigate]);

  const value = React.useMemo(
    () => ({ user, signin, signout, isLoading }),
    [isLoading, signin, signout, user]
  );
  if (isBoostraping) {
    return null;
  }
  return (
    <>
      {isMobile && <NotAllowedMobile />}
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </>
  );
}
function useAuth() {
  return React.useContext(AuthContext);
}
export { AuthProvider, useAuth };
