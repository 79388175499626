import TextEditor from 'components/TextEditor';
import { StyledButton } from 'components/forms/Buttons';
import { StyledModal } from 'components/styled/StyledModal';
import { NOTE_MAX_CHARACTERS } from 'helpers/consts';
import { TAdminNote } from 'helpers/types/adminNotes.type';
import { addNoteSchema } from 'helpers/validationSchema/addNoteSchema';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

type Props = {
  selectedNote?: TAdminNote;
  show: boolean;
  onClose: () => void;
  onSubmit: (data: string) => void;
};

export const AddNoteModal = ({
  selectedNote,
  show,
  onClose,
  onSubmit,
}: Props) => {
  const [content, setContent] = useState(selectedNote?.text || '');

  useEffect(() => {
    return () => {
      setContent('');
    };
  }, [show]);

  const handleSubmit = () => {
    addNoteSchema
      .validate(content)
      .then(() => {
        onSubmit(content);
        onClose();
      })
      .catch((err) => {
        toast.error(err.errors);
      });
  };

  return (
    <StyledModal show={show} size="lg" onHide={onClose} centered maxwidth={590}>
      <Modal.Body>
        <Button variant="transparent" className="close" onClick={onClose}>
          &times;
        </Button>
        <TextEditor
          value={content}
          onChange={(data) => setContent(data)}
          maxChars={NOTE_MAX_CHARACTERS}
          placeholder="Add a note..."
        />
        <div className="text-center">
          <StyledButton onClick={handleSubmit}>Submit</StyledButton>
        </div>
      </Modal.Body>
    </StyledModal>
  );
};
