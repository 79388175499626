// /admin/dashboard-stats

import axios from 'axios';
import { apiClient } from './index';
import { EmailActivity } from 'pages/mails/mails.types';

export interface FeeStructure {
  max_amount?: number;
  min_amount?: number;
  percentage: number;
  minFixedAmount?: number;
  minFixedCharge?: number;
}

export interface StripeFeePayload {
  fee_type: string;
  fee_structure: {
    ACH?: FeeStructure;
    OTHER?: FeeStructure;
    minFixedCharge?: number;
  };
}

export const dashboardStats = () => {
  return apiClient.get('/admin/dashboard-stats').then((res) => res.data);
};

// /admin/support-requests?page=1&limit=10

export const getSupportRequests = ({
  page = 1,
  limit = 10,
  search = '',
}: {
  page?: number;
  limit?: number;
  search?: string;
}) => {
  return apiClient
    .get(
      `/admin/support-requests??page=${page}&limit=${limit}&keyword=${search}`
    )
    .then((res) => res.data);
};
export const getPayoutList = ({
  page = 1,
  limit = 10,
  search = '',
}: {
  page?: number;
  limit?: number;
  search?: string;
}) => {
  return apiClient
    .get(`/admin/seller-payouts?page=${page}&limit=${limit}&keyword=${search}`)
    .then((res) => res.data);
};

export const generateAwsUrl = (data: {
  folder: string;
  file_name: string;
  content_type: string;
}) => {
  return apiClient.post('/general/image/upload', data).then((r) => r.data);
};

export const fakePromise = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, 1000);
  });
};

export const getBrands = () => {
  return apiClient.get('/general/brands').then((res) => res.data);
};

export const addBrand = (data: any) => {
  const payload = {
    ...data,
  };
  return apiClient.post('/admin/brand', payload).then((res) => {
    if (res.data.status) {
      return res.data;
    } else {
      throw new Error(res.data.message);
    }
  });
};

export const deleteUser = (user_id: string, comment?: string) => {
  const payload: any = {
    user_id: user_id,
    deletionNote: comment ? comment : null,
  };
  return apiClient
    .delete('/user/delete', { data: payload })
    .then((res) => res.data);
};

export const getTransactions = ({
  page = 1,
  limit = 100,
  activeFilter,
}: {
  page?: number;
  limit?: number;
  activeFilter?: string;
}) => {
  return apiClient
    .get(
      `/admin/transaction/get?page=${page}&limit=${limit}&filter=${activeFilter}`
    )
    .then((res) => res.data);
};

export const getThisUserReviews = ({
  page = 1,
  limit = 100,
  userId,
}: {
  page?: number;
  limit?: number;
  userId?: string;
}) => {
  return apiClient
    .post(`/user/get-review?is_admin=true`, {
      user_id: userId,
      page: page,
      limit: limit,
    })
    .then((res) => res.data);
};

export const resolveDispute = (payload) => {
  return apiClient
    .post(`/admin/resolve-dispute`, payload)
    .then((res) => res.data);
};

export const resolveInquiry = (payload) => {
  return apiClient
    .post(`/admin/inquiry-respond`, payload)
    .then((res) => res.data);
};

export const endJob = (payload) => {
  return apiClient
    .post(`/job/end-job?is_admin=true`, payload)
    .then((res) => res.data);
};

export const getStripeBalances = () => {
  return apiClient.get(`/admin/stripe-balance`).then((res) => res.data);
};

export const getClients = (query: string) => {
  return apiClient
    .get(`/admin/client/get?keyword=${query}`)
    .then((r) => r.data);
};

export const getCategoriesApi = async (keyword = '') => {
  const { data } = await apiClient.get(`/category/list?keyword=${keyword}`);
  return data;
};

export const getSkillsApi = async (keyword = '') => {
  const { data } = await apiClient.get(`/skill/list?keyword=${keyword}`);
  return data;
};

export const addCategoryApi = async (payload = '') => {
  const { data } = await apiClient.post('/admin/category', payload);
  return data;
};

export const deleteCategoryApi = async (payload) => {
  payload.action = 'delete_category';
  const { data } = await apiClient.post('/admin/category', payload);
  return data;
};

export const addSkillApi = async (payload = '') => {
  const { data } = await apiClient.post('/admin/skill', payload);
  return data;
};

export const getFreelancers = (query: string) => {
  return apiClient
    .get(`/admin/freelancer/get?keyword=${query}`)
    .then((r) => r.data);
};

export const jobSearch = (query: string) => {
  return apiClient.get(`/admin/job-listing?q=${query}`).then((r) => r.data);
};

export const getMessages = (job_id: string) => {
  return apiClient
    .get(`/admin/job-chats?job_id=${job_id}`)
    .then((res) => res.data);
};

export const getEmailActivty = async (params: EmailActivity) => {
  try {
    const {
      data: { messages },
    } = await axios({
      method: 'GET',
      url: 'https://api.sendgrid.com/v3/messages',
      params,
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY ?? ''}`,
      },
    });

    return messages ?? [];
  } catch (error: any) {
    console.log(error.message);
  }
};

export const updateColumns = async (data) => {
  return apiClient.put('/admin/update-columns', data).then((res) => res.data);
};

export const getSystemLogs = async (payload) => {
  try {
    return apiClient
      .post('/admin/log', payload)
      .then((response) => response.data);
  } catch (error: any) {
    console.log(error.message);
  }
};

export const getCountries = (keyword: string) => {
  return apiClient
    .get(`/country/get?keyword=${keyword}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error;
    });
};

export const getAdminCountries = (query = '') => {
  return apiClient
    .get(`/admin/country?keyword=${query}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error;
    });
};

export const addCountryApi = (data: {
  country_name: string;
  country_code: string;
  country_short_name: string;
  currency?: string;
}) => {
  const payload = {
    ...data,
    action: 'add_country',
  };
  return apiClient.post('/admin/country', payload).then((r) => r.data);
};

export const editCountryApi = (data: {
  country_id: number;
  country_name: string;
  country_code: string;
  country_short_name: string;
  currency?: string;
}) => {
  const payload = {
    ...data,
    action: 'edit_country',
  };
  return apiClient.post('/admin/country', payload).then((r) => r.data);
};

export const getStripeFees = () => {
  return apiClient
    .get('/admin/stripe-fees')
    .then((r) => r.data)
    .catch((error) => {
      throw error;
    });
};

export const updateStripeFees = (data: StripeFeePayload) => {
  return apiClient.put('/admin/stripe-fees', data).then((r) => r.data);
};

export const fetchCountriesCode = async () => {
  try {
    const { data } = await getCountries('');
    return data.map((dt) => dt.country_short_name.toLocaleLowerCase());
  } catch (error) {
    return [];
  }
};

export const reopenDeletedProjectAPI = (job_post_id: string) => {
  return apiClient
    .post(`/admin/job-reopen`, { job_post_id })
    .then((r) => r.data)
    .catch((error) => {
      throw error;
    });
};
