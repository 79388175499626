import { StyledButton } from 'components/forms/Buttons';
import PhoneNumberInput from 'components/forms/phone-number-input';
import { StyledModal } from 'components/styled/StyledModal';
import ErrorMessage from 'components/ui/ErrorMessage';
import { editUser } from 'helpers/http/freelancer';
import { getYupErrors } from 'helpers/utils/misc';
import { editBasicDetailsSchema } from 'helpers/validationSchema/editBasicDetailsSchema';
import { FreelancerItem } from 'pages/freelancers/types/freelancers.types';
import { useEffect, useState } from 'react';
import { Button, Container, Modal, Row, Col, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import styled from 'styled-components';

const StyledFormGroup = styled.div`
  margin-top: 1.25rem;
  .mandatory {
    color: ${(props) => props.theme.colors.red};
  }
  .form-input {
    margin-top: 6px;
    padding: 1rem 1.25rem;
    border-radius: 7px;
    border: ${(props) => `1px solid ${props.theme.colors.black}`};
  }

  .common-phone-number-input {
    padding: 0 1.25rem !important;
  }
  .phone-input-wrapper {
    border: ${(props) => `1px solid ${props.theme.colors.black}`};
    padding-left: 0;
    input {
      height: 58px;
      font-size: 1rem;
      border: none;
      outline: none;
      box-shadow: none !important;
    }
  }

  .email-input-wrapper {
    position: relative;
  }

  .email-input {
    padding-right: 100px;
  }
`;

type Props = {
  show: boolean;
  onClose: () => void;
  data: FreelancerItem;
};

type FormDataType = Pick<
  FreelancerItem,
  | 'first_name'
  | 'last_name'
  | 'u_email_id'
  | 'phone_number'
  | 'formatted_phonenumber'
  | 'user_id'
  | 'action'
>;

const initialState: FormDataType = {
  first_name: '',
  last_name: '',
  u_email_id: '',
  phone_number: '',
  user_id: '',
  formatted_phonenumber: '',
  action: 'edit_user',
};

export const EditBasicDetailsModal = ({ show, onClose, data }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<FormDataType>(initialState);
  const [errors, setErrors] = useState<Partial<typeof initialState>>({});

  const handleClose = () => {
    if (!isLoading) onClose();
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      first_name: data?.first_name,
      last_name: data?.last_name,
      u_email_id: data?.u_email_id,
      phone_number: data?.phone_number,
      user_id: data?.user_id,
      formatted_phonenumber: data?.formatted_phonenumber,
    }));
  }, [data, show]);

  const handleSubmit = () => {
    editBasicDetailsSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        setIsLoading(true);
        toast.promise(editUser(formData), {
          loading: 'Loading...',
          success: (res) => {
            setIsLoading(false);

            handleClose();
            return res.message;
          },
          error: (err) => {
            setIsLoading(false);
            return err?.response?.data?.message || err?.message || 'error';
          },
        });
      })
      .catch((err) => {
        const errors = getYupErrors(err);
        setErrors({ ...errors });
      });
  };

  return (
    <StyledModal
      show={show}
      size="lg"
      onHide={handleClose}
      centered
      maxwidth={590}
    >
      <Modal.Body>
        <Container className="form">
          {/* START ----------------------------------------- Username */}
          <Row>
            <Col>
              <StyledFormGroup>
                <div className="fs-sm fw-400">
                  First Name<span className="mandatory">&nbsp;*</span>
                </div>
                <Form.Control
                  placeholder="Enter first name"
                  className="form-input"
                  value={formData?.first_name}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      first_name: e.target.value,
                    }));
                  }}
                  maxLength={35}
                />
                {errors?.first_name && (
                  <ErrorMessage message={errors.first_name} />
                )}
              </StyledFormGroup>
            </Col>
            <Col>
              <StyledFormGroup>
                <div className="fs-sm fw-400">
                  Last Name<span className="mandatory">&nbsp;*</span>
                </div>
                <Form.Control
                  placeholder="Enter last name"
                  className="form-input"
                  value={formData?.last_name}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      last_name: e.target.value,
                    }))
                  }
                  maxLength={35}
                />
                {errors?.last_name && (
                  <ErrorMessage message={errors.last_name} />
                )}
              </StyledFormGroup>
            </Col>
          </Row>
          {/* END ------------------------------------------- Username */}
          <Row>
            {/* START ----------------------------------------- Email */}
            <Col md={12}>
              <StyledFormGroup>
                <div className="fs-sm fw-400">
                  Email<span className="mandatory">&nbsp;*</span>
                </div>
                <div className="email-input-wrapper">
                  <Form.Control
                    placeholder="Enter your email"
                    className="form-input email-input"
                    value={formData?.u_email_id}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        u_email_id: e.target.value,
                      }))
                    }
                  />
                  {errors?.u_email_id && (
                    <ErrorMessage message={errors.u_email_id} />
                  )}
                </div>
              </StyledFormGroup>
            </Col>
            {/* END ------------------------------------------- Email */}
            <Col md={12}>
              <StyledFormGroup className="position-relative">
                <div className="fs-sm fw-400">
                  Phone<span className="mandatory">&nbsp;*</span>
                </div>
                <div className="phone-input-wrapper">
                  <PhoneNumberInput
                    initialValue={formData?.formatted_phonenumber}
                    onChange={(phone, formattedValue) => {
                      setFormData((prev) => ({
                        ...prev,
                        phone_number: phone,
                        formatted_phonenumber: formattedValue,
                      }));
                    }}
                  />
                </div>
              </StyledFormGroup>
              {errors?.formatted_phonenumber && (
                <ErrorMessage message={errors.formatted_phonenumber} />
              )}
            </Col>
          </Row>
        </Container>
        <Button variant="transparent" className="close" onClick={handleClose}>
          &times;
        </Button>
        <div className="text-center mt-4">
          <StyledButton disabled={isLoading} onClick={handleSubmit}>
            Submit
          </StyledButton>
        </div>
      </Modal.Body>
    </StyledModal>
  );
};
