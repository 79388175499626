import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Wrapper } from './projects.styled';
import Search from 'components/forms/Search';
import {
  ColumnOrderState,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { projectTableColumns } from 'pages/projects/partials/projectTableColumns';
import NoDataFound from 'components/ui/NoDataFound';
import PaginationComponent from 'components/ui/Pagination';
import { Button, Spinner } from 'react-bootstrap';
import { ManageColumns } from 'components/ui/ManageColumns';
import { useProjects } from './controller/project';
import { useNonInitialEffect } from 'helpers/hooks/useNonInitialEffect';
import useDebounce from 'helpers/hooks/useDebounce';
import Tabs from 'components/ui/Tabs';
import { TABS } from './consts';
import { CustomTable } from 'components/ui/CustomTable';
import { reopenDeletedProjectAPI, updateColumns } from 'helpers/http/common';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const Projects = () => {
  const {
    isLoading,
    isRefetching,
    data,
    totalPages,
    filters,
    updateFilters,
    refetch,
  } = useProjects();

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [isLoadingManageColumns, setIsLoadingManageColumns] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const debouncedSearchQuery = useDebounce(searchTerm, 500);

  useEffect(() => {
    setSearchTerm(filters.keyword);
  }, [filters.keyword]);

  // gettings freelancer columns settings from user object and updating state
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user?.project_columns?.column_visibility) {
      setColumnVisibility(user.project_columns.column_visibility);
    }
    if (user?.project_columns?.column_order) {
      setColumnOrder(user.project_columns.column_order);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openClosedProjects = (data) => {
    if (typeof data?.job_post_id !== 'string') return;
    const promise = reopenDeletedProjectAPI(data.job_post_id);

    setLoading(true);
    toast.promise(promise, {
      loading: 'Re-opening project',
      success: (data) => {
        refetch();
        setLoading(false);
        return data.message;
      },
      error: (error) => {
        refetch();
        setLoading(false);
        return error.response.data.message;
      },
    });
  };

  useNonInitialEffect(() => {
    updateFilters({
      keyword: debouncedSearchQuery?.trim(),
      page: 1,
      searchBy: '',
      searchText: '',
    });
  }, [debouncedSearchQuery, updateFilters]);

  const memoizedProjectsTableColumns = useMemo(
    () =>
      projectTableColumns(filters, updateFilters, openClosedProjects, loading),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, loading]
  );

  const table = useReactTable({
    data: data?.job_data || [],
    columns: memoizedProjectsTableColumns,
    state: {
      columnVisibility,
      columnOrder,
    },
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
  });

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(e.target.value);

  const onPageChange = (page: { selected: number }) =>
    updateFilters({ page: page?.selected + 1 });

  const onTabChange = (tab: string) => {
    updateFilters({ status: tab as (typeof filters)['status'], page: 1 });
  };

  const handleManageColumnsSave = () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const payload = {
      project_columns: {
        column_visibility: table?.options?.state?.columnVisibility || {},
        column_order: table?.options?.state?.columnOrder || [],
      },
    };

    // No need to make api call if user haven't changed settings
    if (
      JSON.stringify(user?.project_columns) ===
      JSON.stringify(payload.project_columns)
    )
      return;

    setIsLoadingManageColumns(true);
    updateColumns(payload)
      .then(() => {
        setIsLoadingManageColumns(false);
        toast.success('Updated column settings');
        // updating it in localstorage because user object updates only on logout and login
        localStorage.setItem('user', JSON.stringify({ ...user, ...payload }));
      })
      .catch((err) => {
        setIsLoadingManageColumns(false);
        console.error('Update columns:', err);
        toast.error('Failed to update column settings');
      });
  };

  const resetFilter = () => {
    updateFilters({
      sortBy: '',
      searchBy: '',
      sortOrder: '',
      searchText: '',
    });
  };

  return (
    <Wrapper>
      <div className="d-flex align-items-center my-3">
        <h1 className="fs-32 fw-700 mb-0">Projects</h1>
        {(isLoading || isRefetching) && (
          <Spinner animation="border" className="ms-2" size="sm" />
        )}
      </div>

      {/* Search box and status filters*/}
      <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
        {/* Search box */}
        <Search value={searchTerm} onChange={onSearch} />

        {data && (
          <div className="d-flex flex-direction-row align-items-center">
            <Tabs
              tabs={TABS}
              activeTab={filters?.status || ''}
              onTabChange={onTabChange}
              counts={{
                all: data?.all_job,
                active: data?.active,
                prospects: data?.prospects,
                draft: data?.draft,
                closed: data?.closed,
              }}
            />
            {data?.job_data?.length > 0 && (
              <ManageColumns
                className="ms-4"
                table={table}
                elementId="projects-table-manage-columns"
                handleSave={handleManageColumnsSave}
                isLoading={isLoadingManageColumns}
              />
            )}
          </div>
        )}
      </div>

      <div className="listing-table p-3 mt-4">
        {/* Listing table */}

        <div className="listings">
          {isLoading || isRefetching ? (
            <div className="d-flex align-items-center justify-content-center gap-3 mt-5">
              <Spinner animation="border" />
              <span>Loading...</span>
            </div>
          ) : (
            <>
              {data?.job_data && data?.job_data?.length > 0 && (
                <CustomTable
                  table={table}
                  handleRowClick={(row) => {
                    if (row?.original?.job_post_id) {
                      navigate(
                        `/project-details/${row.original.job_post_id}/${row.original._client_user_id}`
                      );
                    }
                  }}
                />
              )}
            </>
          )}

          {/* No data found */}
          {!isLoading &&
            !isRefetching &&
            (!data?.job_data || data?.job_data?.length == 0) && (
              <div className="my-5 py-5">
                <NoDataFound />
                {/* START ----------------------------------------- Showing reset filter button only if any filter is applied */}
                {[
                  'sortBy',
                  'sortOrder',
                  'searchBy',
                  'searchText',
                  'keyword',
                ].some((key) => filters[key]) && (
                  <div className="d-flex justify-content-center mt-2">
                    <Button className="text-center" onClick={resetFilter}>
                      Reset Filters
                    </Button>
                  </div>
                )}
                {/* END ------------------------------------------- Showing reset filter button only if any filter is applied */}
              </div>
            )}
        </div>

        {/* Pagination */}
        {data?.job_data &&
          data?.job_data?.length > 0 &&
          !isLoading &&
          !isRefetching && (
            <div className="d-flex justify-content-center mt-3">
              <PaginationComponent
                total={totalPages}
                onPageChange={onPageChange}
                currentPage={filters.page}
              />
            </div>
          )}
      </div>
    </Wrapper>
  );
};

export default Projects;
