import Categories from './category';
import { CategoryProvider } from './categoryProvider';

// eslint-disable-next-line react/display-name
export default function () {
  return (
    <CategoryProvider>
      <Categories />
    </CategoryProvider>
  );
}
