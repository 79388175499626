import styled from 'styled-components';

export const Wrapper = styled.div`
  .listing-table {
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
    background: ${(props) => props.theme.colors.white};
    border-radius: 12px;
  }
  .listings {
    min-height: 600px;
  }
  .added {
    color: ${(props) => props.theme.colors.green};
  }
  .deducted {
    color: ${(props) => props.theme.colors.darkRed};
  }
  tr {
    // To reemove cursor as pointer because only in this table we don't have the row clickable
    cursor: initial;
  }
  td {
    vertical-align: middle;
  }
  .apply-bg {
    background: #fff1f1;
  }
  .refund-symbol {
    box-sizing: border-box;
    width: 23px;
    height: 23px;
    margin-right: 0.5rem;
    background: #fff1f1;
    border: 1px solid rgba(255, 4, 4, 0.1);
    border-radius: 12px;
  }
  .refund-label {
    margin-right: 2rem;
  }
`;
