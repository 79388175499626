import { useQuery } from 'react-query';
import { getFreelancerJobs } from 'helpers/http/freelancer';
import useDebounce from 'helpers/hooks/useDebounce';
/*
 * This hook will load the client jobs
 */
function useFreelancerJobs({
  query,
  limit,
  freelancerId,
  jobStatus,
}: {
  query: string;
  limit: number;
  freelancerId?: string;
  jobStatus?: string;
}) {
  /* This query is being set from the browser url (Search params) from the parent component */

  const params = new URLSearchParams(query);
  const page = params.get('pg') || '1'; // default page = 1

  const payload = {
    status: jobStatus,
    limit: limit,
    page: Number(page),
    userId: freelancerId,
  };

  const debouncedQuery = useDebounce(query, 500);

  const { data, isLoading, refetch, isRefetching } = useQuery(
    [`get-freelancer-jobs-${jobStatus}`, debouncedQuery],
    () => getFreelancerJobs(payload),
    { keepPreviousData: true, enabled: !!freelancerId } // If query is passed then only this will be called
  );

  return {
    data: data?.data || [],
    totalResults: data?.total || 0,
    isLoading,
    refetch,
    isRefetching,
  };
}

export default useFreelancerJobs;
