import { Table } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  thead {
    th {
      background-color: rgba(243, 243, 243, 1);
      padding: 0.8rem;
      border: 0;
      color: black;
      font-weight: 400;
      font-size: 1rem;
      cursor: initial;
      &.more-button {
        background-color: rgba(243, 243, 243, 1);
      }
    }
    th:first-child {
      border-radius: 6px 0 0 6px;
    }
    th:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
  tbody {
    border-top: 0 !important;
  }
  td {
    border-bottom: 0;
    padding: 0.8rem;
    min-width: 150px;
    max-width: 500px;
    vertical-align: middle;
  }
  .more-button {
    min-width: 0px;
    position: sticky;
    right: 0;
    background-color: inherit;
    padding: 0px;
    padding-right: 10px;
  }
  td:first-child {
    border-radius: 4px 0 0 4px;
  }
  td:last-child {
    border-radius: 0 4px 4px 0;
  }
  tr {
    background-color: white;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: rgba(251, 245, 232, 1);
    }
  }
`;
