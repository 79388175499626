import { TFilters } from 'components/ui/columnOptions.types';
import { apiClient } from 'helpers/http';
import React, { ReactNode, useEffect } from 'react';
import { useQuery } from 'react-query';
import { IFreelancersResponse } from '../types/freelancers.types';

type TFreelancerContext = {
  filters: TFilters;
  updateFilters: (newFilter: Partial<TFilters>) => void;
  data?: IFreelancersResponse;
  isLoading: boolean;
  isRefetching: boolean;
  refetch: () => void;
  totalPages: number;
};

const initialContextValue: TFreelancerContext = {
  filters: {
    keyword: '',
    status: 'all',
    page: 1,
    limit: 10,
    sortBy: '',
    sortOrder: '',
    searchBy: '',
    searchText: '',
  },
  data: undefined,
  isLoading: false,
  isRefetching: false,
  refetch: () => {
    //
  },
  totalPages: 0,
  updateFilters: () => {
    //
  },
};

const FreelancerContext = React.createContext(initialContextValue);

export const getFreelancers = (params) => {
  const [, filters] = params.queryKey;
  const query = new URLSearchParams(filters).toString();
  return apiClient
    .get(`/admin/freelancer/get?${query}`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const FreelancerProvider = ({ children }: { children: ReactNode }) => {
  const queryParamsFilters = Object.fromEntries(
    new URLSearchParams(location.search)
  );

  const [filters, setFilters] = React.useState<TFreelancerContext['filters']>({
    ...initialContextValue.filters,
    ...queryParamsFilters,
  });

  const { data, isLoading, isRefetching, refetch } = useQuery<{
    data: TFreelancerContext['data'];
  }>(['freelancers', filters], getFreelancers, {
    enabled: !!filters,
    keepPreviousData: true,
  });

  const updateFilters = React.useCallback<TFreelancerContext['updateFilters']>(
    (newFilter) => {
      setFilters((prev) => ({ ...prev, ...newFilter }));
    },
    []
  );

  const value = React.useMemo<TFreelancerContext>(() => {
    return {
      filters,
      updateFilters,
      data: data?.data,
      isLoading,
      isRefetching,
      refetch,
      totalPages: data?.data?.total ? Math.ceil(data?.data?.total / 10) : 0,
    };
  }, [data?.data, filters, isLoading, isRefetching, refetch, updateFilters]);

  // Set query params to url
  useEffect(() => {
    const queryParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key]) queryParams.set(key, filters[key]);
    });

    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname;

    if (queryParams.toString())
      newurl = newurl.concat('?' + queryParams.toString());

    window.history.replaceState({ push: newurl }, '', newurl);
  }, [filters]);

  return (
    <FreelancerContext.Provider value={value}>
      {children}
    </FreelancerContext.Provider>
  );
};

function useFreelancers() {
  return React.useContext(FreelancerContext);
}

export { FreelancerProvider, useFreelancers };
