export const TABS = [
  {
    id: 0,
    label: 'All',
    key: 'all',
    showCount: true,
  },
  {
    id: 1,
    label: 'In Progress',
    key: 'active',
    showCount: true,
  },
  {
    id: 3,
    label: 'Posted',
    key: 'prospects',
    showCount: true,
  },
  {
    id: 4,
    label: 'Drafts',
    key: 'draft',
    showCount: true,
  },
  {
    id: 5,
    label: 'Closed',
    key: 'closed',
    showCount: true,
  },
];

export const PROJECT_STATUS = {
  IN_PROGRESS: 'In Progress',
  CLOSED: 'Closed',
  DRAFT: 'Draft',
  POSTED: 'Posted',
};

export const CLOSE_STATUS = {
  INCOMPLETE: 'Incomplete',
  COMPLETED: 'Completed',
};
