import { useQuery } from 'react-query';
import { getDashboardSupportRequests } from 'helpers/http/dashboard';

/* This hook will fetch support requests with filters here activeTab is filter selected from top */
function useSupportRequests({
  activeTab,
  page,
  limit,
  statusFilter,
}: {
  activeTab: string;
  page: number;
  limit: number;
  statusFilter: string;
}) {
  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['get-support-requests', activeTab, page, statusFilter],
    () => getDashboardSupportRequests({ activeTab, page, limit, statusFilter }),
    { keepPreviousData: true }
  );
  return {
    data: data?.data || [],
    totalResults: data?.total || 0,
    isLoading,
    isRefetching,
    refetch,
  };
}

export default useSupportRequests;
