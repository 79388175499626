import styled from 'styled-components';

export const Wrapper = styled.div`
  .listing-table {
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
    background: ${(props) => props.theme.colors.white};
    border-radius: 12px;
    margin-top: 5rem;
  }
  .listings {
    min-height: 600px;
  }
  .avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .select-style {
    width: 250px;
  }
  .filters {
    top: 110px;
  }

  .rmdp-input {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 31px;
    padding: 23px 40px;
    width: 100%;
    min-width: 300px;
  }
  .rmdp-arrow {
    border: solid ${(props) => props.theme.colors.primary};
    border-width: 0 3px 3px 0px;
  }
  .rmdp-arrow-container:hover {
    background-color: ${(props) => props.theme.colors.primary};
    box-shadow: 0 0 3px #fefbf4;
  }
  .rmdp-arrow-container:hover .rmdp-arrow {
    margin-top: 7px;
    margin-right: 2px;
  }
  .rmdp-week-day {
    color: ${(props) => props.theme.colors.primary};
  }
  .rmdp-range {
    background-color: ${(props) => props.theme.colors.primary};
    box-shadow: 0 0 3px ${(props) => props.theme.colors.primary};
  }
  .rmdp-range-hover {
    background-color: ${(props) => props.theme.colors.primary};
  }
  .rmdp-day.rmdp-today span {
    background-color: ${(props) => props.theme.colors.primary};
  }
  .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: #f9d274;
    color: #fff;
  }
`;
