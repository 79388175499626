import React, { useState } from 'react';
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { Wrapper } from './transactions.styled';
import { StyledTable } from 'components/styled/StyledTable';
import useTransactions from './hooks/useTransactions';
import PaginationComponent from 'components/ui/Pagination';
import TimeRangeFilters from 'components/ui/TimeRangeFilters';
import NoDataFound from 'components/ui/NoDataFound';
import StyledHtmlText from 'components/ui/StyledHtmlText';
import { numberWithCommas } from 'helpers/utils/misc';
import { TransactionItem } from './types/transactions.types';
import { ReactComponent as ReceivedIcon } from 'assets/icons/received.svg';
import { ReactComponent as ChargedIcon } from 'assets/icons/charged.svg';
import { ReactComponent as Info } from 'assets/icons/info-circle-gray.svg';
import TransactionsTotals from './partials/TransactionsTotals';

interface transactionEnumTypes {
  [propertyName: string]: {
    icon: React.ReactElement;
    className: string;
    directionLabel: string;
  };
}

/* Two types of transactions will be there: Transfered to freelancer (transfer) and
 * Deducted from client - Charged
 */
const TRANSACTION_ENUM: transactionEnumTypes = {
  charge: {
    icon: <ReceivedIcon />,
    className: 'added',
    directionLabel: 'From',
  },
  transfer: {
    icon: <ChargedIcon />,
    className: 'deducted',
    directionLabel: 'To',
  },
  refund: {
    icon: <ChargedIcon />,
    className: 'deducted',
    directionLabel: 'To',
  },
};

interface filterOptionType {
  [propertyName: string]: string;
}

const FILTER_OPTIONS: filterOptionType = {
  alltime: 'All time',
  today: 'Today',
  thisweek: 'This week',
  thismonth: 'All month',
  thisyear: 'All year',
  last3years: 'last 3 years',
};

const Transactions = () => {
  const RECORDS_PER_PAGE = 10; // 10 records will be displayed per page

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [activeFilter, setActiveFilter] = useState<string>('alltime');

  const onChangeFilter = (key: string) => {
    /* This will change the filter and apply filter for the counts */
    setCurrentPage(1);
    setActiveFilter(key);
  };

  /* fetching transaction data */
  const {
    transactionsData,
    totalZehmizehProfit,
    isLoading,
    totalResults,
    isRefetching,
  } = useTransactions({
    page: currentPage,
    limit: RECORDS_PER_PAGE,
    activeFilter,
  });

  const onPageChange = (page: { selected: number }) => {
    /* This will set next page as active and load new page data */
    setCurrentPage(page?.selected + 1);
  };

  return (
    <Wrapper>
      {/* Heading */}
      <div className="d-flex align-items-center my-3">
        <h1 className="fs-32 fw-700 mb-0">Transactions</h1>

        {(isRefetching || isLoading) && (
          <Spinner animation="border" className="ms-2" size="sm" />
        )}
      </div>

      <TransactionsTotals totalZehmizehProfit={totalZehmizehProfit} />

      <div className="listing-table p-3 mt-4">
        <div className="d-flex flex-lg-row flex-column align-items-lg-center justify-content-end mt-3 mb-4">
          <div className="d-flex">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <label className="refund-symbol"></label>
              <label className="refund-label">Refund</label>
            </div>
            <TimeRangeFilters
              options={FILTER_OPTIONS}
              activeFilter={activeFilter}
              onChange={onChangeFilter}
            />
          </div>
        </div>

        {/* Transaction table */}
        <div className="listings">
          <StyledTable responsive>
            {/* Table heading */}
            <thead>
              <tr>
                <th>Transaction amount</th>
                <th>Milestone amount</th>
                {/* <th>ZehMizeh cut</th> */}
                <th>ZehMizeh profit</th>
                <th>Project name</th>
                <th>From/To</th>
                <th>Description</th>
                <th>Date</th>
              </tr>
            </thead>

            {/* The main listing goes under the body here */}
            <tbody>
              {transactionsData?.length > 0 &&
                transactionsData?.map((item: TransactionItem) => (
                  <tr
                    key={item?.charge_trans_id}
                    className={item.payment_type === 'refund' ? 'apply-bg' : ''}
                  >
                    <td>
                      <div
                        className={`d-flex flex-wrap gap-2 align-items-center fs-18 fw-700 ${
                          TRANSACTION_ENUM[item.payment_type]?.className
                        }`}
                      >
                        <div className="d-flex align-items-center">
                          {item?.payment_type === 'charge' ? '+ ' : '- '}
                          {numberWithCommas(Number(item?.amount), 'USD')}
                        </div>

                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              <div>
                                <div>
                                  Milestone amount:{' '}
                                  {numberWithCommas(
                                    item?.milestone?.milestone_amount,
                                    'USD'
                                  )}
                                </div>
                                {item?.payment_type !== 'refund' ? (
                                  <div className="mt-1">
                                    {item?.payment_type === 'charge'
                                      ? '+'
                                      : '-'}{' '}
                                    ZehMizeh fees({item.fee_percentage}%):{' '}
                                    {numberWithCommas(
                                      Number(item?.fee_amount),
                                      'USD'
                                    )}
                                  </div>
                                ) : null}

                                {item?.payment_type === 'refund' ? (
                                  <div className="mt-1">
                                    - Transaction charge(
                                    {
                                      item.stripe_fee_object
                                        ?.refund_fee_percentage
                                    }
                                    %):{' '}
                                    {numberWithCommas(
                                      Number(
                                        item?.stripe_fee_object?.refund_fee
                                      ),
                                      'USD'
                                    )}
                                  </div>
                                ) : null}
                                <div className="mt-1">
                                  Total:{' '}
                                  {numberWithCommas(
                                    Number(item?.amount),
                                    'USD'
                                  )}
                                </div>
                              </div>
                            </Tooltip>
                          }
                        >
                          <Info />
                        </OverlayTrigger>
                      </div>
                    </td>

                    <td>
                      <div className="d-flex flex-wrap gap-2 align-items-center fs-16 fw-400">
                        <div className="d-flex align-items-center">
                          {numberWithCommas(
                            Number(item?.milestone?.milestone_amount),
                            'USD'
                          )}
                        </div>
                      </div>
                    </td>

                    {/* <td className="fs-1rem fw-400">
                      {item.payment_type !== 'refund'
                        ? numberWithCommas(Number(item?.fee_amount), 'USD')
                        : '-'}
                    </td> */}

                    <td className="fs-1rem fw-400">
                      {item.payment_type !== 'refund'
                        ? numberWithCommas(
                            Number(item?.fee_amount) - item?.stripe_fees,
                            'USD'
                          )
                        : '-'}
                    </td>

                    <td className="fs-1rem fw-400">
                      {item?.jobdata?.job_title}
                    </td>

                    <td>
                      <div className="fs-1rem fw-400 d-flex align-items-center">
                        <div>{TRANSACTION_ENUM[item.payment_type]?.icon}</div>
                        <span className="ms-2">
                          {TRANSACTION_ENUM[item.payment_type]?.directionLabel}
                          &nbsp;
                          {item?.userdata?.first_name}{' '}
                          {item?.userdata?.last_name}
                        </span>
                      </div>
                    </td>

                    <td className="fs-1rem fw-400">
                      <StyledHtmlText
                        htmlString={item?.milestone?.description}
                        needToBeShorten={true}
                        id={`transaction_${item?.charge_trans_id}`}
                        fontSize={0.875}
                      />
                    </td>

                    <td className="fs-1rem fw-400">
                      {item?.date_created
                        ? moment(item?.date_created).format('MMM DD, YYYY')
                        : '-'}
                    </td>
                  </tr>
                ))}
            </tbody>
          </StyledTable>
          {!isLoading && !isRefetching && transactionsData?.length == 0 && (
            <div className="my-5 py-5">
              <NoDataFound />
            </div>
          )}
        </div>

        {/* pagination */}
        {transactionsData?.length > 0 && (
          <div className="d-flex justify-content-center mt-3">
            <PaginationComponent
              total={Math.ceil(totalResults / RECORDS_PER_PAGE)}
              onPageChange={onPageChange}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default Transactions;
