import { Image } from 'react-bootstrap';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { StyledButton } from './forms/Buttons';

const Wrapper = styled.div`
  height: 100vh;
`;

const Page404Title = styled.div`
  font-size: 3.25rem;
  margin-top: 2.5rem;
  color: ${(props) => props.theme.colors.yellow};
`;
const NotFoundText = styled.div`
  opacity: 0.9;
  margin-bottom: 0.938rem;
  margin-top: 1.25rem;
  font-size: 2rem;
`;
const NotFoundDescription = styled.div`
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 2.25rem;
`;

function Page404() {
  const navigate = useNavigate();
  const goBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };
  return (
    <Wrapper className="d-flex justify-content-center align-items-center flex-column">
      <Image src="images/notFound.png" width="275px" height="200px" fluid />

      <Page404Title className="fw-bold">404</Page404Title>
      <NotFoundText className="fw-bold">Page not found!</NotFoundText>
      <NotFoundDescription className="fw-500 fs-1rem text-center">
        The page you are looking for doesn’t exist or is unavailable.
      </NotFoundDescription>
      <StyledButton onClick={goBack}>Go back</StyledButton>
    </Wrapper>
  );
}

export default Page404;
