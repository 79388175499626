import styled from 'styled-components';
import { fileIsAnImage } from 'helpers/utils/misc';
import { ReactComponent as CrossIcon } from 'assets/icons/cross-icon.svg';

const FILE_PATHS = {
  docx: '/images/doc.png',
  pdf: '/images/pdf.png',
  xls: '/images/sheet.png',
  xl: '/images/sheet.png',
  xlsx: '/images/sheet.png',
  xlsb: '/images/sheet.png',
  csv: '/images/sheet.png',
  doc: '/images/doc.png',
  webm: '/images/video.png',
  mp4: '/images/video.png',
  mov: '/images/video.png',
  avi: '/images/video.png',
  mpeg: '/images/video.png',
  mpg: '/images/video.png',
  wmv: '/images/video.png',
  flv: '/images/video.png',
  mkv: '/images/video.png',
  ogg: '/images/video.png',
  ogv: '/images/video.png',
};

type Props = {
  fileName?: string;
  onDelete?: () => void;
  uploadedFile?: any;
  removable?: boolean;
};

const getFileDetails = (file: string) => {
  const attachedName = file.includes('#docname=')
    ? file.split('#docname=').pop()
    : '';
  const fileExtension = file.split('.').pop() || 'Doc';
  const fileName = attachedName || file.split('/').pop() || 'File';
  const fileIcon = FILE_PATHS[fileExtension.toLowerCase()] || FILE_PATHS.doc;
  return { fileExtension, fileName, fileIcon };
};

const PreviewWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.gray6};
  border-radius: 0.75rem;
  img {
    border-radius: 0.75rem;
    object-fit: contain;
  }
  .doctype-preview {
    padding: 0.5rem;
    .file-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .extension {
      color: ${(props) => props.theme.colors.gray8};
    }
    .doctype-preview-details {
      width: 9.5rem;
    }
  }
  .delete-preview {
    top: -5px;
    right: -5px;
    background-color: ${(props) => props.theme.colors.black};
    z-index: 9999;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.colors.white};
  }
`;

const AttachmentPreview = ({
  fileName,
  uploadedFile,
  onDelete,
  removable = true,
}: Props) => {
  /** @function This function will download the sample csv file - works across all browsers */
  const downloadSampleFile = () => {
    // const requestOptions = {
    //   method: 'GET',
    //   mode: 'cors',
    //   headers: { Accept: '*' },
    // };

    fetch(uploadedFile, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: '*',
        pragma: 'no-cache',
        'cache-control': 'no-cache',
      },
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', getFileDetails(uploadedFile)?.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        return Promise.reject({ Error: 'Something Went Wrong', err });
      });
  };

  return (
    <PreviewWrapper
      className="d-flex attachment-preview position-relative"
      title={fileName || getFileDetails(uploadedFile)?.fileName || ''}
    >
      {/* <a href={uploadedFile} target="_blank" rel="noreferrer"> */}
      <div onClick={downloadSampleFile} className="pointer">
        {fileIsAnImage(uploadedFile) ? (
          <img src={uploadedFile} alt="uploaded" height="100px" width="100px" />
        ) : (
          <div className="doctype-preview text-center d-flex align-items-center">
            <img
              src={getFileDetails(uploadedFile)?.fileIcon || '/images/pdf.png'}
              alt="uploaded"
              height="83px"
              width="83px"
            />
            <div className="doctype-preview-details ms-2">
              <div className="file-title text-start text-capitalize fw-500">
                {fileName || getFileDetails(uploadedFile)?.fileName}
              </div>
              <div className="extension text-start text-uppercase fs-sm">
                {getFileDetails(uploadedFile)?.fileExtension}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* </a> */}
      {removable && (
        <div
          className="delete-preview position-absolute d-flex align-items-center justify-content-center pointer"
          onClick={onDelete}
          title="Delete attachment"
        >
          <CrossIcon />
        </div>
      )}
    </PreviewWrapper>
  );
};

export default AttachmentPreview;
