import { useQuery } from 'react-query';
import { getClientDetails } from 'helpers/http/clients';
import { showErr } from 'helpers/utils/misc';

/*
 * This hook will fetch the client details
 */
function useClientProfile(id?: string) {
  const { data, isLoading, isRefetching, error, isError, refetch } = useQuery(
    'get-client-details',
    () => getClientDetails(id),
    {
      onError: (err: any) => {
        showErr(err?.response?.data?.message);
      },
    }
  );
  return {
    data: data?.data || null,
    isLoading,
    isRefetching,
    error,
    isError,
    refetch,
  };
}

export default useClientProfile;
