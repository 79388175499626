import React from 'react';
import { Wrapper } from './utm-info.styled';
import { Button, Spinner } from 'react-bootstrap';
import { useUtm } from './controller/utmInfo';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { utmTableColumns } from './partials/utmTableColumns';
import { CustomTable } from 'components/ui/CustomTable';
import NoDataFound from 'components/ui/NoDataFound';
import Loader from 'components/Loader';
import PaginationComponent from 'components/ui/Pagination';

export const UTMInfo = () => {
  const { isLoading, isRefetching, data, filters, updateFilters, totalPages } =
    useUtm();

  const onPageChange = (page: { selected: number }) =>
    updateFilters({ page: page?.selected + 1 });

  const resetFilter = () => {
    updateFilters({
      sortBy: '',
      searchBy: '',
      sortOrder: '',
      searchText: '',
    });
  };

  const table = useReactTable({
    data: data || [],
    columns: utmTableColumns(filters, updateFilters),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Wrapper>
      <div className="d-flex align-items-center my-3">
        <h1 className="fs-32 fw-700 mb-0">UTM Info</h1>
        {(isRefetching || isLoading) && (
          <Spinner animation="border" className="ms-2" size="sm" />
        )}
      </div>

      {/* Initial loader */}
      {isLoading && data?.length == 0 && <Loader />}

      <div className="listing-table p-3 mt-4">
        {/* Listing table */}

        <div className="listings">
          {data && data?.length > 0 && <CustomTable table={table} />}

          {/* No data found */}
          {!isLoading && !isRefetching && data?.length == 0 && (
            <div className="my-5 py-5">
              <NoDataFound />
              {/* START ----------------------------------------- Showing reset filter button only if any filter is applied */}
              {[
                'sortBy',
                'sortOrder',
                'searchBy',
                'searchText',
                'keyword',
              ].some((key) => filters[key]) && (
                <div className="d-flex justify-content-center mt-2">
                  <Button className="text-center" onClick={resetFilter}>
                    Reset Filters
                  </Button>
                </div>
              )}
              {/* END ------------------------------------------- Showing reset filter button only if any filter is applied */}
            </div>
          )}
        </div>

        {/* Pagination */}
        {data && data?.length > 0 && totalPages > 0 && (
          <div className="d-flex justify-content-center mt-3">
            <PaginationComponent
              total={totalPages}
              onPageChange={onPageChange}
              currentPage={filters.page}
            />
          </div>
        )}
      </div>
    </Wrapper>
  );
};
