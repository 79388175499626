import { TFilters } from 'components/ui/columnOptions.types';
import { apiClient } from 'helpers/http';
import React, { ReactNode, useEffect } from 'react';
import { useQuery } from 'react-query';
import { IClientsResponse } from '../types/clients.types';

type TClientContext = {
  filters: TFilters;
  updateFilters: (newFilter: Partial<TFilters>) => void;
  data?: IClientsResponse['data'];
  isLoading: boolean;
  isRefetching: boolean;
  refetch: () => void;
  totalPages: number;
  totalRecords: number;
  active: number;
  deleted: number;
  flagged: number;
};

const initialContextValue: TClientContext = {
  filters: {
    keyword: '',
    status: 'all',
    page: 1,
    limit: 10,
    sortBy: '',
    sortOrder: '',
    searchBy: '',
    searchText: '',
  },
  data: undefined,
  isLoading: false,
  isRefetching: false,
  refetch: () => {
    //
  },
  totalPages: 0,
  updateFilters: () => {
    //
  },
  active: 0,
  deleted: 0,
  flagged: 0,
  totalRecords: 0,
};

const ClientContext = React.createContext(initialContextValue);

export const getClients = (params) => {
  const [, filters] = params.queryKey;
  const query = new URLSearchParams(filters).toString();
  return apiClient
    .get(`/admin/client/get?${query}`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const ClientProvider = ({ children }: { children: ReactNode }) => {
  const queryParamsFilters = Object.fromEntries(
    new URLSearchParams(location.search)
  );

  const [filters, setFilters] = React.useState<TClientContext['filters']>({
    ...initialContextValue.filters,
    ...queryParamsFilters,
  });

  const { data, isLoading, isRefetching, refetch } = useQuery<IClientsResponse>(
    ['clients', filters],
    getClients,
    {
      enabled: !!filters,
      keepPreviousData: true,
    }
  );

  const updateFilters = React.useCallback(
    (newFilter: Partial<TClientContext['filters']>) => {
      setFilters((prev) => ({ ...prev, ...newFilter }));
    },
    []
  );

  const value = React.useMemo<TClientContext>(
    () => ({
      filters,
      updateFilters,
      data: data?.data,
      isLoading,
      isRefetching,
      refetch,
      totalPages: data?.total ? Math.ceil(data?.total / 10) : 0,
      totalRecords: data?.total || 0,
      active: data?.active || 0,
      deleted: data?.deleted || 0,
      flagged: data?.flagged || 0,
    }),
    [
      data?.active,
      data?.data,
      data?.deleted,
      data?.flagged,
      data?.total,
      filters,
      isLoading,
      isRefetching,
      refetch,
      updateFilters,
    ]
  );

  // Set query params to url
  useEffect(() => {
    const queryParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key]) queryParams.set(key, filters[key]);
    });

    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname;

    if (queryParams.toString())
      newurl = newurl.concat('?' + queryParams.toString());

    window.history.replaceState({ push: newurl }, '', newurl);
  }, [filters]);

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
};

function useClients() {
  return React.useContext(ClientContext);
}

export { ClientProvider, useClients };
