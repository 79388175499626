import { useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { StatusBadge } from 'components/styled/Badges';
import { JOBS_STATUS } from '../consts';
import {
  changeStatusDisplayFormat,
  convertToTitleCase,
  numberWithCommas,
} from 'helpers/utils/misc';
import { JobItemType } from '../types/clientJobs.types';
import { ReactComponent as ApplicantsIcon } from 'assets/icons/applicants.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star-yellow.svg';

const ListingItemWrapper = styled.div`
  box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.08);
  border-radius: 0.875rem;
  background-color: ${(props) => props.theme.colors.white};
  padding: 2rem;
  .job-details {
    gap: 1.25rem;
    max-width: 70%;
    word-wrap: break-word;
  }
  .title {
    line-height: 2rem;
  }
  .light-text {
    opacity: 0.5;
  }
  .applicants {
    border: 1px solid ${(props) => props.theme.colors.gray6};
    border-radius: 2rem;
    padding: 0.5rem 1rem;
  }
  .divider {
    width: 1px;
    height: 1.5rem;
    background: ${(props) => props.theme.colors.gray6};
  }
  .budget-and-date {
    gap: 2rem;
  }
  .client-img-avatar {
    height: 47px;
    width: 47px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const JobsListingItem = ({
  jobItem,
  goToJobDetails,
}: {
  jobItem: JobItemType;
  goToJobDetails: () => void;
}) => {
  const showBudget = useMemo(() => {
    /* IF Job status is prospects
     * => we need to check item.budget obejct
     * => It can have budget either hourly | fixed | unsure
      ELSE
     * => need to check from proposal approved budget in case of in progress | closed jobs
     * => and there we will have a fixed amount either of the whole project amount (Fixed) or Fixed hourly rate
     */

    const jobStatus = jobItem?.status;
    let budgetString = '';

    if (jobStatus == 'prospects' || jobStatus == 'draft') {
      const budget = jobItem?.budget;

      if (budget?.type == 'fixed') {
        budgetString = numberWithCommas(budget?.amount, 'USD');
      } else if (budget?.type == 'hourly') {
        budgetString = `${numberWithCommas(
          budget?.min_amount,
          'USD'
        )}/hr - ${numberWithCommas(budget?.max_amount, 'USD')}/hr`;
      } else if (budget?.type == 'unsure') {
        budgetString = `${numberWithCommas(
          budget?.min_amount,
          'USD'
        )} - ${numberWithCommas(budget?.max_amount, 'USD')}`;
      }
    } else if (jobStatus == 'active' || jobStatus == 'closed') {
      const budget = jobItem?.proposal?.approved_budget;
      const amount = numberWithCommas(Number(budget?.amount), 'USD');

      budgetString = `${amount}`;
      if (budget?.type == 'hourly') {
        budgetString = `${amount}/hr`;
      }
    }
    return budgetString;
  }, [jobItem?.status, jobItem?.budget, jobItem?.proposal?.approved_budget]);

  const dateLabel = useMemo(() => {
    const jobStatus = jobItem.status;
    if (jobStatus === 'prospects') {
      return 'Posted on: ';
    } else if (jobStatus === 'draft') {
      return 'Created on: ';
    } else if (jobStatus == 'active' || jobStatus == 'closed') {
      return 'Paid: ';
    } else return '';
  }, [jobItem?.status]);

  return (
    <ListingItemWrapper
      className="mt-4 d-flex justify-content-between gap-3 flex-wrap pointer"
      onClick={goToJobDetails}
    >
      {/* Left side section */}
      <div className="job-details d-flex flex-column">
        <div className="title fs-24 fw-400">
          {convertToTitleCase(jobItem?.job_title)}
        </div>

        <div className="budget-and-date d-flex align-items-center">
          <div className="fs-20 fw-400">
            <span className="light-text">Budget:</span>
            &nbsp;
            {showBudget}
          </div>

          <div className="divider d-none d-lg-block" />

          <div className="d-flex align-items-center g-1">
            <div className="budget-label fs-20 fw-400 light-text">
              {dateLabel}
            </div>
            <div className="budget-value fs-20 fw-400">
              {jobItem?.status == 'active' || jobItem?.status == 'closed'
                ? numberWithCommas(jobItem?.paid, 'USD')
                : moment(jobItem?.date_created).format('MMM DD, YYYY')}
            </div>
          </div>
        </div>

        {jobItem?.status == 'active' ||
          (jobItem?.status == 'closed' && (
            <div className="d-flex align-items-center gap-3">
              <div className="d-flex align-items-center gap-3">
                <img
                  src={
                    jobItem?.userdata?.user_image ||
                    '/images/default_avatar.png'
                  }
                  className="client-img-avatar"
                />
                <div className="name-w-ratings d-flex align-items-center flex-wrap gap-4">
                  <div className="fs-18 fw-400">
                    {jobItem?.userdata?.first_name}{' '}
                    {jobItem?.userdata?.last_name}{' '}
                    <span className="light-text">(Hired)</span>
                  </div>
                  {jobItem?.status == 'closed' && jobItem?.rate && (
                    <div className="d-flex align-items-center g-1">
                      <Stars ratings={jobItem?.rate} />
                      <div className="fs-18 fw-400 ratings-italic">
                        {jobItem?.rate?.toFixed(1)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>

      {/* Right side section */}
      <div className="d-flex flex-column align-items-md-end justify-content-between">
        <div className="d-flex align-items-center gap-3">
          <div className="d-flex flex-row justify-content-start align-items-center">
            <StatusBadge
              className="width-fit-content"
              color={
                JOBS_STATUS[jobItem?.status]?.color
                  ? JOBS_STATUS[jobItem?.status]?.color
                  : 'gray'
              }
            >
              {jobItem?.status == 'active'
                ? 'Work In Progress'
                : changeStatusDisplayFormat(jobItem?.status)}
            </StatusBadge>
            {jobItem?.status === 'closed' && jobItem?.incomplete_desc && (
              <StatusBadge color="gray" className="status ms-2">
                In-complete
              </StatusBadge>
            )}
          </div>
          {jobItem?.status == 'prospects' && (
            <div className="applicants d-flex align-items-center gap-2">
              <ApplicantsIcon />
              <div className="fs-20 fw-400">{jobItem?.applicants}</div>
              <div className="fs-1rem fw-300 light-text"> Applicants</div>
            </div>
          )}
        </div>

        <div>
          {jobItem?.status == 'active' && (
            <div className="fs-20 fw-400 light-text">
              {moment(jobItem?.job_start_date).format('MMM DD, YYYY')} - Present
            </div>
          )}
          {jobItem?.status == 'closed' && (
            <div className="fs-20 fw-400 light-text">
              {moment(jobItem?.job_start_date).format('MMM DD, YYYY')} -{' '}
              {moment(jobItem?.job_end_date).format('MMM DD, YYYY')}
            </div>
          )}
        </div>
      </div>
    </ListingItemWrapper>
  );
};

export default JobsListingItem;

export const Stars = ({ ratings }: { ratings: number }) => {
  return (
    <div className="d-flex align-items-center gap-1">
      {Array(ratings)
        .fill(null)
        .map((_, i) => (
          <StarIcon key={i} />
        ))}
    </div>
  );
};
