import { useQuery } from 'react-query';
import { getNewRegistrationRequests } from 'helpers/http/dashboard';

/* This hook will fetch the new registration requests (Freelancers) */
function useRegistrationRequests(activeFilter: string) {
  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['new-reg-req', activeFilter],
    () => getNewRegistrationRequests(activeFilter),
    { keepPreviousData: true }
  );
  return {
    data: data,
    isLoading,
    refetch,
    isRefetching,
  };
}

export default useRegistrationRequests;
