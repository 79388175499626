import Clients from './Clients';
import { ClientProvider } from './controller/client';

// eslint-disable-next-line react/display-name
export default function () {
  return (
    <ClientProvider>
      <Clients />
    </ClientProvider>
  );
}
