import { useQuery } from 'react-query';
import { getClientJobs } from 'helpers/http/clients';
import useDebounce from 'helpers/hooks/useDebounce';
/*
 * This hook will load the client jobs
 */
function useClientJobs({
  query,
  limit,
  clientId,
  jobStatus,
}: {
  query: string;
  limit: number;
  clientId?: string;
  jobStatus?: string;
}) {
  /* This query is being set from the browser url (Search params) from the parent component */

  const params = new URLSearchParams(query);
  const page = params.get('pg') || '1'; // default page = 1

  const payload = {
    status: jobStatus,
    limit: limit,
    page: Number(page),
    clientId: clientId,
  };

  const debouncedQuery = useDebounce(query, 500);

  const { data, isLoading, refetch, isRefetching } = useQuery(
    [`get-freelancer-jobs-${jobStatus}`, debouncedQuery],
    () => getClientJobs(payload),
    { keepPreviousData: true, enabled: !!clientId } // If query is passed then only this will be called
  );

  return {
    jobData: data?.data || [],
    totalResults: data?.total || 0,
    isLoading,
    refetch,
    isRefetching,
  };
}

export default useClientJobs;
