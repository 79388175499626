import styled from 'styled-components';

export const Wrapper = styled.div`
  .listing-table {
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
    background: ${(props) => props.theme.colors.white};
    border-radius: 12px;
  }
  .listings,
  .listings > .table-responsive {
    min-height: 600px;
  }
  .avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .dropdown-menu {
    margin-top: 10px;
    border: 0;
    box-shadow: 0 0 15px rgb(0 0 0 / 25%);
    padding: 4px 20px 24px;
    border-radius: 0.5rem;
    .dropdown-item {
      background-color: transparent;
      padding: 0px;
      &:hover {
        transform: scale(1.05);
        transition: 0.1s ease-in;
      }
      margin-top: 20px;
    }
  }
`;
