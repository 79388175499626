// Helper function to call callback if user clicked outside of provided DOM element
export function outsideClick(
  element: HTMLElement | null,
  callback: () => void
): () => void {
  const handleOutsideClick = (event: MouseEvent) => {
    if (element && !element.contains(event.target as Node)) {
      callback();
    }
  };

  if (element) {
    document.addEventListener('click', handleOutsideClick);
  }

  // Return a function to remove the click event listener
  return () => document.removeEventListener('click', handleOutsideClick);
}
