import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledModal } from 'components/styled/StyledModal';
import { StatusBadge } from 'components/styled/Badges';

type Props = {
  show: boolean;
  onCloseModal: () => void;
  jobCloseDetails: {
    job_reason: string;
    incomplete_desc: string;
    is_completed: number;
  };
};

const Wrapper = styled(StyledModal)`
  .label-color {
    opacity: 0.63;
  }
  .status {
    text-transform: none;
  }
`;

const JobEndDetailsModal = ({ show, onCloseModal, jobCloseDetails }: Props) => {
  return (
    <Wrapper show={show} onHide={onCloseModal} centered maxwidth={560}>
      <Modal.Body>
        <Button variant="transparent" className="close" onClick={onCloseModal}>
          &times;
        </Button>
        <>
          <h1 className="fs-32 fw-700 mb-4">End Job Details</h1>

          <div className="d-flex flex-column">
            <label className="my-2 fs-1rem fw-400 label-color">Status</label>
            <StatusBadge
              color={jobCloseDetails?.is_completed ? 'green' : 'gray'}
              className="status fs-1rem mb-1"
            >
              {!jobCloseDetails?.is_completed ? 'In-complete' : 'Completed'}
            </StatusBadge>

            {!jobCloseDetails?.is_completed ? (
              <>
                <label className="mt-4 mb-2 fs-1rem fw-400 label-color">
                  Reason for ending the job
                </label>
                <label className="fs-24 fw-400">
                  {jobCloseDetails?.job_reason}
                </label>

                <label className="mt-4 mb-2 fs-1rem fw-400 label-color">
                  Explaination
                </label>
                <label className="fs-18 fw-400">
                  {jobCloseDetails?.incomplete_desc}
                </label>
              </>
            ) : null}
          </div>
        </>
      </Modal.Body>
    </Wrapper>
  );
};

export default JobEndDetailsModal;
