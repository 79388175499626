import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { FreelancerItem } from 'pages/freelancers/types/freelancers.types';
import { ReactComponent as LocationIcon } from 'assets/icons/location-blue.svg';
import { separateValuesWithComma } from '../../../helpers/utils/misc';
import { ReactComponent as DollarCircleIcon } from 'assets/icons/dollar-circle.svg';
import moment from 'moment';
import {
  FREELANCER_ACCOUNT_STATUS,
  MORE_BUTTON_ID,
  STRIPE_ACCOUNT_STATUS,
  STRIPE_ACCOUNT_STATUS_USER_FACING,
  COMPLETED_PROFILE_PERCENTAGE,
} from 'helpers/consts';
import { StatusBadge } from 'components/styled/Badges';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import MoreButton from 'pages/freelancers/partials/MoreButton';
import { SkillCategoriesAndSkillsColumn } from 'pages/freelancers/partials/SkillCategoriesAndSkillsColumn';
import { MdFlag } from 'react-icons/md';
import { AdminNoteDiv } from 'components/AdminNotes';
import { TFilters } from 'components/ui/columnOptions.types';
import { CustomHeader } from 'components/ui/CustomHeader';

export const freelancerTableColumns = (
  onApproveOrReject: (approve: 0 | 1 | null, id: string) => () => void,
  onLoginAsAnotherUser: (user_id: string) => () => void,
  onFlagChange: (user_id: string, flag: 0 | 1) => () => void,
  selectedId: string,
  filters: TFilters,
  applyFilter: (data: Partial<TFilters>) => void
) => {
  const columns: ColumnDef<FreelancerItem>[] = [
    {
      id: 'Name',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Name"
          id="name"
          sortingFeature
          filterType="SEARCH"
        />
      ),
      cell: ({ row: { original } }) => {
        const flaggedNote = original?.admin_notes?.find(
          (note) => note?.isFlagged
        );
        const isFlagged = original.is_flagged === 1;

        return (
          <div className="d-flex align-items-center">
            {original?.user_image && (
              <img src={original.user_image} className="avatar" />
            )}
            <div className="ms-3 fs-1rem fw-400">
              {original?.first_name || ''} {original?.last_name || ''}{' '}
              {Number(original?.duplicate_count) > 1 && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      Duplicate Accounts: {original.duplicate_count}
                    </Tooltip>
                  }
                >
                  <span className="bg-warning rounded-circle px-2 py-1 fs-12">
                    {original.duplicate_count}
                  </span>
                </OverlayTrigger>
              )}
            </div>
            {/* START ----------------------------------------- Flagged note */}
            {(flaggedNote || isFlagged) && (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  flaggedNote ? (
                    <Tooltip>
                      <AdminNoteDiv
                        dangerouslySetInnerHTML={{
                          __html: flaggedNote.text || '',
                        }}
                      ></AdminNoteDiv>
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <div>
                  <MdFlag className="flag-icon" size={20} />
                </div>
              </OverlayTrigger>
            )}
            {/* END ------------------------------------------- Flagged note */}
          </div>
        );
      },
    },
    {
      id: 'Headline',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Headline"
          id="headline"
          sortingFeature
          filterType="SEARCH"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">{original?.job_title || '-'}</span>
      ),
    },
    {
      id: 'Location',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Location"
          id="location"
          sortingFeature
          filterType="SEARCH"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">
          {original?.location?.state || original?.location?.country_name ? (
            <div className="d-flex align-items-center">
              <div>
                <LocationIcon />
              </div>
              &nbsp;
              <span>
                {separateValuesWithComma([
                  original?.location?.state,
                  original?.location?.country_name,
                ])}
              </span>
            </div>
          ) : (
            '-'
          )}
        </span>
      ),
    },
    {
      id: 'Rate',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Rate"
          id="rate"
          sortingFeature
          filterType="SEARCH"
          numberOnly
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">
          {original?.hourly_rate ? (
            <>
              <DollarCircleIcon />
              &nbsp; ${original.hourly_rate}
            </>
          ) : (
            '-'
          )}
        </span>
      ),
    },
    {
      id: 'Email',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Email"
          id="email"
          sortingFeature
          filterType="SEARCH"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">{original?.u_email_id || '-'}</span>
      ),
    },
    {
      id: 'Applied Date',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Applied Date"
          id="applied_date"
          sortingFeature
          filterType="DATE_FILTER"
          minWidth="166px"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">
          {original?.date_created
            ? moment(original.date_created).format('MMM DD, YYYY')
            : '-'}
        </span>
      ),
    },
    {
      id: 'Rating',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Rating"
          id="avg_rating"
          sortingFeature
          filterType="SEARCH"
          numberOnly
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">
          {original?.avg_rating ? original.avg_rating.toFixed(1) : '0'}
        </span>
      ),
    },
    {
      id: 'Last Login',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Last Login"
          id="last_login"
          sortingFeature
          filterType="DATE_FILTER"
          minWidth="166px"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">
          {original?.last_login
            ? moment(original.last_login).local().format('MMM DD, YYYY')
            : '-'}
        </span>
      ),
    },
    {
      id: 'Approved Date',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Approved Date"
          id="freelancer_approved_date"
          sortingFeature
          filterType="DATE_FILTER"
          minWidth="166px"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">
          {original?.freelancer_approved_date
            ? moment(original.freelancer_approved_date).format('MMM DD, YYYY')
            : '-'}
        </span>
      ),
    },
    {
      id: 'Stripe',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Stripe"
          id="stripe"
          filterType="FILTER"
          options={Object.values(STRIPE_ACCOUNT_STATUS_USER_FACING)}
        />
      ),
      cell: ({ row: { original } }) => {
        // Replacing _ with space because status will have underscore. Status are
        // 1. verified
        // 2. pending
        // 3. bank_account_pending
        // 4. pending_verification
        // 5. currently_due
        let stripeAccountStatus = '';

        switch (original?.stp_account_status) {
          case STRIPE_ACCOUNT_STATUS.PENDING: {
            if (!original?.stp_account_id)
              stripeAccountStatus =
                STRIPE_ACCOUNT_STATUS_USER_FACING.NOT_STARTED;
            else
              stripeAccountStatus =
                STRIPE_ACCOUNT_STATUS_USER_FACING.NEW_SUBMISSIONS_NEEDED;
            break;
          }
          case STRIPE_ACCOUNT_STATUS.CURRENTLY_DUE:
            stripeAccountStatus =
              STRIPE_ACCOUNT_STATUS_USER_FACING.NEW_SUBMISSIONS_NEEDED;
            break;
          case STRIPE_ACCOUNT_STATUS.PENDING_VERIFICATION:
            stripeAccountStatus =
              STRIPE_ACCOUNT_STATUS_USER_FACING.VERIFYING_INFO;
            break;
          case STRIPE_ACCOUNT_STATUS.BANK_ACCOUNT_PENDING:
            stripeAccountStatus =
              STRIPE_ACCOUNT_STATUS_USER_FACING.BANK_ACCOUNT_PENDING;
            break;
          case STRIPE_ACCOUNT_STATUS.VERIFIED:
            stripeAccountStatus = STRIPE_ACCOUNT_STATUS_USER_FACING.VERIFIED;
            break;
          default:
            stripeAccountStatus = (
              original?.stp_account_status as string
            )?.replace(/_/g, ' ');
        }

        return (
          <span className="fs-1rem fw-400 text-capitalize status-col">
            <StatusBadge
              color={
                stripeAccountStatus ===
                STRIPE_ACCOUNT_STATUS_USER_FACING.VERIFIED
                  ? 'green'
                  : stripeAccountStatus ===
                    STRIPE_ACCOUNT_STATUS_USER_FACING.NOT_STARTED
                  ? 'red'
                  : 'yellow'
              }
            >
              {stripeAccountStatus}
            </StatusBadge>
          </span>
        );
      },
    },
    {
      id: 'Profile',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Profile"
          id="profile"
          filterType="FILTER"
          options={['All', 'About me', 'Headline', 'Skills', 'None']}
        />
      ),
      cell: ({ row: { original } }) => {
        const items = {
          about_me: !!original?.about_me,
          headline: !!original?.job_title,
          skills: original?.skills?.length > 0,
        };

        return (
          <div className="d-flex align-items-center">
            {Object.values(items).every((x) => x) ? (
              <StatusBadge className="mx-1" color="green">
                All
              </StatusBadge>
            ) : Object.values(items).every((x) => !x) ? (
              <StatusBadge className="mx-1" color="red">
                None
              </StatusBadge>
            ) : (
              Object.entries(items).map(([key, value]) => {
                if (value)
                  return (
                    <OverlayTrigger
                      key={key}
                      placement="top"
                      overlay={
                        <Tooltip className="text-capitalize">
                          {key.replace(/_/g, ' ')}
                        </Tooltip>
                      }
                    >
                      <StatusBadge className="mx-1" color="green">
                        {key.slice(0, 1)}
                      </StatusBadge>
                    </OverlayTrigger>
                  );
                return <React.Fragment key={key}></React.Fragment>;
              })
            )}
          </div>
        );
      },
    },
    {
      id: 'Portfolio',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Portfolio"
          id="portfolio"
          sortingFeature
          filterType="SEARCH"
          numberOnly
        />
      ),
      cell: ({ row: { original } }) => (
        <div>{original?.portfolio_count || '-'}</div>
      ),
    },
    {
      id: 'Skill Categories',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Skill Categories"
          id="categories"
          filterType="SEARCH"
        />
      ),
      cell: ({ row: { original } }) => (
        <SkillCategoriesAndSkillsColumn
          skills={original.skills}
          type="SKILL_CATEGORIES"
        />
      ),
    },
    {
      id: 'Skills',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Skills"
          id="skills"
          filterType="SEARCH"
        />
      ),
      cell: ({ row: { original } }) => (
        <SkillCategoriesAndSkillsColumn
          skills={original.skills}
          type="SKILLS"
        />
      ),
    },
    {
      id: 'Updated',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Updated"
          id="updated"
          sortingFeature
          filterType="DATE_FILTER"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">
          {original?.req_field_updated
            ? moment(original.req_field_updated).format('MMM DD, YYYY')
            : '-'}
        </span>
      ),
    },
    {
      id: '% Complete',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="% Complete"
          id="completed_percentage"
          sortingFeature
          minWidth="170px"
          filterType="FILTER"
          options={Object.values(COMPLETED_PROFILE_PERCENTAGE)}
        />
      ),
      cell: ({ row: { original } }) => {
        return <div>{original?.completed_percentage ?? '-'}</div>;
      },
    },
    {
      id: 'Status',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Status"
          id="status"
          filterType="FILTER"
          options={Object.values(FREELANCER_ACCOUNT_STATUS)}
        />
      ),
      cell: ({ row: { original } }) => {
        return (
          <div className="status-col">
            <div className="d-flex align-items-center justify-content-between gap-3">
              <StatusBadge
                color={
                  original?.is_account_approved == null
                    ? 'yellow'
                    : original?.is_deleted
                    ? 'gray'
                    : original?.is_account_approved == 0
                    ? 'darkPink'
                    : 'green'
                }
              >
                {original?.is_account_approved == null
                  ? FREELANCER_ACCOUNT_STATUS.UNDER_REVIEW
                  : original?.is_deleted
                  ? FREELANCER_ACCOUNT_STATUS.DELETED
                  : original?.is_account_approved == 0
                  ? FREELANCER_ACCOUNT_STATUS.DECLINED
                  : FREELANCER_ACCOUNT_STATUS.APPROVED}
              </StatusBadge>
            </div>
          </div>
        );
      },
    },

    {
      id: MORE_BUTTON_ID,
      header: () => <span></span>,
      cell: ({ row: { original } }) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            {original?.is_deleted == 0 && (
              <MoreButton
                isFlagged={original?.is_flagged === 1}
                onFlag={onFlagChange(
                  original?.user_id,
                  original?.is_flagged === 1 ? 0 : 1
                )}
                onApprove={onApproveOrReject(1, original?.user_id)}
                onDecline={onApproveOrReject(0, original?.user_id)}
                onSetUnderReview={onApproveOrReject(null, original?.user_id)}
                onLoginAsUser={onLoginAsAnotherUser(original?.user_id)}
                disabled={selectedId === original?.user_id}
                isLoginAsUser={original?.is_account_approved}
                withPortal
              />
            )}
          </div>
        );
      },
    },
  ];
  return columns;
};
