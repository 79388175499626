import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

// Hook that fires only when dependency updates NOT on initial component load
export const useNonInitialEffect = (
  effect: EffectCallback,
  deps?: DependencyList
) => {
  const initialRender = useRef(true);

  useEffect(() => {
    let effectReturns;

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      effectReturns = effect();
    }

    if (effectReturns && typeof effectReturns === 'function') {
      return effectReturns;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
