import { apiClient } from './index';

export const getNewRegistrationRequests = (activeFilter: string) => {
  /*
   * This is an api to get the freelancers who's registration requests are pending and yet to approve | reject
   */
  return apiClient
    .get(
      `/admin/freelancer/get?page=1&limit=500&is_appove=true&filter=${activeFilter}`
    )
    .then((res) => {
      if (res.data.status) {
        return res.data.data;
      } else {
        throw new Error(res.data.message);
      }
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const getDashboardStats = (activeFilter: string) => {
  /*
   * This is an api to get the dashboard counts
   */
  return apiClient
    .get(`/admin/dashboard/count?filter=${activeFilter}`)
    .then((res) => {
      if (res.data.status) {
        return res.data.data;
      } else {
        throw new Error(res.data.message);
      }
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const getDashboardSupportRequests = ({
  page,
  limit,
  activeTab,
  activeFilter,
  statusFilter,
}: {
  page: number;
  limit: number;
  activeTab?: string;
  activeFilter?: string;
  statusFilter?: string;
}) => {
  /*
   * This is an api to get the support requests on dashboard
   */
  let url = `/admin/support/get-list?page=${page}&limit=${limit}`;
  if (activeTab == 'general') {
    url += '&is_general=true';
  } else if (activeTab == 'dispute') {
    url += '&is_dispute=true';
  } else if (activeTab == 'closures') {
    url += '&is_closures=true';
  }
  if (activeFilter) {
    url += `&filter=${activeFilter}`;
  }
  if (statusFilter) {
    url += `&statusFilter=${statusFilter}`;
  }
  return apiClient
    .get(url)
    .then((res) => {
      if (res.data.status) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const getSupportDetails = ({ queryKey }: { queryKey: any[] }) => {
  /*
   * This is an api to get the the support details
   */
  const [_, id, type] = queryKey;
  let payload = {};
  if (type == 'Dispute') {
    payload = {
      dispute_id: id,
    };
  } else if (type == 'General') {
    payload = {
      inquiry_id: id,
    };
  } else {
    payload = {
      user_id: id,
    };
  }
  return apiClient
    .post(`/admin/support/get-details?is_admin=true`, payload)
    .then((res) => {
      if (res.data.status) {
        return res.data.data;
      } else {
        throw new Error(res.data.message);
      }
    })
    .catch((err) => {
      throw new Error(err);
    });
};
