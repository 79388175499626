import { fileIsAnImage, pxToRem } from 'helpers/utils/misc';
import styled, { css } from 'styled-components';
import cns from 'classnames';
import AttachmentPreview from 'components/ui/AttachmentPreview';
import { useEffect } from 'react';
import moment from 'moment';

const MessageWrapper = styled.div<{ type: 'freelancer' | 'client' }>`
  margin: ${pxToRem(80 / 2)} 0;
  /* display: flex; */
  .message__avatar {
    img {
      width: 29px;
      height: 29px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .message__body {
    border-radius: 0px 12px 12px 12px;
    padding: 1rem;
    border: 1px solid #d9d9d9;
    a {
      color: ${(props) => props.theme.colors.yellow};
    }
  }
  .message__body {
    white-space: pre-line;
  }
  .message__time {
    margin-bottom: 0.5rem;
    opacity: 0.5;
    line-height: 1.4;
    font-size: 0.857rem;
  }
  ${(props) =>
    props.type === 'client' &&
    css`
      align-self: flex-end;
      .message__time {
        text-align: right;
      }
      .message__body {
        border-radius: 12px 12px 0px 12px;
        background: #fbf5e8;
        max-width: 70%;
      }
    `}
  .file-msg-img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    object-position: top center;
    border-radius: 12px 12px 0px 12px;
    border: 1px solid ${(props) => props.theme.colors.gray6};
  }
  .file-msg-doc {
    svg {
      width: 200px;
      height: 200px;
      border: 1px solid lightgray;
      border-radius: 0.75rem;
      padding: 1rem;
    }
  }
  .delete-btn {
    background-color: #fbf5e8;
    border-radius: 0.5rem;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding: 0.25rem;
    display: none;
  }
  :hover {
    .delete-btn {
      display: block;
    }
  }
`;

function MessageBubble({
  author: authorType,
  data,
}: {
  author: 'freelancer' | 'client';
  data: any;
}) {
  function urlify(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '">' + url + '</a>';
    });
  }

  useEffect(() => {
    if (data.type !== 'FILE') {
      const msgElement: any = document.getElementById(`msg-${data.chat_id}`);
      msgElement.innerHTML = String(urlify(data?.message_text));
    }
  }, [data.chat_id, data?.message_text, data.type]);

  return (
    <MessageWrapper type={authorType}>
      <div className="message__time">
        {moment.utc(data.date_created).local().format('MM/DD/YYYY hh:mm A')}
      </div>
      {data.type === 'FILE' ? (
        <div
          className={cns('message__content d-md-flex g-1', {
            'justify-content-end': authorType === 'client',
          })}
        >
          <FileMsg path={data.message_text} />
        </div>
      ) : (
        <div
          className={cns('message__content d-md-flex g-1', {
            'justify-content-end': authorType === 'client',
          })}
        >
          <div
            className="message__body capital-first-ltr"
            id={`msg-${data.chat_id}`}
          >
            {data.message_text}
          </div>
        </div>
      )}
    </MessageWrapper>
  );
}

export default MessageBubble;

function FileMsg({ path }: { path: string }) {
  if (fileIsAnImage(path)) {
    return (
      <div className="position-relative">
        <a href={path} target="_blank" rel="noreferrer">
          <img src={path} alt="file" className="file-msg-img" />
        </a>
      </div>
    );
  }
  return (
    <div className="position-relative">
      <AttachmentPreview uploadedFile={path} removable={false} />
    </div>
  );
}
