import { StyledTable } from 'components/styled/StyledTable';
import { Row, Table, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import { MORE_BUTTON_ID } from 'helpers/consts';

type Props<T> = {
  table: Table<T>;
  handleRowClick?: (row: Row<T>) => void;
};

export const CustomTable = <T,>({ table, handleRowClick }: Props<T>) => {
  if (!table) return <></>;

  return (
    <StyledTable responsive>
      {/* Table heading */}
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className={classNames({
                  'more-button': header.id === MORE_BUTTON_ID,
                })}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      {/*
       * Table body and main listing of freelancers
       */}
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className="align-middle"
            onClick={() => {
              handleRowClick && handleRowClick(row);
            }}
          >
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className={classNames({
                  'more-button': cell.column.id === MORE_BUTTON_ID,
                })}
              >
                <div className="d-flex align-items-center">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};
