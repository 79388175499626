import { TUser } from 'helpers/types/user.type';
import { apiClient } from './index';

/*
 * This api is to approve | reject the freelancer registration request
 */
export const editUser = (formData: Partial<TUser>) => {
  return apiClient
    .post(`/admin/user/edit`, formData)
    .then((res) => {
      if (res.data.status) {
        return res.data;
      } else {
        throw new Error(res.data.message);
      }
    })
    .catch((err) => {
      throw err;
    });
};

/*
 * This api is to get the freelancer's details
 */
export const getFreelancerDetails = (id?: string) => {
  return apiClient
    .get(`/user/get?is_admin=true&freelancer_id=${id}`)
    .then((res) => {
      return res.data;
    });
};

/* This api is to get the list of freelancers for freelancers page */
export const getFreelancers = (formData: {
  keyword: string;
  status?: string;
  page: number;
  limit: number;
}) => {
  return apiClient
    .get(
      `/admin/freelancer/get?page=${formData?.page}&limit=${formData?.limit}&keyword=${formData?.keyword}&status=${formData?.status}`
    )
    .then((res) => {
      return res.data;
    });
};

/* This api is to send the reminder to the freelancer to compplete profile details */
export const sendReminder = (user_id: string) => {
  return apiClient.post('/admin/send-reminder', { user_id }).then((res) => {
    return res.data;
  });
};

export const getFreelancerJobs = (payload: {
  userId?: string;
  status?: string;
  limit: number;
  page: number;
  text?: string;
}) => {
  return apiClient
    .post(`/job/freelancer/get?is_admin=true&user_id=${payload.userId}`, {
      status: payload.status,
      limit: payload.limit,
      page: payload.page,
      text: payload.text ? payload.text : '',
    })
    .then((res) => {
      return res.data;
    });
};

export const getJobDetails = (jobId?: string, freelancerId?: string) => {
  return apiClient
    .get(`/job/get-detail/${jobId}?is_admin=true&user_id=${freelancerId}`)
    .then((res) => {
      return res.data;
    });
};

export const loginAsAnotherUser = (user_id?: string) => {
  return apiClient.post('/admin/login-as-user', { user_id }).then((res) => {
    return res.data;
  });
};
