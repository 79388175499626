import styled from 'styled-components';
import { StatusBadge } from 'components/styled/Badges';
import moment from 'moment';
import NoDataFound from 'components/ui/NoDataFound';
import StyledHtmlText from 'components/ui/StyledHtmlText';
import { MilestoneItemType } from '../types/jobDetails.types';
import { PAYMENT_STATUS } from '../consts';
import { formatLocalDate, numberWithCommas } from 'helpers/utils/misc';

const MileStoneItemWrapper = styled.div`
  padding: 1.75rem;
  box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.04);
  background: ${(props) => props.theme.colors.white};
  margin-top: 1.5rem;
  border-radius: 1.25rem;
  gap: 1.5rem;
  max-width: 816px;
  margin: auto;
  word-break: break-word;
  .decline-reason {
    background-color: ${(props) => props.theme.colors.whiteSmoke};
    border-radius: 0.5rem;
    padding: 1.25rem;
    .decline-heading {
      color: ${(props) => props.theme.colors.gray10};
    }
  }
`;

type Props = {
  milestones: MilestoneItemType[];
};

const Milestones = ({ milestones }: Props) => {
  const getDateLabel = (milestoneStatus: string) => {
    let label = '';
    switch (milestoneStatus) {
      case 'released':
        label = 'Paid on';
        break;
      case 'paid':
        label = 'Deposited on';
        break;
      case 'request_revision':
        label = 'Requested Revision on';
        break;
    }
    return label;
  };

  const getDate = (item) => {
    let date = '';
    const milestoneStatus = item.status;
    switch (milestoneStatus) {
      case 'paid':
        date = moment(item?.paid_date).format('MMM DD, YYYY');
        break;
      case 'released':
        date = moment(item?.released_date).format('MMM DD, YYYY');
        break;
      case 'request_revision':
        date = moment(item?.revision_date).format('MMM DD, YYYY');
        break;
    }
    return date;
  };

  return (
    <div>
      {milestones?.length > 0 ? (
        milestones?.map((item: MilestoneItemType) => (
          <MileStoneItemWrapper
            key={item?.milestone_id || item?.hourly_id}
            className="mb-4"
          >
            <div className="d-flex gap-3 justify-content-between flex-wrap mb-2">
              <div>
                <div className="fs-20 fw-400">{item.title}</div>
                <div className="fs-32 fw-400 mt-2">
                  {numberWithCommas(item.total_amount || item?.amount, 'USD')}
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between gap-2 align-items-md-end">
                <StatusBadge
                  color={
                    PAYMENT_STATUS[
                      item?.milestone_id ? item.status : item.hourly_status
                    ]?.color || 'green'
                  }
                >
                  {
                    PAYMENT_STATUS[
                      item?.milestone_id ? item.status : item.hourly_status
                    ]?.label
                  }
                </StatusBadge>
                <div className="fs-18 fw-400">
                  {item.hourly_id && item.hourly_status === 'paid' ? (
                    <div className="fs-18 fw-400">
                      Paid on{' '}
                      {item.paid_date
                        ? moment(item.paid_date).format('MMM DD, YYYY')
                        : ''}
                    </div>
                  ) : null}

                  {item?.milestone_id
                    ? getDateLabel(item.status) + ' ' + getDate(item)
                    : null}
                </div>
              </div>
            </div>
            <StyledHtmlText
              htmlString={item?.description}
              needToBeShorten={true}
              id={`milestone_${item?.milestone_id || item?.hourly_id}`}
            />
            {item?.due_date && (
              <div className="mt-1">
                Due on {formatLocalDate(item.due_date, 'MMM DD, YYYY')}
              </div>
            )}
            {item?.status === 'decline' ? (
              <div className="decline-reason mt-3">
                <span className="decline-heading fw-400 fs-1rem">
                  REASON FOR DECLINING
                </span>
                <div className="mt-2 fs-18 fw-400">{item.decline_reason}</div>
              </div>
            ) : null}
          </MileStoneItemWrapper>
        ))
      ) : (
        <NoDataFound className="my-5" />
      )}
    </div>
  );
};

export default Milestones;
