import { StatusBadge } from 'components/styled/Badges';
import moment from 'moment';
import styled from 'styled-components';
import { convertToTitleCase, numberWithCommas } from 'helpers/utils/misc';
import { ReactComponent as DollarCircleIcon } from 'assets/icons/dollar-circle.svg';
import { JOB_STATUS } from 'helpers/consts';

const StyledJob = styled.div`
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.08);
  border-radius: 0.875rem;
  padding: 2rem;
  word-break: break-word;
  .light-text {
    opacity: 0.5;
  }
  .job-jobData--avatar {
    height: 52px;
    width: 52px;
    border-radius: 50%;
    object-fit: cover;
  }
  .divider {
    width: 1px;
    height: 2rem;
    background: #d1d1d1;
  }
  .job-budget {
    background: ${(props) => props.theme.colors.body2};
    padding: 0.4375rem 0.625rem;
    border-radius: 2rem;
  }
  .status {
    text-transform: none;
  }
`;

const JobItem = ({
  jobData,
  goToJobDetails,
}: {
  jobData: any;
  goToJobDetails: () => void;
}) => {
  return (
    <StyledJob
      className="d-flex flex-column flex-lg-row justify-content-between gap-3 pointer mt-4"
      key={jobData?.job_post_id}
      onClick={goToJobDetails}
    >
      <div className="job-details flex-1">
        <header className="job-ttile fs-24 fw-400">
          {convertToTitleCase(jobData?.job_title)}
        </header>

        <div className="d-flex flex-column flex-lg-row align-items-lg-center mt-4 gap-4">
          {/* Freelancer image and name */}
          <div className="employer-freelancer d-flex align-items-center g-1">
            <img src={jobData?.user_image} className="job-jobData--avatar" />
            <div>
              <span className="fs-1rem fw-400 light-text">Hired by:</span>
              <div className="fs-20 fw-400">
                {jobData?.first_name} {jobData?.last_name}
              </div>
            </div>
          </div>

          <div className="divider d-none d-lg-block" />

          {/* Job approved budget */}
          <div className="d-flex">
            <div className="job-budget d-flex align-items-center gap-1">
              <DollarCircleIcon />
              {jobData?.proposal?.approved_budget?.amount ? (
                <span>
                  {numberWithCommas(
                    Number(jobData?.proposal?.approved_budget?.amount),
                    'USD'
                  )}
                  <span className="light-text">
                    {jobData?.proposal?.approved_budget?.type === 'hourly'
                      ? '/hr'
                      : ' Total cost'}
                  </span>
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* Job status and started date - Only work in porogress jobs goes here */}
      <div className="d-flex flex-column justify-content-between gap-4 align-items-lg-end flex-2">
        <div className="d-flex flex-row justify-content-start align-items-center">
          <StatusBadge color={JOB_STATUS[jobData?.status]?.color || 'blue'}>
            {JOB_STATUS[jobData?.status]?.label || jobData?.status}
          </StatusBadge>
          {jobData?.incomplete_desc && (
            <StatusBadge color="gray" className="status ms-2">
              In-complete
            </StatusBadge>
          )}
        </div>
        <div className="job-started-date fs-20 fw-400 light-text">
          {moment(jobData?.job_start_date)?.format('MMM DD, YYYY')} -{' '}
          {jobData?.status === 'closed'
            ? moment(jobData?.job_end_date)?.format('MMM DD, YYYY')
            : 'Present'}
        </div>
      </div>
    </StyledJob>
  );
};

export default JobItem;
