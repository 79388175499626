import React, { useState } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { jobSearch, getMessages } from 'helpers/http/common';
import { MultiSelectCustomStyle } from './multiSelectCustomStyle';
import MessageBubble from './MessageBubble';
import Loader from 'components/Loader';
import {
  MessageBubbleWrapper,
  MessageHeadBox,
  MessageJobDropdown,
  NoMessagesFound,
} from './messaging.styled';
import { Spinner } from 'react-bootstrap';

const singleSelectProps = {
  closeMenuOnSelect: true,
  isMulti: false,
  styles: MultiSelectCustomStyle,
};

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [freelancerID, setFreelancerID] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const jobLoadOptions = (searchedValue: string) => {
    const jobs: { label: any; value: any }[] = [];
    return jobSearch(searchedValue || '').then((res) => {
      res.jobs.forEach(function (item: any) {
        const obj = {
          label: item.job_title,
          value: item.job_post_id,
        };
        jobs.push(obj);
      });

      return jobs;
    });
  };

  const handleUpdate = (selected) => {
    setIsLoading(true);
    return getMessages(selected.value).then((res) => {
      if (res) {
        setFreelancerID(res.freelancerID);
        setMessages(res.chats);
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <MessageHeadBox>
        <div className="d-flex align-items-center mb-3">
          <h1 className="fs-32 fw-700 mb-0 mt-0">Messages</h1>
          {isLoading && (
            <Spinner animation="border" className="ms-2" size="sm" />
          )}
        </div>

        <MessageJobDropdown className="d-flex gap-3 align-items-end">
          <div className="w-100">
            <p className="job-desc mb-3">
              Please select the job for which you want to see the conversation
              between client and the freelancer.
            </p>

            <AsyncSelect
              {...singleSelectProps}
              placeholder="Select Job"
              components={{ NoOptionsMessage }}
              loadOptions={jobLoadOptions}
              onChange={handleUpdate}
              defaultOptions={true}
            />
          </div>
        </MessageJobDropdown>
      </MessageHeadBox>
      {isLoading && <Loader />}
      <MessageBubbleWrapper>
        {messages?.map((message: any) => (
          <MessageBubble
            author={
              message._from_user_id == freelancerID ? 'freelancer' : 'client'
            }
            data={message}
            key={'chat_id'}
          />
        ))}
      </MessageBubbleWrapper>
      {messages.length <= 0 && (
        <NoMessagesFound>No Message found</NoMessagesFound>
      )}
    </>
  );
};

export default Messages;

const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      <div>
        {props?.selectProps?.inputValue
          ? `No result found for '${props?.selectProps?.inputValue}'`
          : 'Search...'}
      </div>
    </components.NoOptionsMessage>
  );
};
