import { numberWithCommas } from 'helpers/utils/misc';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { ReactComponent as StarIcon } from 'assets/icons/starYellow.svg';

const Wrapper = styled.div`
  .count-item {
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 4px 35px rgba(164, 122, 60, 0.07);
    border-radius: 12px;
    padding: 2rem;
  }
  .light-text {
    opacity: 0.8;
  }
`;

type Props = {
  jobsDone: number;
  toalEarnings: number;
  ratings: number;
  reviewsCount: number;
  onRatingsClick: () => void;
};

const ClientStats = ({
  jobsDone,
  toalEarnings,
  ratings,
  reviewsCount,
  onRatingsClick,
}: Props) => {
  /* This will display the client's stats (counts) */

  return (
    <Wrapper>
      <Row className="g-4">
        <Col lg={4}>
          <div className="count-item">
            <div className="fs-18 fw-400 light-text">Total Jobs Done</div>
            <div className="mt-3 fs-32 fw-700">
              {jobsDone ? numberWithCommas(jobsDone) : 0}
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="count-item">
            <div className="fs-18 fw-400 light-text">Total Earnings</div>
            <div className="mt-3 fs-32 fw-700">
              ${toalEarnings ? numberWithCommas(toalEarnings, 'USD') : 0}
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="count-item pointer" onClick={onRatingsClick}>
            <div className="fs-18 fw-400 light-text">Ratings</div>
            <div className="d-flex align-items-center g-1 mt-3 flex-wrap">
              <StarIcon />
              <div className="fs-32 fw-700">{ratings?.toFixed(1)}</div>
              <div className="fs-18 fw400">
                ({numberWithCommas(reviewsCount)} reviews)
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};
export default ClientStats;
