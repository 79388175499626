/*
 * This component serves the other details of freelancer like
about me, education, languages, skills etc..
 */

import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { StatusBadge } from 'components/styled/Badges';
import StyledHtmlText from 'components/ui/StyledHtmlText';
import ProfileDetailSection from './ProfileDetailSection';
import FreelancerStats from './FreelancerStats';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/external-link.svg';
import { ReactComponent as EducationIcon } from 'assets/icons/education.svg';
import { useOutletContext } from 'react-router-dom';
import StripeDetails from 'components/stripe/stripeDetails';

const Wrapper = styled.div`
  .row {
    margin-bottom: 2rem;
  }
  .portfolio {
    color: ${(props) => props.theme.colors.yellow};
  }
`;

const SkillItem = styled.div`
  padding: 0.625rem 0.75rem;
  background: #f6f6f6;
  border-radius: 0.5rem;
  text-transform: capitalize;
`;

const EducationItem = styled.div`
  border: ${(props) => `1px solid ${props.theme.colors.gray6}`};
  border-radius: 0.875rem;
  .education-details {
    word-break: break-word;
  }
`;

const ProfileDetails = () => {
  const openCertificate = (link: any) => {
    /* This will open the certificate link */
    window.open(link, 'blank');
  };

  const { profileData }: { profileData: any } = useOutletContext();

  return (
    <Wrapper className="mt-4">
      {/* Freelacner stats (counts) */}
      {profileData?.is_account_approved ? (
        <FreelancerStats
          jobsDone={profileData?.done_jobs}
          toalEarnings={profileData?.totalearning}
          ratings={profileData?.avg_rating}
          reviewsCount={profileData?.count_rating}
        />
      ) : null}

      {/* Other details */}
      <Row>
        <Col lg="12">
          <ProfileDetailSection
            title="About"
            details={
              <div>
                {profileData?.about_me && (
                  <div className="about-me fs-18 fw-400">
                    <StyledHtmlText
                      htmlString={profileData?.about_me}
                      needToBeShorten={true}
                      id={`f-profile_${profileData?.user_id}`}
                    />
                  </div>
                )}
                {profileData?.portfolio_link && (
                  <div className="mt-4 fs-1rem fw-400 portfolio">
                    <a
                      href={profileData?.portfolio_link || ''}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit portfolio <ExternalLinkIcon />
                    </a>
                  </div>
                )}
              </div>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <ProfileDetailSection
            title="Categories"
            details={
              <div className="skills list d-flex align-items-center flex-wrap">
                {profileData?.skills?.map((skill: any) => {
                  if (!skill.category_id) return null;
                  return (
                    <SkillItem key={skill.category_id}>
                      <div>{skill.category_name}</div>
                    </SkillItem>
                  );
                })}
              </div>
            }
          />
        </Col>
        <Col lg="6">
          <ProfileDetailSection
            title="Skills"
            details={
              <div className="skills list d-flex align-items-center flex-wrap">
                {profileData?.skills?.map((skill: any) => {
                  if (!skill.skill_id) return null;
                  return (
                    <SkillItem key={skill.skill_id}>
                      <div>{skill.skill_name}</div>
                    </SkillItem>
                  );
                })}
              </div>
            }
          />
        </Col>
      </Row>

      {!profileData?.is_agency ? (
        <Row>
          <Col lg="6">
            <ProfileDetailSection
              title="Education"
              details={
                <div className="list d-flex flex-column gap-3">
                  {profileData?.education?.map((eduItem: any) => (
                    <EducationItem
                      className="d-flex p-3 gap-1"
                      key={eduItem?.education_id}
                    >
                      <div className="education-details d-flex align-items-center">
                        <div>
                          <EducationIcon />
                        </div>
                        <div>
                          <div className="course-name fs-20 fw-400">
                            {eduItem?.course_name}
                          </div>
                          <div className="education-description fs-18 fw-400">
                            {eduItem?.school_name}
                          </div>
                          <div className="education-description fs-18 fw-400">
                            {eduItem?.education_year}
                          </div>
                        </div>
                      </div>
                    </EducationItem>
                  ))}
                </div>
              }
            />
          </Col>
          <Col lg="6">
            <ProfileDetailSection
              fullWidth={true}
              title="Courses and Certifications"
              details={
                <div className="list d-flex flex-column gap-3">
                  {profileData?.certificate_course?.map((courseItem: any) => (
                    <div
                      className="course-certi-details d-flex justify-content-between align-items-center pointer"
                      key={courseItem?.course_id}
                      onClick={() =>
                        openCertificate(courseItem?.certificate_link)
                      }
                    >
                      <div>
                        <div className="course-name fs-20 fw-400">
                          {courseItem?.course_name}
                        </div>
                        <div className="education-description fs-18 fw-400 mt-2">
                          {courseItem?.school_name}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col lg="6">
          <ProfileDetailSection
            title="Languages"
            details={
              <div className="skills list d-flex align-items-center flex-wrap">
                {profileData?.languages?.map((language: any) => (
                  <SkillItem key={language.id}>
                    <div>{language.name}</div>
                  </SkillItem>
                ))}
              </div>
            }
          />
        </Col>
        <Col lg="6">
          <ProfileDetailSection
            fullWidth={true}
            title="Account Details"
            details={
              <div className="list d-flex flex-column gap-3">
                {profileData?.account?.map((item: any) => (
                  <div
                    className="course-certi-details mb-2"
                    key={item?.user_bank_id}
                  >
                    {item?.is_default ? (
                      <div className="mb-3">
                        <StatusBadge color="blue">Default</StatusBadge>
                      </div>
                    ) : null}
                    <div className="fs-18 fw-400">
                      <span className="education-description">
                        Account holder's name: &nbsp;
                      </span>
                      {item?.account_holder_name}
                    </div>
                    <div className="fs-18 fw-400 mt-2">
                      <span className="education-description">
                        Account type: &nbsp;
                      </span>
                      {item?.account_holder_type}
                    </div>
                    <div className="fs-18 fw-400 mt-2">
                      <span className="education-description">
                        Account number:: &nbsp;
                      </span>
                      XXXX XXXX {item?.last_4_digit}
                    </div>
                    {item?.routing_number && (
                      <div className="fs-18 fw-400 mt-2">
                        <span className="education-description">
                          Routing number:: &nbsp;
                        </span>
                        {item?.routing_number}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          {profileData?.stp_account_status && (
            <StripeDetails
              stripeStatus={profileData?.stp_account_status}
              stripe={profileData?.stripe}
              totalEarnings={profileData?.totalearning}
              // refetch={refetch}
            />
          )}
        </Col>
        <Col lg="6"></Col>
      </Row>
    </Wrapper>
  );
};

export default ProfileDetails;
