import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import useDashboardStats from '../hooks/useDashboardStats';
import { numberWithCommas } from 'helpers/utils/misc';
import { transition } from 'styles/transitions';

const STAT_ENUM = [
  {
    label: 'Total Freelancers',
    key: 'freelancer',
    redirectTo: '/freelancers',
  },
  {
    label: 'Total Clients',
    key: 'client',
    redirectTo: '/clients',
  },
  {
    label: 'Total Revenue',
    key: 'total_transaction',
    redirectTo: '/transactions',
  },
  {
    label: 'Total Jobs',
    key: 'jobs',
  },
];

const Wrapper = styled.div`
  .count-item {
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 4px 35px rgba(164, 122, 60, 0.07);
    border-radius: 12px;
    padding: 2rem;
    height: 100%;
  }
  .light-text {
    opacity: 0.8;
  }
  .on-hover {
    ${() => transition()}
  }
`;

const DashboardStats = ({ activeFilter }: { activeFilter: string }) => {
  const navigate = useNavigate();

  // This will fetch the dashboard stats using below hook
  const { data, isLoading } = useDashboardStats(activeFilter);

  const goTo = (redirectTo: string) => () => {
    /* This function will take the user to particular listings page */
    if (redirectTo) {
      navigate(redirectTo);
    }
  };

  return (
    <Wrapper className="mt-4">
      <Row className={`g-3 ${isLoading ? 'opacity-4' : ''}`}>
        {STAT_ENUM.map((item: any) => (
          <Col lg={3} key={item?.key}>
            <div
              className={`count-item ${
                item?.redirectTo ? 'pointer on-hover' : ''
              }`}
              onClick={goTo(item?.redirectTo)}
            >
              <div className="fs-18 fw-400 light-text">{item?.label}</div>

              <div className="mt-2">
                <div className={`fs-32 fw-700`}>
                  {isLoading
                    ? 0
                    : item?.key == 'total_transaction'
                    ? numberWithCommas(data[item?.key], 'USD')
                    : numberWithCommas(data[item?.key])}
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
};
export default DashboardStats;
