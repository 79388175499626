import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as DownArrow } from 'assets/icons/down-arrow.svg';

const DropDownWrapper = styled(Dropdown)`
  .dropdown-toggle {
    background: none;
    border: 1px solid ${(props) => props.theme.colors.gray6};
    padding: 1rem;
    border-radius: 10px;
    color: inherit;
    &::after {
      display: none;
    }
    cursor: pointer;
  }
  border-radius: 5px;
  .dropdown-menu {
    border-radius: 0.5rem;
    border: 1px solid ${(props) => props.theme.colors.gray6};
    background-color: ${(props) => props.theme.colors.white};
    min-width: initial;
    margin-top: 5px;
    .dropdown-item {
      background-color: transparent;
      padding: 9px 16px;
      &:hover {
        transform: scale(1.05);
        transition: 0.1s ease-in;
      }
    }
    .active {
      color: ${(props) => props.theme.colors.yellow};
    }
  }
`;

type Props = {
  activeFilter: string;
  onChange: (key: string) => void;
  options: any;
};

const TimeRangeFilters = ({ options, activeFilter, onChange }: Props) => {
  const onChangeFilter = (key: string) => () => {
    /* This function will be called when any filter is selected from the options  */
    onChange(key);
  };

  return (
    <DropDownWrapper>
      <Dropdown.Toggle as="div">
        <div className="pointer">
          {options[activeFilter]}
          &nbsp;
          <DownArrow />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        {Object.keys(options).map((item: string) => (
          <Dropdown.Item
            key={item}
            className="d-flex align-items-center fs-1rem fw-400"
            onClick={onChangeFilter(item)}
          >
            <div className={activeFilter == item ? 'active' : ''}>
              {options[item]}
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </DropDownWrapper>
  );
};

export default TimeRangeFilters;
