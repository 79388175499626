/*
 * This component displays the general details of the job,in this comoponent the detailsItem component
  serves all different sections available in design like, Description, expertise, budget etc.. *
 */
import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { formatExpertiseStatus, expectedHoursRemap } from 'helpers/utils/misc';
import StyledHtmlText from 'components/ui/StyledHtmlText';
import { JobItemType } from '../types/jobDetails.types';

const DetailStyledItem = styled.div`
  margin-bottom: 2rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);
  border-radius: 0.75rem;
  .job-detail-item-title {
    line-height: 2.1rem;
  }
  .description-text {
    opacity: 0.7;
  }
  .line-height-2rem {
    line-height: 2rem;
  }
  .job-detail-item-value {
    margin-top: 1.375rem;
  }
`;

const JobOtherDetails = ({ data }: { data: JobItemType }) => {
  return (
    <Row>
      <Col lg="6">
        <DetailsItem
          title="Job Description"
          atributeValue={
            <div className="description-text line-height-2rem fw-300 fs-18 mt-3">
              {/* This will convert html to normal text */}
              <StyledHtmlText
                htmlString={data?.job_description}
                needToBeShorten={false}
                id={`job_details_${data?.job_post_id}`}
              />
            </div>
          }
        />

        <DetailsItem
          title="Time scope"
          atributeValue={
            <div className="job-detail-item-value">
              <div className="d-flex align-items-center g-1">
                <div className="description-text fs-20 fw-400">
                  Hours per week:
                </div>
                <div className="fs-20 fw-400">
                  {data?.budget?.hours_per_week
                    ? data?.budget?.hours_per_week
                    : '-'}
                </div>
              </div>
              <div className="d-flex align-items-center g-1 mt-3">
                <div className="description-text fs-20 fw-400">Duration:</div>
                <div className="fs-20 fw-400">
                  {data?.expected_delivery_date}
                </div>
              </div>
            </div>
          }
        />
      </Col>
      <Col lg="6">
        <DetailsItem
          title="Type of job"
          atributeValue={
            <div className="job-detail-item-value fs-18 fw-400">
              {data?.budget?.type == 'fixed'
                ? 'Fixed Price'
                : data?.budget?.type == 'hourly'
                ? 'Hourly'
                : 'Unsure'}
            </div>
          }
        />
        <DetailsItem
          title="Expected Hours Required"
          atributeValue={
            <div className="mt-3 fs-18 fw-400">
              {expectedHoursRemap(data.time_scope) || '-'}
            </div>
          }
        />

        <DetailsItem
          title="Language"
          atributeValue={
            <div className="d-flex align-items-center mt-3 flex-wrap">
              {data.languages?.length
                ? data.languages?.map((item: any, index: number) => (
                    <div
                      className="description-text line-height-2rem fw-300 fs-18"
                      key={item.id}
                    >
                      {item.name}
                      {index < data?.languages?.length - 1 ? ',' : ''}&nbsp;
                    </div>
                  ))
                : '-'}
            </div>
          }
        />
        {/* <DetailsItem
          title="Expertise"
          atributeValue={
            <div className="mt-3 fs-18 fw-400">
              {data?.expertise ? formatExpertiseStatus(data?.expertise) : '-'}
            </div>
          }
        /> */}
        <DetailsItem
          title="Skills"
          atributeValue={
            <div className="d-flex align-items-center mt-3 flex-wrap">
              {data?.skills.length
                ? data?.skills?.map((item: any, index: number) => (
                    <div
                      className="description-text line-height-2rem fw-300 fs-18"
                      key={item.id}
                    >
                      {item.name}
                      {index < data?.skills?.length - 1 ? ',' : ''}&nbsp;
                    </div>
                  ))
                : '-'}
            </div>
          }
        />
      </Col>
    </Row>
  );
};

export default JobOtherDetails;

const DetailsItem = ({
  title,
  atributeValue,
}: {
  title: string;
  atributeValue: React.ReactNode;
}) => {
  return (
    <DetailStyledItem>
      <div className="job-detail-item-title fs-24 fw-400">{title}</div>
      {atributeValue}
    </DetailStyledItem>
  );
};
