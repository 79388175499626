import { useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Modal, Form } from 'react-bootstrap';
import { StyledModal } from 'components/styled/StyledModal';
import { DisputeWrapper, OptionButton } from './dispute-resolve-modal.styled';
import { StyledButton } from 'components/forms/Buttons';
import {
  deleteUser,
  resolveDispute,
  resolveInquiry,
} from 'helpers/http/common';
import { showErr } from 'helpers/utils/misc';

const USERS = ['Client', 'Freelancer'];

type Props = {
  show: boolean;
  supportItem: any;
  onCloseModal: () => void;
  onResolving: () => void;
  isAccept?: boolean;
};

const DisputeResolveModal = ({
  show,
  onCloseModal,
  supportItem,
  onResolving,
  isAccept,
}: Props) => {
  const [inFavourOf, setInFavourOf] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [resolving, setResolving] = useState(false);

  const onChangeInFavour = (item) => () => {
    setInFavourOf(item);
  };

  const onChangeComment = (e: any) => {
    setComment(e.target.value);
  };

  const validate = (e: any) => {
    e.preventDefault();
    if (comment === '') {
      showErr('Please enter a comment');
      return;
    }
    onResolve();
  };

  const onResolve = () => {
    setResolving(true);

    if (supportItem.dispute_id) {
      const body: any = {
        dispute_id: supportItem.dispute_id,
        comment,
      };

      if (inFavourOf === 'Client') {
        body.client_user_id = supportItem.jobdata._client_user_id;
      } else {
        body.freelancer_user_id = supportItem.jobdata._freelancer_user_id;
      }

      const promise = resolveDispute(body);
      toast.promise(promise, {
        loading: 'Loading...',
        success: (res) => {
          setResolving(false);
          onResolving();
          resetForm();
          return res.message;
        },
        error: (err) => {
          setResolving(false);
          return err?.response?.data?.message || 'error';
        },
      });
    } else if (supportItem.inquiry_id || supportItem.user_id) {
      let body: any;
      if (supportItem.inquiry_id) {
        body = {
          inquiry_id: supportItem.inquiry_id,
        };
      } else {
        body = {
          user_id: supportItem.user_id,
        };
      }
      body.action_log = {
        respond: comment,
      };

      if (isAccept === true) {
        const promise = deleteUser(supportItem.user_id, comment);

        toast.promise(promise, {
          loading: 'Loading...',
          success: (res) => {
            /* Once account is deleted, it will take the user one step back */
            onCloseModal();
            onResolving();
            resetForm();
            return res.message;
          },
          error: (err) => {
            onCloseModal();
            return err?.response?.data?.message || 'error';
          },
        });
      } else {
        const promise = resolveInquiry(body);
        toast.promise(promise, {
          loading: 'Loading...',
          success: (res) => {
            setResolving(false);
            onResolving();
            resetForm();
            return res.message;
          },
          error: (err) => {
            setResolving(false);
            return err?.response?.data?.message || 'error';
          },
        });
      }
    }
  };

  const resetForm = () => {
    setComment('');
    setInFavourOf('');
  };

  const onClose = () => {
    resetForm();
    onCloseModal();
  };

  return (
    <StyledModal show={show} size="lg" onHide={onClose} centered maxwidth={590}>
      <Modal.Body>
        <Button variant="transparent" className="close" onClick={onClose}>
          &times;
        </Button>
        <DisputeWrapper>
          <Form className="form d-flex flex-column" onSubmit={validate}>
            {supportItem?.dispute_id && (
              <>
                <div className="fs-28 fw-700 light-black">
                  In whose favor is it?
                </div>
                <div className="d-flex align-items-center gap-3 justify-content-between">
                  {USERS?.map((item: string) => (
                    <OptionButton
                      className={
                        'd-flex justify-content-center fs-24 fw-400 pointer'
                      }
                      selected={item === inFavourOf}
                      key={item}
                      onClick={onChangeInFavour(item)}
                    >
                      {item}
                    </OptionButton>
                  ))}
                </div>
                <div>
                  <label className="fs-1rem fw-400 mb-2">Add Synopsis</label>
                  <Form.Control
                    placeholder="This synopsis will be forever visible to both client and freelancer. 
                  Be sure to include: conciliatory language, an explanation of which policies determined this ruling, 
                  why those policies exist, and advice for moving forward."
                    value={comment}
                    onChange={onChangeComment}
                    className="form-input"
                    maxLength={1500}
                    as="textarea"
                    rows={4}
                  />
                </div>
                <div className="d-flex justify-content-center justify-content-md-end">
                  <StyledButton
                    disabled={!inFavourOf || comment === '' || resolving}
                    type="submit"
                  >
                    Submit
                  </StyledButton>
                </div>
              </>
            )}

            {(supportItem?.inquiry_id || supportItem?.user_id) && (
              <>
                <div>
                  <label className="fs-1rem fw-400 mb-2">Add Synopsis</label>
                  <Form.Control
                    placeholder="This synopsis will be forever visible to both client and freelancer. 
                    Be sure to include: conciliatory language, an explanation of which policies determined this ruling, 
                    why those policies exist, and advice for moving forward."
                    value={comment}
                    onChange={onChangeComment}
                    className="form-input"
                    maxLength={1500}
                    as="textarea"
                    rows={4}
                  />
                </div>
                <div className="d-flex justify-content-center justify-content-md-end">
                  <StyledButton
                    disabled={comment === '' || resolving}
                    type="submit"
                  >
                    Submit
                  </StyledButton>
                </div>
              </>
            )}
          </Form>
        </DisputeWrapper>
      </Modal.Body>
    </StyledModal>
  );
};

export default DisputeResolveModal;
