import { useQuery } from 'react-query';
import { showErr } from 'helpers/utils/misc';
import { getProjectDetails } from 'helpers/http/projects';
import { useMemo } from 'react';

const JOB_STATUS = {
  DRAFT: 'draft',
  PROSPECTS: 'prospects',
  ACTIVE: 'active',
};

const TABS_BY_STATUS = {
  [JOB_STATUS.DRAFT]: [
    {
      id: 0,
      label: 'Details',
      key: 'gen_details',
    },
  ],
  [JOB_STATUS.PROSPECTS]: [
    { id: 0, label: 'Project Details', key: 'gen_details' },
  ],
  [JOB_STATUS.ACTIVE]: [
    { id: 0, label: 'Milestones', key: 'm_stone' },
    { id: 1, label: 'Project Details', key: 'gen_details' },
  ],
  active_hourly: [
    { id: 0, label: 'Milestones', key: 'm_stone' },
    { id: 1, label: 'Project Details', key: 'gen_details' },
  ],
  closed_hourly: [
    { id: 0, label: 'Milestones', key: 'm_stone' },
    { id: 1, label: 'Project Details', key: 'gen_details' },
    { id: 2, label: 'Reviews', key: 'feedback' },
  ],
  active_fixed: [
    { id: 0, label: 'Milestones', key: 'm_stone' },
    { id: 1, label: 'Project Details', key: 'gen_details' },
  ],
  closed_fixed: [
    { id: 0, label: 'Milestones', key: 'm_stone' },
    { id: 1, label: 'Project Details', key: 'gen_details' },
    { id: 2, label: 'Reviews', key: 'feedback' },
  ],
  default: [{ id: 0, label: 'Project Details', key: 'gen_details' }],
};

function useJobDetails(id: string, userId: string) {
  const { data, isLoading, isRefetching, refetch } = useQuery(
    'get-project-details',
    () => getProjectDetails(id, userId),
    {
      keepPreviousData: false,
      enabled: !!id,
      onError: (err: any) => {
        showErr(err?.response?.data?.message);
      },
    }
  );

  const jobStatus = data?.data?.status;
  const approvedBudgetType = data?.data?.proposal?.approved_budget?.type;

  /* Here based on the Job status and approved budget type the tabs will be displayed */

  const tabItems = useMemo(() => {
    if (jobStatus == 'active' || jobStatus == 'closed') {
      if (approvedBudgetType) {
        return TABS_BY_STATUS[`${jobStatus}_${approvedBudgetType}`];
      } else {
        return TABS_BY_STATUS['default'];
      }
    } else if (jobStatus == 'drafts' || jobStatus == 'prospects') {
      return TABS_BY_STATUS[jobStatus];
    } else {
      return TABS_BY_STATUS['default'];
    }
  }, [jobStatus, approvedBudgetType]);

  return {
    jobdetails: data?.data,
    isLoading,
    refetch,
    isRefetching,
    tabItems,
  };
}
export default useJobDetails;
