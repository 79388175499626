import { Row, Col, Container } from 'react-bootstrap';
import moment from 'moment';
import styled from 'styled-components';
import { StatusBadge } from 'components/styled/Badges';
import { convertToTitleCase, numberWithCommas } from 'helpers/utils/misc';
import { JOB_STATUS } from 'helpers/consts';

const Wrapper = styled.div`
  .details-header,
  .details-footer {
    padding: 2.25rem;
  }
  .details-header--content {
    gap: 1.25rem;
  }
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
  background: ${(props) => props.theme.colors.white};
  margin: 2.25rem 0rem 0rem 0rem;
  border-radius: 12px;
  border: ${(props) => `1px solid ${props.theme.colors.yellow}`};
  word-break: break-word;
  .details-footer {
    border-top: ${(props) => `1px solid ${props.theme.colors.yellow}`};
  }
  .light-text {
    opacity: 0.5;
  }
  .hired-by-avatar {
    height: 5.25rem;
    width: 5.25rem;
    border-radius: 50%;
    object-fit: cover;
  }
  .divider {
    width: 1px;
    background-color: ${(props) => props.theme.colors.black};
    height: 100%;
  }
  .status {
    text-transform: none;
  }
`;

const DetailsBanner = ({
  jobDetails,
  userType,
}: {
  jobDetails: any;
  userType: string;
}) => {
  return (
    <Wrapper>
      <div className="details-header d-flex justify-content-between flex-wrap gap-3">
        <div className="details-header--content d-flex flex-column">
          <div className="fs-24 fw-400">
            {convertToTitleCase(jobDetails?.job_title)}
          </div>

          <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3">
            <div className="d-flex align-items-center fs-20 fw-400">
              <div className="light-text">Started:&nbsp;</div>
              {jobDetails?.job_start_date &&
                moment(jobDetails?.job_start_date).format('MMM DD, YYYY')}
            </div>
            {jobDetails.status === 'active' && (
              <>
                <Divider />
                <div className="d-flex align-items-center fs-20 fw-400">
                  <div className="light-text">Due date:&nbsp;</div>
                  {jobDetails?.due_date
                    ? moment(jobDetails?.due_date).format('MMM DD, YYYY')
                    : '-'}
                </div>
              </>
            )}
            {jobDetails?.status === 'closed' && (
              <>
                <Divider />
                <div className="d-flex align-items-center fs-20 fw-400">
                  <div className="light-text">Ended:&nbsp;</div>
                  {jobDetails?.job_end_date &&
                    moment(jobDetails?.job_end_date).format('MMM DD, YYYY')}
                </div>
              </>
            )}
          </div>
          <div className="d-flex flex-row justify-content-start align-items-center">
            <StatusBadge
              color={JOB_STATUS[jobDetails?.status]?.color || 'blue'}
            >
              {JOB_STATUS[jobDetails?.status]?.label || 'Active'}
            </StatusBadge>
            {jobDetails?.status === 'closed' && !jobDetails?.is_completed ? (
              <StatusBadge color="gray" className="status ms-2">
                In-complete
              </StatusBadge>
            ) : null}
          </div>
        </div>

        <div className="details-hired--by d-flex align-items-center flex-wrap gap-3">
          <img
            src={
              jobDetails?.userdata?.user_image || '/images/default_avatar.png'
            }
            className="hired-by-avatar"
          />
          <div>
            <div className="fs-20 fw-400 line-height-28 text-capitalize">
              {jobDetails?.userdata?.first_name}{' '}
              {jobDetails?.userdata?.last_name}
            </div>
            <div className="light-text fs-18 fw-400">
              {userType === 'clients' ? 'Hired' : 'Employer'}
            </div>
          </div>
        </div>
      </div>

      <div className="details-footer">
        <Container>
          <Row>
            <Col lg={4} className="">
              <div className="light-text fs-1rem fw-400">Budget</div>
              <div className="fs-20 fw-400 mt-1">
                {numberWithCommas(
                  Number(jobDetails?.proposal?.approved_budget?.amount),
                  'USD'
                )}
                {jobDetails?.proposal?.approved_budget?.type === 'hourly'
                  ? '/hr'
                  : ``}
              </div>
            </Col>

            <Col lg={4} className="">
              <div className="light-text fs-1rem fw-400">
                {jobDetails?.budget?.type === 'hourly'
                  ? 'Total Hours Worked'
                  : 'Remaining'}
              </div>
              <div className="fs-20 fw-400 mt-1">
                {jobDetails?.budget?.type == 'hourly'
                  ? jobDetails?.total_hours
                    ? `${numberWithCommas(jobDetails?.total_hours)} hours`
                    : '0'
                  : jobDetails?.proposal?.approved_budget?.amount
                  ? numberWithCommas(
                      jobDetails?.proposal?.approved_budget?.amount -
                        jobDetails?.total_earnings,
                      'USD'
                    )
                  : jobDetails?.budget
                  ? numberWithCommas(
                      jobDetails?.budget?.amount - jobDetails?.total_earnings,
                      'USD'
                    )
                  : '-'}
              </div>
            </Col>

            <Col lg={4} className="d-flex gap-3">
              <div className="divider d-none d-lg-block" />
              <div>
                <div className="light-text fs-1rem fw-400">
                  {userType === 'clients' ? 'Paid' : 'Total Earnings'}
                </div>
                <div className="fs-20 fw-400 mt-1">
                  {jobDetails?.total_earnings
                    ? numberWithCommas(jobDetails?.total_earnings, 'USD')
                    : '$0'}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Wrapper>
  );
};

export default DetailsBanner;

const Divider = () => {
  return <div className="d-none d-lg-block divider light-text" />;
};
