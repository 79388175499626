import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { ReactComponent as ClientsIcon } from 'assets/icons/clients.svg';
import { ReactComponent as FlagIcon } from 'assets/icons/flag.svg';
import { createPortal } from 'react-dom';

const UserDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background: none;
    border: none;
    color: inherit;
    &::after {
      display: none;
    }
    padding: 4px 2px;
  }
  border-radius: 5px;
`;

type Props = {
  onLoginAsUser: () => void;
  isFlagged: boolean;
  onFlag: () => void;
  disabled: boolean;
};

const MoreButton = ({ onLoginAsUser, isFlagged, onFlag, disabled }: Props) => {
  const DropdownMenuUI = (
    <Dropdown.Menu align="end">
      <Dropdown.Item
        className="d-flex align-items-center fs-1rem fw-400"
        onClick={onLoginAsUser}
      >
        <ClientsIcon /> <span className="ms-2">Login as User</span>
      </Dropdown.Item>
      <Dropdown.Item
        className="d-flex align-items-center fs-1rem fw-400"
        onClick={onFlag}
      >
        <FlagIcon />{' '}
        <span className="ms-2">{isFlagged ? 'Remove flag' : 'Flag'}</span>
      </Dropdown.Item>
    </Dropdown.Menu>
  );

  return (
    <UserDropdown>
      <Dropdown.Toggle disabled={disabled}>
        <div className="pointer">
          <MoreIcon />
        </div>
      </Dropdown.Toggle>
      {createPortal(
        DropdownMenuUI,
        document.querySelector('#more-button-dropdown-menu') || document.body
      )}
    </UserDropdown>
  );
};

export default MoreButton;
