import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;
function NotAllowedMobile() {
  return (
    <Container>
      <div className="text-center">
        <h2 className="fw-600">NOT SUPPORTED</h2>
        <p>Admin panel supported in desktop only.</p>
      </div>
    </Container>
  );
}

export default NotAllowedMobile;
