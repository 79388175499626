import { Modal, Button } from 'react-bootstrap';
import { StyledModal } from 'components/styled/StyledModal';
import { StyledButton } from 'components/forms/Buttons';
import { useState } from 'react';
import { deleteCategoryApi } from 'helpers/http/common';
import toast from 'react-hot-toast';
import { useCategories } from './categoryProvider';

export const DeleteCategoryPrompt = ({ show, setShow, category }: any) => {
  const toggle = () => setShow(false);
  const [loading, setLoading] = useState(false);
  const { fetchCategories, fetchSkills } = useCategories();

  const onConfirm = async () => {
    const { skills } = category;
    const payload = { category_ids: [category.id] };

    if (Array.isArray(skills) && !!skills.length) {
      skills.forEach(({ id }) => {
        payload.category_ids.push(id);
      });
    }

    const promise = deleteCategoryApi(payload);

    setLoading(true);
    toast.promise(promise, {
      loading: 'deleting...',
      success: (res) => {
        fetchCategories();
        fetchSkills();

        setLoading(false);
        setShow(false);

        return res.message;
      },
      error: (err) => {
        setLoading(false);
        setShow(false);

        return err?.response?.data?.message || 'error';
      },
    });
  };

  return (
    <StyledModal maxwidth={540} show={show} size="sm" onHide={toggle} centered>
      <Modal.Body>
        <Button variant="transparent" className="close" onClick={toggle}>
          &times;
        </Button>

        <div className="fs-28 fw-400 text-center mb-3">
          Are you sure you want to delete{' '}
          <span className="text-capitalize">'{category?.name}'</span> ?
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-center mt-4 gap-3">
          <StyledButton
            className="fs-16 fw-400"
            variant="outline-dark"
            padding="0.8125rem 2rem"
            onClick={toggle}
            disabled={loading}
          >
            Go back
          </StyledButton>
          <StyledButton
            className="fs-16 fw-400"
            variant="primary"
            padding="0.8125rem 2rem"
            onClick={onConfirm}
            disabled={loading}
          >
            Yes, Delete
          </StyledButton>
        </div>
      </Modal.Body>
    </StyledModal>
  );
};
