import Projects from './Projects';
import { ProjectProvider } from './controller/project';

// eslint-disable-next-line react/display-name
export default function () {
  return (
    <ProjectProvider>
      <Projects />
    </ProjectProvider>
  );
}
