import { string } from 'yup';
import { getPlainText } from 'helpers/utils/misc';
import { NOTE_MAX_CHARACTERS } from 'helpers/consts';

export const addNoteSchema = string()
  .test({
    message: `Max characters limit is ${NOTE_MAX_CHARACTERS}`,
    test: (value) => getPlainText(value).length <= NOTE_MAX_CHARACTERS,
  })
  .required('Note required');
