import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { breakpoints } from 'helpers/hooks/useResponsive';

export const StyledModal = styled(Modal)<{ maxwidth?: number }>`
  .modal-dialog {
    max-width: ${(props) => (props.maxwidth ? `${props.maxwidth}px` : '800px')};
  }
  .modal-content {
    border-radius: 12px;
  }
  .modal-body {
    @media ${breakpoints.mobile} {
      padding: 2rem 1rem;
    }
    padding: 3rem;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    color: white;
    transform: translate(30px, -10px);
    font-size: 1.75rem;
    font-weight: 200;
    @media ${breakpoints.mobile} {
      transform: translate(0, 0);
      right: 10px;
      color: #000;
    }
  }
  .close,
  .close:hover {
    border-color: transparent;
  }
  .bottom-buttons {
    margin-top: 1.25rem;
    justify-content: flex-end;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }
`;
