import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { numberWithCommas } from 'helpers/utils/misc';
import styled from 'styled-components';
import { ReactComponent as Info } from 'assets/icons/info-circle-gray.svg';
import useStripeBalance from '../hooks/useStripeBalance';
import { transition } from 'styles/transitions';

type Props = {
  totalZehmizehProfit: number;
};

const Card = styled.div`
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 4px 35px rgba(164, 122, 60, 0.07);
    border-radius: 12px;
    padding: 2rem;
    height: 100%;
  }
  .light-text {
    opacity: 0.8;
  }
  .on-hover {
    ${() => transition()}
`;

const TransactionsTotals = ({ totalZehmizehProfit }: Props) => {
  const {
    availableBalance,
    pendingBalance,
    holdPayoutCard,
    holdPayoutBank,
    cardBalance,
    bankBalance,
    inTransitCard,
    inTransitBank,
  } = useStripeBalance();

  const STATS_ENUM = [
    {
      label: 'Combined ZMZ Funds',
      value: numberWithCommas(availableBalance / 100, 'USD'),
      tooltip: 'Total available funds. Can be withdrawn',
      data: [
        {
          label: 'ZMZ’s Card',
          value: numberWithCommas(Number(cardBalance) / 100, 'USD'),
          tooltip: 'Funds from cards; needed to pay Card Milestones',
        },
        {
          label: 'ZMZ’s Bank',
          value: numberWithCommas(Number(bankBalance) / 100, 'USD'),
          tooltip: 'Funds from banks; needed to pay Bank Milestones',
        },
      ],
    },
    {
      label: 'In Transit to ZMZ',
      value: pendingBalance
        ? numberWithCommas(Number(pendingBalance) / 100, 'USD')
        : '$0',
      tooltip: 'On the way; can be used to pay milestones',
      data: [
        {
          label: 'To Card',
          value: numberWithCommas(Number(inTransitCard) / 100, 'USD'),
          tooltip: 'Charged from a client card',
        },
        {
          label: 'To Bank',
          value: numberWithCommas(Number(inTransitBank) / 100, 'USD'),
          tooltip: 'Charged from a client bank',
        },
      ],
    },
    {
      label: 'Total Milestones Due',
      value: numberWithCommas(Number(holdPayoutCard + holdPayoutBank), 'USD'),
      tooltip: 'Total amount committed in user milestones',
      data: [
        {
          label: 'Card Milestones Due',
          value: numberWithCommas(Number(holdPayoutCard), 'USD'),
          tooltip: 'Total amount owed from ZMZ Card',
        },
        {
          label: 'Bank Milestones Due',
          value: numberWithCommas(Number(holdPayoutBank), 'USD'),
          tooltip: 'Total amount owed from ZMZ Bank',
        },
      ],
    },
    {
      label: 'ZMZ History Profit',
      value: numberWithCommas(totalZehmizehProfit, 'USD'),
      tooltip: 'Approximate profit earned by ZMZ since opening',
      data: [],
    },
  ];

  const renderTooltip = (props, text) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    <Row className="g-3">
      {STATS_ENUM.map((item) => {
        if (!item.value) return <></>;
        return (
          <Col xs={12} sm={12} md={6} lg={4} xl={3} key={item.label}>
            <Card>
              <div className="fs-18 fw-400 light-text">
                {item.label}
                {' - '}
                <span className="fw-700">{item.value}</span>
                <OverlayTrigger
                  placement="bottom"
                  overlay={(props) => renderTooltip(props, item.tooltip)}
                >
                  <Info />
                </OverlayTrigger>
              </div>
              {item?.data && item?.data?.length > 0 && (
                <div className="mt-1">
                  {item.data.map((subItem) => (
                    <div
                      key={subItem.label}
                      className="fs-16 fw-400 light-text"
                    >
                      {subItem.label}
                      {' - '}
                      <span className="fs-16 fw-700">{subItem.value}</span>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={(props) =>
                          renderTooltip(props, subItem.tooltip)
                        }
                      >
                        <Info />
                      </OverlayTrigger>
                    </div>
                  ))}
                </div>
              )}
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default TransactionsTotals;
