import styled from 'styled-components';

export const Wrapper = styled.section``;

export const ProfileBannerWrapper = styled.div`
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
  background: ${(props) => props.theme.colors.white};
  border: ${(props) => `1px solid ${props.theme.colors.yellow}`};
  border-radius: 12px;
  padding: 2.375rem;
  gap: 1.25rem;
  .profile__img-and-details {
    gap: 1.5rem;
  }
  .img {
    height: 9.5625rem;
    width: 9.5625rem;
    border-radius: 50%;
    object-fit: cover;
  }
  .profile__details {
    gap: 1.125rem;
    max-width: 80%;
  }
  .profile__username {
    line-height: 2.1rem;
    word-break: break-word;
  }
  .profile__description {
    color: #999999;
    margin-top: 0.75rem;
    line-height: 1rem;
  }
  .profile__contact {
    gap: 1.125rem;
  }
  .profile__contact-item {
    gap: 0.75rem;
    word-break: break-word;
  }
  .profile__contact-item-value {
    opacity: 0.8;
  }
  .divider {
    width: 1px;
    background: ${(props) => props.theme.colors.gray8};
    height: 1.5rem;
  }
  .budget-and-location {
    gap: 10px;
  }
  .profile__badge {
    background: ${(props) => props.theme.colors.body2};
    padding: 0.375rem 0.75rem;
    border-radius: 1rem;
    gap: 7px;
  }
  .budget-and-location-gray-text {
    opacity: 0.63;
  }
  .accept-decline-btn {
    background: rgba(217, 217, 217, 0.18);
    padding: 10px;
    border-radius: 0.5rem;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: translateY(-2px);
    }
  }
`;
