import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Wrapper = styled.div`
  .item {
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 4px 35px rgba(164, 122, 60, 0.07);
    border-radius: 12px;
    padding: 2rem;
  }
  .light-text {
    opacity: 0.8;
  }
`;

export const UTMInfoUI = ({
  utmInfo,
}: {
  utmInfo?: Record<string, string>;
}) => {
  if (utmInfo && Object.keys(utmInfo || {}).length > 0) {
    return (
      <Wrapper>
        <Row className="g-4">
          {Object.entries(utmInfo).map(([key, value]) => {
            return (
              <Col lg={3} key={key}>
                <div className="item">
                  <div className="fs-18 fw-400 light-text text-capitalize">
                    {key.replaceAll('_', ' ')}
                  </div>
                  <div className="mt-3 fs-24 fw-700">{value}</div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Wrapper>
    );
  }

  return <></>;
};
