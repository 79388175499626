import styled from 'styled-components';

export const Wrapper = styled.div`
  .listing-table {
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
    background: ${(props) => props.theme.colors.white};
    border-radius: 12px;
  }
  .listings,
  .listings > .table-responsive {
    min-height: 500px;
  }
`;
