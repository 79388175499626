import { Modal, Button } from 'react-bootstrap';
import { StyledModal } from 'components/styled/StyledModal';
import { StyledButton } from 'components/forms/Buttons';

type Props = {
  show: boolean;
  toggle: () => void;
  onConfirm: () => void;
  loading: boolean;
  userName: string;
};

const DeleteAccountPrompt = ({
  show,
  toggle,
  onConfirm,
  loading,
  userName,
}: Props) => {
  return (
    <StyledModal maxwidth={540} show={show} size="sm" onHide={toggle} centered>
      <Modal.Body>
        <Button variant="transparent" className="close" onClick={toggle}>
          &times;
        </Button>

        <div className="fs-28 fw-400 text-center mb-3">
          Are you sure you want to delete{' '}
          <span className="text-capitalize">{userName}</span>'s account?
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-center mt-4 gap-3">
          <StyledButton
            className="fs-16 fw-400"
            variant="outline-dark"
            padding="0.8125rem 2rem"
            onClick={toggle}
            disabled={loading}
          >
            Go back
          </StyledButton>
          <StyledButton
            className="fs-16 fw-400"
            variant="primary"
            padding="0.8125rem 2rem"
            onClick={onConfirm}
            disabled={loading}
          >
            Yes, Delete
          </StyledButton>
        </div>
      </Modal.Body>
    </StyledModal>
  );
};

export default DeleteAccountPrompt;
