/*
 * This component displays the banner of job details page
 */
import { useMemo } from 'react';
import moment from 'moment';
import { StatusBadge } from 'components/styled/Badges';
import {
  DraftProspectJobBanner,
  InProgressClosedJobWrapper,
} from '../project-details.styled';
import {
  numberWithCommas,
  changeStatusDisplayFormat,
  showFormattedBudget,
  convertToTitleCase,
} from 'helpers/utils/misc';
import { JOBS_STATUS } from '../consts';

const ProjectBanner = ({ data }: { data: any }) => {
  /* This will format the date label to be displayed */
  const dateLabel = useMemo(() => {
    if (data?.status == 'draft') {
      return 'Date started: ';
    } else if (data?.status == 'prospects') {
      return 'Posted on: ';
    } else {
      return 'Started: ';
    }
  }, [data?.status]);

  /* This will format the date label to be displayed */
  const jobStatus = useMemo(() => {
    if (data?.status === 'draft') {
      return 'Drafts';
    } else if (data?.status === 'prospects') {
      return 'Posted Project';
    } else if (data?.status === 'active') {
      return 'Work In Progress';
    } else if (data?.status === 'closed') {
      return 'Closed';
    }
  }, [data?.status]);

  return data?.status == 'draft' || data?.status == 'prospects' ? (
    <DraftProspectJobBanner className="d-flex flex-column gap-3">
      <div className="header d-flex justify-content-between align-items-start flex-wrap gap-3">
        <header className="banner-title fs-24 fw-400">
          {convertToTitleCase(data.job_title)}
        </header>
        <StatusBadge
          className="width-fit-content"
          color={JOBS_STATUS[data?.status]?.color}
        >
          {jobStatus}
        </StatusBadge>
      </div>
      <div className="budget-and-earnings d-flex flex-column flex-lg-row align-items-lg-center gap-3">
        <div>
          <span className="light-text fs-20 fw-400">{dateLabel}</span>
          <span className="job-date fs-20 fw-400">
            {moment(data?.date_created).format('MMM DD, YYYY')}
          </span>
        </div>
        <Divider />
        <div className="gap-2">
          <span className="light-text fs-20 fw-400">Budget: </span>
          <span className="budget-amount fs-20 fw-400">
            {data?.budget?.isProposal === true
              ? 'Open to Proposals'
              : data?.budget
              ? showFormattedBudget(data?.budget)
              : '-'}
          </span>
        </div>
        {data.status === 'prospects' && data?.due_date && (
          <>
            <Divider />
            <div className="gap-2">
              <span className="light-text fs-20 fw-400">Due Date: </span>
              <span className="budget-amount fs-20 fw-400">
                {moment(data?.due_date).format('MMM DD, YYYY')}
              </span>
            </div>
          </>
        )}
      </div>
    </DraftProspectJobBanner>
  ) : (
    <InProgressClosedJobWrapper>
      <div className="header d-flex flex-column flex-md-row justify-content-between align-items-start gap-3">
        <div className="job-basic-details d-flex flex-column">
          <header className="banner-title fs-24 fw-400">
            {convertToTitleCase(data.job_title)}
          </header>
          <div className="d-flex flex-column flex-lg-row align-lg-items-center gap-3">
            <div>
              <span className="light-text fs-20 fw-400">{dateLabel}</span>
              <span className="fs-20 fw-400">
                {data?.job_start_date &&
                  moment(data?.job_start_date).format('MMM DD, YYYY')}
              </span>
            </div>
            {data.status === 'active' && (
              <div className="d-flex align-items-center gap-3">
                <Divider />
                <div className="d-flex align-items-center gap-2">
                  <span className="light-text fs-20 fw-400">Due Date: </span>
                  <span className="budget-amount fs-20 fw-400">
                    {data?.due_date
                      ? moment(data?.due_date).format('MMM DD, YYYY')
                      : '-'}
                  </span>
                </div>
              </div>
            )}
            {data.status === 'closed' && (
              <div className="d-flex align-items-center gap-3">
                <Divider />
                <div className="d-flex align-items-center gap-2">
                  <span className="light-text fs-20 fw-400">Ended: </span>
                  <span className="budget-amount fs-20 fw-400">
                    {data?.job_end_date
                      ? moment(data?.job_end_date).format('MMM DD, YYYY')
                      : '-'}
                  </span>
                </div>
              </div>
            )}
          </div>
          <StatusBadge
            className="width-fit-content"
            color={
              JOBS_STATUS[data?.status]?.color
                ? JOBS_STATUS[data?.status]?.color
                : 'gray'
            }
          >
            {data?.status == 'active'
              ? 'Work In Progress'
              : changeStatusDisplayFormat(data?.status)}
          </StatusBadge>
        </div>
        <div>
          <img
            src={data?.userdata?.user_image || '/images/default_avatar.png'}
            className="d-flex justify-content-center img"
          />
          <div className="d-flex mt-3 align-items-end">
            <div className="fs-20 fw-400 text-capitalize">
              {data?.userdata?.first_name} {data?.userdata?.last_name}
            </div>
            <span className="light-text fs-18 fw-400 ps-2">Hired</span>
          </div>
        </div>
      </div>

      <div className="budget-and-earnings gap-md-4 gap-3 d-flex flex-column flex-md-row align-items-md-center justify-content-between">
        <div className="flex-1">
          <label className="light-text fs-1rem fw-400">Budget</label>
          <div className="mt-1 fs-20 fw-400">
            {data?.proposal?.approved_budget?.amount ? (
              <>
                {numberWithCommas(
                  Number(data?.proposal?.approved_budget?.amount),
                  'USD'
                )}
                {data?.proposal?.approved_budget?.type === 'hourly'
                  ? '/hr'
                  : ''}
              </>
            ) : null}
          </div>
        </div>
        <div className="flex-1">
          <div className="light-text fs-1rem fw-400">
            {data?.budget?.type == 'hourly'
              ? 'Total Hours Worked'
              : 'Remaining'}
          </div>
          <div className="mt-1 fs-20 fw-400">
            {data?.budget?.type == 'hourly'
              ? data?.total_hours
                ? `${numberWithCommas(data?.total_hours)} Hours`
                : '0'
              : data?.proposal?.approved_budget?.amount
              ? `${numberWithCommas(
                  data?.proposal?.approved_budget?.amount - data?.paid,
                  'USD'
                )}`
              : data?.budget
              ? `${numberWithCommas(data?.budget?.amount - data?.paid, 'USD')}`
              : '-'}
          </div>
        </div>
        <div className="divider d-none d-md-block" />
        <div className="flex-1">
          <label className="light-text fs-1rem fw-400">Paid</label>
          <div className="mt-1 fs-20 fw-400">
            {data?.paid ? numberWithCommas(data?.paid, 'USD') : '$0'}
          </div>
        </div>
      </div>
    </InProgressClosedJobWrapper>
  );
};

export default ProjectBanner;

const Divider = () => {
  return <div className="d-none d-lg-block opacity-50">|</div>;
};
