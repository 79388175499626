import { useEffect, useState } from 'react';
import { useCategories } from './categoryProvider';
import { StyledTable } from 'components/styled/StyledTable';
import moment from 'moment';
import { StatusBadge } from 'components/styled/Badges';
import SkillModal from './skillModal';
import { StyledButton } from 'components/forms/Buttons';
import { DeleteCategoryPrompt } from './delete_category_prompt';
import { CiTrash } from 'react-icons/ci';

const SkillTable = ({ onCloseHandler, showModal, setShowModal }: any) => {
  const { skills, fetchSkills } = useCategories();
  const [skill, setSkill] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    fetchSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!show) setSkill({});
  }, [show]);

  return (
    <div>
      <SkillModal
        show={showModal}
        onClose={() => {
          setSkill({});
          onCloseHandler('skill');
        }}
        skill={skill}
      />

      <DeleteCategoryPrompt show={show} setShow={setShow} category={skill} />

      <StyledTable responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Skills</th>
            <th>Freelancers</th>
            <th>Projects Posted</th>
            <th>Projects Opened</th>
            <th>Date Created</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {!!skills?.length &&
            skills?.map((skill, index) => {
              return (
                <tr key={`cat-${index}`}>
                  <td>{skill.id}</td>
                  <td className="text-capitalize">{skill.name}</td>
                  <td>
                    <div className="d-flex gap-2 flex-wrap">
                      {skill.categories.map((cat, index: number) => (
                        <StatusBadge
                          key={`skill-${index}`}
                          color={index % 2 ? 'blue' : 'darkPink'}
                        >
                          {cat.name}
                        </StatusBadge>
                      ))}
                    </div>
                  </td>
                  <td>{skill?.freelancer_count ?? 0}</td>
                  <td>{skill?.job_count ?? 0}</td>
                  <td>{skill?.open_projects_count ?? 0}</td>
                  <td>
                    {moment(skill?.date_created).format('MMM DD, YYYY') || '-'}
                  </td>
                  <td className="d-flex align-items-center justify-content-center gap-3">
                    <StyledButton
                      className="text-white text-capitalize"
                      style={{
                        padding: '5px 1.2rem',
                        fontSize: '14px',
                        minHeight: 'unset',
                        borderRadius: '8px',
                      }}
                      onClick={() => {
                        setSkill(skill);
                        setShowModal(true);
                      }}
                    >
                      Edit
                    </StyledButton>
                    <CiTrash
                      fill="#de1f1f"
                      size={30}
                      onClick={() => {
                        setSkill(skill);
                        setShow(true);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </StyledTable>
    </div>
  );
};

export default SkillTable;
