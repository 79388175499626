import { UTMProvider } from './controller/utmInfo';
import { UTMInfo } from './UTMInfo';

// eslint-disable-next-line react/display-name
export default function () {
  return (
    <UTMProvider>
      <UTMInfo />
    </UTMProvider>
  );
}
