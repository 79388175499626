import React, { useState } from 'react';
import CountryCard from './CountryCard';
import StripeFeeCard from './StripeFeeCard';
import Tabs from 'components/ui/Tabs';
import { StyledButton } from 'components/forms/Buttons';
import { Spinner } from 'react-bootstrap';

interface TabItem {
  label: string;
  id: number;
  key: string;
  showCount?: boolean;
}

const Settings = () => {
  const [activeTab, setActiveTab] = useState('countries');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const onCloseHandler = (flag: string) => {
    setShow(false);
    // Add any refresh logic here if needed
  };

  const tabs: TabItem[] = [
    {
      id: 1,
      key: 'countries',
      label: 'Countries',
      showCount: false,
    },
    {
      id: 2,
      key: 'stripeFees',
      label: 'Stripe Fees',
      showCount: false,
    },
  ];

  const components: Record<string, React.ReactNode> = {
    countries: (
      <CountryCard
        onCloseHandler={onCloseHandler}
        showModal={show}
        setShowModal={setShow}
      />
    ),
    stripeFees: (
      <StripeFeeCard
        onCloseHandler={onCloseHandler}
        showModal={show}
        setShowModal={setShow}
      />
    ),
  };

  const handleTabChange = (tabKey: string) => {
    setActiveTab(tabKey);
    setShow(false); // Close modal when changing tabs
  };

  const getAddButtonText = () => {
    return activeTab === 'countries' ? 'Add Country' : 'Add Fee Structure';
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <h1 className="fs-32 fw-700 mb-0 mt-0">Settings</h1>
        {loading && <Spinner animation="border" className="ms-2" size="sm" />}
      </div>
      <div className="w-100">
        <div className="d-flex align-items-center justify-content-between">
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={handleTabChange}
            className="settings-tabs"
          />
          <div>
            {activeTab !== 'stripeFees' && (
              <StyledButton
                onClick={() => setShow(true)}
                className="text-white"
              >
                {getAddButtonText()}
              </StyledButton>
            )}
          </div>
        </div>
        <div className="mt-4">{components[activeTab]}</div>
      </div>
    </div>
  );
};

export default Settings;
