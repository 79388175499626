import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import Loader from 'components/Loader';
import BackButton from 'components/ui/BackButton';
import ProjectBanner from './partials/ProjectBanner';
import Tabs from 'components/ui/Tabs';
import GeneralDetails from './partials/GeneralDetails';
import Milestones from './partials/milestones';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';
import useResponsive from 'helpers/hooks/useResponsive';
import useJobDetails from './hooks/useProjectDetails';
import { Wrapper } from './project-details.styled';
import Feedback from './partials/feedback';
import HoursManagement from './partials/hours-management';

const ProjectDetails = () => {
  useStartPageFromTop();
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const { id, userId } = useParams<{ id: string; userId: string }>();

  const [showEndJobButton, setShowEndJobButton] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState('gen_details');

  const { jobdetails, isLoading, tabItems, isRefetching } = useJobDetails(
    id || '',
    userId || ''
  );

  useEffect(() => {
    if (!isLoading && jobdetails) {
      if (
        jobdetails.status === 'active' &&
        jobdetails?.milestone.filter((x: any) => x.is_final_milestone).length >
          0
      ) {
        setActiveTab('m_stone');
      }

      if (
        jobdetails?.milestone.filter((x) => x.is_final_milestone).length > 0 &&
        (jobdetails?.milestone[0]?.hourly_status === 'paid' ||
          jobdetails?.milestone[0]?.hourly_status === 'released') &&
        jobdetails?.status === 'active' &&
        !jobdetails?.milestone
          ?.map(
            (data: any) =>
              data.hourly_status === 'paid' || data.hourly_status === 'released'
          )
          .includes(false)
      ) {
        setShowEndJobButton(true);
      }
    }
  }, [jobdetails, isLoading]);

  const onTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  // If job not found then redirecting to 404 page
  if (!isLoading && !isRefetching && !jobdetails) {
    navigate('/404', { replace: true });
  }

  return (
    <Wrapper className="px-4 px-lg-0 my-3">
      {/* Back button header */}
      <BackButton
        propsElement={
          <div className="d-flex align-items-center pointer flex-wrap fs-18 fw-400">
            &nbsp; | &nbsp;
            {jobdetails && `Projects / ${jobdetails?.job_title || ''}`}
            {isRefetching && (
              <Spinner animation="border" size="sm" className="ms-2" />
            )}
          </div>
        }
      />

      {(isLoading || isRefetching) && <Loader />}

      {!isLoading && !isRefetching && jobdetails ? (
        <ProjectBanner data={jobdetails} />
      ) : null}

      {!isLoading && !isRefetching && jobdetails && (
        <>
          {/* Tabs */}

          <div className="tabs-quick-options d-flex align-items-center justify-content-between flex-wrap mt-2r">
            {tabItems?.length > 1 && jobdetails?.status !== 'draft' ? (
              <Tabs
                tabs={tabItems}
                activeTab={activeTab}
                onTabChange={onTabChange}
              />
            ) : (
              <div className="tabs empty" />
            )}

            {/* Quick options */}

            <div
              className={classNames('justify-content-end', {
                'w-100': isMobile,
              })}
            >
              {/* Moving Job status out of QuickOptions to show independent of desktop/mobile */}
              {jobdetails?.status === 'active' &&
              jobdetails?.is_closure_request &&
              jobdetails?.closure_req_submitted_by === 'CLIENT' &&
              !showEndJobButton ? (
                <div
                  className={
                    isMobile
                      ? 'mt-4 opacity-50 d-flex justify-content-md-end justify-content-center flex-1'
                      : 'opacity-50 d-flex justify-content-md-end justify-content-center flex-1'
                  }
                >
                  {jobdetails?.milestone.filter((x) => x.is_final_milestone)
                    .length > 0
                    ? 'Final Hours Submitted by Freelancer'
                    : 'Requested to End the Project'}
                </div>
              ) : null}
            </div>
          </div>

          {activeTab == 'gen_details' && <GeneralDetails data={jobdetails} />}

          {activeTab == 'feedback' && (
            <Feedback
              feedbackData={jobdetails?.feedback}
              freelancerDetails={jobdetails?.userdata}
            />
          )}

          {activeTab === 'm_stone' && (
            <>
              {jobdetails.proposal?.approved_budget?.type == 'fixed' && (
                <Milestones
                  milestone={jobdetails?.milestone}
                  isRefetching={isRefetching}
                  jobstatus={jobdetails?.status}
                />
              )}
              {jobdetails.proposal?.approved_budget?.type == 'hourly' && (
                <HoursManagement milestone={jobdetails?.milestone} />
              )}
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default ProjectDetails;
