import { useCategories } from './categoryProvider';
import Tabs from 'components/ui/Tabs';
import * as C from './categoryStyles';
import CategoryTable from './categoryTable';
import SkillTable from './skillTable';
import { Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { StyledButton } from 'components/forms/Buttons';

const Categories = () => {
  const {
    activeTab,
    updateTab,
    tabs,
    loading,
    fetchCategories,
    fetchSkills,
  }: any = useCategories();
  const [show, setShow] = useState(false);

  const onCloseHandler = (flag: string) => {
    setShow(false);
    if (flag === 'skill') {
      fetchSkills();
    } else {
      fetchCategories();
    }
  };

  const components = {
    categories: (
      <CategoryTable
        onCloseHandler={onCloseHandler}
        showModal={show}
        setShowModal={setShow}
      />
    ),
    skills: (
      <SkillTable
        onCloseHandler={onCloseHandler}
        showModal={show}
        setShowModal={setShow}
      />
    ),
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <h1 className="fs-32 fw-700 mb-0 mt-0">Categories & Skills</h1>
        {loading && <Spinner animation="border" className="ms-2" size="sm" />}
      </div>
      <div className="w-100">
        {/* Filters */}
        <div className="d-flex align-items-center justify-content-between">
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={(tb) => updateTab(tb)}
          />
          <div>
            <StyledButton
              onClick={() => setShow(true)}
              key={'cat-button'}
              className="text-white"
            >
              Add {activeTab === 'categories' ? 'Category' : 'Skill'}
            </StyledButton>
          </div>
        </div>
        <C.CategoryWrapper>{components[activeTab]}</C.CategoryWrapper>
      </div>
    </div>
  );
};

export default Categories;
