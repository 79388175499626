export const JOBS_STATUS = {
  pending: {
    color: 'yellow',
  },
  prospects: {
    color: 'yellow',
  },
  denied: {
    color: 'darkPink',
  },
  declined: {
    color: 'darkPink',
  },
  active: {
    color: 'blue',
  },
  closed: {
    color: 'green',
  },
  draft: {
    color: 'gray',
  },
};

export const CONSTANTS = {
  ckEditorPlaceholder: `Project description needs at least 50 characters`,
  payment: {
    reviewWorkFirst: `I'll Review the Work First`,
    confirmAndPay: `Confirm and Pay`,
    areYouSureAboutThisTransaction: `Are you sure about this transaction?`,
    areYouSureAboutThisDelivery: `Are you sure about this delivery?`,
  },
  job: {
    selectSubmissionsYoureReadyToPayFor: `Select the submissions that you're ready to pay for`,
  },
};
