import React, { useState } from 'react';
import { FreelancerItem } from '../types/freelancers.types';
import { StatusBadge } from 'components/styled/Badges';

type Props = {
  skills: FreelancerItem['skills'];
  type: 'SKILLS' | 'SKILL_CATEGORIES';
};

export const SkillCategoriesAndSkillsColumn = ({ skills, type }: Props) => {
  const [isShowMore, setIsShowMore] = useState(false);

  const renderData =
    skills?.length > 0
      ? skills.reduce<{ id: string | number; name: string }[]>((acc, item) => {
          if (type === 'SKILLS' && item?.skill_id && item?.skill_name) {
            acc.push({ id: item.skill_id, name: item.skill_name });
          }
          if (
            type === 'SKILL_CATEGORIES' &&
            item?.category_id &&
            item?.category_name
          ) {
            acc.push({ id: item.category_id, name: item.category_name });
          }
          return acc;
        }, [])
      : [];

  if (renderData?.length > 0)
    return (
      <div className="w-max-content">
        {renderData.slice(0, isShowMore ? undefined : 2).map(({ id, name }) => {
          return (
            <StatusBadge key={id} className="my-2" color="green">
              {name}
            </StatusBadge>
          );
        })}
        {renderData.length > 2 && (
          <p
            className="text-capitalize text-decoration-underline fs-14 p-2 text-warning mb-0"
            onClick={(e) => {
              e.stopPropagation();
              setIsShowMore((prev) => !prev);
            }}
          >
            {isShowMore ? 'Show Less' : 'Show More'}
          </p>
        )}
      </div>
    );

  return <p>-</p>;
};
