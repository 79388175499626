export const TABS = [
  {
    id: 0,
    label: 'All',
    key: 'all',
    showCount: true,
  },
  {
    id: 1,
    label: 'Active',
    key: 'active',
    showCount: true,
  },
  {
    id: 2,
    label: 'Flagged',
    key: 'flagged',
    showCount: true,
  },
  {
    id: 3,
    label: 'Deleted',
    key: 'deleted',
    showCount: true,
  },
];
