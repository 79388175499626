import styled from 'styled-components';
import NoDataFound from 'components/ui/NoDataFound';

// This is the styled component for listing wrapper
const ListingWrapper = styled.div`
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 35px rgba(164, 122, 60, 0.07);
  border-radius: 0.75rem;
  min-height: 600px;
  .heading {
    color: ${(props) => props.theme.font.color.heading};
  }
  .listings {
    max-height: 500px;
    overflow-y: auto;
  }
`;

const ListingItem = styled.div`
  border: ${(props) => `1px solid ${props.theme.colors.gray6}`};
  border-radius: 10px;
  .avatar {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    object-fit: cover;
  }
  .divider {
    height: 2rem;
    width: 1px;
    background-color: ${(props) => props.theme.colors.gray6};
  }
  .light-text {
    opacity: 0.7;
  }
  .card-values {
    color: ${(props) => props.theme.colors.gray9};
  }
`;

const PaymentInformation = ({ data }: any) => {
  return (
    <ListingWrapper className="p-4">
      <div className="heading fs-24 fw-700 mb-2">Payment details</div>

      {data?.length > 0 ? (
        <div className="listings">
          {data?.map((item: any) => (
            <ListingItem
              key={item?.user_card_id}
              className="mt-3 p-3 d-flex justify-content-between align-items-center flex-wrap"
            >
              <div className="flex-1">
                <div className="label fs-sm fw-300 light-text">CARD NUMBER</div>
                <div className="card-values fs-1rem fw-400 mt-2">
                  xxxx xxxx xxxx {item?.last_4_digit}
                </div>
              </div>
              <div className="flex-md-1">
                <div className="label fs-sm fw-300 light-text">EXPIRY</div>
                <div className="card-values fs-1rem fw-400 mt-2">
                  {item?.exp_date}
                </div>
              </div>
            </ListingItem>
          ))}
        </div>
      ) : (
        <div className="py-5">
          <NoDataFound />
        </div>
      )}
    </ListingWrapper>
  );
};

export default PaymentInformation;
