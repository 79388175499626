import { apiClient } from 'helpers/http';
import { getCategoriesApi, getSkillsApi } from 'helpers/http/common';
import { createContext, useContext, useEffect, useState } from 'react';

// export interface tabsInt {
//   id: number;
//   label: string;
//   key: string;
// }

// export interface CategoryData {
//   categories: any[];
//   skills: any[];
//   tabs: tabsInt[];
//   activeTab: string;
//   updateTab: (tab: string) => void;
//   loading: boolean;
//   fetchCategories?: (keyword?: string) => void;
// }

// export interface ContextPayload {
//   activeTab: string;
//   categories: any[];
//   skills: any[];
//   tabs: tabsInt[];
//   updateTab: (tab: string) => void;
//   loading: boolean;
//   fetchCategories: (keyword?: string) => void;
// }

const initialValue: any = {
  categories: [],
  skills: [],
  tabs: [
    {
      id: 0,
      label: 'Categories',
      key: 'categories',
    },
    {
      id: 1,
      label: 'Skills',
      key: 'skills',
    },
  ],
  activeTab: 'categories',
  loading: false,
  updateTab: (tab: string) => {
    console.log(typeof tab);
  },
};

const CategoryContext = createContext(initialValue);

const CategoryProvider = ({ children }: any) => {
  const [activeTab, setActiveTab] = useState<string>('categories');
  const [categories, setCategories] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState<any[]>([]);
  const updateTab = (tab: string) => setActiveTab(tab);

  const fetchCategories = async (keyword = '') => {
    try {
      setLoading(true);

      const data = await getCategoriesApi(keyword);
      setLoading(false);

      if (!data.status) return;
      setCategories(data.data);
      return data.data;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  const fetchSkills = async (keyword = '') => {
    try {
      setLoading(true);
      const data = await getSkillsApi(keyword);
      setLoading(false);

      if (!data.status) return;
      setSkills(data.data);
      return data.data;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  const payload: any = {
    activeTab,
    categories,
    skills,
    tabs: initialValue.tabs,
    updateTab,
    loading,
    fetchCategories,
    fetchSkills,
  };

  useEffect(() => {
    fetchCategories();
    fetchSkills();
  }, []);

  return (
    <CategoryContext.Provider value={payload}>
      {children}
    </CategoryContext.Provider>
  );
};

function useCategories() {
  return useContext(CategoryContext);
}

export { useCategories, CategoryProvider };
