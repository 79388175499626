import { apiClient } from 'helpers/http';
import React, { ReactNode, useEffect } from 'react';
import { useQuery } from 'react-query';
import { TJobPostApiResponse } from '../types/projects.types';
import { TFilters } from 'components/ui/columnOptions.types';

type TProjectContext = {
  filters: TFilters;
  updateFilters: (newFilter: Partial<TFilters>) => void;
  data?: TJobPostApiResponse;
  isLoading: boolean;
  isRefetching: boolean;
  refetch: () => void;
  totalPages: number;
};

const initialContextValue: TProjectContext = {
  filters: {
    keyword: '',
    status: 'all',
    page: 1,
    limit: 10,
    sortBy: '',
    sortOrder: '',
    searchBy: '',
    searchText: '',
  },
  isLoading: false,
  isRefetching: false,
  refetch: () => {
    //
  },
  totalPages: 0,
  updateFilters: () => {
    //
  },
  data: undefined,
};

const ProjectContext =
  React.createContext<TProjectContext>(initialContextValue);

export const getProjects = (params): Promise<{ data: TJobPostApiResponse }> => {
  const [, filters] = params.queryKey;
  const queryParamsFilters = Object.fromEntries(new URLSearchParams(filters));
  return apiClient
    .post('/admin/job', queryParamsFilters)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const ProjectProvider = ({ children }: { children: ReactNode }) => {
  const queryParamsFilters = Object.fromEntries(
    new URLSearchParams(location.search)
  );

  const [filters, setFilters] = React.useState<TFilters>({
    ...initialContextValue.filters,
    ...queryParamsFilters,
  });

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['projects', filters],
    getProjects,
    { enabled: !!filters, keepPreviousData: true }
  );

  // Set query params to url
  useEffect(() => {
    const queryParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key]) queryParams.set(key, filters[key]);
    });

    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname;

    if (queryParams.toString())
      newurl = newurl.concat('?' + queryParams.toString());

    window.history.replaceState({ push: newurl }, '', newurl);
  }, [filters]);

  const updateFilters: TProjectContext['updateFilters'] = React.useCallback(
    (newFilter) => {
      setFilters((prev) => ({ ...prev, ...newFilter }));
    },
    []
  );

  // Final context value
  const value = React.useMemo(
    () => ({
      filters,
      updateFilters,
      data: data?.data,
      isLoading,
      isRefetching,
      refetch,
      totalPages: Math.ceil((data?.data?.total || 0) / 10),
    }),
    [data?.data, filters, isLoading, isRefetching, refetch, updateFilters]
  );

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};

const useProjects = () => {
  return React.useContext(ProjectContext);
};

export { ProjectProvider, useProjects };
