import { useQuery } from 'react-query';
import { getFreelancerDetails } from 'helpers/http/freelancer';
import { showErr } from 'helpers/utils/misc';

/*
 * This hook will fetch the freelancer details
 */
function useFreelancerProfile(id?: string) {
  const { data, isLoading, refetch, isRefetching, isError } = useQuery(
    'get-freelancer-details',
    () => getFreelancerDetails(id),
    {
      onError: (err: any) => {
        showErr(err?.response?.data?.message);
      },
    }
  );
  return {
    profileData: data?.data || null,
    isLoading,
    refetch,
    isRefetching,
    isError,
  };
}

export default useFreelancerProfile;
