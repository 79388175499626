import toast from 'react-hot-toast';
import moment from 'moment';

const EXPECTED_HOURS_REMAP = {
  simple: 'Short Project',
  bigger: 'Medium Project',
  ongoing: 'Ongoing Project',
};

export const pxToRem = (px: number) => `${px / 16}rem`;

export const getImageUrl = (data: string, type: 'single' | 'multi'): string => {
  const parsed = JSON.parse(data);
  if (type === 'single') {
    return parsed[0];
  }
  return parsed;
};

export const showMsg = (msg: string) => {
  toast.dismiss();
  toast.success(msg);
};
export const showErr = (msg: string) => {
  toast.dismiss();
  toast.error(msg);
};

export const asyncToast = ({
  promise,
  loading,
  success,
}: {
  promise: any;
  loading: string;
  success: string;
}) => {
  toast.promise(promise, {
    loading,
    success,
    error: (error) => error.toString() || 'Internal error, try later.',
  });
};

export const imageUrl = (url: string) => {
  return url[0] === '/' ? url : `/${url}`;
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  const formattedNumber = phoneNumberString
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  return formattedNumber;
};

export function formatLocalDate(date: string, format?: string) {
  return moment
    .utc(date)
    .local()
    .format(format || 'Do MMM YYYY hh:mm a');
}

/**
 * Maps status with label.
 */

export const statusTitleMapper: { [key: string]: string } = {
  processing: 'Shipping Pending',
};

/**
 * This will return label related to given status.
 * If not found then status itself will be return.
 * @param status string
 * @returns status label
 */

export function getStatusTitle(status: string) {
  return statusTitleMapper[status] || status;
}

/**
 * getYupErrors will format errors to simple object
 * from yup error inner object.
 */
export const getYupErrors = (error: any) => {
  const errors = error.inner.reduce((acc: any, error: any) => {
    return {
      ...acc,
      [error.path]: error.message,
    };
  }, {});
  return errors;
};

export const capitalizeFirstLetter = (str: string) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return '';
  }
};

export function numberWithCommas(number: number | string, currency?: string) {
  if (currency) {
    return number
      ? number.toLocaleString('en-US', { style: 'currency', currency })
      : '$0';
  } else {
    return number ? number.toLocaleString('en-US') : '';
  }
}

export const convertToPlain = (html: string) => {
  // Create a new div element
  const tempDivElement = document.createElement('div');

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || '';
};

export const separateValuesWithComma = (params: string[]) => {
  return params
    .filter(function (val) {
      return val;
    })
    .join(', ');
};

export const changeStatusDisplayFormat = (status: string, splitBy = '-') => {
  let finalString = '';
  if (status) {
    const splits = status.split(splitBy);
    for (let i = 0; i < splits.length; i++) {
      const str = splits[i];
      const str2 = str.charAt(0).toUpperCase() + str.slice(1);
      finalString = finalString + str2 + ' ';
    }
  }
  return finalString;
};

export const formatExpertiseStatus = (expertise: string) => {
  const splits = expertise.split('_');
  let finalString = '';
  for (let i = 0; i < splits.length; i++) {
    const str = splits[i];
    const str2 = str.charAt(0).toUpperCase() + str.slice(1);
    finalString = finalString + str2 + ' ';
  }
  return finalString;
};

export const convertToTitleCase = (str: string) => {
  if (!str) return '';

  const title = str.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  const lowers = [
    'A',
    'An',
    'The',
    'And',
    'But',
    'Or',
    'For',
    'Nor',
    'As',
    'At',
    'By',
    'For',
    'From',
    'In',
    'Into',
    'Near',
    'Of',
    'On',
    'Onto',
    'To',
    'With',
    'Is',
  ];
  const firstWord = title.split(' ')[0];
  let restLine = title.substr(title.indexOf(' ') + 1);

  restLine = restLine
    .split(' ')
    .map((word) => (lowers.includes(word) ? word.toLocaleLowerCase() : word))
    .join(' ');

  return firstWord + ' ' + restLine;
};

export const expectedHoursRemap = (value: string) => {
  return EXPECTED_HOURS_REMAP[value];
};

export const fileIsAnImage = (url) => {
  return url.match(/\.(jpeg|jpg|gif|png|webp|avif)$/);
};

export const showFormattedBudget = (budget) => {
  if (budget) {
    if (budget?.type == 'fixed') {
      // If budget type is fixed
      return numberWithCommas(budget?.amount, 'USD');
    } else if (budget?.type == 'hourly') {
      // If budget type is hourly
      return `${numberWithCommas(budget?.max_amount, 'USD')}/hr`;
    } else {
      // If budget type is unsure
      if (budget?.costType == 'fixed') {
        return `${numberWithCommas(
          budget?.min_amount,
          'USD'
        )} - ${numberWithCommas(budget?.max_amount, 'USD')}`;
      } else {
        return `${numberWithCommas(budget?.max_amount, 'USD')}/hr`;
      }
    }
  } else {
    return ' - '; // If there is no budget
  }
};

export const paymentProcessingStatusHandler = (method = 'OTHER') => {
  const status = {
    ACH: 'Payment Processing (Up to 5 business days)',
    OTHER: 'Payment Processing',
  };
  return status[method];
};

export function getPlainText(strSrc) {
  const divElement = document.createElement('DIV');
  divElement.innerHTML = strSrc;
  const plain = divElement.textContent || divElement.innerText || '';
  return plain;
}
