import { ColumnDef } from '@tanstack/react-table';
import { separateValuesWithComma } from 'helpers/utils/misc';
import { ClientItem } from 'pages/clients/types/clients.types';
import { ReactComponent as LocationIcon } from 'assets/icons/location-blue.svg';
import MoreButton from 'pages/client-profile/partials/MoreButton';
import moment from 'moment';
import { StatusBadge } from 'components/styled/Badges';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AdminNoteDiv } from 'components/AdminNotes';
import { MdFlag } from 'react-icons/md';
import { CustomHeader } from 'components/ui/CustomHeader';
import { TFilters } from 'components/ui/columnOptions.types';
import { MORE_BUTTON_ID } from 'helpers/consts';

export const clientTableColumns = (
  onLoginAsAnotherUser: (user_id: string) => () => void,
  onFlagChange: (id: string, flag: 0 | 1) => () => void,
  selectedId: string,
  filters: TFilters,
  applyFilter: (data: Partial<TFilters>) => void
) => {
  const columns: ColumnDef<ClientItem>[] = [
    {
      id: 'Name',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Name"
          id="name"
          sortingFeature
          filterType="SEARCH"
        />
      ),
      cell: ({ row: { original } }) => {
        const flaggedNote = original?.admin_notes?.find(
          (note) => note?.isFlagged
        );
        const isFlagged = original.is_flagged === 1;

        return (
          <div className="d-flex align-items-center">
            <img src={original?.user_image} className="avatar" />
            <div className="ms-3 fs-1rem fw-400">
              {original?.first_name} {original?.last_name}{' '}
              {Number(original?.duplicate_count) > 1 && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      Duplicate Accounts: {original.duplicate_count}
                    </Tooltip>
                  }
                >
                  <span className="bg-warning rounded-circle px-2 py-1 fs-12">
                    {original.duplicate_count}
                  </span>
                </OverlayTrigger>
              )}
            </div>
            {/* START ----------------------------------------- Flagged note */}
            {(flaggedNote || isFlagged) && (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  flaggedNote ? (
                    <Tooltip>
                      <AdminNoteDiv
                        dangerouslySetInnerHTML={{
                          __html: flaggedNote.text || '',
                        }}
                      ></AdminNoteDiv>
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <div>
                  <MdFlag className="flag-icon" size={20} />
                </div>
              </OverlayTrigger>
            )}
            {/* END ------------------------------------------- Flagged note */}
          </div>
        );
      },
    },
    {
      id: 'Company Name',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Company Name"
          id="company_name"
          sortingFeature
          filterType="SEARCH"
          minWidth="190px"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">{original?.company_name || '-'}</span>
      ),
    },
    {
      id: 'Location',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Location"
          id="location"
          sortingFeature
          filterType="SEARCH"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">
          {original?.location?.state || original?.location?.country_name ? (
            <div className="d-flex align-items-center">
              <div>
                <LocationIcon />
              </div>
              &nbsp;
              <span>
                {separateValuesWithComma([
                  original?.location?.state,
                  original?.location?.country_name,
                ])}
              </span>
            </div>
          ) : (
            '-'
          )}
        </span>
      ),
    },
    {
      id: 'Email',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Email"
          id="email"
          sortingFeature
          filterType="SEARCH"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">{original?.u_email_id || '-'}</span>
      ),
    },
    {
      id: 'Applied Date',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Applied Date"
          id="applied_date"
          sortingFeature
          minWidth="166px"
          filterType="DATE_FILTER"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">
          {moment(original?.date_created).format('MMM DD, YYYY') || '-'}
        </span>
      ),
    },
    {
      id: 'Last Login',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Last Login"
          id="last_login"
          sortingFeature
          filterType="DATE_FILTER"
          minWidth="166px"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">
          {original?.last_login
            ? moment(original.last_login).local().format('MMM DD, YYYY')
            : '-'}
        </span>
      ),
    },

    {
      id: 'Payment Details',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Payment Details"
          id="firstPayMethod"
          sortingFeature
          minWidth="166px"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">
          {original?.attributes?.firstPayMethod
            ? moment(original?.attributes?.firstPayMethod)
                .local()
                .format('MMM DD, YYYY')
            : '-'}
        </span>
      ),
    },
    {
      id: 'Pay Dets.',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Pay Dets."
          id="payment_details"
          sortingFeature
          filterType="FILTER"
          options={['Yes', 'No']}
          minWidth="144px"
        />
      ),
      cell: ({ row: { original } }) => {
        const isDetailsAdded = original?.payment_details;
        return (
          <span className="fs-1rem fw-400">
            <StatusBadge color={isDetailsAdded ? 'green' : 'red'}>
              {isDetailsAdded ? 'Yes' : 'No'}
            </StatusBadge>
          </span>
        );
      },
    },
    {
      id: 'Posted Projects',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Posted Projects"
          id="posted_projects"
          sortingFeature
          filterType="SEARCH"
          numberOnly
          minWidth="186px"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">
          {original?.job_post_count ?? '-'}
        </span>
      ),
    },
    {
      id: 'Open Projects',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Open Projects"
          id="active_jobs_count"
          sortingFeature
          // filterType="SEARCH"
          numberOnly
          minWidth="186px"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">
          {original?.active_jobs_count ?? '-'}
        </span>
      ),
    },
    {
      id: '$ Spent',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="$ Spent"
          id="total_spent"
          sortingFeature
          numberOnly
          minWidth="186px"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">${original?.total_spent ?? '0'}</span>
      ),
    },
    {
      id: 'Completed Projects',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Completed Projects"
          id="completed_projects"
          sortingFeature
          filterType="SEARCH"
          numberOnly
          minWidth="214px"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400">
          {original?.completed_jobs ?? '-'}
        </span>
      ),
    },
    {
      id: 'Phone',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Phone"
          id="phone"
          filterType="SEARCH"
          numberOnly
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="fs-1rem fw-400 gap-3">
          <div className="d-flex align-items-center justify-content-between">
            {original?.formatted_phonenumber || original?.phone_number}
          </div>
        </span>
      ),
    },
    {
      id: MORE_BUTTON_ID,
      header: () => <span></span>,
      cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.is_deleted !== 1 && (
              <div onClick={(e) => e.stopPropagation()}>
                <MoreButton
                  disabled={selectedId === original?.user_id}
                  isFlagged={original?.is_flagged === 1}
                  onFlag={onFlagChange(
                    original?.user_id,
                    original?.is_flagged === 1 ? 0 : 1
                  )}
                  onLoginAsUser={onLoginAsAnotherUser(original?.user_id)}
                />
              </div>
            )}
          </div>
        );
      },
    },
  ];
  return columns;
};
