import { useQuery } from 'react-query';
import { getStripeBalances } from 'helpers/http/common';

/*
 * This hook will load the strip balances
 */

function useStripeBalance() {
  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['get-stripe-balances'],
    () => getStripeBalances(),
    { keepPreviousData: true }
  );
  return {
    availableBalance: data?.data?.available[0]?.amount || 0,
    pendingBalance: data?.data?.pending[0]?.amount || 0,
    inTransitCard: data?.data?.pending[0]?.source_types?.card || 0,
    inTransitBank: data?.data?.pending[0]?.source_types?.bank_account || 0,
    holdPayoutCard: data?.data?.holdPayoutCard || 0,
    holdPayoutBank: data?.data?.holdPayoutBank || 0,
    cardBalance: data?.data?.available[0]?.source_types?.card || 0,
    bankBalance: data?.data?.available[0]?.source_types?.bank_account || 0,
    isLoading,
    isRefetching,
    refetch,
  };
}

export default useStripeBalance;
