import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { string, object } from 'yup';

export const editBasicDetailsSchema = object({
  first_name: string()
    .required('Please enter first name')
    .min(2, 'First name must have more than one letter.'),
  last_name: string()
    .required('Please enter last name')
    .min(2, 'Last name must have more than one letter.'),
  u_email_id: string()
    .email('Please enter valid email address')
    .required('Please enter email address'),
  formatted_phonenumber: string()
    .required('Please enter phone number')
    .test('phoneValidation', 'Please enter a valid phone number', (phone) =>
      isPossiblePhoneNumber(phone || '')
    ),
}).required();
