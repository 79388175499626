import logo from 'assets/icons/logo.svg';
import { Card } from 'components/styled/Auth.styled';

export default function AuthLayout({
  children,
  center,
  small,
  logoClass,
}: {
  children?: React.ReactNode;
  center?: boolean;
  small?: any;
  logoClass?: string;
}) {
  if (center) {
    return (
      <div style={{ height: '100%', display: 'grid', textAlign: 'center' }}>
        <Card small={small}>
          <Logo className={logoClass} />
          {children}
        </Card>
      </div>
    );
  }

  return (
    <div style={{ height: '100%', display: 'grid' }}>
      <Card small={small}>
        <Logo className={logoClass} />
        {children}
      </Card>
    </div>
  );
}

const Logo = ({ className }: { className?: string }) => {
  return (
    <div
      className={className || ''}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <img src={logo} alt="logo" width={70} />
    </div>
  );
};
