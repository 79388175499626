import { useQuery } from 'react-query';
import { getDashboardStats } from 'helpers/http/dashboard';

/*
 * This hook will load the dashboard stats
 */
function useDashboardStats(activeFilter: string) {
  const { data, isLoading } = useQuery(
    ['get-dashboard-stats', activeFilter],
    () => getDashboardStats(activeFilter)
  );
  return {
    data: data,
    isLoading,
  };
}

export default useDashboardStats;
