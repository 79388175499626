import { ColumnDef } from '@tanstack/react-table';
import { StatusBadge, TStatusBadgeColor } from 'components/styled/Badges';
import { dateDifference } from 'helpers/utils/dateDifference';
import { numberWithCommas } from 'helpers/utils/misc';
import moment from 'moment';
import { ProjectItem } from 'pages/projects/types/projects.types';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { ReactComponent as InfoIcon } from 'assets/icons/info-circle-gray.svg';
import { CustomHeader } from 'components/ui/CustomHeader';
import { TFilters } from 'components/ui/columnOptions.types';
import { CLOSE_STATUS, PROJECT_STATUS } from '../consts';
import { StyledButton } from 'components/forms/Buttons';

export const projectTableColumns = (
  filters: TFilters,
  applyFilter: (data: Partial<TFilters>) => void,
  openClosedProjects: (data: Partial<TFilters>) => void,
  loading: boolean
) => {
  const columns: ColumnDef<ProjectItem>[] = [
    {
      id: 'Name',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Name"
          id="name"
          sortingFeature
          filterType="SEARCH"
        />
      ),
      cell: ({ row: { original } }) => (
        <span>{original?.job_title || '-'}</span>
      ),
    },
    {
      id: 'Client Name',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Client Name"
          id="client_name"
          sortingFeature
          filterType="SEARCH"
          minWidth="162px"
        />
      ),
      cell: ({ row: { original } }) => (
        <span>{original?.client_name || '-'}</span>
      ),
    },
    {
      id: 'Freelancer Name',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Freelancer Name"
          id="freelancer_name"
          sortingFeature
          filterType="SEARCH"
          minWidth="198px"
        />
      ),
      cell: ({ row: { original } }) => (
        <span>{original?.freelancer_name || '-'}</span>
      ),
    },
    {
      id: 'Status',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Status"
          id="status"
          sortingFeature
          filterType="FILTER"
          options={Object.values(PROJECT_STATUS)}
        />
      ),
      cell: ({ row: { original } }) => {
        let status = '';
        let color: TStatusBadgeColor = 'yellow';
        switch (original.status) {
          case 'active':
            status = PROJECT_STATUS.IN_PROGRESS;
            color = 'yellow';
            break;
          case 'closed':
            status = PROJECT_STATUS.CLOSED;
            color = 'green';
            break;
          case 'draft':
            status = PROJECT_STATUS.DRAFT;
            color = 'darkPink';
            break;
          case 'prospects':
            status = PROJECT_STATUS.POSTED;
            color = 'red';
            break;
          default:
            status = original.status;
        }
        return (
          <StatusBadge color={color} className="text-capitalize">
            {status ? status.replace(/_/g, ' ') : '-'}
          </StatusBadge>
        );
      },
    },
    {
      id: 'Posted Date',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Posted Date"
          id="posted_date"
          sortingFeature
          minWidth="164px"
          filterType="DATE_FILTER"
        />
      ),
      cell: ({ row: { original } }) => (
        <span className="w-max-content">
          {original?.date_created
            ? moment(original.date_created).format('MMM DD, YYYY')
            : '-'}{' '}
          {(original?.proposal_count || original?.proposal_count === 0) && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>Total Proposals: {original.proposal_count}</Tooltip>
              }
            >
              <span className="bg-warning rounded-circle px-2 py-1 fs-12">
                {original.proposal_count}
              </span>
            </OverlayTrigger>
          )}
        </span>
      ),
    },
    {
      id: 'Opened Date',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Opened Date"
          id="opened_date"
          sortingFeature
          minWidth="170px"
          filterType="DATE_FILTER"
        />
      ),
      cell: ({ row: { original } }) => {
        return (
          <span className="w-max-content">
            {original?.job_start_date
              ? moment(original.job_start_date).format('MMM DD, YYYY')
              : '-'}{' '}
            {(original?.milestone_count || original?.milestone_count === 0) && (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Total Milestones: {original.milestone_count}
                  </Tooltip>
                }
              >
                <span className="bg-warning rounded-circle px-2 py-1 fs-12">
                  {original.milestone_count}
                </span>
              </OverlayTrigger>
            )}
          </span>
        );
      },
    },
    {
      id: 'Updated at',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Updated at"
          id="updated_at"
          sortingFeature
          minWidth="156px"
          filterType="DATE_FILTER"
        />
      ),
      cell: ({ row: { original } }) => {
        const result = dateDifference(original.date_modified);
        if (result) {
          return (
            <span className="w-max-content">
              {result} ago{' '}
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    {moment(original.date_modified).format(
                      'MMM DD, YYYY HH:MM A'
                    )}
                  </Tooltip>
                }
              >
                <InfoIcon />
              </OverlayTrigger>
            </span>
          );
        }
        return <span>-</span>;
      },
    },
    {
      id: 'Budget',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Budget"
          id="budget"
        />
      ),
      cell: ({ row: { original } }) => {
        const renderItem = () => {
          // if project status is active or closed it means that proposal accepted and project started
          // so it need to take values from approved_budget
          if (['closed', 'active'].includes(original?.status)) {
            if (
              original?.approved_budget?.type &&
              (original?.approved_budget?.amount ||
                original?.approved_budget?.amount === 0)
            ) {
              const budgetOrHourly =
                original.approved_budget.type === 'fixed' ? '' : '/hr';
              return (
                <span>
                  {numberWithCommas(
                    Number(original.approved_budget.amount),
                    'USD'
                  )}
                  {budgetOrHourly}
                </span>
              );
            }
          }

          // if project status is propect(posted) or draft that means project is posted and
          // still need to approve a proposal so
          // if isProposal true then its open to proposal
          // budget type project can show fix amount and for hourly it need to use max_amount
          if (['prospects', 'draft'].includes(original?.status)) {
            if (original?.budget?.isProposal)
              return <span>Open to Proposal</span>;
            if (original?.budget?.type === 'fixed') {
              return (
                <span>
                  {numberWithCommas(Number(original.budget.amount), 'USD')}{' '}
                </span>
              );
            }
            if (original?.budget?.type === 'hourly') {
              return (
                <span>
                  {numberWithCommas(Number(original.budget.max_amount), 'USD')}
                  /hr
                </span>
              );
            }
          }
          return <span>-</span>;
        };
        return <div className="w-max-content">{renderItem()}</div>;
      },
    },
    {
      id: 'Paid',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Paid"
          id="paid"
          sortingFeature
        />
      ),
      cell: ({ row: { original } }) => {
        if (original?.paid_amount || original?.paid_amount === 0) {
          return (
            <span>{numberWithCommas(Number(original.paid_amount), 'USD')}</span>
          );
        }
        return <span>-</span>;
      },
    },
    {
      id: 'Closed Date',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Closed Date"
          id="closed_date"
          sortingFeature
          minWidth="164px"
          filterType="DATE_FILTER"
        />
      ),
      cell: ({ row: { original } }) => {
        if (original?.job_end_date) {
          return (
            <span>{moment(original.job_end_date).format('MMM DD, YYYY')}</span>
          );
        }
        return <span>-</span>;
      },
    },
    {
      id: 'Close Status',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Close Status"
          id="close_status"
          sortingFeature
          filterType="FILTER"
          options={Object.values(CLOSE_STATUS)}
          minWidth="166px"
        />
      ),
      cell: ({ row: { original } }) => {
        // job close status
        let status = '';
        let color: 'red' | 'green' = 'red';

        if (original.status === 'deleted') {
          status = 'Closed By Client';
        }
        if (original?.attributes?.isExpired) {
          status = 'Expired';
        }
        if (original.status === 'closed') {
          status =
            original.is_completed === 0
              ? CLOSE_STATUS.INCOMPLETE
              : CLOSE_STATUS.COMPLETED;
          if (original.is_completed === 1) color = 'green';
        }
        if (!status) return '-';
        return <StatusBadge color={color}>{status}</StatusBadge>;
      },
    },
    {
      id: 'Action',
      header: () => (
        <CustomHeader
          filters={filters}
          applyFilter={applyFilter}
          headerText="Actions"
          id="job_actions"
          minWidth="166px"
        />
      ),
      cell: ({ row: { original: job } }) => (
        <div onClick={(e) => e.stopPropagation()}>
          {job?.status === 'deleted' &&
          !job?.freelancer_name &&
          !job?.attributes?.isExpired ? (
            <StyledButton
              className="d-flex align-items-center justify-content-center gap-2"
              disabled={loading}
              onClick={() => {
                openClosedProjects(job);
              }}
            >
              {loading && <Spinner animation="border" size="sm" />}
              Reopen
            </StyledButton>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ];
  return columns;
};
