import { apiClient } from './index';

/*
 * This api is to get the freelancer's details
 */
export const getProjectDetails = (id: string, userId: string) => {
  return apiClient
    .get(`/job/get-detail/${id}?is_admin=true&user_id=${userId}`)
    .then((res) => {
      return res.data;
    });
};
