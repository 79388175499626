import ActiveJobs from './ActiveJobs';
import ClosedJobs from './ClosedJobs';

const CLIENT_JOBS_ROUTES = [
  {
    id: 'active',
    label: 'Jobs In Progress',
    component: ActiveJobs,
  },
  {
    id: 'closed',
    label: 'Closed Jobs',
    component: ClosedJobs,
  },
];

export default CLIENT_JOBS_ROUTES;
