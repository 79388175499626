import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackArrow } from 'assets/icons/back-arrow.svg';

type Props = {
  className?: string;
  propsElement?: React.ReactNode;
  onBack?: () => void;
};

const BackButton = ({ className, propsElement, onBack }: Props) => {
  const navigate = useNavigate();
  const goBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={`${className ? className : ''} d-flex`}>
      <div
        className="d-flex back-button d-flex align-items-center pointer flex-wrap"
        onClick={goBack}
      >
        <BackArrow /> &nbsp;<span className="fs-18 fw-400">Back</span>
        {propsElement && propsElement}
      </div>
    </div>
  );
};

export default BackButton;
