import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import Loader from 'components/Loader';
import NoDataFound from 'components/ui/NoDataFound';
import JobsListingItem from './partials/JobsListingItem';
import PaginationComponent from 'components/ui/Pagination';
import { JobItemType } from './types/clientJobs.types';
import useClientJobs from './hooks/useJobDetails';

const RECORDS_PER_PAGE = 10;

const ClientJobs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { clientId }: { clientId: string } = useOutletContext();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [query, setQuery] = useState<string>('');

  /* This will fetch the client jobs - at start, when page is changed, when filter is changed */
  const { jobData, totalResults, isLoading, isRefetching } = useClientJobs({
    query,
    limit: RECORDS_PER_PAGE,
    clientId: clientId,
    jobStatus: 'closed',
  });

  useEffect(() => {
    if (location.search) {
      const params: any = new URLSearchParams(location.search);
      const pg = params.get('pg') || 1;
      setQuery(`pg=${pg}`);
      setCurrentPage(parseInt(pg));
    }
  }, [location.search]);

  /** @function This will set next page as active and load new page data - Pagination is implemented locally  */
  const onPageChange = (page: { selected: number }) => {
    setCurrentPage(page?.selected + 1);
    const searchQuery = `pg=${page?.selected + 1}`;
    changeWindowLocation(searchQuery);
    setQuery(searchQuery);
  };

  /** @function This will take job id and take the user to job details page */
  const goToJobDetails = (id: string) => () => {
    navigate(`/clients/job-details/${id}/${clientId}`);
  };

  const changeWindowLocation = (path: string) => {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${path}`;

    window.history.pushState({ path: newurl }, '', newurl);
  };

  return (
    <>
      {/* Title */}

      {isLoading && <Loader />}
      {!isLoading && !isRefetching && jobData?.length == 0 && (
        <NoDataFound className="py-5" />
      )}

      {jobData?.length > 0 && (
        <div className="listings">
          {jobData?.map((item: JobItemType) => (
            <JobsListingItem
              jobItem={item}
              key={item?.job_post_id}
              goToJobDetails={goToJobDetails(item?.job_post_id)}
            />
          ))}
        </div>
      )}

      {jobData?.length > 0 && (
        <div className="d-flex justify-content-center mt-3 align-items-center">
          <PaginationComponent
            total={Math.ceil(totalResults / RECORDS_PER_PAGE)}
            onPageChange={onPageChange}
            currentPage={currentPage}
          />
        </div>
      )}
    </>
  );
};

export default ClientJobs;
