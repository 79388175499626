import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import NoDataFound from 'components/ui/NoDataFound';
import { numberWithCommas } from 'helpers/utils/misc';
import { ReactComponent as DollarCircleIcon } from 'assets/icons/dollar-circle.svg';

// This is the styled component for listing wrapper
const ListingWrapper = styled.div`
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 35px rgba(164, 122, 60, 0.07);
  border-radius: 0.75rem;
  min-height: 600px;
  .heading {
    color: ${(props) => props.theme.font.color.heading};
  }
  .listings {
    max-height: 500px;
    overflow-y: auto;
  }
  a {
    color: ${(props) => props.theme.colors.lightBlue};
  }
`;

const ListingItem = styled.div`
  border: ${(props) => `1px solid ${props.theme.colors.gray6}`};
  border-radius: 10px;
  word-break: break-word;
  .avatar {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    object-fit: cover;
  }
  .divider {
    height: 2rem;
    width: 1px;
    background-color: ${(props) => props.theme.colors.gray6};
  }
  .budget {
    background-color: ${(props) => props.theme.colors.body2};
    border-radius: 1rem;
    padding: 0.375rem 0.75rem;
  }
  .light-text {
    opacity: 0.7;
  }
`;

const Jobs = ({ data, clientId, clientName }: any) => {
  const navigate = useNavigate();

  const goToJobDetails = (jobId: string) => () => {
    navigate(`/clients/job-details/${jobId}/${clientId}`);
  };
  return (
    <ListingWrapper className="p-4">
      {/* Heading  */}
      <div className="d-flex align-items-center justify-content-between">
        <div className="heading fs-24 fw-700 mb-2">Jobs in progress</div>
        <Link to={`/client-jobs/${clientId}/${clientName}`}>View all jobs</Link>
      </div>

      {data?.length > 0 ? (
        <div className="listings">
          {data?.map((item: any) => (
            <ListingItem
              key={item?.job_post_id}
              className="mt-3 p-3 justify-content-between pointer"
              onClick={goToJobDetails(item.job_post_id)}
            >
              <div className="fs-18 fw-400">{item?.job_title}</div>

              <div className="d-flex align-items-center gap-4 flex-wrap details mt-2">
                {/* Freelancer profile and name */}
                <div className="d-flex align-items-center gap-2">
                  <img src={item?.userdata?.user_image} className="avatar" />
                  <div>
                    <div className="light-text fs-sm fw-400">Freelancer:</div>
                    <div className="fs-1rem fw-400">
                      {item?.userdata?.first_name} {item?.userdata?.last_name}
                    </div>
                  </div>
                </div>

                <div className="divider" />

                <div className="d-flex align-items-center gap-1 fs-1rem fw-400 budget">
                  <DollarCircleIcon />
                  {item?.proposal?.approved_budget?.amount ? (
                    <span>
                      {numberWithCommas(
                        Number(item?.proposal?.approved_budget?.amount),
                        'USD'
                      )}
                      <span className="light-text">
                        {item?.proposal?.approved_budget?.type === 'hourly'
                          ? '/hr'
                          : ' Total cost'}
                      </span>
                    </span>
                  ) : null}
                </div>
              </div>
            </ListingItem>
          ))}
        </div>
      ) : (
        <div className="py-5">
          <NoDataFound />
        </div>
      )}
    </ListingWrapper>
  );
};

export default Jobs;
