import styled from 'styled-components';

export const Wrapper = styled.section`
  .job-end-details {
    transition: all 0.2s ease-in-out;
    color: ${(props) => props.theme.colors.lightBlue};
    &:hover {
      transform: translateY(-2px);
    }
  }
`;
