import React, { useState } from 'react';
import { Form, Table, Modal } from 'react-bootstrap';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-hot-toast';
import {
  getAdminCountries,
  addCountryApi,
  editCountryApi,
} from 'helpers/http/common';
import styled from 'styled-components';
import { StyledButton } from 'components/forms/Buttons';

const StyledCard = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  box-shadow: 0px 4px 1rem rgba(0, 0, 0, 0.04);
  overflow: hidden;
`;

const StyledHeader = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray6};
`;

const StyledBody = styled.div`
  padding: 1.5rem;
`;

interface Country {
  country_id: number;
  country_name: string;
  country_code: string;
  country_short_name: string;
  currency?: string;
  is_deleted?: number;
  date_created?: string;
  date_modified?: string;
}

interface FormData {
  country_name: string;
  country_code: string;
  country_short_name: string;
  currency?: string;
}

interface CountryCardProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onCloseHandler: (flag: string) => void;
}

const CountryCard: React.FC<CountryCardProps> = ({
  showModal,
  setShowModal,
  onCloseHandler,
}) => {
  const [search, setSearch] = useState('');
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [formData, setFormData] = useState<FormData>({
    country_name: '',
    country_code: '',
    country_short_name: '',
    currency: '',
  });

  const queryClient = useQueryClient();

  const { data: countries, isLoading: isLoadingCountries } = useQuery(
    ['countries', search],
    () => getAdminCountries(search),
    {
      select: (response) => (response.data as Country[]) || [],
      keepPreviousData: true,
    }
  );

  const addCountryMutation = useMutation(addCountryApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(['countries']);
      toast.success('Country added successfully');
      onCloseHandler('country');
    },
    onError: (error: any) => {
      toast.error(error.message || 'Failed to add country');
    },
  });

  const editCountryMutation = useMutation(editCountryApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(['countries']);
      toast.success('Country updated successfully');
      onCloseHandler('country');
    },
    onError: (error: any) => {
      toast.error(error.message || 'Failed to update country');
    },
  });

  const handleAdd = () => {
    setSelectedCountry(null);
    setFormData({
      country_name: '',
      country_code: '',
      country_short_name: '',
      currency: '',
    });
    setShowModal(true);
  };

  const handleEdit = (country: Country) => {
    setSelectedCountry(country);
    setFormData({
      country_name: country.country_name,
      country_code: country.country_code,
      country_short_name: country.country_short_name,
      currency: country.currency || '',
    });
    setShowModal(true);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedCountry) {
      editCountryMutation.mutate({
        ...formData,
        country_id: selectedCountry.country_id,
      });
    } else {
      addCountryMutation.mutate(formData);
    }
  };

  const isLoading =
    isLoadingCountries ||
    addCountryMutation.isLoading ||
    editCountryMutation.isLoading;

  return (
    <StyledCard>
      <StyledHeader className="d-flex justify-content-between align-items-center">
        <h5 className="fs-20 fw-600 mb-0">Countries Management</h5>
      </StyledHeader>
      <StyledBody>
        <Form.Group className="mb-4">
          <Form.Control
            type="text"
            placeholder="Search countries..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="fs-16"
          />
        </Form.Group>

        {isLoading ? (
          <div className="text-center">Loading...</div>
        ) : (
          <Table responsive hover className="fs-14">
            <thead>
              <tr>
                <th>Country Name</th>
                <th>Country Code</th>
                <th>Short Name</th>
                <th>Currency</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {countries?.map((country) => (
                <tr key={country.country_id}>
                  <td>{country.country_name}</td>
                  <td>{country.country_code}</td>
                  <td>{country.country_short_name}</td>
                  <td>{country.currency}</td>
                  <td>
                    <StyledButton
                      onClick={() => handleEdit(country)}
                      className="text-primary"
                      variant="link"
                    >
                      Edit
                    </StyledButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </StyledBody>

      <Modal show={showModal} onHide={() => onCloseHandler('country')} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fs-20 fw-600">
            {selectedCountry ? 'Edit Country' : 'Add Country'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className="fs-14">Country Name</Form.Label>
              <Form.Control
                type="text"
                value={formData.country_name}
                onChange={(e) =>
                  setFormData({ ...formData, country_name: e.target.value })
                }
                required
                className="fs-16"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="fs-14">Country Code</Form.Label>
              <Form.Control
                type="text"
                value={formData.country_code}
                onChange={(e) =>
                  setFormData({ ...formData, country_code: e.target.value })
                }
                required
                className="fs-16"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="fs-14">Short Name</Form.Label>
              <Form.Control
                type="text"
                value={formData.country_short_name}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    country_short_name: e.target.value,
                  })
                }
                required
                className="fs-16"
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="fs-14">Currency</Form.Label>
              <Form.Control
                type="text"
                value={formData.currency}
                onChange={(e) =>
                  setFormData({ ...formData, currency: e.target.value })
                }
                className="fs-16"
              />
            </Form.Group>
            <div className="d-flex justify-content-end gap-2">
              <StyledButton
                variant="outline"
                onClick={() => onCloseHandler('country')}
              >
                Cancel
              </StyledButton>
              <StyledButton
                type="submit"
                disabled={isLoading}
                className="text-white"
              >
                {isLoading ? 'Saving...' : selectedCountry ? 'Update' : 'Add'}
              </StyledButton>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </StyledCard>
  );
};

export default CountryCard;
