import { useQuery } from 'react-query';
import { getJobDetails } from 'helpers/http/freelancer';

/*
 * This hook will fetch the freelancer details
 */
function useJobDetails(jobId?: string, freelancerId?: string) {
  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['get-job-details', jobId, freelancerId],
    () => getJobDetails(jobId, freelancerId),
    { enabled: !!jobId }
  );
  return {
    jobData: data?.data || null,
    isLoading,
    refetch,
    isRefetching,
  };
}

export default useJobDetails;
