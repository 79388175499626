import React from 'react';

import {
  Button,
  Image,
  NavLink,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from 'helpers/contexts/auth-context';
import logo from 'assets/icons/logo.svg';
import { ReactComponent as Dashboard } from 'assets/icons/dashboard.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as FreelancersIcon } from 'assets/icons/freelancers.svg';
import { ReactComponent as ClientsIcon } from 'assets/icons/clients.svg';
import { ReactComponent as SupportIcon } from 'assets/icons/support.svg';
import { ReactComponent as TransactionsIcon } from 'assets/icons/transactions.svg';
import { ReactComponent as MessagesIcon } from 'assets/icons/message.svg';
import { ReactComponent as EmailsIcon } from 'assets/icons/message.svg';
import packageJson from '../../../package.json';
import { AiOutlineAppstore } from 'react-icons/ai';
import { GoProjectRoadmap } from 'react-icons/go';
import { IoAnalytics } from 'react-icons/io5';
import { IoSettingsOutline } from 'react-icons/io5';

const SidebarWrapper = styled.div`
  .minimizeSidebar {
    width: var(--minimizeNavWidth) !important;
    .toggle {
      transform: rotate(180deg);
      background-color: transparent !important;
    }
  }
  .sidenav {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.font.color.heading};
    transition: all 0.2s ease;
    width: 250px;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: scroll;
  }

  .sidenavInner {
    padding-left: 0;
    list-style: none;
    flex: 1;
    li > a {
      display: flex;
      color: inherit;
      align-items: center;
      text-decoration: none;
      padding: 0 1.25rem;
      height: 60px;
      border-radius: 10px;
      margin-bottom: 0.5rem;
      opacity: 0.8;
      border: ${(props) => `1px solid ${props.theme.colors.white}`};
      svg {
        margin-right: 0.8rem;
      }

      &.active,
      &:hover {
        background-color: rgba(254, 251, 244, 1);
        color: ${(props) => props.theme.font.color.heading};
        border: 1px solid rgba(242, 180, 32, 0.17);
        opacity: 1;
      }
      &.minimized {
        svg {
          margin-right: 0;
        }
      }
      .icon {
        margin-right: 10px;
      }
    }
  }
  .logout {
    background: rgba(29, 30, 27, 0.04);
    border: ${(props) => `1px solid ${props.theme.colors.gray6}`};
    padding: 1rem 1.25rem;
    border-radius: 0.5rem;
  }
`;

const LINKS = [
  {
    title: 'Dashboard',
    toLink: '/dashboard',
    icon: <Dashboard />,
  },
  {
    title: 'Freelancers',
    toLink: '/freelancers',
    icon: <FreelancersIcon />,
    subLinks: ['freelancer-profile', 'freelancers'],
  },
  {
    title: 'Clients',
    toLink: '/clients',
    icon: <ClientsIcon />,
    subLinks: ['client-profile', 'client-jobs', 'clients'],
  },
  {
    title: 'Projects',
    toLink: '/projects',
    icon: <GoProjectRoadmap size={26} />,
    subLinks: ['projects', 'project-details'],
  },
  {
    title: 'Messages',
    toLink: '/messages',
    icon: <MessagesIcon />,
  },
  {
    title: 'Transactions',
    toLink: '/transactions',
    icon: <TransactionsIcon />,
  },
  {
    title: 'Categories',
    toLink: '/categories',
    icon: <AiOutlineAppstore />,
  },
  {
    title: 'Settings',
    toLink: '/settings',
    icon: <IoSettingsOutline />,
  },
  {
    title: 'UTM Info',
    toLink: '/utm-info',
    icon: <IoAnalytics />,
  },
  {
    title: 'Support',
    toLink: '/support',
    icon: <SupportIcon />,
  },
  {
    title: 'Email Log',
    toLink: '/email-log',
    icon: <EmailsIcon />,
  },
  {
    title: 'System Log',
    toLink: '/system-log',
    icon: <FreelancersIcon />,
  },
];

const getActive = (path: string, link: (typeof LINKS)[0]) => {
  if (path.includes(link.toLink)) {
    return true;
  }
  if (link.subLinks) {
    return link.subLinks.find((item) => path.includes(item)) !== undefined;
  }
  return false;
};

function SideBar({ show }: any) {
  const { signout, user } = useAuth();
  return (
    <SidebarWrapper>
      <aside
        className={'sidenav  p-3 pb-0'}
        style={{
          width: show ? '250px' : '76px',
        }}
      >
        <div className="app-brand d-flex justify-content-between align-items-center mb-4 mt-1">
          <NavLink href="/dashboard">
            <Image src={logo} width="48px" fluid />
          </NavLink>
        </div>

        <ul className={'sidenavInner'}>
          {LINKS.map((alink) => (
            <CustomNavLink
              {...alink}
              key={alink.title}
              isMinimized={!show}
              active={getActive(window.location.pathname, alink)}
            />
          ))}
        </ul>

        <div
          className={`${'footer'} d-flex align-items-center justify-content-between logout`}
        >
          {show && (
            <div className="d-flex align-items-center">
              <div>
                <Image
                  src="/images/default_avatar.png"
                  width="44px"
                  roundedCircle={true}
                />
              </div>
              <div className="flex-1 ms-3">
                <h6 className="mb-0">
                  {user?.first_name} {user?.last_name}
                </h6>
              </div>
            </div>
          )}

          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-logout`}>Log out</Tooltip>}
          >
            <Button title="Logout" variant="link" onClick={signout}>
              <Logout stroke="white" />
            </Button>
          </OverlayTrigger>
        </div>
        <div className="mt-2 text-center">Version: {packageJson.version}</div>
      </aside>
    </SidebarWrapper>
  );
}

export default SideBar;

function CustomNavLink(props: {
  title: string;
  icon?: React.ReactNode;
  active: boolean;
  isMinimized: boolean;
  toLink: string;
  disable?: boolean;
}) {
  return (
    <li key={props.title}>
      <NavLink
        disabled={props.disable}
        as={Link}
        to={props.toLink}
        className={`${'link fw-400 fs-18'} ${
          props.active && !props.disable ? 'active' : ''
        } ${props.isMinimized ? 'minimized' : ''}`}
      >
        <>
          {props.icon}
          {!props.isMinimized && <span>{props.title}</span>}
        </>
      </NavLink>
    </li>
  );
}
