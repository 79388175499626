import { Routes, Route, Navigate } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import { useAuth } from 'helpers/contexts/auth-context';
import PageLayout from 'components/Layout/PageLayout';
import SuspenseWrapper from './SuspenseWrapper';

/* Routes */
import Login from 'pages/login';
import Dashboard from 'pages/dashboard';
import Freelancers from 'pages/freelancers';
import FreelancerProfile from 'pages/freelancer-profile';
import JobDetails from 'pages/job-details';
import Clients from 'pages/clients';
import ClientProfile from 'pages/client-profile';
import ClientJobs from 'pages/client-jobs';
import Messages from 'pages/messages';
import Transactions from 'pages/transactions';
import Support from 'pages/support';

import Page404 from 'components/Page404';
import FREELANCER_PROFILE_ROUTES from 'pages/freelancer-profile/FREELANCER_PROFILE_ROUTES';
import CLIENT_JOBS_ROUTES from 'pages/client-jobs/CLIENT_JOBS_ROUTES';
import SendGridLogs from 'pages/mails/SendGridLogs';
import SystemLog from 'pages/logs/SystemLog';
import Categories from 'pages/categories';
import Projects from 'pages/projects';
import ProjectDetails from 'pages/project-details';
import UTMInfo from 'pages/utm-info';
import Settings from 'pages/settings';

const authRoutes = [
  {
    path: '/login',
    component: <Login />,
  },
  {
    path: '/login',
    component: <Login />,
  },
];

const privateRoutes = [
  {
    path: '/dashboard',
    component: <Dashboard />,
  },
  {
    path: '/freelancers',
    component: <Freelancers />,
  },
  {
    path: ':userType/job-details/:jobId/:id',
    component: <JobDetails />,
  },
  {
    path: '/clients',
    component: <Clients />,
  },
  {
    path: '/client-profile/:id',
    component: <ClientProfile />,
  },
  {
    path: '/email-log',
    component: <SendGridLogs />,
  },
  {
    path: '/messages',
    component: <Messages />,
  },
  {
    path: '/categories',
    component: <Categories />,
  },
  {
    path: '/transactions',
    component: <Transactions />,
  },
  {
    path: '/support',
    component: <Support />,
  },
  {
    path: '/system-log',
    component: <SystemLog />,
  },
  {
    path: '/settings',
    component: <Settings />,
  },
  {
    path: '/projects',
    component: <Projects />,
  },
  {
    path: '/project-details/:id/:userId',
    component: <ProjectDetails />,
  },
  {
    path: '/utm-info',
    component: <UTMInfo />,
  },
];

function AppRoutes() {
  return (
    <Routes>
      <Route element={<PageLayout />}>
        {authRoutes.map(({ path, component }) => (
          <Route
            key={path}
            path={path}
            element={<SuspenseWrapper>{component}</SuspenseWrapper>}
          />
        ))}
        <Route
          path={'/404'}
          element={
            <SuspenseWrapper>
              <Page404 />
            </SuspenseWrapper>
          }
        />
        {privateRoutes.map(({ path, component }) => (
          <Route
            key={path}
            path={path}
            element={
              <RequireAuth>
                <SuspenseWrapper>{component}</SuspenseWrapper>
              </RequireAuth>
            }
          />
        ))}

        <Route path="/freelancer-profile/:id" element={<FreelancerProfile />}>
          {FREELANCER_PROFILE_ROUTES.map((route) => {
            const Component = route.component;
            return (
              <Route key={route.id} path={route.id} element={<Component />} />
            );
          })}
          <Route
            index
            element={<Navigate replace to={FREELANCER_PROFILE_ROUTES[0].id} />}
          />
        </Route>

        <Route
          path="/client-jobs/:clientId/:clientName"
          element={<ClientJobs />}
        >
          {CLIENT_JOBS_ROUTES.map((route) => {
            const Component = route.component;
            return (
              <Route key={route.id} path={route.id} element={<Component />} />
            );
          })}
          <Route
            index
            element={<Navigate replace to={CLIENT_JOBS_ROUTES[0].id} />}
          />
        </Route>
      </Route>

      <Route path="/" element={<DefaultRedirect />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
}

export default AppRoutes;

const DefaultRedirect = () => {
  const auth = useAuth();
  return <Navigate to={auth.user ? 'dashboard' : 'login'} />;
};
