import styled from 'styled-components';
import { transition } from 'styles/transitions';

// This is the main Wrapper for Dashboard
export const DashboardWrapper = styled.div``;

// This is the styled component for listing wrapper
export const ListingWrapper = styled.div`
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 35px rgba(164, 122, 60, 0.07);
  border-radius: 0.75rem;
  .heading {
    color: ${(props) => props.theme.font.color.heading};
    border-bottom: ${(props) => `1px solid ${props.theme.colors.gray6}`};
  }
  .listings {
    height: 70vh; // TODO: Mudit is this good, as per yogesh, it should cover the available space of the screen
    overflow-y: auto;
  }
`;

// This is the styled component for listing item
export const NewRegistrationItem = styled.div`
  border: ${(props) => `1px solid ${props.theme.colors.gray6}`};
  border-radius: 10px;
  .avatar {
    height: 68px;
    width: 68px;
    border-radius: 50%;
    object-fit: cover;
  }
  .designation {
    opacity: 0.7;
  }
  ${() => transition()}

  .approve-reject-btn {
    background: rgba(217, 217, 217, 0.18);
    border-radius: 0.5rem;
    width: 40px;
    height: 40px;
  }
`;
