import moment from 'moment';

export const dateDifference = (pastDate, futureDate = new Date()) => {
  const modifiedDate = moment.utc(pastDate);
  const currentDate = moment.utc(futureDate);
  if (modifiedDate.isSame(currentDate, 'day')) {
    const hours = currentDate.diff(modifiedDate, 'hours');
    modifiedDate.add(hours, 'hours');

    const minutes = currentDate.diff(modifiedDate, 'minutes');
    modifiedDate.add(minutes, 'minutes');

    let result = '';
    if (hours) {
      result = result.concat(`${hours} hour${hours > 1 ? 's' : ''} `);
    }
    if (minutes) {
      result = result.concat(`${minutes} minute${minutes > 1 ? 's' : ''} `);
    }
    return result;
  }

  const years = currentDate.diff(modifiedDate, 'year');
  modifiedDate.add(years, 'years');

  const months = currentDate.diff(modifiedDate, 'months');
  modifiedDate.add(months, 'months');

  const days = currentDate.diff(modifiedDate, 'days');

  let result = '';
  if (years) {
    result = result.concat(`${years} year${years > 1 ? 's' : ''} `);
  }
  if (months) {
    result = result.concat(`${months} month${months > 1 ? 's' : ''} `);
  }
  if (days) {
    result = result.concat(`${days} day${days > 1 ? 's' : ''} `);
  }
  return result;
};
