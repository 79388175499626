import styled from 'styled-components';

export const DisputeWrapper = styled.div`
  gap: 2rem;
  .light-black {
    color: ${(props) => props.theme.font.color.heading};
  }
  .light-text {
    opacity: 0.7;
  }
  .heading-title {
    max-width: 80%;
  }
  .support-request--by {
    background-color: ${(props) => props.theme.colors.body2};
    border-radius: 9px;
  }
  .project-owner-details {
    background-color: ${(props) => props.theme.colors.whiteSmoke};
    border-radius: 9px;
  }
  .support-description::first-letter {
    text-transform: capitalize;
  }
  .support-request--avatar {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    object-fit: cover;
  }
  .support-attachment--link {
    background-color: #f8f8f8;
    border: 1px solid ${(props) => props.theme.colors.gray8};
    padding: 0.75rem 1.25rem;
    border-radius: 0.5rem;
    gap: 10px;
  }
  .divider {
    width: 1px;
    background: ${(props) => props.theme.colors.gray8};
    height: 1.5rem;
  }
`;
