/*
 * This is the main compoent of this route
 */
import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Spinner } from 'react-bootstrap';
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Wrapper } from './freelancer-profile.styled';
import Loader from 'components/Loader';
import Tabs from 'components/ui/Tabs';
import BackButton from 'components/ui/BackButton';
import DeleteAccountPrompt from 'components/profile/DeleteAccountPrompt';
import ProfileBanner from './partials/ProfileBanner';
import { deleteUser } from 'helpers/http/common';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';
import useFreelancerProfile from './hooks/useFreelancerProfile';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import VerificationSection from './VerificationSection';

const ACCOUNT_UNAPPROVED_TABS = [
  {
    id: 0,
    key: 'details',
    label: 'General details',
  },
  {
    id: 2,
    key: 'admin-notes',
    label: 'Admin Notes',
  },
];

const ACCOUNT_APPROVED_TABS = [
  {
    id: 0,
    key: 'details',
    label: 'General details',
  },
  {
    id: 1,
    key: 'active-jobs',
    label: 'Jobs working on',
  },
  {
    id: 2,
    key: 'closed-jobs',
    label: 'Closed jobs',
  },
  {
    id: 3,
    key: 'message-history',
    label: 'Messages',
  },
  {
    id: 4,
    key: 'admin-notes',
    label: 'Admin Notes',
  },
];

const FreelancerProfile = () => {
  useStartPageFromTop();

  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams<{ id: string }>();

  const [searchParams] = useSearchParams();
  const isRegRequest = searchParams.get('reg_request');

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('details');

  useEffect(() => {
    const subUrl = location.pathname.split(`/freelancer-profile/${id}/`)[1];
    if (subUrl) {
      setActiveTab(subUrl);
    }
  }, [id, location.pathname]);

  /* This will fetch the freelancer profile details */
  const { profileData, isLoading, refetch, isRefetching } =
    useFreelancerProfile(id);

  /** @function This function will take the user one step back */
  const goBack = useCallback(() => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  }, [navigate]);

  const onTabChange = (value: string) => {
    /* This function will make the selected tab as active and change the below content */
    setActiveTab(value);
    navigate(`/freelancer-profile/${id}/` + value, { replace: true });
  };

  /* This will open/close the ratings (reviews) modal */
  const toggleDeleteAccountPrompt = () => {
    setShowDeletePrompt((prev) => !prev);
  };

  const deleteAccount = () => {
    /* This function will delete the user account */

    /* If deleting is not already started (!loading) */
    if (id && !isDeleting) {
      setIsDeleting(true);

      /* Delete account api call */
      const promise = deleteUser(id);

      toast.promise(promise, {
        loading: 'Loading...',
        success: (res) => {
          /* Once account is deleted, it will take the user one step back  */
          setIsDeleting(false);
          toggleDeleteAccountPrompt();
          goBack();
          return res.message;
        },
        error: (err) => {
          setIsDeleting(false);
          toggleDeleteAccountPrompt();
          return err?.response?.data?.message || 'error';
        },
      });
    }
  };

  const tabs = useMemo(() => {
    let temp: (typeof ACCOUNT_APPROVED_TABS)[0][] = [];
    if (profileData?.is_account_approved) temp = ACCOUNT_APPROVED_TABS;
    else temp = ACCOUNT_UNAPPROVED_TABS;

    if (temp.findIndex((x) => x.key === 'utm_info') >= 0) return temp;

    if (Object.keys(profileData?.utm_info || {}).length > 0) {
      temp = [...temp, { id: 10, key: 'utm-info', label: 'UTM Info' }];
    }
    return temp;
  }, [profileData?.is_account_approved, profileData?.utm_info]);

  return (
    <>
      <Wrapper className="my-3">
        {/* Back button header with delete account option */}

        <div className="d-flex align-items-center justify-content-between pointer flex-wrap gap-2">
          <BackButton
            propsElement={
              <div className="d-flex align-items-center">
                {profileData && (
                  <span className="fs-18 fw-400">
                    &nbsp; | &nbsp;
                    {isRegRequest
                      ? 'Dashboard / Registration request'
                      : `Freelancers / ${profileData?.first_name} ${profileData?.last_name}`}
                  </span>
                )}
                {isRefetching && (
                  <Spinner animation="border" size="sm" className="ms-2" />
                )}
              </div>
            }
          />

          {/*
           * Delete button
           * If account is approved then the admin will be able to see "Delete account" button
           */}
          {profileData &&
          profileData?.is_account_approved &&
          !profileData?.is_deleted ? (
            <div
              className={`d-flex align-items-center ${
                isDeleting ? 'opacity-4' : ''
              }`}
              onClick={toggleDeleteAccountPrompt}
            >
              <TrashIcon className="mx-2" />
              <div className="fs-1rem fw-400">Delete account</div>
            </div>
          ) : null}
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          profileData && (
            <>
              <ProfileBanner data={profileData} refetch={refetch} />
              <VerificationSection profileData={profileData} />

              {/* If the user's account is approved then only the tabs will be displayed */}
              <div className="my-4">
                <Tabs
                  activeTab={activeTab}
                  tabs={tabs}
                  onTabChange={onTabChange}
                />
              </div>

              {/* Send reminder to freelancer to complete details */}
              {/* Hidden as per simcha request */}
              {/* {!profileData?.is_profile_completed && (
                <div className="d-flex justify-content-center justify-content-lg-end mt-4">
                  <StyledButton
                    disabled={sendingRemider}
                    onClick={onSendReminder}
                  >
                    Send reminder to fill the details
                  </StyledButton>
                </div>
              )} */}
            </>
          )
        )}
        {profileData && (
          <Outlet
            context={{ profileData, freelancerId: id, refetch, isRefetching }}
          />
        )}

        <DeleteAccountPrompt
          show={showDeletePrompt}
          toggle={toggleDeleteAccountPrompt}
          onConfirm={deleteAccount}
          userName={`${profileData?.first_name} ${profileData?.last_name}`}
          loading={isDeleting}
        />
      </Wrapper>
    </>
  );
};

export default FreelancerProfile;
