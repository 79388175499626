import cns from 'classnames';
import styled from 'styled-components';
import useResponsive from 'helpers/hooks/useResponsive';

const Wrapper = styled.div`
  position: relative;
  max-width: 100%;
  .tab-container {
    display: flex;
    justify-content: space-around;
    gap: 0.25rem;
    border: 1px solid ${({ theme }) => theme.colors.gray6};
    border-radius: 50px;
    gap: 4px;
    padding: 4px;
  }
  .tab {
    padding: 0 1.75rem;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    transition: all 0.5s;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.gray3};
    &:hover {
      color: inherit;
      background-color: ${(props) => props.theme.colors.white};
    }
    &.active {
      color: ${(props) => props.theme.colors.black};
      background-color: ${(props) => props.theme.colors.white};
      box-shadow: 0px 4px 1rem rgba(0, 0, 0, 0.04);
    }
  }
  .horizontal-tabs {
    display: flex;
  }
  @media (max-width: 768px) {
    .horizontal-tabs {
      max-width: 100%;
      overflow-x: auto;
      ::-webkit-scrollbar {
        height: 0px;
      }
    }
    .overlay {
      position: absolute;
      width: 80px;
      height: 100%;
      right: 0;
      top: 0;
      transform: translateX(0.85rem);
      background: linear-gradient(
        270deg,
        #fefbf4 0%,
        #fefbf4 20%,
        transparent 100%
      );
    }
  }
`;

const Tabs = ({
  tabs,
  activeTab,
  onTabChange,
  className,
  counts,
}: {
  tabs: {
    label: string;
    id: number;
  }[];
  activeTab: string;
  onTabChange: (id: string) => void;
  className?: string;
  counts?: { [key: string]: number };
}) => {
  const { isMobile } = useResponsive();
  const onChange = (id: string) => () => onTabChange(id);
  return (
    <Wrapper className={className}>
      <div className="horizontal-tabs">
        <div className="tab-container">
          {tabs?.map((item: any) => (
            <a
              key={item.id}
              className={cns('tab fs-16 fw-400 pointer', {
                active: activeTab === item.key,
              })}
              onClick={onChange(item.key)}
            >
              {item.label}
              {item?.showCount && counts && ` (${counts[item?.key] || 0})`}
            </a>
          ))}
        </div>
      </div>
      {isMobile && <div className="overlay" />}
    </Wrapper>
  );
};

export default Tabs;
