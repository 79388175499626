import { useQuery } from 'react-query';
import { getDashboardSupportRequests } from 'helpers/http/dashboard';

/* This hook will fetch support requests */
function useSupportRequests(activeFilter: string) {
  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['get-dashboard-support-requests', activeFilter],
    () =>
      getDashboardSupportRequests({
        page: 1,
        limit: 100,
        activeFilter,
        statusFilter: 'active',
      }),
    { keepPreviousData: true }
  );
  return {
    data: data?.data || [],
    isLoading,
    isRefetching,
    refetch,
  };
}

export default useSupportRequests;
