import { useMediaQuery } from 'react-responsive';
function useResponsive() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  const isIpadPro = useMediaQuery({ minWidth: 991, maxWidth: 1250 });
  const isLaptop = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  return {
    isDesktop,
    isTablet,
    isMobile,
    isNotMobile,
    isIpadPro,
    isLaptop,
  };
}

export const breakpoints = {
  mobile: 'screen and (max-width: 767px)',
};

export default useResponsive;
