import moment from 'moment';
import { MilestonesWrapper, MileStoneListItem } from './milestones.styled';
import { StatusBadge } from 'components/styled/Badges';
import NoDataFound from 'components/ui/NoDataFound';
import AttachmentPreview from 'components/ui/AttachmentPreview';
import {
  convertToTitleCase,
  formatLocalDate,
  numberWithCommas,
  paymentProcessingStatusHandler,
} from 'helpers/utils/misc';
import StyledHtmlText from 'components/ui/StyledHtmlText';

const PAYMENT_STATUS = {
  pending: {
    color: 'yellow',
    label: 'Milestone Proposal Pending',
  },
  released: {
    color: 'gray',
    label: 'Paid',
  },
  paid: {
    color: 'green',
    label: 'Milestone Accepted',
  },
  under_dispute: {
    color: 'darkPink',
    label: 'Under Dispute',
  },
  waiting_for_release: {
    color: 'yellow',
    label: 'Waiting for Approval',
  },
  decline: {
    color: 'darkPink',
    label: 'Milestone Terminated',
  },
  declined: {
    color: 'darkPink',
    label: 'Milestone Terminated',
  },
  payment_processing: {
    color: 'yellow',
    label: 'Payment Processing',
  },
  cancelled: {
    color: 'darkPink',
    label: 'Canceled by Freelancer',
  },
  request_revision: {
    color: 'yellow',
    label: 'Revisions Requested',
  },
  decline_dispute: {
    color: 'darkPink',
    label: '',
  },
};

const Milestones = ({
  milestone,
  jobstatus,
  isRefetching,
}: {
  milestone: any;
  jobstatus?: string;
  isRefetching: boolean;
}) => {
  const getDateLabel = (milestoneStatus: string) => {
    let label = '';
    switch (milestoneStatus) {
      case 'released':
        label = 'Paid on';
        break;
      case 'paid':
        label = 'Payment Deposited on';
        break;
      case 'request_revision':
        label = 'Requested Revision on';
        break;
    }
    return label;
  };

  const getDate = (item) => {
    let date = '';
    const milestoneStatus = item.status;
    switch (milestoneStatus) {
      case 'paid':
        date = moment(item?.paid_date).format('MMM DD, YYYY');
        break;
      case 'released':
        date = moment(item?.released_date).format('MMM DD, YYYY');
        break;
      case 'request_revision':
        date = moment(item?.revision_date).format('MMM DD, YYYY');
        break;
    }
    return date;
  };

  return (
    <MilestonesWrapper>
      {milestone?.length == 0 && <NoDataFound className="pb-5" />}

      {milestone?.length > 0 &&
        !isRefetching &&
        milestone?.map((item: any, index: number) => (
          <MileStoneListItem
            key={`item?.milestone_id` + index}
            className="d-flex flex-column"
          >
            <div className="d-flex flex-md-row flex-column justify-content-between gap-md-3 gap-4">
              <div>
                <div className="fs-20 fw-400 capital-first-ltr">
                  {convertToTitleCase(item.title)}
                </div>
                <div className="fs-32 fw-400 line-height-100-perc mt-3">
                  {numberWithCommas(item.amount, 'USD')}
                </div>
              </div>
              <div className="status">
                <div className="d-flex flex-column align-items-md-end">
                  <>
                    <StatusBadge
                      color={PAYMENT_STATUS[item?.status]?.color || 'green'}
                    >
                      {['decline_dispute'].includes(item.status) &&
                      item?.dispute_submitted_by === 'CLIENT'
                        ? 'Closed by Client'
                        : ['decline_dispute'].includes(item.status) &&
                          item?.dispute_submitted_by === 'FREELANCER'
                        ? 'Canceled'
                        : jobstatus !== 'active' && item.status === 'pending'
                        ? 'Milestone Never Accepted'
                        : item?.status === 'payment_processing'
                        ? paymentProcessingStatusHandler(item?.payment_method)
                        : PAYMENT_STATUS[item?.status]?.label}
                    </StatusBadge>
                  </>
                  {!!item?.date_created && (
                    <div className="fs-18 fw-400 mt-3">
                      Submitted on
                      {item?.date_created
                        ? ' ' +
                          moment(item?.date_created).format('MMM DD, YYYY')
                        : ' -'}
                    </div>
                  )}
                  {!!item.cancelled_date && (
                    <div className="fs-18 fw-400">
                      Closed on{' '}
                      {item.cancelled_date
                        ? moment(item.cancelled_date).format('MMM DD, YYYY')
                        : ''}
                    </div>
                  )}
                  {item?.status !== 'pending' && (
                    <div className="fs-18 fw-400">
                      {getDateLabel(item.status)} {getDate(item)}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex mt-md-1 flex-md-row flex-column justify-content-between align-items-md-end gap-3">
              <div>
                <StyledHtmlText
                  needToBeShorten
                  htmlString={item.description}
                  id={`mstone_${item.milestone_id}`}
                />
                {item.due_date && (
                  <div className="mt-1">
                    {'Milestone Submission Scheduled: ' +
                      formatLocalDate(item.due_date, 'MMM DD, YYYY')}
                  </div>
                )}
              </div>
            </div>
            {item?.attachments && (
              <div className="d-flex align-items-center gap-4 flex-wrap mt-3">
                {item?.attachments
                  .split(',')
                  .map((attachment, index: number) => (
                    <div key={`milestone-${index}`}>
                      <AttachmentPreview
                        uploadedFile={attachment}
                        removable={false}
                      />
                    </div>
                  ))}
              </div>
            )}
          </MileStoneListItem>
        ))}
    </MilestonesWrapper>
  );
};

export default Milestones;
